import queryString from 'query-string';
import React from 'react';
import CountUp from 'react-countup';
import { FaFire } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import vendorAssign from '../../../../../../assets/custom/v2/vendor.svg';

const StatusCard = ({ statusBooking, cardData, filter }) => {
  const history = useHistory();

  const totalCount =
    cardData?.[statusBooking?.name]?.data?.totalCount || cardData?.[statusBooking?.name]?.data?.totalCount === 0
      ? Number(cardData?.[statusBooking?.name]?.data?.totalCount)
      : null;

  console.log('totalCount', totalCount);
  

 

  const routeHandler = () => {
    const params = {
      name: statusBooking?.name,
      type: statusBooking?.type,
      startDate: filter?.startDate,
      endDate: filter?.endDate,
    };
    if (statusBooking?.nextNavigation) {
      history.push({
        pathname: '/v2/booking/airport-transfer',
        search: `?${queryString.stringify(params)}`,
      });
    }
  };

  return (
    <Card
      style={{
        border: '1px solid #FFFFFF',
        borderRadius: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', // Added boxShadow
        cursor: statusBooking?.nextNavigation && 'pointer',
      }}
      className="status-card"
      onClick={routeHandler}
    >
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            {/* <FaFire className="icon-fire" size={20} color="#EA9C2B" /> */}
            <img src={statusBooking?.icon ? statusBooking?.icon : vendorAssign} className="img-fluid" alt="" style={{ width: '50px' }} />
          </div>
          <div>
            <p className="m-0" style={{ fontWeight: '500', fontSize: '16px' }}>
              {statusBooking?.displayName}
            </p>
            <div style={{ textAlign: 'end' }}>
              {totalCount != null ? (
                <CountUp
                  separator=""
                  className="fw-semibold"
                  start={0}
                  end={totalCount}
                  duration={2}
                  style={{ fontSize: '25px', fontWeight: '500' }}
                />
              ) : (
                <p className="fw-bold text-format-3 m-0">-</p>
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default StatusCard;
