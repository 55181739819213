import { LIST_TEMPLATE, LIST_TEMPLATE_SUCCESS, LIST_TEMPLATE_ERROR, LIST_TEMPLATE_EMPTY } from '../../../actionType.js';

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const GetTemplateList = (state = initialState, action) => {
  switch (action.type) {
    case LIST_TEMPLATE:
      state = {
        ...state,
        // data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      };
      break;

    case LIST_TEMPLATE_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };
      break;

    case LIST_TEMPLATE_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };
      break;

    case LIST_TEMPLATE_EMPTY:
      state = {
        ...initialState,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GetTemplateList;
