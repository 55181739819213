import { all, fork } from 'redux-saga/effects';
//layout
import LayoutSaga from './layouts/saga';

//Auth
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';

//calendar
import calendarSaga from './calendar/saga';

//chat
import chatSaga from './chat/saga';

//ecommerce
import ecommerceSaga from './ecommerce/saga';

//Project
import projectSaga from './projects/saga';

// Task
import taskSaga from './tasks/saga';

// Crypto
import cryptoSaga from './crypto/saga';

//TicketsList
import ticketsSaga from './tickets/saga';

//crm
import crmSaga from './crm/saga';

//invoice
import invoiceSaga from './invoice/saga';

//mailbox
import mailboxSaga from './mailbox/saga';

/* ---------------------------- THRIWE-One-Admin ---------------------------- */
// User Login
import userLoginSaga from './application/common/login/saga';

// User Logout
import userLogoutSaga from './application/common/logout/saga';

/* ---------------------------- COMMON API'S ---------------------------- */
import getRedemptionTypeSaga from './application/supplyStore/common/getRedemptionType/saga';
import getParentCategorySaga from './application/supplyStore/common/getParentCategory/saga';
import DownloadDump from './application/common/downloadDump/saga';
import allGolfCoursesSaga from './application/common/golfcourses/saga';
import allProjectsSaga from './application/common/projects/saga';
import sendMailSaga from './application/common/mail/saga';
import bookingTypesListSaga from './application/bookings/bookingTypes/saga';
import airportListSaga from './application/common/airports/saga';
import permissionsByProjectIdSaga from './application/common/getPermissionByProjectId/saga';
import deleteFileSaga from './application/coupon/deleteFile/saga';

/* ---------------------------- DASHBOARD ---------------------------- */
import getSurgeReportSaga from './application/Dashboard/SurgeReport/saga';
import getUsersListSaga from './application/Dashboard/UserInfo/saga';
import getUserPointsSaga from './application/Dashboard/UserPoints/saga';
import getUserBookingsSaga from './application/Dashboard/UserBookings/saga';
import getMisReportSaga from './application/Dashboard/MisReport/saga';

/* ---------------------------- SUPPLY STORE ---------------------------- */
import uploadsInfoSaga from './application/supplyStore/common/getUploadsInfo/saga';

// Category
import createCategorySaga from './application/supplyStore/category/createCategory/saga';
import updateCategorySaga from './application/supplyStore/category/updateCategory/saga';
import getCategorySaga from './application/supplyStore/category/listCategory/saga';

// Partner
import createPartnerSaga from './application/supplyStore/Partner/createPartner/saga';
import updatePartnerSaga from './application/supplyStore/Partner/updatePartner/saga';
import getPartnerSaga from './application/supplyStore/Partner/listPartner/saga';

// Facility
import createLocationSaga from './application/supplyStore/Location/createLocation/saga';
import updateLocationSaga from './application/supplyStore/Location/updateLocation/saga';
import getLocationSaga from './application/supplyStore/Location/listLocation/saga';

// Service
import createServiceSaga from './application/supplyStore/Service/createService/saga';
import updateServiceSaga from './application/supplyStore/Service/updateService/saga';
import getServiceSaga from './application/supplyStore/Service/listService/saga';

/* ---------------------------- COUPON MANAGEMENT ---------------------------- */
import getCouponSaga from './application/coupon/getCoupon/saga';
import getCouponsSaga from './application/coupon/getCoupons/saga';
import getCouponCategoriesSaga from './application/coupon/category/saga';
import getCouponFacilitySaga from './application/coupon/facility/saga';
import getCouponPartnerSaga from './application/coupon/partner/saga';
import getCouponProjectSaga from './application/coupon/projects/saga';
import getCouponServiceSaga from './application/coupon/service/saga';
import getCouponAssignedStatusSaga from './application/coupon/assignedStatus/saga';
import getCouponUploadedBySaga from './application/coupon/uploadedBy/saga';
import postCouponSaga from './application/coupon/uploadCoupons/saga';
import assignCouponSaga from './application/coupon/AssignCoupon/saga';
import getCountrySaga from './application/common/country/saga';
import getRegionSaga from './application/common/region/saga';
import getSubRegionSaga from './application/common/subRegion/saga';
import updateCouponSaga from './application/coupon/UpdateCoupon/saga';
import postStaticCouponSaga from './application/coupon/uploadStaticCoupon/saga';
import getStaticCouponsSaga from './application/coupon/getStaticCoupon/saga';
import updateStaticCouponSaga from './application/coupon/UpdateStaticCoupon/saga';

/* ---------------------------- BOOKING MANAGEMENT ---------------------------- */
import bookingListSaga from './application/bookings/bookingList/saga';
import updateBookingSaga from './application/bookings/updateBooking/saga';
import getVendorsListSaga from './application/bookings/getVendorsList/saga';
import getVendorsByBookingSaga from './application/bookings/getVendor/saga';
import viewFileSaga from './application/bookings/viewFile/saga';

/* ---------------------------- USER MANAGEMENT ---------------------------- */
import getAllRightsSaga from './application/common/userRights/saga';
import createUserSaga from './application/common/createUser/saga';
import programUserSaga from './application/CustomerManagement/programsUser/saga';
import membershipDetailsSaga from './application/CustomerManagement/membershipDetails/saga';
import cardDetailsSaga from './application/CustomerManagement/CardDetails/saga';
import getBulkUsersSaga from './application/CustomerManagement/GetBulkUsers/saga';
import getUserCardsSaga from './application/CustomerManagement/GetUserCards/saga';
import updateUserCardSaga from './application/CustomerManagement/UpdateUserCard/saga';
import getMembershipsSaga from './application/CustomerManagement/GetMemberships/saga';
import updateCustomerSaga from './application/CustomerManagement/UpdateCustomer/saga';
import getUserDetailsSaga from './application/CustomerManagement/GetUserDetails/saga';

/* ---------------------------- DUPLICATE CARD MANAGEMENT ---------------------------- */
import getDuplicateCardsListSaga from './application/DuplicateCardManagement/DuplicateCardList/saga';

/* ---------------------------- BENEFIT MANAGEMENT ---------------------------- */
import getBenefitGroupSaga from './application/common/benefitGroups/saga';
import benefitPacksListSaga from './application/Benefits/BenefitPackList/saga';
import getClientsSaga from './application/common/Clients/saga';
import createBenefitPackSaga from './application/Benefits/CreateBenefitPack/saga';
import benefitPackInfoSaga from './application/Benefits/BenefitPackInfo/saga';
import updateBenefitPackSaga from './application/Benefits/UpdateBenefitPack/saga';
import createInventoryQuotaSaga from './application/Benefits/CreateInventoryQuota/saga';
import getInventoryQuotaSaga from './application/Benefits/GetInventoryQuota/saga';
import getWorkflowsListSaga from './application/Benefits/GetWorkflowsList/saga';

/* ---------------------------- DOWNLOADS ---------------------------- */
import downloadDumpSaga from './application/Downloads/DownloadDump/saga';
import downloadsListSaga from './application/Downloads/DownloadList/saga';
import resendMailSaga from './application/bookings/resendMail/saga';

/* ---------------------------- BLOCK DATE MANAGEMENT ---------------------------- */
import CreateBlockDate from './application/BlockDate/CreateBlockDate/saga';
import listBlockedDatesSaga from './application/BlockDate/ListBlockDate/saga';
import getServiceOnFacilitySaga from './application/BlockDate/ServicesBasedOnFacility/saga';
import DeactivateBlockDate from './application/BlockDate/DeactivateBlockDate/saga';

/* ---------------------------- RULES MANAGEMENT ---------------------------- */
import createExpenditureRuleSaga from './application/RulesManagement/Expenditure/CreateExpenditureRule/saga';
import expenditureRulesListSaga from './application/RulesManagement/Expenditure/ListExpenditure/saga';
import updateExpenditureRuleSaga from './application/RulesManagement/Expenditure/UpdateExpenditureRule/saga';
import deleteExpenditureRuleSaga from './application/RulesManagement/Expenditure/DeleteExpenditureRule/saga';

import createEventRuleSaga from './application/RulesManagement/Event/CreateEventRule/saga';
import createEventRuleWithUserIdsSaga from './application/RulesManagement/Event/CreateEventRuleWithUserIds/saga';
import createEventRuleWithBenefitGroupIdSaga from './application/RulesManagement/Event/CreateEventRuleWithBenefitGroupId/saga';
import eventRulesListSaga from './application/RulesManagement/Event/ListEvent/saga';
import updateEventRuleSaga from './application/RulesManagement/Event/UpdateEventRule/saga';
import deleteEventRuleSaga from './application/RulesManagement/Event/DeleteEventRule/saga';

import createServiceWorthSaga from './application/RulesManagement/ServiceWorth/CreateServiceWorth/saga';
import serviceWorthListSaga from './application/RulesManagement/ServiceWorth/ListServiceWorth/saga';
import updateServiceWorthSaga from './application/RulesManagement/ServiceWorth/UpdateServiceWorth/saga';
import deleteServiceWorthSaga from './application/RulesManagement/ServiceWorth/DeleteServiceWorth/saga';

/* ---------------------------- GOLF ADMIN ---------------------------- */
import deleteGolfCourseFormatsByIdSaga from './application/GolfAdmin/deleteGolfCourseFormatById/saga';
import getGolfCourseFormatsByIdSaga from './application/GolfAdmin/getGolfCourseFormatById/saga';
import getGolfCourseFormatsSaga from './application/GolfAdmin/getGolfCourseFormats/saga';
import getGolfCourseUnavailableSaga from './application/GolfAdmin/getGolfCourseUnavailable/saga';
import createGolfCourseSlotSaga from './application/GolfAdmin/createGolfCourseSlot/saga';
import getGolfCoursesSaga from './application/GolfAdmin/golfCourses/saga';
import getGolfCourseSlotsSaga from './application/GolfAdmin/getGolfCourseSlots/saga';
import downloadSlotsExcelSaga from './application/GolfAdmin/downloadExcel/saga';
import disableGolfCourseUnavailablilitySaga from './application/GolfAdmin/disableGolfCourseUnavailablility/saga';
import createGolfCourseUnavailabilitySaga from './application/GolfAdmin/createGolfCourseUnavailablility/saga';
import updateGolfCourseSlotsSaga from './application/GolfAdmin/updateGolfCourseSlots/saga';

/* ---------------------------- USER MANAGEMENT ---------------------------- */
import usersSaga from './application/UsersMangement/GetUsers/saga';
import PermissionsSaga from './application/UsersMangement/GetPermissions/saga';
import updatePermissionsSaga from './application/UsersMangement/UpdatePermissions/saga';

/* ---------------------------- PROJECT MANAGEMENT ---------------------------- */
import createProjectSaga from './application/ProjectManagement/CreateProject/saga';
import projectsListSaga from './application/ProjectManagement/ProjectsList/saga';
import getPaymentGatewaysSaga from './application/ProjectManagement/GetPaymentGateways/saga';
import getPaymentsListSaga from './application/ProjectManagement/GetPaymentsList/saga';
import createPaymentSaga from './application/ProjectManagement/CreatePayment/saga';
import updatePaymentSaga from './application/ProjectManagement/UpdatePayment/saga';
import binsListSaga from './application/ProjectManagement/ListBins/saga';
import updateBinsSaga from './application/ProjectManagement/UpdateBins/saga';
import createBinsSaga from './application/ProjectManagement/CreateBins/saga';
import paymentLogsSaga from './application/ProjectManagement/PaymentLogs/saga';
import createClientSaga from './application/ProjectManagement/CreateClient/saga';
import updateClientSaga from './application/ProjectManagement/UpdateClient/saga';
import createParentProjectSaga from './application/ProjectManagement/CreateParentProject/saga';
import getParentProjectsSaga from './application/ProjectManagement/GetParentProjects/saga';
import updateParentProjectSaga from './application/ProjectManagement/UpdateParentProject/saga';
import updateProjectSaga from './application/ProjectManagement/UpdateProject/saga';

/* ---------------------------- CONFIG STORE MANAGEMENT ---------------------------- */
import creatBenefitGroupSaga from './application/ConfigStoreManagement/BenefitGroup/saga';
import CreateBenefitGroupItemsModal from './application/ConfigStoreManagement/BenefitGroupItem/saga';
import creatBenefitGroupCategoriesSaga from './application/ConfigStoreManagement/CreateBenefitGroupCategory/saga';
import getBenefitGroupCategoriesSaga from './application/ConfigStoreManagement/BenefitGroupCategories/saga';
import getBenefitGroupsListSaga from './application/ConfigStoreManagement/BenefitGroupsList/saga';
import getServicesInBenefitItemSaga from './application/ConfigStoreManagement/Service/saga';
import updateBenefitGroupSaga from './application/ConfigStoreManagement/UpdateBenefitGroup/saga';
import updateBenefitGroupItemsSaga from './application/ConfigStoreManagement/UpdateBenefitGroupItems/saga';
import getBenefitGroupItemsListSaga from './application/ConfigStoreManagement/BenefitGroupItemsList/saga';
import getBenefitItemsListSaga from './application/ConfigStoreManagement/BenefitItemsList/saga';
import updateBenefitItemsSaga from './application/ConfigStoreManagement/UpdateItems/saga';

/* ---------------------------- CUSTOMER DATA MANAGEMENT ---------------------------- */
import clientCustomersSaga from './application/CustomerDataManagement/ClientsCustomer/saga';
import customerUploadsInfoSaga from './application/CustomerDataManagement/CustomerUploadsInfo/saga';
import updateClientsCustomerSaga from './application/CustomerDataManagement/UpdateClientsCustomer/saga';

/* ---------------------------- SURGE SPHERE ---------------------------- */
// CAMPAIGN
import createCampaignSaga from './application/SurgeSphere/Campaign/createCampaign/saga';
import updateCampaignSaga from './application/SurgeSphere/Campaign/updateCampaign/saga';
import getCampaignsSaga from './application/SurgeSphere/Campaign/listCampaign/saga';

// RULES
import createRuleSaga from './application/SurgeSphere/Rules/createRule/saga';
import getRulesSaga from './application/SurgeSphere/Rules/listRule/saga';
import updateRuleSaga from './application/SurgeSphere/Rules/updateRule/saga';
import deleteRuleSaga from './application/SurgeSphere/Rules/deleteRule/saga';

// EVENTS
import createEventSaga from './application/SurgeSphere/Event/createEvent/saga';
import getEventsSaga from './application/SurgeSphere/Event/listEvent/saga';
import updateEventSaga from './application/SurgeSphere/Event/updateEvent/saga';
import deleteEventSaga from './application/SurgeSphere/Event/DeleteEvent/saga';

// ATTRIBUTE
import createAttributeSaga from './application/SurgeSphere/Attributes/createAttribute/saga';
import getAttributesSaga from './application/SurgeSphere/Attributes/listAttribute/saga';
import updateAttributeSaga from './application/SurgeSphere/Attributes/updateAttribute/saga';
import deleteAttributeSaga from './application/SurgeSphere/Attributes/deleteAttribute/saga';

// WALLETS
import getWalletsSaga from './application/SurgeSphere/Wallet/walletsList/saga';

/* ----------------------------------- DATA POOL ----------------------------------- */
import getFilesSummarySaga from './application/DataPool/GetFilesSummary/saga';
import getFileSummarySaga from './application/DataPool/GetFileRowSummary/saga';
import uploadFileSaga from './application/DataPool/UploadData/saga';

/* ----------------------------------- DATA INTEGRATION ----------------------------------- */
import getIntegrationDataSaga from './application/ProjectManagement/GetIntegrationData/saga';
import createIntegrationDataSaga from './application/ProjectManagement/CreateDataIntegration/saga';
import updateIntegrationDataSaga from './application/ProjectManagement/UpdateIntegrationData/saga';

/* ----------------------------------- COMMUNICATION ----------------------------------- */
import createTemplateSaga from './application/Communication/CreateTemplate/saga';
import getTemplateListSaga from './application/Communication/TemplateList/saga';
import updateTemplateSaga from './application/Communication/UpdateTemplate/saga';
import createComsConfigSaga from './application/Communication/CreateComsConfig/saga';
import updateComsConfigSaga from './application/Communication/UpdateComsConfig/saga';
import getComsConfigListSaga from './application/Communication/ComsConfigList/saga';
import getComsEventListSaga from './application/Communication/ComsEventList/saga';
import createComsChannelConfigSaga from './application/Communication/CreateComsChannel/saga';
import updateComsChannelConfigSaga from './application/Communication/UpdateComsChannel/saga';
import getComsChannelConfigSaga from './application/Communication/GetComsChannel/saga';
import getComsBenefitSaga from './application/Communication/GetComsBenefit/saga';
import getVariablesSaga from './application/Communication/Variables/saga';
import sendCommunicationSaga from './application/Communication/SendCommunication/saga';

/* ----------------------------------- DASHBOARD ----------------------------------- */
import getBookingSummarySaga from './application/Dashboard/BookingSummary/saga';

/* ----------------------------------- THRIWE PRIME BOOKING ----------------------------------- */
import thriwePrimeBookingListSaga from './application/bookings/thriwePrimeBooking/saga';

/* ----------------------------------- REPORTS MANAGEMENT ----------------------------------- */
import getReportSummarySaga from './application/Reports/CommsReport/saga';

/* ----------------------------------- VENDOR PANEL MANAGEMENT ----------------------------------- */
import getVendorsSaga from './application/VendorPanel/VendorsList/saga';
import createVendorSaga from './application/VendorPanel/CreateVendor/saga';
import getBenefitDomainsListSaga from './application/VendorPanel/BenefitDomainsList/saga';
import createVendorAgentSaga from './application/VendorPanel/CreateVendorAgent/saga';

/* ----------------------------------- UPDATE MEMBERSHIP ----------------------------------- */
import updateMembershipSaga from './application/CustomerManagement/UpdateBenefitPack/saga';

/* ----------------------------------- CLIENT PANEL MANAGEMENT ----------------------------------- */
import getProjectUtilitySaga from './application/Client/ProjectUtility/saga';
import clientLoginSaga from './application/Client/ClientLogin/saga';
import validateTokenSaga from './application/Client/ValidateToken/saga';
import clientForgotPasswordSaga from './application/Client/ForgotPassword/saga';
import clientResetPasswordSaga from './application/Client/ResetPassword/saga';
import createBookingSaga from './application/Client/CreateBooking/saga';
import updateProfileSaga from './application/Client/UpdateProfile/saga';
import getMembershipsDataSaga from './application/Client/GetMembershipsData/saga';
import getBenefitItemsSaga from './application/Client/GetBenefitItem/saga';
import getBinsListSaga from './application/Client/GetBins/saga';
import createClientUserSaga from './application/Client/CreateUser/saga';
import getClientHistorySaga from './application/Client/History/saga';
import getClientBenefitConfigSaga from './application/Client/GetBenefitConfig/saga';
import getClientAirportSaga from './application/Client/GetClientAirport/saga';
import clientDownloadsListSaga from './application/Client/DownloadList/saga';
import clientDownloadDumpSaga from './application/Client/DownloadDump/saga';
import sendEmailSaga from './application/Client/SendEMail/saga';
import getClientRegionSaga from './application/Client/subRegion/saga';
import getBusinessSaga from './application/Client/GetBusiness/saga';
import updateBusinessSaga from './application/Client/UpdateBusiness/saga';
import getQuestionAnswerSaga from './application/Client/GetQuestionAnswer/saga';
import getPostSaga from './application/Client/GetPost/saga';
import updatePostSaga from './application/Client/UpdatePost/saga';
import getOfferSaga from './application/Client/GetOffer/saga';
import updateOfferSaga from './application/Client/UpdateOffer/saga';
import askQuestionSaga from './application/Client/AskQuestion/saga';
import getAnnoucementsSaga from './application/Client/GetAnnouncements/saga';
import updateAnnouncementSaga from './application/Client/PostAnnouncement/saga';
import getPostTypesSaga from './application/Client/GetPostTypes/saga';
import postImageSaga from './application/Client/UploadImage/saga';
import getAdibSurgeReportSaga from './application/Client/SurgeReport/saga';
import getAdibMonthlySummarySaga from './application/Client/GetMonthlySummary/saga';
import getAdibPartnersMonthlySummarySaga from './application/Client/GetPartnerMonthlySummary/saga';
import getClientCustomersSaga from './application/Client/Customers/saga';
import downloadBookingsDumpSaga from './application/Client/DownloadBookings/saga';
import downloadsBookingsInfoSaga from './application/Client/DownloadsInfo/saga';
import downloadBookingsDumpAdibSaga from './application/Client/DownloadAdibBookings/saga';
import clientDownloadDumpAdibSaga from './application/Client/DownloadInfoAdib/saga';
import getPostSettingSaga from './application/Client/GetPostSetting/saga';
import updatePostSettingSaga from './application/Client/UpdatePostSetting/saga';
import updateUserSettingSaga from './application/Client/UpdateUserSetting/saga';
import getClientUserSaga from './application/Client/ClientUsers/saga';
import getClientRoleForUserSaga from './application/Client/GetClientRoleForUser/saga';
import updateClientRoleSaga from './application/Client/UpdateRole/saga';
import getClientRoleSaga from './application/Client/GetClientRole/saga';
import createAdibClientSaga from './application/Client/CreateAdibClient/saga';
import updateUserStatusSaga from './application/Client/UpdateUserStatus/saga';
import getAdibDashboardSaga from './application/Client/AdibDashboard/saga';
import downloadUserLogsSaga from './application/Client/DownloadUserLogs/saga';
import downloadUserReportSaga from './application/Client/DownloadUserReport/saga';
import getUserDownloadsSaga from './application/Client/UserDownloads/saga';

/* ------------------------ AIRPORT TRANSFER MANUAL BOOKING --------------------------------- */
import getAdminMembershipsDataSaga from './application/bookings/GetMembershipData/saga';
import getBenefitCategorySaga from './application/bookings/GetBenefitCategory/saga';
import getUserCardDataSaga from './application/bookings/GetUserCards/saga';
import getMembershipQuotaSaga from './application/bookings/GetMembershipQuota/saga';
import createManualBookingSaga from './application/bookings/CreateManualBooking/saga';
import getAdminBenefitItemsSaga from './application/bookings/GetBenefitItem/saga';
import createUploadTicketSaga from './application/bookings/UploadTicket/saga';

/* ----------------------------------- VENDOR ----------------------------------- */
import vendorLoginSaga from './application/Vendor/Login/saga';
import vendorsListSaga from './application/Vendor/VendorsList/saga';
import getVendorsDashboardDataSaga from './application/Vendor/Dashboard/saga';
import vendorBookingsSaga from './application/Vendor/VendorBookings/saga';
import vendorDownloadDumpSaga from './application/Vendor/DownloadDump/saga';
import vendorDownloadsListSaga from './application/Vendor/DownloadList/saga';
import vendorUpdateBookingSaga from './application/Vendor/UpdateVendorBookings/saga';
import vendorOtpValidateSaga from './application/Vendor/ValidateOtp/saga';
import vendorResendOtpSaga from './application/Vendor/ResendOtp/saga';
import vendorGenerateQrSaga from './application/Vendor/GenerateQr/saga';
import getUserProfileSaga from './application/Vendor/GetProfile/saga';
import validateUserSaga from './application/CustomerManagement/ValidateUser/saga';

/* ----------------------------------- BOOKING TYPE V2 ----------------------------------- */
import bookingListV2Saga from './application/Bookings_V2/BookingList/saga';
import confirmBookingV2Saga from './application/Bookings_V2/ConfirmBooking/saga';
import cancelBookingV2Saga from './application/Bookings_V2/CancelBooking/saga';
import proccessBookingV2Saga from './application/Bookings_V2/ProccessBooking/saga';
import completeBookingV2Saga from './application/Bookings_V2/CompleteBooking/saga';
import cancelBookingGolfSaga from './application/Bookings_V2/Golf/CancelBooking/saga';
import confirmBookingGolfSaga from './application/Bookings_V2/Golf/ConfirmBooking/saga';
import downloadDumpV2Saga from './application/Bookings_V2/DownloadBooking/saga';
import downloadsListV2Saga from './application/Bookings_V2/DownloadList/saga';

/* ----------------------------------- PERMISSION CHECKER ----------------------------------- */
import getPermissionCheckerSaga from './application/common/permission-checker/saga';
import updatePermissionRequestSaga from './application/common/UpdateRequestStatus/saga';
import BookingSearchSaga from './application/bookings/bookingSearch/saga';
import getClientPermissionForUserSaga from './application/Client/GetPermissionForUser/saga';

/* ----------------------------------- GM REPORT ----------------------------------- */
import getFileTypesSaga from './application/GmReport/GetFileTypes/saga';
import getFileTypesConfigSaga from './application/GmReport/GetFileTypesConfig/saga';
import getRecordDataSaga from './application/GmReport/GetRecordData/saga';
import getTrackerDataSaga from './application/GmReport/GetTrackerData/saga';
import uploadConfigExcelSaga from './application/GmReport/UploadExcel/saga';
import downloadExcelSaga from './application/GmReport/DownloadExcel/saga';
import updateRecordDataSaga from './application/GmReport/UpdateRecord/saga';
import publishRecordSaga from './application/GmReport/PublishRecord/saga';
import approveRejectUpdateSaga from './application/GmReport/ApproveReject/saga';
import sendApprovalMailSaga from './application/GmReport/SendMail/saga';
import deleteTrackerRecordSaga from './application/GmReport/DeleteTrackerRecord/saga';
import getClientPriceSaga from './application/GmReport/GetClientPrice/saga';
import getAmenitiesDetailsSaga from './application/GmReport/Amenities/saga';
import getPriceDetailsSaga from './application/GmReport/GetPriceDetails/saga';
import publishDataWithAuthSaga from './application/GmReport/PublishDataWithAuth/saga';
import pricePreviewSaga from './application/GmReport/PricePreview/saga';
import uploadAttachmentSaga from './application/GmReport/UploadAttachment/saga';
import deleteAttachmentSaga from './application/GmReport/DeleteAttachment/saga';
import getReconDetailsSaga from './application/GmReport/GetReconDetails/saga';
import getGmReportSaga from './application/GmReport/GetGmReport/saga';
import getReconPreviewSaga from './application/GmReport/GetReconPreview/saga';

/* ----------------------------------- SURGE V2 ----------------------------------- */
//RULES
import createSurgeRuleSaga from './application/Surge-v2/Rules/CreateRule/saga';
import createSurgeWorkflowSaga from './application/Surge-v2/Rules/CreateSurgeWorkflow/saga';
import getSurgeRulesSaga from './application/Surge-v2/Rules/ListRules/saga';
import ListSurgeRuleTablesSaga from './application/Surge-v2/Rules/ListRuleTables/saga';

// REFERRAL
import getReferralSaga from './application/Surge-v2/Referral/GetReferral/saga';
import validateReferralSaga from './application/Surge-v2/Referral/ValidateReferral/saga';
import getLedgerSaga from './application/Surge-v2/Ledger/GetLedger/saga';

// EVENTS
import listSurgeEventsSaga from './application/Surge-v2/Events/ListEvents/saga';
import getRegistratioSaga from './application/Surge-v2/UserRegistration/UploadFile/saga';
import getUserRegistratioSaga from './application/Surge-v2/UserRegistration/GetUserRegistration/saga';

// EFFECTS
import listSurgeEffectsSaga from './application/Surge-v2/Effects/ListEffects/saga';
import getDownloadSampleFile from './application/Surge-v2/UserRegistration/GetSampleFile/saga';

// VOUCHER
import getVoucherBookingSaga from './application/Surge-v2/Voucher/VoucherBooking/saga';
import getVoucherBookingDetailsSaga from './application/Surge-v2/Voucher/VoucherBookingDetails/saga';
import validateVoucherSaga from './application/Surge-v2/Voucher/ValidateVoucherBooking/saga';
import vChaufferAssign from './application/Bookings_V2/ChaufferAssignV2/saga';

import getAnnouncementForGlobeSaga from './application/Surge-v2/Announcement/GetAnnouncement/saga';
import createAnnouncementForGlobeToyotaSaga from './application/Surge-v2/Announcement/CreateAnnouncement/saga';
import updateAnnouncementForGlobeToyotaSaga from './application/Surge-v2/Announcement/UpdateAnnoucement/saga';
import getTemplateSaga from './application/Surge-v2/Rules/ListTemplate/saga';
import templateDetailsSaga from './application/Surge-v2/Rules/TemplateDetails/saga';
import getRuleDetailsSaga from './application/Surge-v2/Rules/RuleDetails/saga';
import createTemplateWorkflowSaga from './application/Surge-v2/Rules/CreateTemplateWorkflow/saga';
import createTemplateMappingSaga from './application/Surge-v2/Rules/CreateTemplateMapping/saga';
import getRuleDashboardSaga from './application/Surge-v2/GetRuleDashboard/saga';

import userLoginV2Saga from './application/common/loginV2/saga';
import watchUserTokenSaga from './application/V2/UserToken/saga';
import vendorLoginV2Saga from './application/common/VendorLoginV2/saga';
import watchV2DashboardSaga from './application/V2/Dashboard/saga';
import v2DashboardDetailsSaga from './application/V2/DashboardDetails/saga';
import changeActivitySaga from './application/V2/ChangeActivity/saga';

import { bookingsSagas } from './application/V2/sagas';
import fetchActivitySaga from './application/V2/FetchActivity/saga';
import getDashboardConfigurationSaga from './application/V2/GetDashboardConfiguration/saga';
import fetchAllCardsSaga from './application/V2/GetCardSummary/saga';

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(chatSaga),
    fork(projectSaga),
    fork(taskSaga),
    fork(cryptoSaga),
    fork(ticketsSaga),
    fork(calendarSaga),
    fork(ecommerceSaga),
    fork(crmSaga),
    fork(invoiceSaga),
    fork(mailboxSaga),
    fork(deleteFileSaga),
    fork(getProjectUtilitySaga),
    /* ---------------------------- THRIWE-One-Admin ---------------------------- */
    // Common
    fork(getCountrySaga),
    fork(getRegionSaga),
    fork(getSubRegionSaga),
    fork(allProjectsSaga),
    fork(allGolfCoursesSaga),
    fork(userLoginSaga),
    fork(userLogoutSaga),
    fork(sendMailSaga),
    fork(DownloadDump),
    fork(getRedemptionTypeSaga),
    fork(getParentCategorySaga),
    fork(bookingTypesListSaga),
    fork(airportListSaga),
    fork(permissionsByProjectIdSaga),

    /* ---------------------------- DASHBOARD ---------------------------- */
    fork(getSurgeReportSaga),
    fork(getUsersListSaga),
    fork(getUserPointsSaga),
    fork(getUserBookingsSaga),
    fork(getMisReportSaga),

    /* ---------------------------- SUPPLY STORE ---------------------------- */
    fork(uploadsInfoSaga),

    // Category
    fork(createCategorySaga),
    fork(updateCategorySaga),
    fork(getCategorySaga),

    // Location
    fork(createLocationSaga),
    fork(updateLocationSaga),
    fork(getLocationSaga),

    // Partner
    fork(createPartnerSaga),
    fork(updatePartnerSaga),
    fork(getPartnerSaga),

    // Service
    fork(createServiceSaga),
    fork(updateServiceSaga),
    fork(getServiceSaga),

    /* ---------------------------- COUPON MANAGEMENT ---------------------------- */
    fork(getCouponSaga),
    fork(getCouponsSaga),
    fork(getCouponFacilitySaga),
    fork(getCouponCategoriesSaga),
    fork(getCouponPartnerSaga),
    fork(getCouponProjectSaga),
    fork(getCouponServiceSaga),
    fork(getCouponAssignedStatusSaga),
    fork(getCouponUploadedBySaga),
    fork(postCouponSaga),
    fork(assignCouponSaga),
    fork(updateCouponSaga),
    fork(postStaticCouponSaga),
    fork(getStaticCouponsSaga),
    fork(updateStaticCouponSaga),

    /* ---------------------------- BOOKING MANAGEMENT ---------------------------- */
    fork(bookingListSaga),
    fork(updateBookingSaga),
    fork(resendMailSaga),
    fork(getVendorsListSaga),
    fork(getVendorsByBookingSaga),
    fork(viewFileSaga),

    /* ---------------------------- USER MANAGEMENT ---------------------------- */
    fork(getAllRightsSaga),
    fork(createUserSaga),
    fork(programUserSaga),
    fork(membershipDetailsSaga),
    fork(cardDetailsSaga),
    fork(getBulkUsersSaga),
    fork(getUserCardsSaga),
    fork(updateUserCardSaga),
    fork(getMembershipsSaga),
    fork(updateCustomerSaga),
    fork(getUserDetailsSaga),
    fork(validateUserSaga),

    /* ---------------------------- DUPLICATE CARD MANAGEMENT ---------------------------- */
    fork(getDuplicateCardsListSaga),

    /* ---------------------------- BENEFIT MANAGEMENT ---------------------------- */
    fork(getBenefitGroupSaga),
    fork(benefitPacksListSaga),
    fork(getClientsSaga),
    fork(createBenefitPackSaga),
    fork(benefitPackInfoSaga),
    fork(updateBenefitPackSaga),
    fork(createInventoryQuotaSaga),
    fork(getInventoryQuotaSaga),
    fork(getWorkflowsListSaga),

    /* ---------------------------- DOWNLOADS ---------------------------- */
    fork(downloadDumpSaga),
    fork(downloadsListSaga),

    /* ---------------------------- BLOCK DATE MANAGEMENT ---------------------------- */
    fork(CreateBlockDate),
    fork(getServiceOnFacilitySaga),
    fork(DeactivateBlockDate),
    fork(listBlockedDatesSaga),

    /* ---------------------------- RULES MANAGEMENT ---------------------------- */
    // Expenditure
    fork(createExpenditureRuleSaga),
    fork(createEventRuleWithUserIdsSaga),
    fork(createEventRuleWithBenefitGroupIdSaga),
    fork(expenditureRulesListSaga),
    fork(updateExpenditureRuleSaga),
    fork(deleteExpenditureRuleSaga),

    // Event
    fork(createEventRuleSaga),
    fork(eventRulesListSaga),
    fork(updateEventRuleSaga),
    fork(deleteEventRuleSaga),

    // Assign coins
    fork(createServiceWorthSaga),
    fork(serviceWorthListSaga),
    fork(updateServiceWorthSaga),
    fork(deleteServiceWorthSaga),

    /* ---------------------------- GOLF ADMIN ---------------------------- */
    fork(deleteGolfCourseFormatsByIdSaga),
    fork(getGolfCourseFormatsByIdSaga),
    fork(getGolfCourseFormatsSaga),
    fork(getGolfCourseUnavailableSaga),
    fork(createGolfCourseSlotSaga),
    fork(getGolfCoursesSaga),
    fork(getGolfCourseSlotsSaga),
    fork(downloadSlotsExcelSaga),
    fork(disableGolfCourseUnavailablilitySaga),
    fork(createGolfCourseUnavailabilitySaga),
    fork(updateGolfCourseSlotsSaga),

    /* ---------------------------- USER MANAGEMENT ---------------------------- */
    fork(usersSaga),
    fork(PermissionsSaga),
    fork(updatePermissionsSaga),

    /* ---------------------------- PROJECT MANAGEMENT ---------------------------- */
    fork(createProjectSaga),
    fork(projectsListSaga),
    fork(getPaymentGatewaysSaga),
    fork(getPaymentsListSaga),
    fork(createPaymentSaga),
    fork(updatePaymentSaga),
    fork(binsListSaga),
    fork(updateBinsSaga),
    fork(createBinsSaga),
    fork(paymentLogsSaga),
    fork(createClientSaga),
    fork(updateClientSaga),
    fork(createParentProjectSaga),
    fork(getParentProjectsSaga),
    fork(updateParentProjectSaga),
    fork(updateProjectSaga),

    /* ---------------------------- CONFIG STORE MANAGEMENT ---------------------------- */
    fork(creatBenefitGroupSaga),
    fork(CreateBenefitGroupItemsModal),
    fork(creatBenefitGroupCategoriesSaga),
    fork(getBenefitGroupCategoriesSaga),
    fork(getBenefitGroupsListSaga),
    fork(getServicesInBenefitItemSaga),
    fork(updateBenefitGroupSaga),
    fork(updateBenefitGroupItemsSaga),
    fork(getBenefitGroupItemsListSaga),
    fork(getBenefitItemsListSaga),
    fork(updateBenefitItemsSaga),

    /* ---------------------------- CUSTOMER DATA MANAGEMENT ---------------------------- */
    fork(clientCustomersSaga),
    fork(customerUploadsInfoSaga),
    fork(updateClientsCustomerSaga),

    // CAMPAIGN
    fork(createCampaignSaga),
    fork(getCampaignsSaga),
    fork(updateCampaignSaga),

    // RULES
    fork(createRuleSaga),
    fork(getRulesSaga),
    fork(updateRuleSaga),
    fork(deleteRuleSaga),

    // EVENTS
    fork(createEventSaga),
    fork(getEventsSaga),
    fork(updateEventSaga),
    fork(deleteEventSaga),

    // ATTRIBUTE
    fork(createAttributeSaga),
    fork(getAttributesSaga),
    fork(updateAttributeSaga),
    fork(deleteAttributeSaga),

    // WALLETS
    fork(getWalletsSaga),

    /* ----------------------------------- DATA POOL ----------------------------------- */
    fork(getFilesSummarySaga),
    fork(getFileSummarySaga),
    fork(uploadFileSaga),

    /* ----------------------------------- DATA INTEGRATION ----------------------------------- */
    fork(getIntegrationDataSaga),
    fork(createIntegrationDataSaga),
    fork(updateIntegrationDataSaga),

    /* ----------------------------------- COMMUNICATION ----------------------------------- */
    fork(createTemplateSaga),
    fork(getTemplateListSaga),
    fork(updateTemplateSaga),
    fork(createComsConfigSaga),
    fork(updateComsConfigSaga),
    fork(getComsConfigListSaga),
    fork(getComsEventListSaga),
    fork(createComsChannelConfigSaga),
    fork(updateComsChannelConfigSaga),
    fork(getComsChannelConfigSaga),
    fork(getComsBenefitSaga),
    fork(getVariablesSaga),
    fork(sendCommunicationSaga),

    /* ----------------------------------- DASHBOARD ----------------------------------- */
    fork(getBookingSummarySaga),

    /* ----------------------------------- THRIWE PRIME BOOKING ----------------------------------- */
    fork(thriwePrimeBookingListSaga),

    /* ----------------------------------- REPORTS MANAGEMENT ----------------------------------- */
    fork(getReportSummarySaga),

    /* ----------------------------------- VENDOR PANEL MANAGEMENT ----------------------------------- */
    fork(getVendorsSaga),
    fork(createVendorSaga),
    fork(getBenefitDomainsListSaga),
    fork(createVendorAgentSaga),

    /* ----------------------------------- UPDATE MEMBERSHIP ----------------------------------- */
    fork(updateMembershipSaga),

    /* ----------------------------------- CLIENT PANEL MANAGEMENT ----------------------------------- */
    fork(clientLoginSaga),
    fork(validateTokenSaga),
    fork(clientForgotPasswordSaga),
    fork(clientResetPasswordSaga),
    fork(createBookingSaga),
    fork(updateProfileSaga),
    fork(getMembershipsDataSaga),
    fork(getBenefitItemsSaga),
    fork(getBinsListSaga),
    fork(createClientUserSaga),
    fork(getClientHistorySaga),
    fork(getClientBenefitConfigSaga),
    fork(getClientAirportSaga),
    fork(clientDownloadsListSaga),
    fork(clientDownloadDumpSaga),
    fork(sendEmailSaga),
    fork(getClientRegionSaga),
    fork(getBusinessSaga),
    fork(updateBusinessSaga),
    fork(getQuestionAnswerSaga),
    fork(getPostSaga),
    fork(updatePostSaga),
    fork(getOfferSaga),
    fork(updateOfferSaga),
    fork(askQuestionSaga),
    fork(getAnnoucementsSaga),
    fork(updateAnnouncementSaga),
    fork(getPostTypesSaga),
    fork(postImageSaga),
    fork(getAdibSurgeReportSaga),
    fork(getAdibMonthlySummarySaga),
    fork(getAdibPartnersMonthlySummarySaga),
    fork(getClientCustomersSaga),
    fork(downloadBookingsDumpSaga),
    fork(downloadsBookingsInfoSaga),
    fork(downloadBookingsDumpAdibSaga),
    fork(clientDownloadDumpAdibSaga),
    fork(getPostSettingSaga),
    fork(updatePostSettingSaga),
    fork(updateUserSettingSaga),
    fork(getClientUserSaga),
    fork(getClientRoleForUserSaga),
    fork(updateClientRoleSaga),
    fork(getClientRoleSaga),
    fork(createAdibClientSaga),
    fork(getClientPermissionForUserSaga),
    fork(updateUserStatusSaga),
    fork(getAdibDashboardSaga),
    fork(downloadUserReportSaga),
    fork(downloadUserLogsSaga),
    fork(getUserDownloadsSaga),

    /* ----------------------------------- VENDOR ----------------------------------- */
    fork(vendorLoginSaga),
    fork(vendorsListSaga),
    fork(getVendorsDashboardDataSaga),
    fork(vendorBookingsSaga),
    fork(vendorDownloadDumpSaga),
    fork(vendorDownloadsListSaga),
    fork(vendorUpdateBookingSaga),
    fork(vendorOtpValidateSaga),
    fork(vendorResendOtpSaga),
    fork(vendorGenerateQrSaga),
    fork(getUserProfileSaga),
    /* ----------------------------------- AIRPORT TRANSFER MANUAL BOOKING ----------------------------------- */
    fork(getAdminMembershipsDataSaga),
    fork(getBenefitCategorySaga),
    fork(getUserCardDataSaga),
    fork(getMembershipQuotaSaga),
    fork(createManualBookingSaga),
    fork(getAdminBenefitItemsSaga),
    fork(createUploadTicketSaga),

    /* ----------------------------------- BOOKING TYPE V2 ----------------------------------- */
    fork(bookingListV2Saga),
    fork(confirmBookingV2Saga),
    fork(cancelBookingV2Saga),
    fork(proccessBookingV2Saga),
    fork(completeBookingV2Saga),
    fork(cancelBookingGolfSaga),
    fork(confirmBookingGolfSaga),
    fork(downloadDumpV2Saga),
    fork(downloadsListV2Saga),

    /* ----------------------------------- PERMISSION CHECKER ----------------------------------- */
    fork(getPermissionCheckerSaga),
    fork(updatePermissionRequestSaga),
    fork(BookingSearchSaga),
    /* ----------------------------------- GM REPORT ----------------------------------- */
    fork(getFileTypesSaga),
    fork(getFileTypesConfigSaga),
    fork(getRecordDataSaga),
    fork(getTrackerDataSaga),
    fork(uploadConfigExcelSaga),
    fork(downloadExcelSaga),
    fork(updateRecordDataSaga),
    fork(publishRecordSaga),
    fork(approveRejectUpdateSaga),
    fork(sendApprovalMailSaga),
    fork(deleteTrackerRecordSaga),
    fork(getClientPriceSaga),
    fork(getAmenitiesDetailsSaga),
    fork(getPriceDetailsSaga),
    fork(publishDataWithAuthSaga),
    fork(pricePreviewSaga),
    fork(uploadAttachmentSaga),
    fork(deleteAttachmentSaga),
    fork(getReconDetailsSaga),
    fork(getGmReportSaga),
    fork(getReconPreviewSaga),

    /* ----------------------------------- SURGE V2 ----------------------------------- */
    // RULES
    fork(createSurgeRuleSaga),
    fork(createSurgeWorkflowSaga),
    getSurgeRulesSaga,
    fork(getSurgeRulesSaga),
    fork(ListSurgeRuleTablesSaga),

    // REFERRAL
    fork(getReferralSaga),
    fork(validateReferralSaga),
    fork(getLedgerSaga),

    // EVENTS
    fork(listSurgeEventsSaga),

    // EFFECTS
    fork(listSurgeEffectsSaga),

    // USER REGISTRATION
    fork(getRegistratioSaga),
    fork(getUserRegistratioSaga),

    // VOUCHER
    fork(getVoucherBookingSaga),
    fork(getVoucherBookingDetailsSaga),
    fork(validateVoucherSaga),
    fork(getDownloadSampleFile),

    fork(getAnnouncementForGlobeSaga),
    fork(createAnnouncementForGlobeToyotaSaga),
    fork(updateAnnouncementForGlobeToyotaSaga),
    fork(getTemplateSaga),
    fork(templateDetailsSaga),
    fork(getRuleDetailsSaga),
    fork(createTemplateWorkflowSaga),
    fork(createTemplateMappingSaga),
    fork(getRuleDashboardSaga),

    fork(vChaufferAssign),

    fork(changeActivitySaga),

    fork(userLoginV2Saga),
    fork(watchUserTokenSaga),
    fork(vendorLoginV2Saga),
    fork(bookingsSagas),
    fork(fetchActivitySaga),
    fork(getDashboardConfigurationSaga),
    fork(fetchAllCardsSaga),
    fork(watchV2DashboardSaga),
    fork(v2DashboardDetailsSaga),
  ]);
}
