import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';

interface TimeSlotProps {
  timeSlots: { id: string; label: string }[];
  activeTimeSlot: string;
  onTimeSlotSelect: (slotId: string) => void;
}

const TimeSlot: React.FC<TimeSlotProps> = ({ timeSlots, activeTimeSlot, onTimeSlotSelect }) => {
  return (
    <Nav className="d-flex justify-content-center gap-2">
      {timeSlots.map(slot => (
        <NavItem key={slot.id}>
          <NavLink
            style={{
              cursor: 'pointer',
              border: '1px solid #ccc',
              padding: '6px 10px',
              borderRadius: '8px',
              backgroundColor: activeTimeSlot === slot.id ? '#07357A' : '#fff',
              color: activeTimeSlot === slot.id ? '#fff' : '#000',
            }}
            className={`${classNames({ active: activeTimeSlot === slot.id })} fs-10`}
            onClick={() => onTimeSlotSelect(slot.id)}
          >
            {slot.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default TimeSlot;
