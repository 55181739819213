import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import cogoToast from 'cogo-toast';
import { adminAPIURL, customHeaders } from '../../../../../../Containers/Config/index.js';
import apiCall from '../../../../apiCall.js';
import { RAISE_DISPUTE, RAISE_DISPUTE_ERROR, RAISE_DISPUTE_SUCCESS } from '../../../actions.js';

function* raiseDispute({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/raise-dispute`,
      method: 'POST',
      headers: {
        ...filteredHeaders,
      },
      data: {
        ...payload?.body,
      },
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: RAISE_DISPUTE_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: RAISE_DISPUTE_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: RAISE_DISPUTE_ERROR, payload: error });
  }
}

export function* watchraiseDispute() {
  yield takeEvery(RAISE_DISPUTE, raiseDispute);
}

function* raiseDisputeSaga() {
  yield all([fork(watchraiseDispute)]);
}

export default raiseDisputeSaga;
