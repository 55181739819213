import { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import { formattedWithComma, hasPermission } from '../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No.',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'Booking ID',
          selector: row => (
            <span
              className="fw-semibold"
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: '#3577f1',
              }}
              onClick={() => actionBtnHandler(`VIEW`, row?.data)}
            >
              {row?.data?.bookingId}
            </span>
          ),
          wrap: true,
          width: '150px',
        },
        {
          name: 'Full Name',
          selector: row => row?.name,
          wrap: true,
          width: '200px',
        },
        {
          name: 'Email',
          selector: row => row?.email,
          wrap: true,
          width: '300px',
        },
        {
          name: 'Mobile',
          selector: row => row?.mobile,
          wrap: true,
          width: '150px',
        },
        {
          name: 'Date Of Request',
          selector: row => row?.createdAt,
          minWidth: '200px',
          wrap: true,
        },
        {
          name: 'Date Of Travel',
          selector: row => row?.bookingDateTime,
          minWidth: '200px',
          wrap: true,
        },
        {
          name: 'Booking Status',
          width: '150px',
          cell: row => (
            <span
              className={`booking_status badge break-word bg-${
                Constant.BOOKING_STATUSES.filter(status => status?.name === row?.bookingStatus)[0]?.bgColor
              }`}
            >
              {row.bookingStatus}
            </span>
          ),
        },
        {
          name: 'Actions',
          width: '180px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100 p-2">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CLIENT_DOWNLOAD_DUMP) && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`UPDATE`, row?.data);
                  }}
                >
                  Resend Mail
                </span>
              )}

              {![
                Constant.BOOKING_STATUS.CANCEL_BY_THRIWE,
                Constant.BOOKING_STATUS.CANCEL_BY_USER,
                Constant.BOOKING_STATUS.SHORT_NOTICE_CANCELLED_BY_USER,
                Constant.BOOKING_STATUS.SHORT_NOTICE_CANCELLED_BY_THRIWE,
                Constant.BOOKING_STATUS.BOOKING_SHORTNOTICE_CANCELED_BY_THRIWE_WITH_QUOTA,
                Constant.BOOKING_STATUS.CANCELLED,
              ].includes(row?.data?.bookingStatusCode) && (
                <span
                  className="btn btn-sm btn-danger mb-1"
                  onClick={() => {
                    actionBtnHandler(`CANCEL`, row?.data);
                  }}
                >
                  Cancel
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;

          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            name: data?.user?.name || `NA`,
            email: data?.user?.email || `NA`,
            mobile: data?.user?.mobile || `NA`,
            createdAt: data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            bookingDateTime: data?.createdAt ? moment(new Date(`${data?.bookingDateTime}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            bookingStatus: status,
            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === 'VIEW') {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `CANCEL`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{`History`}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CLIENT_DOWNLOAD_DUMP) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.onClickPrimaryBtn}>
                  <div className="ms-2">
                    <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                  </div>
                </span>
              )}
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
