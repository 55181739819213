import React, { useState, useEffect } from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { GET_REASON_BY_ACTIONS, RAISE_DISPUTE, RAISE_DISPUTE_EMPTY } from '../../../../../../../store/actions';
import cogoToast from 'cogo-toast';

const RaiseDispute = ({ onClose, currentSelectedBooking }) => {
  const dispatch = useDispatch();

  const [selectedRemark, setSelectedRemark] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { raiseDispute, raiseDisputeLoading, remarksList, remarksListLoading } = useSelector(state => ({
    raiseDispute: state?.RaiseDispute?.data,
    raiseDisputeLoading: state?.RaiseDispute?.loading,

    remarksList: state?.GetReasonByActions?.data,
    remarksListLoading: state?.GetReasonByActions?.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_REASON_BY_ACTIONS,
      payload: {
        urlParams: {
          booking_type: 'airport_transfers',
          booking_status: currentSelectedBooking?.bookingStatus,
          action: 'raise_a_dispute',
          is_active: true,
        },
      },
    });
  }, [currentSelectedBooking]);

  const handleSubmit = () => {
    if (selectedRemark) {
      dispatch({
        type: RAISE_DISPUTE,
        payload: {
          body: {
            booking_id: currentSelectedBooking?.id,
            reason_id: selectedRemark?.value,
          },
        },
      });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (raiseDispute !== null) {
      dispatch({
        type: RAISE_DISPUTE_EMPTY,
      });
      onClose();
    }

    return () => {
      dispatch({
        type: RAISE_DISPUTE_EMPTY,
      });
    };
  }, [raiseDispute]);

  useEffect(() => {
    if (raiseDisputeLoading || remarksListLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [raiseDisputeLoading, remarksListLoading]);

  return (
    <Modal isOpen={true} centered size="md">
      <ModalHeader toggle={onClose}>
        <h4>Select Reason for Dispute</h4>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={12} className="mt-3 mb-3">
            <Label htmlFor="name" className="form-label">
              Remarks
            </Label>
            <Select
              id="decline-remarks"
              name="decline-remarks"
              placeholder="Select reason for Dispute"
              value={selectedRemark}
              options={remarksList?.reasons?.map((remark, index) => ({
                label: `${remark?.reason}`,
                value: `${remark?.id}`,
              }))}
              onChange={data => {
                setSelectedRemark(data);
                setError(false);
              }}
            />
            {error && <div className="text-danger">Please select remarks</div>}
          </Col>

          <Col md={12} className="mt-3 mb-4 text-center">
            {!loading && (
              <Button color="primary" style={{ fontSize: '14px', width: '200px' }} onClick={handleSubmit}>
                Raise Dispute
              </Button>
            )}

            {loading && (
              <Button color="primary" style={{ fontSize: '14px', width: '200px' }}>
                <Spinner className="btn-replacer-spinner" />
              </Button>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default RaiseDispute;
