import React, { useEffect, useState } from 'react';
import { Constant } from '../Containers/Helpers/constant';

const NavdataV2 = () => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [isDashboard, setIsDashboard] = useState(false);
  const [iscurrentState, setIscurrentState] = useState('Dashboard');
  const [isDynamicReason, setIsDynamicReason] = useState(false);
  const [isAirportTransfer, setIsAirportTransfer] = useState(false);

  const userRole = JSON.parse(localStorage.getItem('selectedRole'));

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu');
      const iconItems = ul.querySelectorAll('.nav-icon.active');
      let activeIconItems = [...iconItems];
      activeIconItems.forEach(item => {
        item.classList.remove('active');
        var id = item.getAttribute('subitems');
        if (document.getElementById(id)) document.getElementById(id).classList.remove('show');
      });
    }
  }

  /* ---------------------------- MENU ---------------------------- */
  let menuItems = [
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'ri-dashboard-2-line',
      link: Constant.userTypes.VENDOR_USER_V2 === Number(localStorage.getItem('userType')) ? '/v2/vendor-dashboard' : '/v2/dashboard',
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsDashboard(!isDashboard);
        setIscurrentState('Dashboard');
      },
    },

    // DYNAMIC REASONS
    userRole?.code === 'OPERATIONS_HEAD' &&
      Constant.userTypes.ADMIN_USER_V2 === Number(localStorage.getItem('userType')) && {
        id: 'dynamic-reasons',
        label: 'Dynamic Reasons',
        icon: 'ri-dashboard-2-line',
        link: '#',
        stateVariables: isDynamicReason,
        click: function (e) {
          e.preventDefault();
          updateIconSidebar(e);
          setIsDynamicReason(!isDynamicReason);
          setIscurrentState('dynamic-reasons');
        },
        subItems: [],
      },
  ];

  // CATEGORY
  const itemAirportTransfer = {
    label: 'Airport Transfer',
    link: '/v2/dynamic-reason',
    parentId: 'dynamic-reasons',
    // typeId: parseInt(Constant?.BOOKING_TYPES?.pickupAndDropBookings?.objectId),
  };

  /* ---------------------------- HANDLE SUB-MENU'S ---------------------------- */
  menuItems = menuItems?.map(item => {
    if (item?.id === `dynamic-reasons`) {
      let tempSubItems = [...item.subItems];

      /* -------------- SUPPLY-STORE MANAGEMENT -------------- */
      // Airport Transfer
      const tempItemCategory = { ...itemAirportTransfer };
      tempSubItems.push({ ...tempItemCategory });
      return {
        ...item,
        subItems: tempSubItems,
      };
    } else {
      return item;
    }
  });

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default NavdataV2;
