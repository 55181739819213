import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// import { baseUrl, customHeaders, umsUrl, adminAPIURL } from '../../../../Containers/Config/index.js';
import { baseUrl, customHeaders, umsUrl, adminAPIURL } from '../../../../../Containers/Config/index.js';
import { ADD_REASON, ADD_REASON_SUCCESS, ADD_REASON_ERROR } from '../../actions.js';
// import apiCall from '../../apiCall';
import apiCall from '../../../apiCall.js';

function* addReason({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v2/remarks`,
      method: 'POST',
      headers: {
        // Authorization: Constant?.CLIENT_TOKEN,
        ...filteredHeaders,
        // withCredentials: true
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: ADD_REASON_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: ADD_REASON_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: ADD_REASON_ERROR, payload: error });
  }
}

export function* watchaddReason() {
  yield takeEvery(ADD_REASON, addReason);
}

function* addReasonSaga() {
  yield all([fork(watchaddReason)]);
}

export default addReasonSaga;
