import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Container, Nav, NavItem, Row, TabContent, TabPane } from 'reactstrap';

import {
  CREATE_BOOKING_EMPTY,
  CREATE_CLIENT_USER_EMPTY,
  GET_BENEFIT_ITEMS_EMPTY,
  GET_BINS_LIST_EMPTY,
  GET_CLIENT_BENEFIT_CONFIG_EMPTY,
  GET_MEMBERSHIPS_DATA_EMPTY,
  GET_USER_DETAILS_EMPTY,
  VALIDATE_USER_EMPTY,
  VENDOR_OTP_VALIDATE_EMPTY,
  VENDOR_RESEND_OTP_EMPTY,
} from '../../../../../../store/actions';
import DefaultScreen from './Components/DefaultScreen';
import ExistingCustomer from './Components/ExistingCustomer';
import NewCardVoucher from './Components/NewCardVoucher';
import ProjectsSelection from './Components/ProjectsSelection';

const GenerateVoucher = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [activeTab, setActiveTab] = useState(`project-selection`);
  const [selectedBank, setSelectedBank] = useState(null);

  const componentUnmount = () => {
    dispatch({
      type: GET_BINS_LIST_EMPTY,
    });
    dispatch({
      type: CREATE_CLIENT_USER_EMPTY,
    });
    dispatch({
      type: GET_MEMBERSHIPS_DATA_EMPTY,
    });
    dispatch({
      type: GET_BENEFIT_ITEMS_EMPTY,
    });
    dispatch({
      type: GET_CLIENT_BENEFIT_CONFIG_EMPTY,
    });
    dispatch({
      type: VENDOR_RESEND_OTP_EMPTY,
    });
    dispatch({
      type: VENDOR_OTP_VALIDATE_EMPTY,
    });
    dispatch({
      type: VALIDATE_USER_EMPTY,
    });
    dispatch({
      type: GET_USER_DETAILS_EMPTY,
    });
    dispatch({
      type: CREATE_BOOKING_EMPTY,
    });
  };

  useEffect(() => {
    return () => {
      setActiveTab(`project-selection`);
      componentUnmount();
    };
  }, []);

  console.log('hello active tab', {
    activeTab,
    selectedBank,
  });

  /* ---------------------------- TAB HANDLER ---------------------------- */
  const pillsToggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }

    componentUnmount();
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        {activeTab === `project-selection` && (
          <ProjectsSelection activeTab={activeTab} setActiveTab={setActiveTab} selectedBank={selectedBank} setSelectedBank={setSelectedBank} />
        )}

        {activeTab === `journey-selection` && (
          <>
            <Row>
              <Col md={12}>
                <h4 className="mb-4 text-primary">{t(`Generate Voucher`)}</h4>
              </Col>

              {(selectedBank?.value === `SAB_LANDMARK` || selectedBank?.value === `JIZAN_UNIVERSITY`) && (
                <Col md={6}>
                  <DefaultScreen
                    data={{
                      name: `${t('New card voucher')}`,
                      description: `Generate gift voucher against new credit card for

                      ${selectedBank?.value === `SAB_LANDMARK` ? 'SAB' : ''}
                      ${selectedBank?.value === `ALRAJHI_LANDMARK` ? 'ALRAJHI' : ''}
                      ${selectedBank?.value === `JIZAN_UNIVERSITY` ? 'Jihan university' : ''}
                        Bank`,
                      btnTitle: `${t(`Generate Voucher`)}`,
                      icon: `ri-ticket-2-fill`,
                    }}
                    onClick={() => setActiveTab(`new-card-voucher`)}
                  />
                </Col>
              )}

              {(selectedBank?.value === `SAB_LANDMARK` ||
                selectedBank?.value === `ALRAJHI_LANDMARK` ||
                selectedBank?.value === `JIZAN_UNIVERSITY`) && (
                <Col md={6}>
                  <DefaultScreen
                    data={{
                      name: `${t('Existing Customer')}`,
                      description: `${t(
                        `Generate discount voucher for   
                         ${selectedBank?.value === `SAB_LANDMARK` ? 'SAB' : ''}
                        ${selectedBank?.value === `ALRAJHI_LANDMARK` ? 'ALRAJHI' : ''}
                        ${selectedBank?.value === `JIZAN_UNIVERSITY` ? 'Jihan university' : ''} 
                      Bank existing credit card customers`,
                      )}`,
                      btnTitle: `${t(`Redeem offer`)}`,
                      icon: `ri-group-fill`,
                    }}
                    onClick={() => setActiveTab(`existing-customer`)}
                  />
                </Col>
              )}
            </Row>
          </>
        )}

        {(activeTab === `existing-customer` || activeTab === `new-card-voucher`) && (
          <>
            <Row>
              <Col xxl={12}>
                <h4 className="mb-4 text-primary">{t(`Generate Voucher`)}</h4>
                <Card>
                  <CardBody>
                    <Nav pills className="nav nav-pills mb-3">
                      {(selectedBank?.value === `SAB_LANDMARK` || selectedBank?.value === `JIZAN_UNIVERSITY`) && (
                        <NavItem>
                          <button
                            onClick={() => {
                              pillsToggle('new-card-voucher');
                            }}
                            className={`btn ${activeTab === `new-card-voucher` && 'bg-soft-dark fw-semibold'} `}
                          >
                            {t(`New card voucher`)}
                          </button>
                        </NavItem>
                      )}

                      {(selectedBank?.value === `SAB_LANDMARK` ||
                        selectedBank?.value === `JIZAN_UNIVERSITY` ||
                        selectedBank?.value === `ALRAJHI_LANDMARK`) && (
                        <NavItem>
                          <button
                            onClick={() => {
                              pillsToggle('existing-customer');
                            }}
                            className={`btn ${activeTab === `existing-customer` && 'bg-soft-dark fw-semibold'} `}
                          >
                            {t(`Existing Customer`)}
                          </button>
                        </NavItem>
                      )}
                    </Nav>
                    <hr />

                    <TabContent activeTab={activeTab} className="text-muted">
                      <TabPane tabId="new-card-voucher">{activeTab === `new-card-voucher` && <NewCardVoucher selectedBank={selectedBank} />}</TabPane>

                      <TabPane tabId="existing-customer">
                        {activeTab === `existing-customer` && <ExistingCustomer selectedBank={selectedBank} />}
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};
export default GenerateVoucher;
