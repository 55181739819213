import {
  GET_DASHBOARD_CONFIGURATION,
  GET_DASHBOARD_CONFIGURATION_SUCCESS,
  GET_DASHBOARD_CONFIGURATION_ERROR,
  GET_DASHBOARD_CONFIGURATION_EMPTY,
} from '../../../actions';

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const GetDashboardConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_CONFIGURATION:
      state = {
        ...state,
        // data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      };
      break;

    case GET_DASHBOARD_CONFIGURATION_SUCCESS:
      console.log('hello action', action.payload);
      state = {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };
      break;

    case GET_DASHBOARD_CONFIGURATION_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };
      break;

    case GET_DASHBOARD_CONFIGURATION_EMPTY:
      state = {
        ...initialState,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GetDashboardConfiguration;
