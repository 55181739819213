import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { customHeaders, umsUrl } from '../../../../Containers/Config/index.js';
import { LOGOUT, LOGOUT_SUCCESS, LOGOUT_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';

function* userLogout() {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${umsUrl}/admin/v2/logout`,
      method: 'POST',
      headers: {
        ...filteredHeaders,
      },
    });

    if (response?.error === undefined) {
      yield put({ type: LOGOUT_SUCCESS, payload: response });
    } else {
      yield put({ type: LOGOUT_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: LOGOUT_ERROR, payload: error });
  }
}
export function* watchUserLogout() {
  yield takeEvery(LOGOUT, userLogout);
}

function* userLogoutSaga() {
  yield all([fork(watchUserLogout)]);
}

export default userLogoutSaga;
