import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { adminAPIURL, customHeaders } from '../../../../../../Containers/Config/index.js';
import apiCall from '../../../../apiCall.js';
import { CANCELLATION_REMARKS_FOR_VENDOR, CANCELLATION_REMARKS_FOR_VENDOR_ERROR, CANCELLATION_REMARKS_FOR_VENDOR_SUCCESS } from '../../../actions.js';

function* cancellationRemarksForVendor({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/remarks`,
      method: 'GET',
      headers: {
        ...filteredHeaders,
      },
      params: {
        ...payload?.params,
      },
    });

    if (response.status === 200) {
      yield put({
        type: CANCELLATION_REMARKS_FOR_VENDOR_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CANCELLATION_REMARKS_FOR_VENDOR_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CANCELLATION_REMARKS_FOR_VENDOR_ERROR, payload: error });
  }
}

export function* watchCancellationRemarksForVendor() {
  yield takeEvery(CANCELLATION_REMARKS_FOR_VENDOR, cancellationRemarksForVendor);
}

function* cancellationRemarksForVendorSaga() {
  yield all([fork(watchCancellationRemarksForVendor)]);
}

export default cancellationRemarksForVendorSaga;
