import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_POST_TYPES, GET_POST_TYPES_SUCCESS, GET_POST_TYPES_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getPostTypes({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/adib/generic/post-types`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
        pageSize: Constant?.TABLE_PAGE_SIZE,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: GET_POST_TYPES_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_POST_TYPES_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_POST_TYPES_ERROR, payload: error });
  }
}

export function* watchGetPostTypes() {
  yield takeEvery(GET_POST_TYPES, getPostTypes);
}

function* getPostTypesSaga() {
  yield all([fork(watchGetPostTypes)]);
}

export default getPostTypesSaga;
