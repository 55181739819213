import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BOOKING_ASSIGN_TO_ME_EMPTY } from '../../../../../../../store/actions';
import { Loader } from '../../../../../../Common';

const LoaderData = ({ setCurrentActiveBookingAction }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATE ---------------------------- */
  const [loading, setLoading] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const {
    bookingAssignToMeLoading,
    assignVendorRemarksLoading,
    cancellationRemarksLoading,
    carsListOfVendorLoading,
    chauffeursListLoading,
    vendorsListLoading,
    assignToMeLoading,
    assignChauffeurLoading,
  } = useSelector((state: any) => ({
    assignVendorRemarksLoading: state?.AssignVendorRemarksList?.loading,
    cancellationRemarksLoading: state?.CancellationRemarksForVendor?.loading,
    carsListOfVendorLoading: state?.GetCarsListOfVendor?.loading,
    chauffeursListLoading: state?.GetChauffersList?.loading,
    vendorsListLoading: state?.GetVendorsListData?.loading,
    assignToMeLoading: state?.BookingAssignToMe?.loading,
    assignChauffeurLoading: state?.AssignChauffeur?.loading,
    bookingAssignToMeLoading: state?.BookingAssignToMe?.loading,
  }));

  useEffect(() => {
    if (
      bookingAssignToMeLoading ||
      assignVendorRemarksLoading ||
      cancellationRemarksLoading ||
      carsListOfVendorLoading ||
      chauffeursListLoading ||
      vendorsListLoading ||
      assignToMeLoading ||
      assignChauffeurLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    bookingAssignToMeLoading,
    assignVendorRemarksLoading,
    cancellationRemarksLoading,
    carsListOfVendorLoading,
    chauffeursListLoading,
    vendorsListLoading,
    assignToMeLoading,
    assignChauffeurLoading,
  ]);

  const { bookingAssignToMe } = useSelector((state: any) => ({
    bookingAssignToMe: state?.BookingAssignToMe?.loading,
  }));

  useEffect(() => {
    if (bookingAssignToMe !== null) {
      dispatch({
        type: BOOKING_ASSIGN_TO_ME_EMPTY,
      });
      setCurrentActiveBookingAction(null);
    }
  }, [bookingAssignToMe]);

  return <>{loading && <Loader zIndex={true} />}</>;
};

export default LoaderData;
