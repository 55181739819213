import React, { useState, useEffect } from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { GET_REASON_BY_ACTIONS, MODIFY_BOOKING, MODIFY_BOOKING_EMPTY } from '../../../../../../../store/actions';
import cogoToast from 'cogo-toast';

const ModifyBooking = ({ onClose, currentSelectedBooking }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedRemark, setSelectedRemark] = useState(null);
  const [initialState, setInitialState] = useState({});

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { modifyBooking, modifyBookingLoading, showModifyField, showModifyFieldLoading, remarksList, remarksListLoading } = useSelector(state => ({
    modifyBooking: state?.ModifyBooking?.data,
    modifyBookingLoading: state?.ModifyBooking?.loading,

    showModifyField: state?.ShowModifyField?.data,
    showModifyFieldLoading: state?.ShowModifyField?.loading,

    remarksList: state?.GetReasonByActions?.data,
    remarksListLoading: state?.GetReasonByActions?.loading,
  }));

  useEffect(() => {
    if (showModifyField?.fields && currentSelectedBooking) {
      const newState = showModifyField.fields.reduce((acc, field) => {
        if (field?.key === 'service_date') {
          const [date] = currentSelectedBooking?.serviceDateTime?.split(' ');
          acc[field.key] = moment(date, 'DD/MM/YYYY')?.format('YYYY-MM-DD');
        } else if (field?.key === 'service_time') {
          const [, time, day] = currentSelectedBooking?.serviceDateTime?.split(' ');
          acc[field.key] = moment(`${time} ${day}`, 'h:mm A').format('HH:mm');
        } else {
          acc[field.key] = currentSelectedBooking?.[field?.key] || '';
        }
        return acc;
      }, {});
      setState(newState);
      setInitialState(newState); //
    }
  }, [showModifyField, currentSelectedBooking]);

  useEffect(() => {
    dispatch({
      type: GET_REASON_BY_ACTIONS,
      payload: {
        urlParams: {
          booking_type: 'airport_transfers',
          booking_status: currentSelectedBooking?.bookingStatus,
          action: 'modify_booking',
          is_active: true,
        },
      },
    });
  }, [currentSelectedBooking]);

  const handleChange = (key, value) => {
    setState(prevState => ({ ...prevState, [key]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [key]: '' }));
  };

  const validateForm = () => {
    let newErrors = {};

    showModifyField?.fields?.forEach(field => {
      const value = state[field.key];

      if (!value) {
        newErrors[field.key] = `${field.name} is required`;
      }

      // Validate number fields
      if (field.type === 'number' && value !== '' && (value < 0 || value > field.max)) {
        newErrors[field.key] = `Value must be between 0 and ${field.max}`;
      }

      if (field.type === 'date' && value !== '' && moment(value).isBefore(moment(field.from))) {
        newErrors[field.key] = `The date must be between ${field.from} and ${field.to}.`;
      }
    });

    if (!selectedRemark) {
      newErrors['reason_id'] = 'Remarks are required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      let updatedFields = Object.keys(state).reduce((acc, key) => {
        if (state[key] !== initialState[key]) {
          acc[key] = state[key];
        }
        return acc;
      }, {});

      if (Object.keys(updatedFields).length === 0) {
        cogoToast.error('No changes detected. Please make the necessary modifications to the booking.');
        return;
      }

      updatedFields = {
        ...updatedFields,
        ...(updatedFields?.service_date && { service_time: state?.service_time }),
        ...(updatedFields?.service_time && { service_date: state?.service_date }),
      };

      dispatch({
        type: MODIFY_BOOKING,
        payload: {
          body: {
            ...updatedFields,
            booking_id: currentSelectedBooking?.id,
            reason_id: selectedRemark?.value,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (modifyBooking !== null) {
      dispatch({
        type: MODIFY_BOOKING_EMPTY,
      });
      onClose();
    }

    return () => {
      dispatch({
        type: MODIFY_BOOKING_EMPTY,
      });
    };
  }, [modifyBooking]);

  return (
    <Modal isOpen={true} centered size="md">
      <ModalHeader toggle={onClose}>
        <h4>Modify Booking</h4>
      </ModalHeader>

      <ModalBody>
        <Row>
          {Object.keys(state).length > 0 &&
            showModifyField?.fields?.length > 0 &&
            showModifyField?.fields?.map(field => (
              <Col md={6} className="mb-3" key={field.key}>
                <Label className="form-label">{field.name}</Label>
                {field.type === 'date' ? (
                  <div className="area_expandable">
                    <Flatpickr
                      className="form-control"
                      options={{
                        dateFormat: 'Y-m-d',
                        mode: 'single',
                        minDate: state[field.key] && moment(state[field.key]).isBefore(moment(field.from)) ? state[field.key] : field.from,
                        maxDate: field.to,
                        // enable: state?.[field?.key] ? [state?.[field.key]] : [], // Allow showing the initial value even if it's outside range
                      }}
                      value={state?.[field?.key]}
                      onChange={date => handleChange(field.key, moment(date[0]).format('YYYY-MM-DD'))}
                    />
                    <span className="icon_calendar_v2">
                      {' '}
                      <i className="ri-calendar-2-line"></i>
                    </span>
                  </div>
                ) : field.type === 'time' ? (
                  <Input type="time" value={state?.[field?.key]} onChange={e => handleChange(field.key, e.target.value)} />
                ) : (
                  <Input
                    type="number"
                    value={state?.[field?.key]}
                    onChange={e => {
                      let value = e.target.value;

                      // Remove leading zeros and ensure it's a valid number
                      value = value.replace(/^0+/, '') || '0';
                      value = parseInt(value, 10);

                      if (isNaN(value)) value = ''; // Allow clearing the input

                      handleChange(field.key, String(value));

                      // Validate and set error message
                      if (value < 0 || value > field.max) {
                        setErrors(prevErrors => ({
                          ...prevErrors,
                          [field.key]: `Value must be between 0 and ${field.max}`,
                        }));
                      } else {
                        setErrors(prevErrors => ({
                          ...prevErrors,
                          [field.key]: '', // Clear error when valid
                        }));
                      }
                    }}
                  />
                )}
                {errors[field.key] && <div className="text-danger">{errors[field.key]}</div>}
              </Col>
            ))}
          <Col md={12} className="mt-3 mb-3">
            <Label htmlFor="name" className="form-label">
              Remarks
            </Label>
            <Select
              id="decline-remarks"
              name="decline-remarks"
              placeholder="Select reason for Modify the booking"
              value={selectedRemark}
              options={remarksList?.reasons?.map((remark, index) => ({
                label: `${remark?.reason}`,
                value: `${remark?.id}`,
              }))}
              onChange={data => {
                setSelectedRemark(data);
                setErrors(prevErrors => ({ ...prevErrors, reason_id: '' }));
              }}
            />
            {errors['reason_id'] && <div className="text-danger">{errors['reason_id']}</div>}
          </Col>

          <Col md={12} className="mt-3 mb-4 text-center">
            {!modifyBookingLoading && (
              <Button color="primary" style={{ fontSize: '14px', width: '200px' }} onClick={handleSubmit}>
                Modify Booking
              </Button>
            )}

            {modifyBookingLoading && (
              <Button color="primary" style={{ fontSize: '14px', width: '200px' }}>
                <Spinner className="btn-replacer-spinner" />
              </Button>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ModifyBooking;
