import React from 'react';
import CountUp from 'react-countup';
import { FaFire } from 'react-icons/fa';
import { Card, CardBody } from 'reactstrap';
import vendorAssign from '../../../../../assets/custom/v2/vendor.svg';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

const StatusCard = ({ timelineBooking, date, cardData }) => {
  const history = useHistory();

  const totalCount =
    cardData?.[timelineBooking?.name]?.data?.totalCount || cardData?.[timelineBooking?.name]?.data?.totalCount === 0
      ? Number(cardData?.[timelineBooking?.name]?.data?.totalCount)
      : null;

  const routeHandler = () => {
    const params = {
      startDate: date?.startDate,
      endDate: date?.endDate,
      name: timelineBooking?.name,
      type: timelineBooking?.type,
    };
    if (timelineBooking?.nextNavigation) {
      history.push({
        pathname: '/v2/booking/airport-transfer',
        search: `?${queryString.stringify(params)}`,
      });
    }
  };

  return (
    <Card
      style={{
        border: '1px solid #FFFFFF',
        borderRadius: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', // Added boxShadow
        cursor: timelineBooking?.nextNavigation && 'pointer',
      }}
      className="status-card"
      onClick={routeHandler}
    >
      <CardBody>
        <div className="d-flex justify-content-between">
          <div
          // className="icon-container"
          // style={{
          //   background: '#E62E3F1A',
          //   padding: '10px',
          //   borderRadius: '10px',
          //   height: '40px',
          // }}
          >
            <img src={timelineBooking?.icon ? timelineBooking?.icon : vendorAssign} style={{ maxWidth: '51px' }} alt="" />
          </div>
          <div>
            <p className="m-0" style={{ fontWeight: '500', fontSize: '15px', color: 'black' }}>
              {timelineBooking?.displayName}
            </p>
            <div style={{ textAlign: 'end' }}>
              {totalCount != null ? (
                <CountUp
                  separator=""
                  start={0}
                  end={totalCount}
                  duration={2}
                  style={{ color: 'black', fontSize: '25px', fontWeight: '500' }}
                  className="fw-semibold"
                />
              ) : (
                <p className="fw-bold text-format-3 m-0">-</p>
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default StatusCard;
