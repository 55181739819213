import React from 'react';
import CountUp from 'react-countup';
import { Card, CardBody, Col } from 'reactstrap';

interface CustomCardProps {
  label: string;
  counter: string;
  icon: string;
  iconClass: string;
}

const CustomCard: React.FC<CustomCardProps> = ({ label, counter, icon, iconClass }) => {
  return (
    <Col xl={4} md={6}>
      <Card className="card-animate" style={{ borderRadius: '10px' }}>
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <p className="text-uppercase mb-0 text-end" style={{ fontWeight: '550' }}>
                {label}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-end justify-content-between">
            <div className="avatar-sm flex-shrink-0">
              <span className={`avatar-title rounded fs-3 bg-soft-${iconClass}`}>
                <i className={`${icon} text-${iconClass}`}></i>
              </span>
            </div>
            <div>
              <h4 className="fs-22 fw-semibold ff-secondary">
                <span className="counter-value">
                  <CountUp separator="" start={0} end={Number(counter)} duration={4} className="fw-semibold" />
                </span>
              </h4>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CustomCard;
