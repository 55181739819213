import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';

import { COMPLETE_BOOKING_EMPTY, COMPLETE_BOOKING, GET_REASON_BY_ACTIONS } from '../../../../../../../store/actions';
import { Error } from '../../../../../../Common';

const MarkAsComplete = ({ onClose, currentSelectedBooking, isMultiSelectable }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [selectedRemark, setSelectedRemark] = useState(null);
  const [isShowError, setIsShowError] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { completeBooking, completeBookingLoading, markAsCompleteReasons, markAsCompleteReasonsLoading } = useSelector(state => ({
    completeBooking: state?.CompleteBooking?.data,
    completeBookingLoading: state?.CompleteBooking?.loading,

    markAsCompleteReasons: state?.GetReasonByActions?.data,
    markAsCompleteReasonsLoading: state?.GetReasonByActions?.loading,
  }));

  useEffect(() => {
    if (completeBooking !== null) {
      dispatch({
        type: COMPLETE_BOOKING_EMPTY,
      });
      onClose();
    }

    return () => {
      dispatch({
        type: COMPLETE_BOOKING_EMPTY,
      });
    };
  }, [completeBooking]);

  useEffect(() => {
    let currentBookingStatus = ``;
    if (isMultiSelectable) {
      currentBookingStatus = currentSelectedBooking?.[0]?.bookingStatus;
    } else {
      currentBookingStatus = currentSelectedBooking?.bookingStatus;
    }

    dispatch({
      type: GET_REASON_BY_ACTIONS,
      payload: {
        urlParams: { booking_type: 'airport_transfers', booking_status: currentBookingStatus, action: 'complete_booking', is_active: true },
      },
    });
  }, [currentSelectedBooking]);

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    if (label === `reason_id`) {
      setSelectedRemark(data);
    } else {
      setIsShowError(false);
    }
  };

  /* ---------------------------- COMPLETE BOOKING HANDLER ---------------------------- */
  const completeBookingHandler = () => {
    if (selectedRemark === null) {
      setIsShowError(true);
    } else {
      dispatch({
        type: COMPLETE_BOOKING,
        payload: {
          body: {
            booking_ids: isMultiSelectable ? currentSelectedBooking?.map(booking => booking?.id) : [`${currentSelectedBooking?.id}`],
            reason_id: `${selectedRemark?.value}`,
          },
        },
      });
    }
  };

  return (
    <Modal isOpen={true} centered>
      <ModalHeader toggle={onClose}>
        {isMultiSelectable && Array.isArray(currentSelectedBooking) && currentSelectedBooking?.length > 1 ? (
          <h4>Mark {currentSelectedBooking?.length ?? 0} Bookings as Complete</h4>
        ) : (
          <h4>Mark Booking as Complete</h4>
        )}

        {isMultiSelectable && Array.isArray(currentSelectedBooking) && currentSelectedBooking?.length === 1 && (
          <p className="modal-subheading">Booking ID : {currentSelectedBooking?.[0]?.bookingId ?? ''}</p>
        )}
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={12} className="mb-3">
            <Select
              id="completion-remarks"
              name="completion-remarks"
              placeholder="Select reason to mark selected bookings as complete"
              value={selectedRemark}
              options={markAsCompleteReasons?.reasons?.map((remark, index) => ({
                label: `${remark?.reason}`,
                value: `${remark?.id}`,
              }))}
              onChange={data => inputSelectHandler(`reason_id`, data)}
            />
            {isShowError && selectedRemark === null && <Error text="Kindly select reason to mark selected bookings as complete" ref={null} />}
          </Col>

          <Col md={12} className="mt-3 mb-4 text-center">
            {!completeBookingLoading && (
              <Button color="primary" style={{ fontSize: '14px', width: '160px' }} onClick={completeBookingHandler}>
                Complete Booking
              </Button>
            )}

            {completeBookingLoading && (
              <Button color="primary" style={{ fontSize: '14px', width: '160px' }}>
                <Spinner className="btn-replacer-spinner" />
              </Button>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default MarkAsComplete;
