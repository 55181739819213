import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import * as moment from 'moment';

import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input, ButtonGroup, Button } from 'reactstrap';
import Select from 'react-select';
import { Constant } from '../../../../../../../Helpers/constant';
import { PreviewCardHeader } from '../../../../../../../Common';
import { isANumber, isValidAlphaNumbericWithoutSpace } from '../../../../../../../Helpers/Helpers';
import { GET_CLIENT_HISTORY_LIST } from '../../../../../../../../store/actions';

const statuses = [
  {
    name: `Pending`,
    objectId: Constant?.BOOKING_STATUS?.PENDING,
  },
  {
    name: `Processed`,
    objectId: Constant?.BOOKING_STATUS?.PROCESSED,
  },
  {
    name: `Confirmed`,
    objectId: Constant?.BOOKING_STATUS?.CONFIRM,
  },
  {
    name: `Cancelled (ALL)`,
    objectId: Constant?.BOOKING_STATUS?.CANCELLED,
  },
  {
    name: `Cancelled by User`,
    objectId: Constant?.BOOKING_STATUS?.CANCEL_BY_USER,
  },
  {
    name: `Cancelled by Thriwe`,
    objectId: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
  },
  {
    name: `Short Notice Cancelled by User`,
    objectId: Constant?.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_USER,
  },
  {
    name: `Short Notice Cancelled by Thriwe`,
    objectId: Constant?.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_THRIWE,
  },
  {
    name: `Short Notice Cancelled By Thriwe With Quota`,
    objectId: Constant?.BOOKING_STATUS?.BOOKING_SHORTNOTICE_CANCELED_BY_THRIWE_WITH_QUOTA,
  },
];

const initialState = {
  customerDetails: {
    mobile: ``,
  },
  bookingId: ``,
  updatedAtRange: {
    from: ``,
    to: ``,
  },
  status: 0,
};

const Filter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [filter, setFilter] = useState(initialState);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };

    let tempMobileNumber = '';

    switch (name) {
      case 'mobileNumber':
        if (value?.length === 0) {
          tempMobileNumber = ``;
        } else {
          tempMobileNumber = isANumber(value) ? value : filter?.customerDetails?.mobile;
        }

        updatedState = {
          ...filter,
          customerDetails: {
            mobile: tempMobileNumber,
          },
        };
        break;

      case 'bookingId':
        updatedState = {
          ...filter,
          bookingId: isValidAlphaNumbericWithoutSpace(value) ? value : filter?.bookingId,
        };
        break;

      case 'status-type':
        updatedState = {
          ...filter,
          status: parseInt(value),
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };

    switch (flag) {
      case 'updatedAtRange':
        updatedState = {
          ...filter,
          updatedAtRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        break;

      default:
      // do nothing
    }
    setFilter(updatedState);
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    props?.setCurrentPageNumber(0);

    dispatch({
      type: GET_CLIENT_HISTORY_LIST,
      payload: {
        urlParam: {
          pageIndex: 0,
          bookingTypeId: props?.bookingTypeId,
        },
        headers: {
          code: 'ADIB',
        },
      },
    });
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            {/* <button onClick={() => console.log({ filter })}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Row>
                  <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="mobileNumber" className="form-label">
                        Phone Number
                      </Label>
                      <Input
                        id="mobileNumber"
                        name="mobileNumber"
                        type="text"
                        className="form-control"
                        value={filter?.customerDetails?.mobile}
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="bookingID" className="form-label">
                        Booking Id
                      </Label>
                      <Input
                        id="bookingId"
                        name="bookingId"
                        type="text"
                        className="form-control"
                        value={filter?.bookingId}
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="date-range" className="form-label">
                        Select date range
                        <span className="icon_calendar">
                          <i className="las la-calendar"></i>
                        </span>
                      </Label>
                      <div className="area_expandable">
                        <Flatpickr
                          className="form-control"
                          value={[filter?.updatedAtRange?.from, filter?.updatedAtRange?.to]}
                          onChange={date => datePickHandler(`updatedAtRange`, date)}
                          options={{
                            mode: 'range',
                            dateFormat: 'Y-m-d',
                            maxDate: 'today',
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="status-type" className="form-label">
                        Booking Status
                      </Label>
                      <select id="status-type" name="status-type" className="form-select" value={filter?.status} onChange={inputChangeHandler}>
                        <option value="0">Choose...</option>
                        {statuses?.map((status, index) => (
                          <option key={index} value={status.objectId}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3" onClick={filterSubmitHandler}>
                      Search
                    </button>

                    <button type="submit" className="btn btn-light" onClick={resetFilterHandler}>
                      Reset Filter
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
