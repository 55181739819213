import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { indianProjectsBaseURL, customHeaders, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_ADIB_DASHBOARD, GET_ADIB_DASHBOARD_SUCCESS, GET_ADIB_DASHBOARD_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getAdibDashboard({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/adib/admin/dashboard`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...payload?.headers,
      },
      data: payload?.data,
      params: payload?.urlParams,
    });

    if (response.status === 200) {
      yield put({
        type: GET_ADIB_DASHBOARD_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: GET_ADIB_DASHBOARD_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_ADIB_DASHBOARD_ERROR, payload: error });
  }
}

export function* watchgetAdibDashboard() {
  yield takeEvery(GET_ADIB_DASHBOARD, getAdibDashboard);
}

function* getAdibDashboardSaga() {
  yield all([fork(watchgetAdibDashboard)]);
}

export default getAdibDashboardSaga;
