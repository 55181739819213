import { useEffect, useState } from 'react';

// import { Constant } from '../../../../../../Helpers/constant';
import { Constant } from '../../../../../Helpers/constant';

import { Input } from 'reactstrap';
// import HotClock from './../../../../../../../assets/custom/hot-clock.png';
// import SufficientClock from './../../../../../../../assets/custom/sufficient-clock.png';
// import WarningClock from './../../../../../../../assets/custom/warning-clock.png';
import CustomizedTable from '../../../Bookings/AirportTransfer/Components/Views/CustomizedTable';
import editIcon from '../../../../../../assets/custom/edit-icon.svg';
import { useDispatch } from 'react-redux';
import { UPDATE_REASON } from '../../../../../../store/actions';
// import CustomizedTable from './CustomizedTable';
const TabularView = ({
  data,
  totalCount,
  skip,
  limit,
  handleRowsPerPageChange,
  handlePageChange,
  actionBtnHandler,
  setCurrentSelectedBooking,
  isTableDisplay,
  setIsModalOpen,
}) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookingsData, setBookingsData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '70px',
          wrap: true,
          selector: row => row?.serial_number,
        },
        {
          name: 'Reasons',
          minWidth: '400px',
          cell: row => <span>{row?.reason}</span>,
        },
        {
          name: 'Action',
          width: '250px',
          cell: row => (
            <div className="d-flex justify-content-center gap-2" style={{ cursor: 'pointer' }}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCurrentSelectedBooking(row);
                  setIsModalOpen(true);
                }}
              >
                <img src={editIcon} />
              </div>
              <div className="form-switch">
                <Input
                  className="form-check-input code-switcher form-switch-md"
                  type="checkbox"
                  id="dataUploadRestriction"
                  name="dataUploadRestriction"
                  checked={row?.is_active ? true : false}
                  onChange={() => {
                    dispatch({
                      type: UPDATE_REASON,
                      payload: {
                        id: row?.id,
                        data: {
                          is_active: row?.is_active ? false : true,
                        },
                      },
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);

      setBookingsData(
        data?.map((bookingData, index) => {
          const serial_number = Number(limit || 0) * Number(skip || 0) + (index + 1);
          return {
            serial_number: serial_number,
            ...bookingData,
          };
        }),
      );
    } else {
      setBookingsData([]);
      setColumns([]);
    }
  }, [data]);

  return (
    <CustomizedTable
      columns={columns ?? []}
      data={bookingsData ?? []}
      totalCount={totalCount}
      limit={limit}
      handleRowsPerPageChange={handleRowsPerPageChange}
      currentPage={skip}
      handlePageChange={handlePageChange}
      indexType={Constant.INDEX_TYPE.ZERO_BASED}
      isTableDisplay={isTableDisplay}
    />
  );
};

export default TabularView;
