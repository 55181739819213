import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import classnames from 'classnames';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { AsyncPaginate } from 'react-select-async-paginate';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Card, CardBody, Col, Form, Label, Row, Input, CardFooter, Accordion, AccordionItem, Collapse } from 'reactstrap';

import { Constant } from '../../../../../Helpers/constant';
import { baseUrl, customHeaders } from '../../../../../Config';
import { Error, PreviewCardHeader } from '../../../../../Common';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';
import {
  GET_AIRPORTS_LIST,
  GET_BOOKING_TYPES_LIST,
  GET_COUNTRY,
  GET_COUPON_CATEGORY,
  GET_COUPON_PARTNER,
  GET_REDEMPTION_TYPE,
  GET_REGION,
  GET_SUB_REGION,
  CREATE_LOCATION,
  GET_SUB_REGION_EMPTY,
  UPDATE_LOCATION,
} from '../../../../../../store/application/actionType';
import {
  hasPermission,
  isValidAlphaNumbericWithoutSpace,
  isValidAlphaWithSpec,
  isValidEmail,
  isValidNumber,
  isValidUrl,
} from '../../../../../Helpers/Helpers';
import Flatpickr from 'react-flatpickr';
import axios, { Axios } from 'axios';

const LocationForm = props => {
  const dispatch = useDispatch();

  // Accordions
  const [accordions, setAccordions] = useState({
    general: {
      status: true,
      error: false,
    },
    displayProperties: {
      status: false,
      error: false,
    },
    bookingProperties: {
      status: false,
      error: false,
    },
    tat: {
      status: false,
      error: false,
    },
    merchantDetails: {
      status: false,
      error: false,
    },
    contactDetails: {
      status: false,
      error: false,
    },
    amenities: {
      status: false,
      error: false,
    },
    disclaimer: {
      status: false,
      error: false,
    },
  });

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: '',
    displayName: '',
    category: null,
    partner: null,
    defaultRedemptionType: -1,
    wildCardQuota: 0,
    images: {
      coverImageMobile: '',
      coverImageWeb: '',
      displayImageMobile: '',
      displayImageWeb: '',
    },
    longDescription: '',
    shortDescription: '',
    phone: '',
    countryCode: '',
    whatsappNumber: '',
    address: '',
    area: '',
    pincode: '',
    latitude: '0',
    longitude: '0',
    country: null,
    region: null,
    subRegion: null,
    merchant: {
      username: '',
      password: '',
    },
    thirdPartyWebsite: '',
    redemptionProcess: '',
    benefitOffered: '',
    termsAndCondition: '',
    bookingType: {
      objectId: '',
      name: '',
      typeId: 3,
      redemptionType: {
        id: '',
        name: '',
      },
      collectionName: '',
    },
    type: 2,
    // isActive: false,
    globalFacilityId: '',

    priority: 0,
    tat: {
      minimum: {
        value: 0,
        unit: 0,
      },
      maximum: {
        value: 0,
        unit: 0,
      },
      cancel: {
        value: 0,
        unit: 0,
      },
      confirm: {
        value: 0,
        unit: 0,
      },
    },
    amenities: [],
    isTopRated: {
      value: false,
      label: 'No',
    },
    // emails:'',

    isKidsAllowed: false,
    kids: {
      noOfFreeKids: 0,
      complimentaryAgeLimit: 0,
      maxNoOfKids: 0,
    },
    emailRemarks: '',
    emailTandC: '',
    emailRedemptionProcess: '',
    airport: null,
    facilityDisclaimer: {
      startDate: '',
      endDate: '',
      title: '',
      body: '',
      isActive: false,
    },
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [isMerchant, setIsMerchant] = useState(false);

  const [allCategories, setAllCategories] = useState([]);
  const [allPartners, setAllPartners] = useState([]);
  const [allRedemptionTypes, setAllRedemptionTypes] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [allRegions, setAllRegions] = useState(null);
  const [allSubRegions, setAllSubRegions] = useState(null);
  const [allAmenities, setAllAmenities] = useState([]);
  const [mobileNumber, setMobileNumbers] = useState(['']);
  const [emails, setEmails] = useState(['']);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Categories
  const { categories } = useSelector(state => ({
    categories: state?.GetCouponCategory?.data,
  }));

  // Partners
  const { partners } = useSelector(state => ({
    partners: state?.GetCouponPartner?.data,
  }));

  // Redemption Types
  const { redemptionTypes } = useSelector(state => ({
    redemptionTypes: state?.GetRedemptionType?.data,
  }));

  // Countries
  const { countries } = useSelector(state => ({
    countries: state?.Countries?.data,
  }));

  // Regions
  const { regions } = useSelector(state => ({
    regions: state?.Regions?.data,
  }));

  // Sub Regions
  const { subRegions } = useSelector(state => ({
    subRegions: state?.SubRegions?.data,
  }));

  // Airports
  const { airports } = useSelector(state => ({
    airports: state?.Airports?.data,
  }));

  // Booking Types
  const { bookingTypes } = useSelector(state => ({
    bookingTypes: state?.BookingTypes?.data,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (redemptionTypes === null) {
      dispatch({
        type: GET_REDEMPTION_TYPE,
      });
    }

    if (countries === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }

    if (airports === null) {
      dispatch({
        type: GET_AIRPORTS_LIST,
      });
    }

    if (bookingTypes === null) {
      dispatch({
        type: GET_BOOKING_TYPES_LIST,
      });
    }

    if (categories === null) {
      dispatch({
        type: GET_COUPON_CATEGORY,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (partners !== null) {
      setAllPartners(
        partners?.map(partner => {
          return {
            value: partner?.objectId,
            label: partner?.name,
            data: partner,
          };
        }),
      );

      if (state?.partner !== null) {
        const dPartner = partners?.filter(partner => partner?.objectId === state?.partner?.value);
        if (dPartner?.length > 0) {
          const selectedPartner = dPartner?.length > 0 ? dPartner[0] : null;
          setAllAmenities(
            selectedPartner?.amenities?.map(amenity => ({
              value: amenity?.name,
              label: amenity?.name,
              data: amenity,
            })),
          );
        }
      }
    }
  }, [partners]);

  useEffect(() => {
    if (categories !== null) {
      setAllCategories(
        categories?.map(category => {
          return {
            value: category?.objectId,
            label: category?.name,
            data: category,
          };
        }),
      );
    }
  }, [categories]);

  useEffect(() => {
    if (redemptionTypes !== null) {
      setAllRedemptionTypes(
        redemptionTypes?.map(redemptionType => {
          return {
            value: redemptionType?.objectId,
            label: redemptionType?.name,
          };
        }),
      );
    }
  }, [redemptionTypes]);

  useEffect(() => {
    if (countries !== null) {
      setAllCountries(
        countries?.map(country => {
          return {
            value: country?.countryId,
            label: country?.name,
            data: country,
          };
        }),
      );
    } else {
      setAllCountries(null);
    }
  }, [countries]);

  useEffect(() => {
    if (regions !== null) {
      setAllRegions(
        regions?.map(region => {
          return {
            value: region?.stateId,
            label: region?.name,
          };
        }),
      );
    } else {
      setAllRegions(null);
    }
  }, [regions]);

  useEffect(() => {
    if (subRegions !== null) {
      setAllSubRegions(
        subRegions?.map(subRegion => {
          return {
            value: subRegion?.cityId,
            label: subRegion?.name,
          };
        }),
      );
    } else {
      setAllSubRegions(null);
    }
  }, [subRegions]);

  useEffect(() => {
    if (airports !== null) {
      // setAllAirports(
      //   airports?.map((airport) => {
      //     return {
      //       value: airport?.objectId,
      //       label: airport?.name,
      //       data: airport,
      //     }
      //   }),
      // )
    }
  }, [airports]);

  useEffect(() => {
    if (bookingTypes !== null) {
      // setAllBookingTypes(
      //   bookingTypes?.map((bookingType) => {
      //     return {
      //       value: bookingType?.objectId,
      //       label: bookingType?.name,
      //       data: bookingType,
      //     }
      //   }),
      // )
    }
  }, [bookingTypes]);

  useEffect(() => {
    if (props?.data !== undefined) {
      const tempStateData = {
        ...props?.data,

        category: {
          value: props?.data?.category?.id,
          label: props?.data?.category?.name,
          data: props?.data?.category,
        },
        partner: {
          value: props?.data?.partner?.id,
          label: props?.data?.partner?.name,
          data: props?.data?.partner,
        },
        bookingType: {
          value: props?.data?.bookingType?.objectId,
          label: props?.data?.bookingType?.name,
          data: props?.data?.bookingType,
        },
        amenities: props?.data?.amenities?.map(amenity => ({
          value: amenity?.name,
          label: amenity?.logo,
          data: amenity,
        })),
        isTopRated: {
          value: props?.data?.isTopRated,
          label: props?.data?.isTopRated ? `Yes` : `No`,
        },
        country: {
          value: props?.data?.country?.id,
          label: props?.data?.country?.name,
        },
        region: {
          value: props?.data?.region?.id,
          label: props?.data?.region?.name,
        },
        airport: {
          value: props?.data?.airport?.id,
          label: props?.data?.airport?.name,
          completeData: {
            address: props?.data?.airport?.address,
          },
        },
        subRegion: {
          value: props?.data?.subRegion?.id,
          label: props?.data?.subRegion?.name,
        },
      };
      setState({
        ...tempStateData,
      });

      if (props?.data?.partner?.amenities?.length > 0) {
        setAllAmenities(
          props?.data?.partner?.amenities?.map(amenity => ({
            value: amenity?.name,
            label: amenity?.name,
            data: amenity,
          })),
        );
      }

      if (props?.data?.phoneNumbers?.length > 0) {
        setMobileNumbers(props?.data?.phoneNumbers);
      }

      if (props?.data?.emails?.length > 0) {
        setEmails(props?.data?.emails);
      }

      dispatch({
        type: GET_COUPON_PARTNER,
        payload: {
          urlParam: {
            categoryId: props?.data?.category?.id,
          },
        },
      });

      // validationCheck({ ...tempStateData });
    }
  }, [props?.data]);

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */

  const loadOptionForOutlet = async (search,type) => {
    console.log(search,type);
    let URL='';
    if (type === `partner`) {
      URL = `${baseUrl}/admin/partners?pageIndex=0&pageSize=10`;
     
    } else if (type === `category`) {
     
      URL = `${baseUrl}/admin/categories?pageIndex=0&pageSize=10`;
     
    } 
console.log('url-->',URL);
    return await axios({
      method: `GET`,
      url: URL,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search === '' ? null : search,
        categoryId:type==='partner'? state?.category?.value :null
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.objectId,
              label: `${data?.name}`,
              data: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  };
  
  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: isValidAlphaWithSpec(value) ? value : state?.name,
        };
        break;

      case 'emailRemarks':
        updatedState = {
          ...state,
          emailRemarks: value,
        };
        break;

      case 'displayName':
        updatedState = {
          ...state,
          displayName: isValidAlphaWithSpec(value) ? value : state?.displayName,
        };
        break;

      case 'logo':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            logo: value,
          },
        };
        break;

      case 'coverImageMobile':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            coverImageMobile: value,
          },
        };
        break;

      case 'coverImageWeb':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            coverImageWeb: value,
          },
        };
        break;

      case 'displayImageMobile':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            displayImageMobile: value,
          },
        };
        break;

      case 'displayImageWeb':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            displayImageWeb: value,
          },
        };
        break;

      case 'thirdPartyWebsite':
        updatedState = {
          ...state,
          thirdPartyWebsite: value,
        };
        break;

      case 'globalFacilityId':
        updatedState = {
          ...state,
          globalFacilityId: isValidAlphaNumbericWithoutSpace(value) ? value : state?.globalFacilityId,
        };
        break;

      case 'shortDescription':
        updatedState = {
          ...state,
          shortDescription: isValidAlphaWithSpec(value) ? value : state?.shortDescription,
        };
        break;

      case 'longDescription':
        updatedState = {
          ...state,
          longDescription: isValidAlphaWithSpec(value) ? value : state?.longDescription,
        };
        break;

      case 'is-mql-active':
        updatedState = {
          ...state,
          mql: {
            isActive: !state.mql.isActive,
          },
        };
        break;

      case 'mqlAlertLevelFirst':
        updatedState = {
          ...state,
          mql: {
            ...state.mql,
            mqlAlertLevelFirst: value,
          },
        };
        break;

      case 'mqlAlertLevelSecond':
        updatedState = {
          ...state,
          mql: {
            ...state.mql,
            mqlAlertLevelSecond: value,
          },
        };
        break;

      case 'mqlAlertLevelThird':
        updatedState = {
          ...state,
          mql: {
            ...state.mql,
            mqlAlertLevelThird: value,
          },
        };
        break;

      case 'isMerchant':
        setIsMerchant(!isMerchant);
        updatedState = {
          ...state,
          merchant: {
            username: ``,
            password: ``,
          },
        };
        break;

      case 'username':
        updatedState = {
          ...state,
          merchant: {
            ...state.merchant,
            username: isValidAlphaWithSpec(value) ? value : state?.merchant?.username,
          },
        };
        break;

      case 'password':
        updatedState = {
          ...state,
          merchant: {
            ...state.merchant,
            password: value,
          },
        };
        break;

      case 'address':
        updatedState = {
          ...state,
          address: isValidAlphaWithSpec(value) ? value : state?.address,
        };
        break;

      case 'area':
        updatedState = {
          ...state,
          area: isValidAlphaWithSpec(value) ? value : state?.area,
        };
        break;

      case 'pincode':
        updatedState = {
          ...state,
          pincode: isValidNumber(value) ? value : state?.pincode,
        };
        break;

      case 'location-type':
        updatedState = {
          ...state,
          type: parseInt(value),
        };
        break;

      case 'latitude':
        updatedState = {
          ...state,
          latitude: parseInt(value || 0) > 90 || parseInt(value || 0) < -90 ? state.latitude : value,
        };
        break;

      case 'longitude':
        updatedState = {
          ...state,
          longitude: parseInt(value || 0) > 180 || parseInt(value || 0) < -180 ? state.longitude : value,
        };
        break;

      case 'whatsappNumber':
        updatedState = {
          ...state,
          whatsappNumber: isValidNumber(value) ? value : state?.whatsappNumber,
        };
        break;

      case 'phone':
        updatedState = {
          ...state,
          phone: isValidNumber(value) ? value : state?.phone,
        };
        break;

      case 'email':
        // updatedState = {
        //   ...state,
        //   email: value,
        // }
        setEmails([value]);
        break;

      case 'priority':
        updatedState = {
          ...state,
          priority: value.length > 4 ? state?.priority : isNaN(value) ? state?.priority : parseInt(value || 0),
        };
        break;

      case 'wildCardQuota':
        updatedState = {
          ...state,
          wildCardQuota: value.length > 4 ? state?.wildCardQuota : isNaN(value) ? state?.wildCardQuota : parseInt(value || 0),
        };
        break;

      // -------
      case 'tat-minimum-unit':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            minimum: {
              ...state?.tat?.minimum,
              unit: parseInt(value || 0),
              value: value === '0' ? 0 : state?.tat?.minimum?.value,
            },
          },
        };
        break;

      case 'tat-minimum-value':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            minimum: {
              ...state?.tat?.minimum,
              value: value.length > 3 ? state?.tat?.minimum?.value : isNaN(value) ? state?.tat?.minimum?.value : parseInt(value || 0),
            },
          },
        };
        break;

      case 'tat-maximum-unit':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            maximum: {
              ...state?.tat?.maximum,
              unit: parseInt(value || 0),
              value: value === '0' ? 0 : state?.tat?.maximum?.value,
            },
          },
        };
        break;

      case 'tat-maximum-value':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            maximum: {
              ...state?.tat?.maximum,
              value: value.length > 3 ? state?.tat?.maximum?.value : isNaN(value) ? state?.tat?.maximum?.value : parseInt(value || 0),
            },
          },
        };
        break;

      case 'tat-cancel-unit':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            cancel: {
              ...state?.tat?.cancel,
              unit: parseInt(value || 0),
              value: value === '0' ? 0 : state?.tat?.cancel?.value,
            },
          },
        };
        break;

      case 'tat-cancel-value':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            cancel: {
              ...state?.tat?.cancel,
              value: value.length > 3 ? state?.tat?.cancel?.value : isNaN(value) ? state?.tat?.cancel?.value : parseInt(value || 0),
            },
          },
        };
        break;

      case 'noOfFreeKids':
        updatedState = {
          ...state,
          kids: {
            ...state?.kids,
            noOfFreeKids: value.length > 4 ? state?.noOfFreeKids : isNaN(value) ? state?.noOfFreeKids : parseInt(value || 0),
          },
        };
        break;

      case 'complimentaryAgeLimit':
        updatedState = {
          ...state,
          kids: {
            ...state?.kids,
            complimentaryAgeLimit:
              value.length > 4 ? state?.complimentaryAgeLimit : isNaN(value) ? state?.complimentaryAgeLimit : parseInt(value || 0),
          },
        };
        break;

      case 'isKidsAllowed':
        updatedState = {
          ...state,
          isKidsAllowed: !state?.isKidsAllowed,
        };
        break;

      case 'maxNoOfKids':
        updatedState = {
          ...state,
          kids: {
            ...state?.kids,
            maxNoOfKids: value.length > 4 ? state?.maxNoOfKids : isNaN(value) ? state?.maxNoOfKids : parseInt(value || 0),
          },
        };
        break;

      case 'emails':
        updatedState = {
          ...state,
          emails: value,
        };
        break;

      case 'title':
        updatedState = {
          ...state,
          facilityDisclaimer: {
            ...state.facilityDisclaimer,
            title: value,
          },
        };
        break;

      case 'body':
        updatedState = {
          ...state,
          facilityDisclaimer: {
            ...state.facilityDisclaimer,
            body: value,
          },
        };
        break;

      case 'isActive':
        updatedState = {
          ...state,
          facilityDisclaimer: {
            ...state.facilityDisclaimer,
            isActive: !state.facilityDisclaimer?.isActive,
          },
        };
        break;
      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  const inputSelectHandler = (label,data) => {
    console.log('llll111',label,data);
    // return false;
    //  let x =data;
    // let label="dss";
    // data=x.option;
    let updatedState = { ...state };
    // let tempData={label,value:44,data:data[0]};
   
    if (label === `category`) {
      // let data ={data: x?.[0]?.completeData}
      updatedState = {
        ...state,
        category: data,
        partner: null,
        images: {
          ...data?.data?.images,
        },
        shortDescription: data?.data?.shortDescription,
        longDescription: data?.data?.longDescription,
        bookingType: {
          data: { ...data?.data?.bookingType },
          label: data?.data?.bookingType?.name,
          value: data?.data?.bookingType?.objectId,
        },
        amenities: [],
      };
      setAllAmenities(
        data?.data?.amenities?.map(amenity => {
          return {
            value: amenity.name,
            label: amenity.name,
            data: amenity,
          };
        }),
      );

      dispatch({
        type: GET_COUPON_PARTNER,
        payload: {
          urlParam: {
            categoryId: data.value,
          },
        },
      });
    } else if (label === `amenities`) {
      updatedState = {
        ...state,
        amenities: data,
      };
    } else if (label === `partner`) {
      updatedState = {
        ...state,
        partner: data,
        images: {
          ...data?.data?.images,
        },
        shortDescription: data?.data?.shortDescription,
        longDescription: data?.data?.longDescription,
        termsAndCondition: data?.data?.defaultTermsAndCondition ?? ``,
        benefitOffered: data?.data?.defaultBenefitOffered ?? ``,
        redemptionProcess: data?.data?.defaultRedemptionProcess ?? ``,
        bookingType: {
          data: { ...data?.data?.bookingType },
          label: data?.data?.bookingType?.name,
          value: data?.data?.bookingType?.objectId,
        },
        thirdPartyWebsite: data?.data?.thirdPartyWebsite,
        amenities: [],
        tat: data?.data?.tat,
      };
      setAllAmenities(
        data?.data?.amenities?.map(amenity => {
          return {
            value: amenity.name,
            label: amenity.name,
            data: amenity,
          };
        }),
      );
    } else if (label === `redemptionType`) {
      updatedState = {
        ...state,
        redemptionType: data,
      };
    } else if (label === `country`) {
      updatedState = {
        ...state,
        country: data,
        region: null,
        subRegion: null,
      };

      dispatch({ type: GET_SUB_REGION_EMPTY });

      dispatch({
        type: GET_REGION,
        payload: {
          urlParam: {
            countryId: parseInt(data?.value),
          },
        },
      });
    } else if (label === `region`) {
      updatedState = {
        ...state,
        region: data,
        subRegion: null,
      };

      dispatch({
        type: GET_SUB_REGION,
        payload: {
          urlParam: {
            regionId: parseInt(data?.value),
          },
        },
      });
    } else if (label === `subregion`) {
      updatedState = {
        ...state,
        subRegion: data,
      };
    } else if (label === `type`) {
      updatedState = {
        ...state,
        type: data,
      };
    } else if (label === `bookingType`) {
      updatedState = {
        ...state,
        bookingType: data,
      };
    } else if (label === `airport`) {
      updatedState = {
        ...state,
        airport: data,
      };
    } else if (label === `isTopRated`) {
      updatedState = {
        ...state,
        isTopRated: data,
      };
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  const ckEditorDataInput = (name, data) => {
    let updatedState = { ...state };

    switch (name) {
      case 'redemptionProcess':
        updatedState = {
          ...state,
          redemptionProcess: data,
        };
        break;

      case 'benefitOffered':
        updatedState = {
          ...state,
          benefitOffered: data,
        };
        break;

      case 'termsAndCondition':
        updatedState = {
          ...state,
          termsAndCondition: data,
        };
        break;

      case 'shortDescription':
        updatedState = {
          ...state,
          shortDescription: data,
        };
        break;

      case 'longDescription':
        updatedState = {
          ...state,
          longDescription: data,
        };
        break;

      case 'emailTandC':
        updatedState = {
          ...state,
          emailTandC: data,
        };
        break;

      case 'emailRedemptionProcess':
        updatedState = {
          ...state,
          emailRedemptionProcess: data,
        };
        break;

      case 'body':
        updatedState = {
          ...state,
          facilityDisclaimer: {
            ...state.facilityDisclaimer,
            body: data,
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  const mobileChangeHandler = (e, index) => {
    const { name, value } = e.target;

    const tempMobileNumbers = [...mobileNumber];
    if (name === 'mobile') {
      tempMobileNumbers[index] = value;
    }
    // validationCheck(state, tempMobileNumbers);
    setMobileNumbers(tempMobileNumbers);
  };

  const datePickHandler = (flag, date) => {
    let updatedState = { ...state };

    switch (flag) {
      case 'startDate':
        updatedState = {
          ...state,
          facilityDisclaimer: {
            ...state.facilityDisclaimer,
            startDate: new Date(date).toISOString(),
          },
        };
        break;

      case 'endDate':
        updatedState = {
          ...state,
          facilityDisclaimer: {
            ...state.facilityDisclaimer,
            endDate: new Date(date).toISOString(),
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- ADD AMENITY ---------------------------- */
  const addNewMobileNumberHandler = index => {
    const tempMobileNumber = [...mobileNumber];
    tempMobileNumber.push('');
    setMobileNumbers(tempMobileNumber);
    // validationCheck(state, tempMobileNumber);
  };

  /* ---------------------------- REMOVE AMENITY ---------------------------- */
  const removeNewMobileNumberHandler = index => {
    const tempMobileNumber = [...mobileNumber];
    tempMobileNumber.splice(index, 1);
    setMobileNumbers(tempMobileNumber);
    // validationCheck(state, tempMobileNumber);
  };

  /* ---------------------------- AMANITIES HANDLER ---------------------------- */
  const amenitiesHandler = amenityData => {
    const tempStateAmenities = [...(state.amenities ?? [])];

    const amenityIndex = tempStateAmenities?.findIndex(amenity => amenity?.value === amenityData?.value);
    if (amenityIndex === -1) {
      tempStateAmenities?.push(amenityData);
    } else {
      tempStateAmenities.splice(amenityIndex, 1);
    }

    setState({
      ...state,
      amenities: tempStateAmenities,
    });
  };

  /* ---------------------------- VALIDATE ERROR ---------------------------- */
  const validateErrors = (flag, data = state) => {
    let isError = false;
    let accordionsTemp = { ...accordions };

    if (data?.category === null || data?.partner === null || data?.name === `` || data?.globalFacilityId === `` || data?.name?.trim() === ``) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        general: {
          ...accordionsTemp?.general,
          status: flag === `validationCheck` ? accordionsTemp?.general?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        general: {
          status: flag === `validationCheck` ? accordionsTemp?.general?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
      };
    }

    if (
      data?.displayName?.trim() === `` ||
      !isValidUrl(data?.images?.coverImageMobile) ||
      !isValidUrl(data?.images?.coverImageWeb) ||
      !isValidUrl(data?.images?.displayImageMobile) ||
      !isValidUrl(data?.images?.displayImageWeb)
    ) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        displayProperties: {
          ...accordionsTemp?.displayProperties,
          status: flag === `validationCheck` ? accordionsTemp?.displayProperties?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        displayProperties: {
          status: flag === `validationCheck` ? accordionsTemp?.displayProperties?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
      };
    }

    if (!isValidUrl(data?.thirdPartyWebsite)) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        bookingProperties: {
          ...accordionsTemp?.bookingProperties,
          status: flag === `validationCheck` ? accordionsTemp?.bookingProperties?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        bookingProperties: {
          status: flag === `validationCheck` ? accordionsTemp?.bookingProperties?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
      };
    }

    if (
      data?.address === `` ||
      data?.area === `` ||
      data?.pincode === `` ||
      data?.country === null ||
      data?.region === null ||
      data?.subRegion === null ||
      !isValidEmail(emails[0])
    ) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        contactDetails: {
          ...accordionsTemp?.contactDetails,
          status: flag === `validationCheck` ? accordionsTemp?.contactDetails?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        contactDetails: {
          status: flag === `validationCheck` ? accordionsTemp?.contactDetails?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
      };
    }

    if (data?.merchant?.username === `` || data?.merchant?.password === ``) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        merchantDetails: {
          ...accordionsTemp?.merchantDetails,
          status: flag === `validationCheck` ? accordionsTemp?.merchantDetails?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        merchantDetails: {
          status: flag === `validationCheck` ? accordionsTemp?.merchantDetails?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
      };
    }

    if (data?.amenities?.length === 0 || data?.amenities === null || data?.amenities === undefined) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        amenities: {
          ...accordionsTemp?.amenities,
          status: flag === `validationCheck` ? accordionsTemp?.amenities?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        amenities: {
          status: flag === `validationCheck` ? accordionsTemp?.amenities?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
      };
    }

    if (
      data?.facilityDisclaimer?.startDate === '' ||
      data?.facilityDisclaimer?.endDate === '' ||
      data?.facilityDisclaimer?.title === '' ||
      data?.facilityDisclaimer?.body === ''
    ) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        disclaimer: {
          ...accordionsTemp?.disclaimer,
          status: flag === `validationCheck` ? accordionsTemp?.disclaimer?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        disclaimer: {
          status: flag === `validationCheck` ? accordionsTemp?.disclaimer?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
      };
    }

    setIsValidationShow(flag === `submissionCheck` ? isError : isValidationShow ? true : false);
    setAccordions({ ...accordionsTemp });
    return isError;
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    validateErrors(`submissionCheck`);

    if (!validateErrors(`submissionCheck`)) {
      const payload = {
        ...state,
        countryCode: state?.country?.data?.phoneCode,
        category: {
          id: state?.category.value,
          name: state?.category.label,
          images: state?.category.data.images,
        },
        partner: {
          id: state?.partner.value,
          name: state?.partner.label,
          images: state?.partner.images,
        },
        airport: {
          name: state?.airport?.label,
          address: state?.airport?.completeData?.address,
          id: state?.airport?.value,
        },
        country: {
          id: state?.country.value,
          name: state?.country.label,
        },
        region: {
          id: state?.region.value,
          name: state?.region.label,
        },
        subRegion: {
          id: state?.subRegion.value,
          name: state?.subRegion.label,
        },
        emails: emails,
        amenities:
          state?.amenities?.length > 0
            ? state?.amenities?.map(amenity => {
                return {
                  ...amenity.data,
                };
              })
            : [],

        phoneNumbers: mobileNumber,
        isTopRated: state?.isTopRated?.value,
      };

      if (props?.title === `Update Outlet`) {
        dispatch({
          type: UPDATE_LOCATION,
          payload: {
            data: {
              requests: [{ ...payload }],
            },
          },
        });
      } else if (props?.title === `Add Outlet`) {
        dispatch({
          type: CREATE_LOCATION,
          payload: {
            data: payload,
          },
        });
      }
    }
  };

  /* ---------------------------- ACCORDION HANDLER ---------------------------- */
  const accordionToggleHandler = flag => {
    setAccordions({
      ...accordions,
      [flag]: {
        ...accordions[flag],
        status: !accordions[flag]['status'],
      },
    });
  };

  async function getAirportsHandler(search) {
    const response = await fetch(`${baseUrl}/admin/airports?name=${search}&subRegionId=${state?.subRegion?.value}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const airportsResponse = await response.json();

    return {
      options: airportsResponse?.results?.map((data, index) => {
        return {
          value: data.objectId,
          label: `${data.name}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  const onSelectAirport = event => {
    let updatedState = null;

    updatedState = {
      ...state,
      airport: event,
    };
    setState(updatedState);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Row>
      <Col xxl={12}>
        <Card>
          <PreviewCardHeader
            title={props?.title}
            primaryButton={hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE) && props?.title === `Add Outlet`}
            primaryButtonText={`Upload Outlets`}
            primaryClickHandler={() => props?.bulkUploadAction()}
          />
          {/* <button onClick={() => console.log(state)}>Get State</button> */}
          <CardBody>
            <Form>
              <Row>
                <Col xxl={12}>
                  <CardBody>
                    <Accordion
                      className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-secondary"
                      id="accordionBordered"
                    >
                      {/* ---------------------------- ACCORDION - General ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.general?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`general`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>General</div>
                              {accordions?.general?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.general?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="category" className="form-label">
                                    Sub-Category <span className="lbl_mandatory">*</span>
                                  </Label>
                                  {/* <Select
                                    id="category"
                                    name="category"
                                    options={allCategories ?? []}
                                    value={state?.category}
                                    onChange={data => inputSelectHandler(`category`, data)}
                                  /> */}
                                  <AsyncPaginate
                                    debounceTimeout={500}
                                    value={state?.category}
                                    // loadOptions={loadOptionForBenefitGroup}
                                    loadOptions={search => loadOptionForOutlet(search, 'category')}
                                    // onChange={inputSelectHandler}
                                    // loadOptions={loadOptions}
                                    onChange={data => inputSelectHandler('category', data)}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={() => 'No results found'}
                                    cacheUniqs={[['code']]}
                                    placeholder="Select..."
                                    // isDisabled={isServiceDisabled}
                                  />
                                  {isValidationShow && state?.category === null && <Error text="Please select" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="partner" className="form-label">
                                    Partner <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <AsyncPaginate
                                    debounceTimeout={500}
                                    value={state?.partner}
                                    // loadOptions={loadOptionForBenefitGroup}
                                    loadOptions={search => loadOptionForOutlet(search, 'partner')}
                                    // onChange={inputSelectHandler}
                                    // loadOptions={loadOptions}
                                    onChange={data => inputSelectHandler('partner', data)}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={() => 'No results found'}
                                    cacheUniqs={[['code']]}
                                    placeholder="Select..."
                                    isDisabled={!state?.category}
                                  />
                                  {isValidationShow && state?.partner === null && <Error text="Please select" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="name" className="form-label">
                                    System Name
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    value={state?.name}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                    disabled={props?.title !== `Add Outlet`}
                                  />
                                  {isValidationShow && state?.name?.trim() === `` && <Error text="Please enter system name" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="priority" className="form-label">
                                    Priority
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="priority"
                                    name="priority"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.priority}
                                    autoComplete="off"
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="wildCardQuota" className="form-label">
                                    Wildcard Quota
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="wildCardQuota"
                                    name="wildCardQuota"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.wildCardQuota}
                                    autoComplete="off"
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="globalFacilityId" className="form-label">
                                    Global Outlet Id
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="globalFacilityId"
                                    name="globalFacilityId"
                                    type="text"
                                    className="form-control"
                                    value={state.globalFacilityId}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && state.globalFacilityId === `` && <Error text="Please enter" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="isTopRated" className="form-label">
                                    Top Rated
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Select
                                    id="isTopRated"
                                    name="isTopRated"
                                    options={[
                                      {
                                        value: true,
                                        label: 'Yes',
                                      },
                                      {
                                        value: false,
                                        label: 'No',
                                      },
                                    ]}
                                    value={state?.isTopRated}
                                    onChange={data => inputSelectHandler(`isTopRated`, data)}
                                  />
                                </div>
                              </Col>

                              <Col md={12}>
                                <div
                                  style={{
                                    marginBottom: '10px',
                                  }}
                                >
                                  <div className="form-switch d-inline-block ">
                                    <Input
                                      className="form-check-input code-switcher cursor-pointer"
                                      type="checkbox"
                                      id="isKidsAllowed"
                                      name="isKidsAllowed"
                                      checked={state?.isKidsAllowed}
                                      onChange={inputChangeHandler}
                                    />
                                  </div>
                                  <Label className="form-label">Are Kids Allowed?</Label>
                                </div>
                              </Col>

                              {state?.isKidsAllowed && (
                                <>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="noOfFreeKids" className="form-label">
                                        Number Of Free Kids
                                      </Label>
                                      <Input
                                        disabled={!state?.isKidsAllowed}
                                        id="noOfFreeKids"
                                        name="noOfFreeKids"
                                        type="text"
                                        className="form-control"
                                        onChange={inputChangeHandler}
                                        value={state?.kids?.noOfFreeKids}
                                        autoComplete="off"
                                        min="0"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="complimentaryAgeLimit" className="form-label">
                                        Complimentary Age Limit
                                      </Label>
                                      <Input
                                        disabled={!state?.isKidsAllowed}
                                        id="complimentaryAgeLimit"
                                        name="complimentaryAgeLimit"
                                        type="text"
                                        className="form-control"
                                        onChange={inputChangeHandler}
                                        value={state?.kids?.complimentaryAgeLimit}
                                        autoComplete="off"
                                        min="0"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="maxNoOfKids" className="form-label">
                                        Maximum Number Of Kids
                                      </Label>
                                      <Input
                                        id="maxNoOfKids"
                                        name="maxNoOfKids"
                                        type="text"
                                        className="form-control"
                                        onChange={inputChangeHandler}
                                        value={state?.kids?.maxNoOfKids}
                                        autoComplete="off"
                                        min="0"
                                      />
                                    </div>
                                  </Col>
                                </>
                              )}

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="emailRemarks" className="form-label">
                                    Email remarks
                                  </Label>
                                  <Input
                                    id="emailRemarks"
                                    name="emailRemarks"
                                    type="textarea"
                                    className="form-control"
                                    value={state?.emailRemarks}
                                    onChange={inputChangeHandler}
                                  />
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="emailTandC" className="form-label">
                                    Email T&C
                                  </Label>
                                  <div className="snow-editor" style={{ minHeight: 300 }}>
                                    {/* <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.emailTandC}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`emailTandC`, data);
                                      }}
                                    /> */}
                                  </div>
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="emailRedemptionProcess" className="form-label">
                                    Email Redemption Process
                                  </Label>
                                  <div className="snow-editor" style={{ minHeight: 300 }}>
                                    {/* <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.emailRedemptionProcess}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`emailRedemptionProcess`, data);
                                      }}
                                    /> */}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Display Properties ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.displayProperties?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`displayProperties`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Display Properties</div>
                              {accordions?.displayProperties?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.displayProperties?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="displayName" className="form-label">
                                    Display Name
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="displayName"
                                    name="displayName"
                                    type="text"
                                    className="form-control"
                                    value={state?.displayName}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && state?.displayName?.trim() === `` && <Error text="Please enter display name" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="coverImageMobile" className="form-label">
                                    Cover Image Mobile URL {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <Input
                                    id="coverImageMobile"
                                    name="coverImageMobile"
                                    type="text"
                                    className="form-control"
                                    value={state.images.coverImageMobile}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && !isValidUrl(state?.images?.coverImageMobile) && <Error text="Please enter valid URL" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="coverImageWeb" className="form-label">
                                    Cover Image Web URL {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <Input
                                    id="coverImageWeb"
                                    name="coverImageWeb"
                                    type="text"
                                    className="form-control"
                                    value={state.images.coverImageWeb}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && !isValidUrl(state?.images?.coverImageWeb) && <Error text="Please enter valid URL" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="displayImageMobile" className="form-label">
                                    Display Image Mobile URL {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <Input
                                    id="displayImageMobile"
                                    name="displayImageMobile"
                                    type="text"
                                    className="form-control"
                                    value={state.images.displayImageMobile}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && !isValidUrl(state?.images?.displayImageMobile) && <Error text="Please enter valid URL" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="displayImageWeb" className="form-label">
                                    Display Image Web URL {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <Input
                                    id="displayImageWeb"
                                    name="displayImageWeb"
                                    type="text"
                                    className="form-control"
                                    value={state.images.displayImageWeb}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && !isValidUrl(state?.images?.displayImageWeb) && <Error text="Please enter valid URL" />}
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="shortDescription" className="form-label">
                                    Short Description {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={state?.shortDescription}
                                    config={{
                                      toolbar: {
                                        items: Constant.CkEditorToolbar,
                                      },
                                    }}
                                    onBlur={(event, editor) => {
                                      const data = editor.getData();
                                      ckEditorDataInput(`shortDescription`, data);
                                    }}
                                  />
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="longDescription" className="form-label">
                                    Long Description {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={state?.longDescription}
                                    config={{
                                      toolbar: {
                                        items: Constant.CkEditorToolbar,
                                      },
                                    }}
                                    onBlur={(event, editor) => {
                                      const data = editor.getData();
                                      ckEditorDataInput(`longDescription`, data);
                                    }}
                                  />
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="redemptionProcess" className="form-label">
                                    Redemption Process{' '}
                                  </Label>
                                  <div className="snow-editor" style={{ minHeight: 300 }}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.redemptionProcess}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`redemptionProcess`, data);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="termsAndCondition" className="form-label">
                                    T&C
                                  </Label>
                                  <div className="snow-editor" style={{ minHeight: 300 }}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.termsAndCondition}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`termsAndCondition`, data);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Booking Properties ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.bookingProperties?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`bookingProperties`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Booking Properties</div>
                              {accordions?.bookingProperties?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.bookingProperties?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="thirdPartyWebsite" className="form-label">
                                    Default Third Party Website URL
                                    {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <Input
                                    id="thirdPartyWebsite"
                                    name="thirdPartyWebsite"
                                    type="text"
                                    className="form-control"
                                    value={state.thirdPartyWebsite}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && !isValidUrl(state?.thirdPartyWebsite) && <Error text="Please enter valid URL" />}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - TAT ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.tat?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`tat`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>TAT</div>
                              {accordions?.tat?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.tat?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              {/* ------------------------ TAT Minimum ------------------------ */}
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-minimum-unit" className="form-label">
                                    TAT Minimum Unit
                                  </Label>

                                  <select
                                    id="tat-minimum-unit"
                                    name="tat-minimum-unit"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.minimum?.unit}
                                  >
                                    <option value="0">Choose</option>
                                    <option value="2">Months</option>
                                    <option value="3">Days</option>
                                    <option value="4">Hours</option>
                                    <option value="5">Minute</option>
                                    <option value="6">Second</option>
                                    <option value="7">Clear Day</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-minimum-value" className="form-label">
                                    TAT Minimum Value
                                  </Label>

                                  <Input
                                    id="tat-minimum-value"
                                    name="tat-minimum-value"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.minimum?.value}
                                    autoComplete="off"
                                    min="0"
                                    disabled={state?.tat?.minimum?.unit === 0}
                                  />
                                </div>
                              </Col>

                              {/* ------------------------ TAT Maximum ------------------------ */}
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-maximum-unit" className="form-label">
                                    TAT Maximum Unit
                                  </Label>

                                  <select
                                    id="tat-maximum-unit"
                                    name="tat-maximum-unit"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.maximum?.unit}
                                  >
                                    <option value="0">Choose</option>
                                    <option value="2">Months</option>
                                    <option value="3">Days</option>
                                    <option value="4">Hours</option>
                                    <option value="5">Minute</option>
                                    <option value="6">Second</option>
                                    <option value="7">Clear Day</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-maximum-value" className="form-label">
                                    TAT Maximum Value
                                  </Label>

                                  <Input
                                    id="tat-maximum-value"
                                    name="tat-maximum-value"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.maximum?.value}
                                    autoComplete="off"
                                    min="0"
                                    disabled={state?.tat?.maximum?.unit === 0}
                                  />
                                </div>
                              </Col>

                              {/* ------------------------ TAT Cancel ------------------------ */}
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-cancel-unit" className="form-label">
                                    TAT Cancel Unit
                                  </Label>

                                  <select
                                    id="tat-cancel-unit"
                                    name="tat-cancel-unit"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.cancel?.unit}
                                  >
                                    <option value="0">Choose</option>
                                    <option value="2">Months</option>
                                    <option value="3">Days</option>
                                    <option value="4">Hours</option>
                                    <option value="5">Minute</option>
                                    <option value="6">Second</option>
                                    <option value="7">Clear Day</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-cancel-value" className="form-label">
                                    TAT Cancel Value
                                  </Label>

                                  <Input
                                    id="tat-cancel-value"
                                    name="tat-cancel-value"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.cancel?.value}
                                    autoComplete="off"
                                    min="0"
                                    disabled={state?.tat?.cancel?.unit === 0}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Contact Details ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.contactDetails?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`contactDetails`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Contact Details</div>
                              {accordions?.contactDetails?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.contactDetails?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="address" className="form-label">
                                    Address <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="address"
                                    name="address"
                                    type="text"
                                    className="form-control"
                                    value={state.address}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && state.address === `` && <Error text="Please enter address" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="area" className="form-label">
                                    Area <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="area"
                                    name="area"
                                    type="text"
                                    className="form-control"
                                    value={state.area}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && state.area === `` && <Error text="Please enter area" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="country" className="form-label">
                                    Country <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Select
                                    id="country"
                                    name="country"
                                    options={allCountries ?? []}
                                    value={state?.country}
                                    onChange={data => inputSelectHandler(`country`, data)}
                                  />
                                  {isValidationShow && state.country === null && <Error text="Please select country" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="region" className="form-label">
                                    Region <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Select
                                    id="region"
                                    name="region"
                                    options={allRegions ?? []}
                                    value={state?.region}
                                    onChange={data => inputSelectHandler(`region`, data)}
                                  />
                                  {isValidationShow && state.region === null && <Error text="Please select region" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="subregion" className="form-label">
                                    Subregion <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Select
                                    id="subregion"
                                    name="subregion"
                                    options={allSubRegions ?? []}
                                    value={state?.subRegion}
                                    onChange={data => inputSelectHandler(`subregion`, data)}
                                  />
                                  {isValidationShow && state.subRegion === null && <Error text="Please select sub region" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="pincode" className="form-label">
                                    Pincode <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="pincode"
                                    name="pincode"
                                    type="text"
                                    className="form-control"
                                    value={state.pincode}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                    min="0"
                                  />
                                  {isValidationShow && state.pincode === `` && <Error text="Please enter pincode" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="location-type" className="form-label">
                                    Location Type <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <select
                                    id="location-type"
                                    name="location-type"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.type}
                                  >
                                    <option value="1">Domestic</option>
                                    <option value="2">International</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="latitude" className="form-label">
                                    Latitude
                                    {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <Input
                                    id="latitude"
                                    name="latitude"
                                    type="number"
                                    onWheel={e => e.target.blur()}
                                    className="form-control"
                                    value={state.latitude}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="longitude" className="form-label">
                                    Longitude
                                    {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <Input
                                    id="longitude"
                                    name="longitude"
                                    type="number"
                                    onWheel={e => e.target.blur()}
                                    className="form-control"
                                    value={state.longitude}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                    min="0"
                                    max="90"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="email" className="form-label">
                                    Email I'd
                                    {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <Input
                                    id="email"
                                    name="email"
                                    type="text"
                                    className="form-control"
                                    value={emails[0]}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && emails[0] !== `` && !isValidEmail(emails[0]) && <Error text="Please enter valid email" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="whatsappNumber" className="form-label">
                                    Whatsapp Number
                                    {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <Input
                                    id="whatsappNumber"
                                    name="whatsappNumber"
                                    type="text"
                                    className="form-control"
                                    value={state?.whatsappNumber}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="whatsappNumber" className="form-label">
                                    Select Airport
                                    {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <AsyncPaginate
                                    debounceTimeout={500}
                                    value={state?.airport}
                                    loadOptions={getAirportsHandler}
                                    onChange={e => onSelectAirport(e)}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={() => 'No results found'}
                                    cacheUniqs={[['code']]}
                                    placeholder=""
                                    isDisabled={state?.subRegion === null}
                                  />
                                </div>
                              </Col>

                              <Col md={12}>
                                <Card>
                                  <PreviewCardHeader title="Mobile Information" />
                                  <CardBody>
                                    <Row>
                                      {mobileNumber?.map((mobile, index) => {
                                        return (
                                          <Row key={index}>
                                            <Col md={5}>
                                              <div className="mb-3">
                                                <Label htmlFor="mobile" className="form-label">
                                                  Mobile No.
                                                  {/* <span className="lbl_mandatory"> *</span> */}
                                                </Label>
                                                <Input
                                                  id="mobile"
                                                  name="mobile"
                                                  type="number"
                                                  onWheel={e => e.target.blur()}
                                                  className="form-control"
                                                  onChange={e => mobileChangeHandler(e, index)}
                                                  value={mobile}
                                                  autoComplete="off"
                                                  min="0"
                                                />
                                              </div>
                                            </Col>

                                            <Col md={2}>
                                              <div className="mb-3">
                                                <Label htmlFor="display-img-web-url" className="form-label">
                                                  &nbsp;
                                                </Label>
                                                <div>
                                                  <button
                                                    type="submit"
                                                    className="btn btn-primary mx-3 "
                                                    onClick={e => {
                                                      e.preventDefault();
                                                      addNewMobileNumberHandler(index);
                                                    }}
                                                  >
                                                    +
                                                  </button>

                                                  <button
                                                    type="submit"
                                                    className="btn btn-danger mx-3 "
                                                    onClick={e => {
                                                      e.preventDefault();
                                                      removeNewMobileNumberHandler(index);
                                                    }}
                                                    disabled={mobileNumber?.length === 1}
                                                  >
                                                    -
                                                  </button>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Merchant Details ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.merchantDetails?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`merchantDetails`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Merchant Details</div>
                              {accordions?.merchantDetails?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.merchantDetails?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="username" className="form-label">
                                    Merchant Username
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>

                                  <Input
                                    id="username"
                                    name="username"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.merchant?.username}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && state?.merchant?.username === `` && <Error text="Please enter username" />}
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="password" className="form-label">
                                    Merchant Password
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>

                                  <Input
                                    id="password"
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.merchant?.password}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && state?.merchant?.password === `` && <Error text="Please enter valid password" />}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Amenities ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.amenities?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`amenities`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Amenities</div>
                              {accordions?.amenities?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.amenities?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              {allAmenities?.length > 0 ? (
                                allAmenities?.map((amenity, index) => (
                                  <Col md={4} key={index}>
                                    <div className="mb-3">
                                      <input
                                        type="checkbox"
                                        className="form-check-input mx-1"
                                        id={index}
                                        style={{ cursor: 'pointer' }}
                                        checked={state?.amenities?.filter(data => data?.value === amenity?.value)?.length > 0}
                                        onChange={() => amenitiesHandler(amenity)}
                                      />
                                      <Label htmlFor={index} style={{ cursor: 'pointer', fontWeight: 'normal' }}>
                                        {amenity?.label}
                                      </Label>
                                    </div>
                                  </Col>
                                ))
                              ) : (
                                <span>Amenities not available!</span>
                              )}

                              {/* <Col md={4}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="amenities"
                                    className="form-label"
                                  >
                                    Amenities{" "}
                                    <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Select
                                    id="amenities"
                                    name="amenities"
                                    options={allAmenities ?? []}
                                    value={state?.amenities}
                                    isMulti={true}
                                    onChange={(data) =>
                                      inputSelectHandler(`amenities`, data)
                                    }
                                  />
                                  {isValidationShow &&
                                    state?.amenities?.length === 0 && (
                                      <Error text="Please select" />
                                    )}
                                </div>
                              </Col> */}
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Disclaimer ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.disclaimer?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`disclaimer`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Disclaimer</div>
                              {accordions?.disclaimer?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>
                        <Collapse isOpen={accordions?.disclaimer?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="address" className="form-label">
                                    Start Date <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Flatpickr
                                    className="form-control"
                                    onChange={date => datePickHandler(`startDate`, date)}
                                    value={state?.facilityDisclaimer?.startDate}
                                    options={{
                                      dateFormat: 'Y-m-d',
                                      // minDate: 'today',
                                    }}
                                  />
                                  {isValidationShow && state.facilityDisclaimer?.startDate === `` && <Error text="Please select start date" />}
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="address" className="form-label">
                                    End Date <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Flatpickr
                                    className="form-control"
                                    onChange={date => datePickHandler(`endDate`, date)}
                                    value={state?.facilityDisclaimer?.endDate}
                                    options={{
                                      dateFormat: 'Y-m-d',
                                      // minDate: 'today',
                                    }}
                                  />
                                  {isValidationShow && state.facilityDisclaimer?.endDate === `` && <Error text="Please select end date" />}
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="address" className="form-label">
                                    Title <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="title"
                                    name="title"
                                    type="text"
                                    className="form-control"
                                    value={state?.facilityDisclaimer?.title}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && state.facilityDisclaimer?.title === `` && <Error text="Please enter title" />}
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <div className="form-switch d-inline-block ">
                                    <Input
                                      className="form-check-input code-switcher cursor-pointer"
                                      type="checkbox"
                                      id="isActive"
                                      name="isActive"
                                      checked={state?.facilityDisclaimer?.isActive}
                                      onChange={inputChangeHandler}
                                    />
                                  </div>
                                  <Label className="form-label">Status</Label>
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="address" className="form-label">
                                    Body <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <div className="snow-editor" style={{ minHeight: 300 }}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.facilityDisclaimer?.body}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`body`, data);
                                      }}
                                    />
                                    {isValidationShow && state.facilityDisclaimer?.body === `` && <Error text="Please enter body" />}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>
                    </Accordion>
                  </CardBody>
                </Col>
              </Row>
            </Form>
          </CardBody>

          <CardFooter>
            <Row>
              <Col md={12}>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler}>
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};

export default LocationForm;
