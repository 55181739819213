import { useHistory } from 'react-router-dom';

const Home = () => {
  const history = useHistory();

  if (window.location.hostname === 'admin-v2.thriwe.com') {
    history.push(`/login-v2`);
  } else {
    history.push('/login');
  }
};

export default Home;
