import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { ADIB_SURGE_REPORT, ADIB_SURGE_REPORT_SUCCESS, ADIB_SURGE_REPORT_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getAdibSurgeReport({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/surge-report-adib`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'Project-Code': 'ADIB',
      },
      data: payload?.data,
    });

    if (response?.status === 200) {
      yield put({
        type: ADIB_SURGE_REPORT_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: ADIB_SURGE_REPORT_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: ADIB_SURGE_REPORT_ERROR, payload: error });
  }
}

export function* watchGetAdibSurgeReport() {
  yield takeEvery(ADIB_SURGE_REPORT, getAdibSurgeReport);
}

function* getAdibSurgeReportSaga() {
  yield all([fork(watchGetAdibSurgeReport)]);
}

export default getAdibSurgeReportSaga;
