import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { adminAPIURL, customHeaders } from '../../../../../Containers/Config/index.js';
import apiCall from '../../../apiCall.js';
import { GET_BOOKING_GLOBAL_SEARCH, GET_BOOKING_GLOBAL_SEARCH_ERROR, GET_BOOKING_GLOBAL_SEARCH_SUCCESS } from '../../actions.js';

function* getBookingGlobalSearch({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v2/global-search`,
      method: 'GET',
      headers: {
        ...filteredHeaders,
      },
      params: {
        ...payload?.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_BOOKING_GLOBAL_SEARCH_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_BOOKING_GLOBAL_SEARCH_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_BOOKING_GLOBAL_SEARCH_ERROR, payload: error });
  }
}

export function* watchGetBookingGlobalSearch() {
  yield takeEvery(GET_BOOKING_GLOBAL_SEARCH, getBookingGlobalSearch);
}

function* getBookingGlobalSearchSaga() {
  yield all([fork(watchGetBookingGlobalSearch)]);
}

export default getBookingGlobalSearchSaga;
