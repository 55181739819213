import classnames from 'classnames';
import { useState } from 'react';
import { Card, CardBody, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

const Summary = ({ voucherDetails }) => {
  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = useState('1');
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <Row id="summary-card">
      <Col xxl={12}>
        <Card>
          <div className="col-md-6">
            <Nav tabs className="mt-3 nav nav-tabs nav-tabs-custom nav-primary nav-justified mb-3">
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === '1',
                  })}
                  onClick={() => {
                    toggleCustom('1');
                  }}
                >
                  Booking Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === '2',
                  })}
                  onClick={() => {
                    toggleCustom('2');
                  }}
                >
                  Logs
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <CardBody>
            <TabContent activeTab={customActiveTab} className="text-muted">
              <TabPane tabId="1" id="home1">
                <Row>
                  <Col xxl={12}>
                    <Card>
                      <CardBody className="p-4">
                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="d-flex">{'Customer Name'}</Label>
                              <Label className="text-muted">{voucherDetails?.customer_name || `NA`}</Label>
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="d-flex">{'Mobile Number'}</Label>
                              <Label className="text-muted">{voucherDetails?.customer_mobile_number || `NA`}</Label>
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="d-flex">{'Voucher Code'}</Label>
                              <Label className="text-muted">{voucherDetails?.voucher_code || `NA`}</Label>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="d-flex">{'Voucher Generation Date & Time'}</Label>
                              <Label className="text-muted">{voucherDetails?.voucher_generated_on || `NA`}</Label>
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="d-flex">{'Voucher Worth'}</Label>
                              <Label className="text-muted">{voucherDetails?.voucher_worth || `NA`}</Label>
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="d-flex">{'Voucher Expiry'}</Label>
                              <Label className="text-muted">{voucherDetails?.voucher_expiry || `NA`}</Label>
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="d-flex">{'Payment Left'}</Label>
                              <Label className="text-muted">{voucherDetails?.payment_left || `NA`}</Label>
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="d-flex">{'Discount'}</Label>
                              <Label className="text-muted">
                                {voucherDetails?.discount || `NA`}{' '}
                                {voucherDetails?.discount && voucherDetails?.discount_type === 'percentage' ? '%' : ''}
                              </Label>
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="d-flex">{'Points Redeemed'}</Label>
                              <Label className="text-muted">{voucherDetails?.points_redeemed || `NA`} </Label>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="2">
                <Row>
                  <Col xxl={12}>
                    <Card>
                      <CardBody className="p-4">
                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="d-flex">{'Voucher Generated On'}</Label>
                              <Label className="text-muted">{voucherDetails?.voucher_generated_on || `NA`}</Label>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="d-flex">{'Voucher Validated On'}</Label>
                              <Label className="text-muted">{voucherDetails?.voucher_validated_on || `NA`}</Label>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Summary;
