import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import cogoToast from 'cogo-toast';
import { customHeaders, thriweDiscoveryUrl } from '../../../../../Containers/Config/index.js';
import { Constant } from '../../../../../Containers/Helpers/constant.js';
import { CREATE_TEMPLATE_MAPPING, CREATE_TEMPLATE_MAPPING_ERROR, CREATE_TEMPLATE_MAPPING_SUCCESS } from '../../../actionType.js';
import apiCall from '../../../apiCall.js';

function* createTemplateMapping({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweDiscoveryUrl}/v1/admin/rules/create-workflow-template-mapping`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: CREATE_TEMPLATE_MAPPING_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CREATE_TEMPLATE_MAPPING_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_TEMPLATE_MAPPING_ERROR, payload: error });
  }
}

export function* watchCreateTemplateMapping() {
  yield takeEvery(CREATE_TEMPLATE_MAPPING, createTemplateMapping);
}

function* createTemplateMappingSaga() {
  yield all([fork(watchCreateTemplateMapping)]);
}

export default createTemplateMappingSaga;
