import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import cogoToast from 'cogo-toast';
import { adminAPIURL, customHeaders } from '../../../../../../Containers/Config/index.js';
import apiCall from '../../../../apiCall.js';
import { APPROVAL_ACTION, APPROVAL_ACTION_ERROR, APPROVAL_ACTION_SUCCESS } from '../../../actions.js';

function* approvalActionBooking({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/approval-action`,
      method: 'POST',
      headers: {
        ...filteredHeaders,
      },
      data: {
        ...payload?.body,
      },
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: APPROVAL_ACTION_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: APPROVAL_ACTION_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: APPROVAL_ACTION_ERROR, payload: error });
  }
}

export function* watchapprovalActionBooking() {
  yield takeEvery(APPROVAL_ACTION, approvalActionBooking);
}

function* approvalActionBookingSaga() {
  yield all([fork(watchapprovalActionBooking)]);
}

export default approvalActionBookingSaga;
