import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweDiscoveryUrl } from '../../../../Containers/Config/index.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import apiCall from '../../apiCall';
import { GET_RULE_DASHBOARD, GET_RULE_DASHBOARD_ERROR, GET_RULE_DASHBOARD_SUCCESS } from './../../actionType.js';

function* getRuleDashboard({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweDiscoveryUrl}/v1/admin/rules/dashboard`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_RULE_DASHBOARD_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_RULE_DASHBOARD_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_RULE_DASHBOARD_ERROR, payload: error });
  }
}

export function* watchGetDashboard() {
  yield takeEvery(GET_RULE_DASHBOARD, getRuleDashboard);
}

function* getRuleDashboardSaga() {
  yield all([fork(watchGetDashboard)]);
}

export default getRuleDashboardSaga;
