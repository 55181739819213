import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import NewRedemption from './Components/NewRedemption';

import { Loader } from '../../../../../../../Common';
import VerificationOTP from '../Common/VerificationOTP';
import GeneratedVoucher from '../Common/GeneratedVoucher';
import NewCardVoucherForm from './Components/NewCardVoucherForm';
import {
  CREATE_BOOKING,
  CREATE_BOOKING_EMPTY,
  CREATE_CLIENT_USER,
  CREATE_CLIENT_USER_EMPTY,
  GET_BENEFIT_ITEMS,
  GET_BENEFIT_ITEMS_EMPTY,
  GET_BINS_LIST_EMPTY,
  GET_CLIENT_BENEFIT_CONFIG,
  GET_MEMBERSHIPS_DATA,
  VENDOR_OTP_VALIDATE,
  VENDOR_RESEND_OTP,
  VENDOR_RESEND_OTP_EMPTY,
} from '../../../../../../../../store/actions';

const NewCardVoucher = ({ selectedBank }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [bookingsDetails, setBookingsDetails] = useState(null);

  const [registerCustomerData, setRegisterCustomerData] = useState(null);
  const [currentRedemptionJourney, setCurrentRedemptionJourney] = useState(`new-customer-journey`);

  const [newVoucherBookingDetails, setNewVoucherBookingDetails] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // CLIENT USER
  const { createUser } = useSelector(state => ({
    createUser: state?.CreateClientUser?.data,
  }));

  // RESEND OTP
  const { resendOTPResLoading } = useSelector(state => ({
    resendOTPResLoading: state?.VendorResendOtp?.loading,
  }));

  // OTP VALIDATE
  const { otpValidate, otpValidateLoading } = useSelector(state => ({
    otpValidate: state?.VendorOtpValidate?.data,
    otpValidateLoading: state?.VendorOtpValidate?.loading,
  }));

  // GET MEMBERSHIPS
  const { membershipData, membershipDataLoading } = useSelector(state => ({
    membershipData: state?.GetMembershipsData?.data?.results,
    membershipDataLoading: state?.GetMembershipsData?.loading,
  }));

  // GET BENEFIT CONFIG
  const { clientBenefitConfig, clientBenefitConfigLoading } = useSelector(state => ({
    clientBenefitConfig: state?.GetClientBenefitConfig.data?.results,
    clientBenefitConfigLoading: state?.GetClientBenefitConfig?.loading,
  }));

  // GET BENEFIT ITEMS
  const { getBenefitItems, getBenefitItemsLoading } = useSelector(state => ({
    getBenefitItems: state?.GetBenefitItems.data?.results,
    getBenefitItemsLoading: state?.GetBenefitItems?.loading,
  }));

  // CREATE BOOKING
  const { createBooking, createBookingLoading } = useSelector(state => ({
    createBooking: state.CreateBooking.data,
    createBookingLoading: state.CreateBooking.loading,
  }));

  useEffect(() => {
    if (otpValidate !== null) {
      dispatch({
        type: CREATE_CLIENT_USER_EMPTY,
      });

      let auth = JSON.parse(window.localStorage.getItem('authUser'));
      auth = { validateOtpToken: otpValidate?.headers?.authorization, ...auth };
      localStorage.setItem('authUser', JSON.stringify(auth));

      dispatch({
        type: GET_MEMBERSHIPS_DATA,
        endpoint: '/client/memberships',
        payload: { headers: { Authorization: otpValidate?.headers?.authorization } },
      });
    }
  }, [otpValidate]);

  useEffect(() => {
    if (membershipData?.length > 0 && currentRedemptionJourney === `new-customer-journey`) {
      const { benefitGroupId, membershipId, objectId } = membershipData[0];
      dispatch({
        type: GET_CLIENT_BENEFIT_CONFIG,
        endpoint: '/v2/client/benefit-configs',
        payload: {
          urlParam: {
            benefitGroupId: benefitGroupId,
            membershipId: objectId,
          },
          headers: { Authorization: otpValidate?.headers?.authorization },
        },
      });
    }
  }, [membershipData]);

  useEffect(() => {
    if (clientBenefitConfig?.length > 0 && currentRedemptionJourney === `new-customer-journey`) {
      const newUserBenefitConfig = clientBenefitConfig?.filter(benefitConfig => benefitConfig?.allocatedTo === `new_user`);

      if (newUserBenefitConfig?.length > 0) {
        dispatch({
          type: GET_BENEFIT_ITEMS,
          endpoint: '/v2/client/benefit-items',
          payload: {
            urlParam: {
              benefitConfigId: newUserBenefitConfig?.[0]?.objectId,
            },
            headers: { Authorization: otpValidate?.headers?.authorization },
          },
        });

        setBookingsDetails({
          membershipId: membershipData?.[0]?.objectId,
          benefitConfigId: newUserBenefitConfig?.[0]?.objectId,
        });
      }
    }
  }, [clientBenefitConfig]);

  useEffect(() => {
    if (getBenefitItems?.length > 0 && currentRedemptionJourney === `new-customer-journey`) {
      setBookingsDetails({ ...bookingsDetails, serviceId: getBenefitItems?.[0]?.service?.id });
      let userId = JSON.parse(localStorage.getItem('authUser'))?.data?.userId;

      if (getBenefitItems != null) {
        dispatch({
          type: CREATE_BOOKING,
          endpoint: '/v2/booking',
          payload: {
            data: {
              process: 'initiate-booking',
              vendor: {
                vendorId: userId,
              },
            },
            headers: { Authorization: otpValidate?.headers?.authorization },

            urlParams: {
              benefitConfigId: bookingsDetails?.benefitConfigId,
              membershipId: bookingsDetails?.membershipId,
              serviceId: getBenefitItems?.[0]?.service?.id,
            },
          },
        });
      }
    }
  }, [getBenefitItems]);

  useEffect(() => {
    if (createBooking !== null && currentRedemptionJourney === `new-customer-journey`) {
      setNewVoucherBookingDetails(createBooking);
      dispatch({
        type: GET_BINS_LIST_EMPTY,
      });
      dispatch({
        type: CREATE_CLIENT_USER_EMPTY,
      });
      dispatch({
        type: GET_BENEFIT_ITEMS_EMPTY,
      });
      dispatch({
        type: VENDOR_RESEND_OTP_EMPTY,
      });
      dispatch({
        type: CREATE_BOOKING_EMPTY,
      });
    }
  }, [createBooking]);

  /* ---------------------------- RESEND OTP HANDLER ---------------------------- */
  const onResendOTPHandler = () => {
    dispatch({
      type: VENDOR_RESEND_OTP,
      payload: {
        headers: {
          Authorization: localStorage?.getItem('landmarkAuthToken'),
          'Project-Code': localStorage.getItem('bankCode') === null ? 'ALRAJHI_LANDMARK' : localStorage.getItem('bankCode'),
        },
      },
    });
  };

  /* ---------------------------- OTP MODAL CLOSE HANDLER ---------------------------- */
  const onCloseHandler = () => {
    dispatch({
      type: CREATE_CLIENT_USER_EMPTY,
    });
  };

  /* ---------------------------- OTP SUBMIT HANDLER ---------------------------- */
  const onConfirmHandler = otp => {
    dispatch({
      type: VENDOR_OTP_VALIDATE,
      payload: {
        data: {
          otp,
        },
        headers: {
          token: localStorage?.getItem('landmarkAuthToken'),
        },
      },
    });
  };

  /* ---------------------------- ON REGISTER CUSTOMER HANDLER ---------------------------- */
  const onRegisterCustomer = payloadData => {
    setRegisterCustomerData(payloadData);

    dispatch({
      type: CREATE_CLIENT_USER,
      payload: {
        data: payloadData,
      },
    });
  };

  /* ---------------------------- LOADER HANDLER ---------------------------- */
  useEffect(() => {
    if (membershipDataLoading || clientBenefitConfigLoading || getBenefitItemsLoading || createBookingLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [membershipDataLoading, clientBenefitConfigLoading, getBenefitItemsLoading, createBookingLoading]);

  return (
    <div>
      {loading && <Loader />}

      {createUser?.status === `success` && (
        <VerificationOTP
          data={registerCustomerData}
          loading={otpValidateLoading || resendOTPResLoading}
          isResendLoader={resendOTPResLoading}
          onClose={onCloseHandler}
          onConfirm={onConfirmHandler}
          onResend={onResendOTPHandler}
        />
      )}

      {newVoucherBookingDetails === null ? (
        <>
          <NewCardVoucherForm onSubmit={onRegisterCustomer} selectedBank={selectedBank} />
        </>
      ) : (
        <Row>
          <GeneratedVoucher data={newVoucherBookingDetails} flag={`new_user`} />
          <NewRedemption
            createBookingRes={createBooking}
            registerCustomerData={registerCustomerData}
            otpValidate={otpValidate}
            membership={membershipData}
            benefitConfig={clientBenefitConfig}
            benefitItems={getBenefitItems}
            currentRedemptionJourney={currentRedemptionJourney}
            setCurrentRedemptionJourney={setCurrentRedemptionJourney}
          />
        </Row>
      )}
    </div>
  );
};

export default NewCardVoucher;
