import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// import { baseUrl, customHeaders, umsUrl, adminAPIURL } from '../../../../Containers/Config/index.js';
import { baseUrl, customHeaders, umsUrl, adminAPIURL } from '../../../../../Containers/Config/index.js';
import { GET_REASON_BY_ACTIONS, GET_REASON_BY_ACTIONS_SUCCESS, GET_REASON_BY_ACTIONS_ERROR } from '../../actions.js';
// import apiCall from '../../apiCall';
import apiCall from '../../../apiCall.js';

function* getReasonByActions({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v2/remarks`,
      method: 'GET',
      headers: {
        // Authorization: Constant?.CLIENT_TOKEN,
        ...filteredHeaders,
        // withCredentials: true
      },
      params: payload?.urlParams,
    });

    if (response.status === 200) {
      yield put({
        type: GET_REASON_BY_ACTIONS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_REASON_BY_ACTIONS_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_REASON_BY_ACTIONS_ERROR, payload: error });
  }
}

export function* watchGetReasonByActions() {
  yield takeEvery(GET_REASON_BY_ACTIONS, getReasonByActions);
}

function* getReasonByActionsSaga() {
  yield all([fork(watchGetReasonByActions)]);
}

export default getReasonByActionsSaga;
