import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Card, CardBody, Col, Row, Container, Label, CardHeader } from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';

const BookingDetails = ({ details, onViewTicket }) => {
  const [bookingDetails, setBookingDetails] = useState([]);

  let lastUpdatedBy = `NA`;
  let confirmedBy = `NA`;
  let processedBy = `NA`;
  let cancelledBy = `NA`;
  let cancelledReason = `NA`;

  let confirmedAt = `NA`;
  let processedAt = `NA`;
  let cancelledAt = `NA`;
  let latestBookingStatus = details?.bookingStatusCode;

  details?.bookingModifyPersons?.forEach(element => {
    if (element?.bookingStatus === latestBookingStatus) {
      lastUpdatedBy = element?.name;
    }
    if (
      element?.bookingStatus === Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE ||
      element?.bookingStatus === Constant.BOOKING_STATUS.SHORT_NOTICE_CANCELLED_BY_THRIWE ||
      element?.bookingStatus === Constant.BOOKING_STATUS.BOOKING_SHORTNOTICE_CANCELED_BY_THRIWE_WITH_QUOTA
    ) {
      cancelledBy = element?.name;
      cancelledReason = element.remarks;
      cancelledAt = moment(`${element?.modifiedAt}`).format('DD-MM-YYYY, h:mm A');
    }
    if (element?.bookingStatus === Constant?.BOOKING_STATUS?.CONFIRM) {
      confirmedBy = element?.name;
      confirmedAt = moment(`${element?.modifiedAt}`).format('DD-MM-YYYY, h:mm A');
    }
    if (element?.bookingStatus === Constant?.BOOKING_STATUS?.PROCESSED) {
      processedBy = element?.name;
      processedAt = moment(`${element?.modifiedAt}`).format('DD-MM-YYYY, h:mm A');
    }
  });

  useEffect(() => {
    setBookingDetails(details);
  }, [details]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const getAddressData = addressData => {
    let address = ``;

    if (addressData?.address !== ``) {
      address += `${addressData?.address || ''}`;
    }
    if (addressData?.landmark !== ``) {
      address += `, ${addressData?.landmark || ''}`;
    }
    if (addressData?.region !== ``) {
      address += `, ${addressData?.region || ''}`;
    }
    if (addressData?.emirates !== ``) {
      address += `, ${addressData?.emirates || ''}`;
    }
    if (addressData?.zipCode !== ``) {
      address += `-${addressData?.zipCode || ''}`;
    }
    return address;
  };

  return (
    <Container fluid>
      {/* <button onClick={() => console.log(bookingDetails)}>Get state</button> */}
      <Row>
        <Col lg={12}>
          <Card className="mt-n4 mx-n4 mb-n5">
            <div className="bg-soft-warning">
              <CardBody className="pb-4 mb-5">
                <Row>
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md-auto mb-1 mt-4"></div>
                      <div className="col-md">
                        <h4 className="fw-semibold" id="ticket-title">
                          {details?.bookingId || ''}
                        </h4>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Personal Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Full Name'}</Label>
                    <Label className="text-muted">{details?.user?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Email Address'}</Label>
                    <Label className="text-muted">{details?.user?.email || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Mobile Number'}</Label>
                    <Label className="text-muted">{details?.user?.mobile || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Passenger Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Passenger name'}</Label>
                    {details?.bookingForSomeoneElse === 1 && <Label className="text-muted">{details?.playerDetails?.name || `NA`}</Label>}
                    {details?.bookingForSomeoneElse === 0 && <Label className="text-muted">{details?.user?.name || `NA`}</Label>}
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Passenger mobile'}</Label>
                    {details?.bookingForSomeoneElse === 1 && <Label className="text-muted">{details?.playerDetails?.mobileNumber || `NA`}</Label>}
                    {details?.bookingForSomeoneElse === 0 && <Label className="text-muted">{details?.user?.mobile || `NA`}</Label>}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Creation and Service</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Date of request'}</Label>
                    <Label className="text-muted">{moment(`${details?.createdAt}`).format('DD-MM-YYYY, h:mm A') || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Pick up Date'}</Label>
                    <Label className="text-muted">{details?.pickupDate ? moment(`${details?.pickupDate}`).format('DD-MM-YYYY') : 'NA'}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Pick up Time'}</Label>
                    <Label className="text-muted">{`${
                      details?.pickupTime ? moment(`${details?.pickupTime}`, 'HH:mm').format('h:mm A') : `NA`
                    }`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {bookingDetails?.guest !== null &&
        bookingDetails?.guest?.map((person, index) => (
          <Row key={index}>
            <Col xxl={12}>
              <Card>
                <CardBody className="p-4">
                  <h6 className="fw-semibold text-uppercase mb-3">Guest Details</h6>
                  <div className="border mt-2 mb-3 border"></div>

                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{`Guest Name`}</Label>
                        <Label className="text-muted">{person?.name || `NA`}</Label>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{`Guest Phone`}</Label>
                        <Label className="text-muted">{person?.phone || `NA`}</Label>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{`Guest Email`}</Label>
                        <Label className="text-muted">{person?.email || `NA`}</Label>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ))}

      {details?.vendor?.vendorId !== '' && (
        <Row>
          <Col xxl={12}>
            <Card>
              <CardBody className="p-4">
                <h6 className="fw-semibold text-uppercase mb-3">Vendor Details</h6>
                <div className="border mt-2 mb-3 border"></div>

                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Name'}</Label>
                      <Label className="text-muted">{details?.vendor?.name || `NA`}</Label>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Price'}</Label>
                      <Label className="text-muted">{details?.vendor?.price || `NA`}</Label>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Car Type'}</Label>
                      <Label className="text-muted">{details?.vendor?.carType || `NA`}</Label>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Remarks'}</Label>
                      <Label className="text-muted">{details?.vendor?.remarks || `NA`}</Label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">PASSENGERS &amp; LUGGAGES</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'No. of Adults'}</Label>
                    <Label className="text-muted">{details?.numberOfAdults?.toString() || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'No. of Baby Seats'}</Label>
                    <Label className="text-muted">{details?.numberOfBabySeats?.toString() || `0`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'No. of Luggages'}</Label>
                    <Label className="text-muted">{details?.numberOfLuggages?.toString() || `0`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'No. of Passengers'}</Label>
                    <Label className="text-muted">{details?.numberOfPassengers?.toString() || `0`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'No. of Infants'}</Label>
                    <Label className="text-muted">{details?.numberOfInfants?.toString() || `0`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Action Date & Time</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Processed At'}</Label>
                    <Label className="text-muted">{processedAt}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Confirmed At'}</Label>
                    <Label className="text-muted">{confirmedAt}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Cancelled At'}</Label>
                    <Label className="text-muted">{cancelledAt}</Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Last updated at'}</Label>
                    <Label className="text-muted">{moment(`${details?.updatedAt}`).format('DD-MM-YYYY, h:mm A') || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Action By</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Last Updated By'}</Label>
                    <Label className="text-muted">{lastUpdatedBy}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{' Processed By'}</Label>
                    <Label className="text-muted">{processedBy}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Confirmed By'}</Label>
                    <Label className="text-muted">{confirmedBy}</Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Cancelled By'}</Label>
                    <Label className="text-muted">{cancelledBy}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Flight &amp; Travel details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Car type (Comfort, MPV & Luxury)'}</Label>
                    <Label className="text-muted">{details?.chauffer?.carType === '1' ? 'Auto' : 'Automatic' || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Airport Name'}</Label>
                    <Label className="text-muted">{details?.airport?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Class Of Travel'}</Label>
                    <Label className="text-muted">{details?.classOfTravel?.type || `NA`}</Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Flight Number'}</Label>
                    <Label className="text-muted">{details?.flightNumber || `NA`}</Label>
                  </div>
                </Col>

                {Constant.PROJECT_CODE === 'ENBD_V3' ? (
                  <>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{'Terminal Name'}</Label>
                        <Label className="text-muted">{details?.terminalName || `NA`}</Label>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{'Terminal Number'}</Label>
                        <Label className="text-muted">{details?.terminalNumber || `NA`}</Label>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{'Ticket Purchase Amount'}</Label>
                        <Label className="text-muted">
                          {details?.ticketPurchaseAmount?.value || `NA`} {details?.ticketPurchaseAmount?.currency || ``}{' '}
                        </Label>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{'Ticket Purchase Date'}</Label>
                        <Label className="text-muted">
                          {details?.ticketPurchaseDate ? moment(`${details?.ticketPurchaseDate}`).format('DD-MM-YYYY, h:mm A') || `NA` : 'NA'}
                        </Label>
                      </div>
                    </Col>
                  </>
                ) : (
                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Terminal Name'}</Label>
                      <Label className="text-muted">{details?.terminal || `NA`}</Label>
                    </div>
                  </Col>
                )}

                {details?.ticketUrl && (
                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'View Ticket'}</Label>
                      <Label className="text-success text-decoration-underline cursor-pointer" onClick={() => onViewTicket(details)}>
                        View<i className="ri-arrow-right-up-line label-icon align-middle fs-16 me-2"></i>
                      </Label>
                    </div>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Address</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Pick up address'}</Label>
                    <Label className="text-muted">{getAddressData(details?.pickupLocation)}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Drop Address'}</Label>
                    <Label className="text-muted">{getAddressData(details?.dropLocation)}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Chauffeur details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Chauffeur Name'}</Label>
                    <Label className="text-muted">{details?.chauffer?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Chauffeur Mobile Number'}</Label>
                    <Label className="text-muted">{details?.chauffer?.mobile || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Car Number'}</Label>
                    <Label className="text-muted">{details?.chauffer?.carNumber || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Remarks and Reason</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Remarks'}</Label>
                    <Label className="text-muted">{details?.remarks || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Reason For Cancellation'}</Label>
                    <Label className="text-muted">{cancelledReason}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* ------------------------- PAYMENT SUMMARY ------------------------- */}
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0 flex-grow-1">PAYMENT SUMMARY</h4>
            </CardHeader>

            <CardBody className="m-4 mb-0 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3">Total Payable</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{details?.paymentSummary?.totalPayable?.name || ``}</Label>
                    <Label className="text-muted">
                      {`${details?.paymentSummary?.totalPayable?.value}` || `NA`}({details?.paymentSummary?.totalPayable?.currency || `NA`})
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Payment Status'}</Label>
                    <Label className="text-muted">{details?.payment?.status || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Payment Id'}</Label>
                    <Label className="text-muted">{details?.payment?.paymentId || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>

            <CardBody className="m-4 mb-0 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3">Booking Tax</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{details?.paymentSummary?.bookingTax?.name || ``}</Label>
                    <Label className="text-muted">
                      {`${details?.paymentSummary?.bookingTax?.value}` || `NA`}({details?.paymentSummary?.bookingTax?.currency || `NA`})
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>

            <CardBody className="m-4 mb-0 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3">Booking Discount</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{details?.paymentSummary?.bookingDiscount?.name || ``}</Label>
                    <Label className="text-muted">
                      {`${details?.paymentSummary?.bookingDiscount?.value}` || `NA`}({details?.paymentSummary?.bookingDiscount?.currency || `NA`})
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>

            <CardBody className="m-4 mb-0 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3">Booking Fee</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{details?.paymentSummary?.bookingFee?.name || ``}</Label>
                    <Label className="text-muted">
                      {`${details?.paymentSummary?.bookingFee?.value}` || `NA`}({details?.paymentSummary?.bookingFee?.currency || `NA`})
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>

            <CardBody className="m-4 mb-0 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3">Discounts</h6>
              <div className="border mt-2 mb-3 border"></div>

              {details?.paymentSummary?.discounts?.map((discount, index) => {
                return (
                  <Row key={index}>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{'Amount'}</Label>
                        <Label className="text-muted">
                          {`${discount?.amount}` || `NA`}({discount?.currency || `NA`})
                        </Label>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{discount?.name || ``}</Label>
                        <Label className="text-muted">{`${discount?.value}%` || `NA`}</Label>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </CardBody>

            <CardBody className="m-4 mb-0 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3"> Fees</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                {details?.paymentSummary?.fees?.map((fee, index) => {
                  return (
                    <Col key={index} md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{fee?.name || ``}</Label>
                        <Label className="text-muted">
                          {`${fee?.value}` || `NA`}({fee?.currency || `NA`})
                        </Label>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </CardBody>

            <CardBody className="m-4 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3"> Taxes</h6>
              <div className="border mt-2 mb-3 border"></div>

              {details?.paymentSummary?.taxes?.map((tax, index) => {
                return (
                  <Row key={index}>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{tax?.name || ``}</Label>
                        <Label className="text-muted">{`${tax?.value}%` || `NA`}</Label>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{`Amount`}</Label>
                        <Label className="text-muted">
                          {`${tax?.amount}` || `NA`}({tax?.currency || `NA`})
                        </Label>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BookingDetails;
