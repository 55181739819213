import { GET_CARD_SUMMARY, GET_CARD_SUMMARY_SUCCESS, GET_CARD_SUMMARY_ERROR, GET_CARD_SUMMARY_EMPTY } from '../../../actions';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const GetCardSummary = (state = initialState, action) => {
  switch (action.type) {
    case GET_CARD_SUMMARY:
      return {
        ...state,
        loading: true,
      };

    case GET_CARD_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state?.data,
          [action.meta.cardName]: action.payload, // Store response per card
        },
      };

    case GET_CARD_SUMMARY_ERROR:
      return {
        ...state,
        loading: false,
        error: {
          ...state.error,
          [action.meta.cardName]: action.payload, // Track errors per card
        },
      };

    case GET_CARD_SUMMARY_EMPTY:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default GetCardSummary;
