import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import classnames from 'classnames';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Card, CardBody, Col, Form, Label, Row, Input, CardFooter, Accordion, AccordionItem, Collapse, CardHeader } from 'reactstrap';

import { Constant } from '../../../../../../Helpers/constant';
import { Error, PreviewCardHeader } from '../../../../../../Common';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { GET_COUPON_CATEGORY, CREATE_PARTNER, GET_BOOKING_TYPES_LIST, UPDATE_PARTNER } from '../../../../../../../store/application/actionType';
import {
  hasPermission,
  isValidAlphaNumbericWithoutSpace,
  isValidAlphaWithSpec,
  isValidAplha,
  isValidEmail,
  isValidNumber,
  isValidUrl,
  validateUrl,
} from '../../../../../../Helpers/Helpers';
import { GoPlus } from 'react-icons/go';
import DynamicForm from '../Modals/DynamicForm';
import DataTable from 'react-data-table-component';
import DeletionDynamicFieldModal from '../Modals/DynamicFieldDeletion';
import { AsyncPaginate } from 'react-select-async-paginate';
import { baseUrl } from '../../../../../../Config';
import axios from 'axios';

const initialState = {
  name: '',
  displayName: '',
  category: null,
  images: {
    logo: '',
    coverImageMobile: '',
    coverImageWeb: '',
    displayImageMobile: '',
    displayImageWeb: '',
    popularPartnerImage: '',
  },
  longDescription: '',
  shortDescription: '',
  redemptionType: {
    id: '',
    name: '',
  },
  isPopular: false,
  defaultRedemptionType: '-1',
  facilityRequired: false,
  isGuestBooking: false,
  merchant: {
    username: '',
    password: '',
  },
  thirdPartyWebsite: '',
  defaultRedemptionProcess: '',
  defaultBenefitOffered: '',
  defaultTermsAndCondition: '',
  mql: {
    isActive: true,
  },
  isActive: false,
  globalPartnerId: '',
  bookingType: null,
  priority: 0,

  tat: {
    minimum: {
      value: 0,
      unit: 0,
    },
    maximum: {
      value: 0,
      unit: 0,
    },
    cancel: {
      value: 0,
      unit: 0,
    },
    confirm: {
      value: 0,
      unit: 0,
    },
  },
  quota: 0,
  amenities: [],
  bankDetails: {
    name: '',
    nameOnBank: '',
    accountNumber: '',
    branch: '',
    ifscCode: '',
    signatoryName: '',
    signatoryNumber: '',
    signatoryEmail: '',
    spocSignatoryName: '',
    spocSignatoryNumber: '',
    spocSignatoryEmail: '',
    thriweCommission: '',
    payoutFrequency: '',
  },
  bccToFacility: false,
};

const inputTypeOptions = [
  { value: 1, label: 'Text' },
  { value: 2, label: 'Select' },
  { value: 3, label: 'Date' },
  { value: 4, label: 'Time' },
  { value: 5, label: 'Address' },
  { value: 6, label: 'Number' },
];

const AddPartnerForm = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [accordions, setAccordions] = useState({
    general: {
      status: true,
      error: false,
    },
    displayProperties: {
      status: false,
      error: false,
    },
    bookingProperties: {
      status: false,
      error: false,
    },
    tat: {
      status: false,
      error: false,
    },
    amenities: {
      status: false,
      error: false,
    },
    partnerBank: {
      status: false,
      error: false,
    },
    customDynamicField: {
      status: false,
      error: false,
    },
  });

  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [isMerchant, setIsMerchant] = useState(false);

  const [allCategories, setAllCategories] = useState([]);
  const [allRedemptionTypes, setAllRedemptionTypes] = useState([]);

  const [allAmenities, setAllAmenities] = useState([]);
  const [bookingTypesData, setBookingTypesData] = useState([]);
  const [selectedDynamicField, setSelectedDynamicField] = useState(null);
  const [isShowDynamicForm, setIsShowDynamicForm] = useState(false);
  const [isShowDeletionModal, setIsShowDeletionModal] = useState(false);
  const [dynamicField, setDynamicField] = useState([]);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Categories
  const { categories } = useSelector(state => ({
    categories: state?.GetCouponCategory?.data,
  }));

  // Redemption Types
  const { redemptionTypes } = useSelector(state => ({
    redemptionTypes: state?.GetRedemptionType?.data,
  }));

  // Booking Types
  const { bookingTypes } = useSelector(state => ({
    bookingTypes: state?.BookingTypes?.data,
  }));

  useEffect(() => {
    if (categories === null) {
      // dispatch({
      //   type: GET_COUPON_CATEGORY,
      // });
    }
    if (bookingTypes === null) {
      dispatch({
        type: GET_BOOKING_TYPES_LIST,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (bookingTypes !== null) {
      setBookingTypesData(
        bookingTypes?.results?.map(bookingType => {
          return {
            value: bookingType?.objectId,
            label: bookingType?.name,
            data: bookingType,
          };
        }),
      );
    }
  }, [bookingTypes]);

  useEffect(() => {
    if (categories !== null) {
      setAllCategories(
        categories?.map(category => {
          return {
            value: category?.objectId,
            label: category?.name,
            data: category,
          };
        }),
      );
    }
  }, [categories]);

  useEffect(() => {
    if (redemptionTypes !== null) {
      setAllRedemptionTypes(
        redemptionTypes?.map(redemptionType => {
          return {
            value: redemptionType?.objectId,
            label: redemptionType?.name,
          };
        }),
      );
    }
  }, [redemptionTypes]);

  useEffect(() => {
    if (dynamicField.length > 0) {
      const fetchCoumns = [
        {
          name: 'S.No',
          width: '150px',
          wrap: true,
          selector: row => row?.serialNumber,
        },
        {
          name: 'Input Type',
          maxWidth: '200px',
          wrap: true,
          selector: row => row?.inputType,
        },
        {
          name: 'Header Name',
          maxWidth: '200px',
          wrap: true,
          selector: row => row?.headerName,
        },
        {
          name: 'Placeholder',
          maxWidth: '200px',
          wrap: true,
          selector: row => row?.placeholder,
        },
        {
          name: 'Action',
          width: '250px',
          wrap: true,
          cell: row => (
            <div className="d-flex justify-content-center flex-column m-4">
              <button
                className="btn btn-sm btn-primary mb-1"
                onClick={e => {
                  e.preventDefault();
                  setIsShowDynamicForm(true);
                  setSelectedDynamicField(row?.orignalData);
                }}
                style={{ width: '150px' }}
              >
                Update
              </button>

              <button
                className="btn btn-sm btn-danger mb-1"
                onClick={e => {
                  e.preventDefault();
                  setIsShowDeletionModal(true);
                  setSelectedDynamicField(row?.orignalData);
                }}
                style={{ width: '150px' }}
              >
                Remove
              </button>
            </div>
          ),
        },
      ];
      setColumns(fetchCoumns);
      setData(
        dynamicField?.map((dynamicData, index) => {
          return {
            serialNumber: index + 1,
            inputType: inputTypeOptions.find(option => option?.value === dynamicData?.inputType)?.label || 'NA',
            headerName: dynamicData?.headerName || 'NA',
            placeholder: dynamicData?.placeholder || 'NA',
            orignalData: { ...dynamicData, index },
          };
        }),
      );
    } else {
      setColumns([]);
      setData([]);
    }
  }, [dynamicField]);

  useEffect(() => {
    if (props?.data !== undefined) {
      const tempStateData = {
        ...props?.data,
        category: {
          value: props?.data?.category?.id,
          label: props?.data?.category?.name,
        },
        bookingType: {
          value: props?.data?.bookingType?.objectId,
          label: props?.data?.bookingType?.name,
          data: props?.data?.bookingType,
        },
        amenities: props?.data?.amenities?.map(amenity => ({
          value: amenity?.name,
          label: amenity?.name,
          data: amenity,
        })),
      };
      setState({ ...tempStateData });

      if (props?.data?.category?.amenities?.length > 0) {
        setAllAmenities(
          props?.data?.category?.amenities?.map((amenity, index) => ({
            value: `${amenity?.name}`,
            label: amenity?.name,
            data: amenity,
          })),
        );
      }

      if (props?.data?.dynamicFields?.length > 0) {
        setDynamicField(props?.data?.dynamicFields);
      }
      // validationCheck({ ...tempStateData });
    }
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: isValidAlphaWithSpec(value) ? value : state?.name,
        };
        break;

      case 'displayName':
        updatedState = {
          ...state,
          displayName: isValidAlphaWithSpec(value) ? value : state?.displayName,
        };
        break;

      case 'quota':
        updatedState = {
          ...state,
          quota: value?.length > 4 ? state?.quota : isNaN(value) ? state?.quota : parseInt(value || 0),
        };
        break;

      case 'logo':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            logo: value,
          },
        };
        break;

      case 'coverImageMobile':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            coverImageMobile: value,
          },
        };
        break;

      case 'coverImageWeb':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            coverImageWeb: value,
          },
        };
        break;

      case 'displayImageMobile':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            displayImageMobile: value,
          },
        };
        break;

      case 'displayImageWeb':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            displayImageWeb: value,
          },
        };
        break;

      case 'thirdPartyWebsite':
        updatedState = {
          ...state,
          thirdPartyWebsite: value,
        };
        break;

      case 'shortDescription':
        updatedState = {
          ...state,
          shortDescription: isValidAlphaWithSpec(value) ? value : state?.shortDescription,
        };
        break;

      case 'longDescription':
        updatedState = {
          ...state,
          longDescription: isValidAlphaWithSpec(value) ? value : state?.longDescription,
        };
        break;

      case 'is-mql-active':
        updatedState = {
          ...state,
          mql: {
            mqlAlertLevelFirst: ``,
            mqlAlertLevelSecond: ``,
            mqlAlertLevelThird: ``,
            isActive: !state.mql.isActive,
          },
        };
        break;

      case 'mqlAlertLevelFirst':
        updatedState = {
          ...state,
          mql: {
            ...state.mql,
            mqlAlertLevelFirst: value,
          },
        };
        break;

      case 'mqlAlertLevelSecond':
        updatedState = {
          ...state,
          mql: {
            ...state.mql,
            mqlAlertLevelSecond: value,
          },
        };
        break;

      case 'mqlAlertLevelThird':
        updatedState = {
          ...state,
          mql: {
            ...state.mql,
            mqlAlertLevelThird: value,
          },
        };
        break;

      case 'isMerchant':
        setIsMerchant(!isMerchant);
        updatedState = {
          ...state,
          merchant: {
            username: ``,
            password: ``,
          },
        };
        break;

      case 'username':
        updatedState = {
          ...state,
          merchant: {
            ...state.merchant,
            username: isValidAplha(value) ? value : state?.merchant?.username,
          },
        };
        break;

      case 'password':
        updatedState = {
          ...state,
          merchant: {
            ...state.merchant,
            password: value,
          },
        };
        break;

      case 'globalPartnerId':
        updatedState = {
          ...state,
          globalPartnerId: isValidAlphaNumbericWithoutSpace(value) ? value : state?.globalPartnerId,
        };
        break;

      case 'priority':
        updatedState = {
          ...state,
          priority: value.length > 4 ? state?.priority : isNaN(value) ? state?.priority : parseInt(value || 0),
        };
        break;

      case 'bccToFacility':
        updatedState = {
          ...state,
          bccToFacility: !state?.bccToFacility,
        };
        break;

      // -------
      case 'tat-minimum-unit':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            minimum: {
              ...state?.tat?.minimum,
              unit: parseInt(value || 0),
              value: value === '0' ? 0 : state?.tat?.minimum?.value,
            },
          },
        };
        break;

      case 'tat-minimum-value':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            minimum: {
              ...state?.tat?.minimum,
              value: value.length > 3 ? state?.tat?.minimum?.value : isNaN(value) ? state?.tat?.minimum?.value : parseInt(value || 0),
            },
          },
        };
        break;

      case 'tat-maximum-unit':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            maximum: {
              ...state?.tat?.maximum,
              unit: parseInt(value || 0),
              value: value === '0' ? 0 : state?.tat?.maximum?.value,
            },
          },
        };
        break;

      case 'tat-maximum-value':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            maximum: {
              ...state?.tat?.maximum,
              value: value.length > 3 ? state?.tat?.maximum?.value : isNaN(value) ? state?.tat?.maximum?.value : parseInt(value || 0),
            },
          },
        };
        break;

      case 'tat-cancel-unit':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            cancel: {
              ...state?.tat?.cancel,
              unit: parseInt(value || 0),
              value: value === '0' ? 0 : state?.tat?.cancel?.value,
            },
          },
        };
        break;

      case 'tat-cancel-value':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            cancel: {
              ...state?.tat?.cancel,
              value: value.length > 3 ? state?.tat?.cancel?.value : isNaN(value) ? state?.tat?.cancel?.value : parseInt(value || 0),
            },
          },
        };
        break;

      // ------- Bank Details -------
      case 'bank-name':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            name: isValidAplha(value) ? value : state.bankDetails?.name,
          },
        };
        break;

      case 'name-on-bank':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            nameOnBank: isValidAplha(value) ? value : state.bankDetails?.nameOnBank,
          },
        };
        break;

      case 'bank-account-number':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            accountNumber: isValidNumber(value) ? value : state?.bankDetails?.accountNumber,
          },
        };
        break;

      case 'branch':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            branch: value,
          },
        };
        break;

      case 'ifsc-code':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            ifscCode: isValidAlphaNumbericWithoutSpace(value) ? value : state?.bankDetails?.ifscCode,
          },
        };
        break;

      case 'authorised-signatory-name':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            signatoryName: isValidAplha(value) ? value : state.bankDetails?.signatoryName,
          },
        };
        break;

      case 'authorised-signatory-number':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            signatoryNumber: isValidNumber(value) ? value : state.bankDetails?.signatoryNumber,
          },
        };
        break;

      case 'authorised-signatory-email':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            signatoryEmail: value,
          },
        };
        break;

      case 'spoc-signatory-name':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            spocSignatoryName: isValidAplha(value) ? value : state.bankDetails?.spocSignatoryName,
          },
        };
        break;

      case 'spoc-signatory-number':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            spocSignatoryNumber: isValidNumber(value) ? value : state?.bankDetails?.spocSignatoryNumber,
          },
        };
        break;

      case 'spoc-signatory-email':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            spocSignatoryEmail: value,
          },
        };
        break;

      case 'thriwe-commission':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            thriweCommission: isValidNumber(value)
              ? value > 100
                ? state?.bankDetails?.thriweCommission
                : value
              : state?.bankDetails?.thriweCommission,
          },
        };
        break;

      case 'payout-frequency':
        updatedState = {
          ...state,
          bankDetails: {
            ...state.bankDetails,
            payoutFrequency: value,
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  const ckEditorDataInput = (name, data) => {
    let updatedState = { ...state };

    switch (name) {
      case 'shortDescription':
        updatedState = {
          ...state,
          shortDescription: data,
        };
        break;

      case 'longDescription':
        updatedState = {
          ...state,
          longDescription: data,
        };
        break;

      case 'defaultRedemptionProcess':
        updatedState = {
          ...state,
          defaultRedemptionProcess: data,
        };
        break;

      case 'defaultTermsAndCondition':
        updatedState = {
          ...state,
          defaultTermsAndCondition: data,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };
  const loadOptions = async (search,type) => {
    
    console.log('ddd',search,type);
    let URL='';
    if (type === `category`) {
      
      URL = `${baseUrl}/admin/categories?pageIndex=0&pageSize=10`;
    
    } 
    console.log('url-->',URL);
    return await axios({
      method: `GET`,
      url: URL,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        // ...customHeaders,
      },
      params: {
        name: search === '' ? null : search,
        // categoryId:type==='category'? state?.category?.value :null
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.objectId,
              label: `${data?.name}`,
              data: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
    
  };
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };
    console.log('--load---',label, data);
    if (label === `category`) {
      updatedState = {
        ...state,
        category: data,
        images: {
          ...data?.data?.images,
        },
        shortDescription: data?.data?.shortDescription,
        longDescription: data?.data?.longDescription,
        bookingType: {
          data: { ...data?.data?.bookingType },
          label: data?.data?.bookingType?.name,
          value: data?.data?.bookingType?.objectId,
        },
        amenities: [],
      };

      setAllAmenities(
        data?.data?.amenities?.map((amenity, index) => {
          return {
            value: `${amenity?.name}`,
            label: amenity.name,
            data: amenity,
          };
        }),
      );
    } else if (label === `amenities`) {
      updatedState = {
        ...state,
        amenities: data,
      };
    } else if (label === `redemptionType`) {
      updatedState = {
        ...state,
        redemptionType: data,
      };
    } else if (label === `bookingType`) {
      updatedState = {
        ...state,
        bookingType: data,
      };
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- AMANITIES HANDLER ---------------------------- */
  const amenitiesHandler = amenityData => {
    let tempStateAmenities = [];

    if (state?.amenities?.length > 0) {
      tempStateAmenities = [...state.amenities];
    }

    const amenityIndex = tempStateAmenities?.findIndex(amenity => amenity?.value === amenityData?.value);
    if (amenityIndex === -1) {
      tempStateAmenities?.push(amenityData);
    } else {
      tempStateAmenities.splice(amenityIndex, 1);
    }

    setState({
      ...state,
      amenities: tempStateAmenities,
    });
  };

  /* ---------------------------- VALIDATE ERROR ---------------------------- */
  const validateErrors = (flag, data = state) => {
    let isError = false;
    let accordionsTemp = { ...accordions };

    if (
      data?.category === null ||
      data?.name?.trim() === `` ||
      !isValidUrl(data?.images?.logo) ||
      data?.globalPartnerId === `` ||
      data?.bookingType === null
    ) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        general: {
          ...accordionsTemp?.general,
          status: flag === `validationCheck` ? accordionsTemp?.general?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        general: {
          status: flag === `validationCheck` ? accordionsTemp?.general?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
        amenities: {
          status: flag === `validationCheck` ? accordionsTemp?.amenities?.status : false,
          error: false,
        },
      };
    }

    if (
      data?.displayName?.trim() === `` ||
      !isValidUrl(data?.images?.coverImageMobile) ||
      !isValidUrl(data?.images?.coverImageWeb) ||
      !isValidUrl(data?.images?.displayImageMobile) ||
      !isValidUrl(data?.images?.displayImageWeb)
    ) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        displayProperties: {
          ...accordionsTemp?.displayProperties,
          status: flag === `validationCheck` ? accordionsTemp?.displayProperties?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        displayProperties: {
          status: flag === `validationCheck` ? accordionsTemp?.displayProperties?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
        amenities: {
          status: flag === `validationCheck` ? accordionsTemp?.amenities?.status : false,
          error: false,
        },
      };
    }

    if (!isValidUrl(data?.thirdPartyWebsite)) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        bookingProperties: {
          ...accordionsTemp?.bookingProperties,
          status: flag === `validationCheck` ? accordionsTemp?.bookingProperties?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        bookingProperties: {
          status: flag === `validationCheck` ? accordionsTemp?.bookingProperties?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
        amenities: {
          status: flag === `validationCheck` ? accordionsTemp?.amenities?.status : false,
          error: false,
        },
      };
    }

    if (!isValidEmail(data?.bankDetails?.signatoryEmail) || !isValidEmail(data?.bankDetails?.spocSignatoryEmail)) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        partnerBank: {
          ...accordionsTemp?.partnerBank,
          status: flag === `validationCheck` ? accordionsTemp?.partnerBank?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        partnerBank: {
          status: flag === `validationCheck` ? accordionsTemp?.partnerBank?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
        amenities: {
          status: flag === `validationCheck` ? accordionsTemp?.amenities?.status : false,
          error: false,
        },
      };
    }

    setIsValidationShow(flag === `submissionCheck` ? isError : isValidationShow ? true : false);
    setAccordions({ ...accordionsTemp });
    return isError;
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (!validateErrors(`submissionCheck`)) {
      const payload = {
        ...state,
        dynamicFields: dynamicField,
        category: {
          id: state?.category.value,
          name: state?.category.label,
        },
        amenities:
          state?.amenities?.length > 0
            ? state?.amenities?.map(amenity => {
                return {
                  name: amenity?.data?.name,
                  logo: amenity?.data?.logo,
                };
              })
            : [],
        bookingType: state?.bookingType?.data,
      };

      if (props?.title === `Update Brand`) {
        dispatch({
          type: UPDATE_PARTNER,
          payload: {
            data: {
              requests: [{ ...payload }],
            },
          },
        });
      } else if (props?.title === `Add Brand`) {
        dispatch({
          type: CREATE_PARTNER,
          payload: {
            data: payload,
          },
        });
      }
    }
  };

  /* ---------------------------- ACCORDION HANDLER ---------------------------- */
  const accordionToggleHandler = flag => {
    setAccordions({
      ...accordions,
      [flag]: {
        ...accordions[flag],
        status: !accordions[flag]['status'],
      },
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const saveDynamicField = (flag, index = 0, data) => {
    if (flag === 'CREATE') {
      setDynamicField([...dynamicField, data]);
    } else if (flag === 'UPDATE') {
      setDynamicField(dynamicField.map((item, idx) => (idx === index ? data : item)));
    }
  };

  const deleteDynamicField = () => {
    const updatedDynamicField = dynamicField.filter((_, index) => index !== selectedDynamicField?.index);
    console.log(updatedDynamicField);
    setDynamicField(updatedDynamicField);
  };

  return (
    <Row>
      {/* <button onClick={() => console.log(state, allAmenities)}>Get State</button> */}

      {isShowDynamicForm && (
        <DynamicForm
          selectedDynamicField={selectedDynamicField}
          saveDynamicField={saveDynamicField}
          onCloseHandler={() => setIsShowDynamicForm(false)}
        />
      )}

      {isShowDeletionModal && (
        <DeletionDynamicFieldModal deleteDynamicField={deleteDynamicField} onCloseHandler={() => setIsShowDeletionModal(false)} />
      )}
      <Col xxl={12}>
        <Card>
          <CardHeader className="d-flex">
            <h4 className="card-title mb-0 flex-grow-1">{props?.title}</h4>

            {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE) && props?.title === `Add Brand` && (
              <span className="btn btn-primary mb-1 mx-1" onClick={props?.bulkUploadAction}>
                <i className="ri-upload-cloud-2-line me-1 text-white fs-14 align-middle"></i>Upload Brand
              </span>
            )}
          </CardHeader>

          <CardBody>
            <Form>
              <Row>
                <Col xxl={12}>
                  <CardBody>
                    <Accordion
                      className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-secondary"
                      id="accordionBordered"
                    >
                      {/* ---------------------------- ACCORDION - General ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.general?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`general`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>General</div>
                              {accordions?.general?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.general?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              {/* <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="category" className="form-label">
                                    Sub-Category <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Select
                                    id="category"
                                    name="category"
                                    options={allCategories ?? []}
                                    value={state?.category}
                                    onChange={data => inputSelectHandler(`category`, data)}
                                  />
                                  {isValidationShow && state?.category === null && <Error text="Please select" />}
                                </div>
                              </Col> */}
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="category" className="form-label">
                                    Sub-Category <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <AsyncPaginate
                                    debounceTimeout={500}
                                    loadOptionsOnMenuOpen={false}

                                    value={state?.category}
                                    // loadOptions={loadOptionForBenefitGroup}
                                    loadOptions={search => loadOptions(search, 'category')}
                                    // onChange={inputSelectHandler}
                                    // loadOptions={loadOptions}
                                    onChange={data => inputSelectHandler('category', data)}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={() => 'No results found'}
                                    cacheUniqs={[['code']]}
                                    placeholder="Select..."
                                    // isDisabled={isServiceDisabled}
                                  />
                                  {isValidationShow && state?.category === null && <Error text="Please select" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="name" className="form-label">
                                    System Name
                                    <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    value={state.name}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                    disabled={props?.title !== `Add Brand`}
                                  />
                                  {isValidationShow && state?.name?.trim() === `` && <Error text="Please enter system name" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="logo" className="form-label">
                                    Logo <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Input
                                    id="logo"
                                    name="logo"
                                    type="text"
                                    className="form-control"
                                    value={state.images.logo}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && !validateUrl(state?.images?.logo) && <Error text="Please enter valid URL" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="globalPartnerId" className="form-label">
                                    Global Brand Id
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="globalPartnerId"
                                    name="globalPartnerId"
                                    type="text"
                                    className="form-control"
                                    value={state.globalPartnerId}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && state.globalPartnerId === `` && <Error text="Please enter" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="bookingType" className="form-label">
                                    Booking Type
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Select
                                    id="bookingType"
                                    name="bookingType"
                                    options={bookingTypesData ?? []}
                                    value={state?.bookingType}
                                    onChange={data => inputSelectHandler(`bookingType`, data)}
                                  />
                                  {isValidationShow && state?.bookingType === null && <Error text="Please enter" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="priority" className="form-label">
                                    Priority
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="priority"
                                    name="priority"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.priority}
                                    autoComplete="off"
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="Quota" className="form-label">
                                    Quota
                                  </Label>
                                  <Input
                                    id="quota"
                                    name="quota"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.quota}
                                    autoComplete="off"
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={12}>
                                <div
                                  style={{
                                    marginBottom: '10px',
                                  }}
                                >
                                  <div className="form-switch d-inline-block ">
                                    <Input
                                      className="form-check-input code-switcher cursor-pointer"
                                      type="checkbox"
                                      id="bccToFacility"
                                      name="bccToFacility"
                                      checked={state?.bccToFacility}
                                      onChange={inputChangeHandler}
                                    />
                                  </div>
                                  <Label className="form-label">Send email to outlets in BCC?</Label>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Display Properties ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.displayProperties?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`displayProperties`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Display Properties</div>
                              {accordions?.displayProperties?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.displayProperties?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="displayName" className="form-label">
                                    Display Name <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Input
                                    id="displayName"
                                    name="displayName"
                                    type="text"
                                    className="form-control"
                                    value={state?.displayName}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && state?.displayName?.trim() === `` && <Error text="Please enter display name" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="coverImageMobile" className="form-label">
                                    Cover Image Mobile URL
                                  </Label>
                                  <Input
                                    id="coverImageMobile"
                                    name="coverImageMobile"
                                    type="text"
                                    className="form-control"
                                    value={state.images.coverImageMobile}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && !isValidUrl(state?.images?.coverImageMobile) && <Error text="Please enter valid URL" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="coverImageWeb" className="form-label">
                                    Cover Image Web URL
                                  </Label>
                                  <Input
                                    id="coverImageWeb"
                                    name="coverImageWeb"
                                    type="text"
                                    className="form-control"
                                    value={state.images.coverImageWeb}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && !isValidUrl(state?.images?.coverImageWeb) && <Error text="Please enter valid URL" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="displayImageMobile" className="form-label">
                                    Display Image Mobile URL
                                  </Label>
                                  <Input
                                    id="displayImageMobile"
                                    name="displayImageMobile"
                                    type="text"
                                    className="form-control"
                                    value={state.images.displayImageMobile}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && !isValidUrl(state?.images?.displayImageMobile) && <Error text="Please enter valid URL" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="displayImageWeb" className="form-label">
                                    Display Image Web URL
                                  </Label>
                                  <Input
                                    id="displayImageWeb"
                                    name="displayImageWeb"
                                    type="text"
                                    className="form-control"
                                    value={state.images.displayImageWeb}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && !isValidUrl(state?.images?.displayImageWeb) && <Error text="Please enter valid URL" />}
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="defaultRedemptionProcess" className="form-label">
                                    Short Description
                                  </Label>
                                  <div className="snow-editor" style={{ minHeight: 300 }}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.shortDescription || ''}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`shortDescription`, data);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="defaultRedemptionProcess" className="form-label">
                                    Long Description
                                  </Label>
                                  <div className="snow-editor" style={{ minHeight: 300 }}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.longDescription || ''}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`longDescription`, data);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="defaultRedemptionProcess" className="form-label">
                                    Default Redemption Process
                                  </Label>
                                  <div className="snow-editor" style={{ minHeight: 300 }}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.defaultRedemptionProcess || ''}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`defaultRedemptionProcess`, data);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="defaultTermsAndCondition" className="form-label">
                                    Default T&C
                                  </Label>
                                  <div className="snow-editor" style={{ minHeight: 300 }}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.defaultTermsAndCondition || ''}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`defaultTermsAndCondition`, data);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Booking Properties ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.bookingProperties?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`bookingProperties`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Booking Properties</div>
                              {accordions?.bookingProperties?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.bookingProperties?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="thirdPartyWebsite" className="form-label">
                                    Default Third Party Website URL
                                  </Label>
                                  <Input
                                    id="thirdPartyWebsite"
                                    name="thirdPartyWebsite"
                                    type="text"
                                    className="form-control"
                                    value={state?.thirdPartyWebsite}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && !isValidUrl(state?.thirdPartyWebsite) && <Error text="Please enter valid URL" />}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - TAT ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.tat?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`tat`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>TAT</div>
                              {accordions?.tat?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.tat?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-minimum-unit" className="form-label">
                                    TAT Minimum Unit
                                  </Label>

                                  <select
                                    id="tat-minimum-unit"
                                    name="tat-minimum-unit"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.minimum?.unit}
                                  >
                                    <option value="0">Choose</option>
                                    <option value="2">Months</option>
                                    <option value="3">Days</option>
                                    <option value="4">Hours</option>
                                    <option value="5">Minute</option>
                                    <option value="6">Second</option>
                                    <option value="7">Clear Day</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-minimum-value" className="form-label">
                                    TAT Minimum Value
                                  </Label>

                                  <Input
                                    id="tat-minimum-value"
                                    name="tat-minimum-value"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.minimum?.value}
                                    autoComplete="off"
                                    min="0"
                                    disabled={state?.tat?.minimum?.unit === 0}
                                  />
                                </div>
                              </Col>

                              {/* ------------------------ TAT Maximum ------------------------ */}
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-maximum-unit" className="form-label">
                                    TAT Maximum Unit
                                  </Label>

                                  <select
                                    id="tat-maximum-unit"
                                    name="tat-maximum-unit"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.maximum?.unit}
                                  >
                                    <option value="0">Choose</option>
                                    <option value="2">Months</option>
                                    <option value="3">Days</option>
                                    <option value="4">Hours</option>
                                    <option value="5">Minute</option>
                                    <option value="6">Second</option>
                                    <option value="7">Clear Day</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-maximum-value" className="form-label">
                                    TAT Maximum Value
                                  </Label>

                                  <Input
                                    id="tat-maximum-value"
                                    name="tat-maximum-value"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.maximum?.value}
                                    autoComplete="off"
                                    min="0"
                                    disabled={state?.tat?.maximum?.unit === 0}
                                  />
                                </div>
                              </Col>

                              {/* ------------------------ TAT Cancel ------------------------ */}
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-cancel-unit" className="form-label">
                                    TAT Cancel Unit
                                  </Label>

                                  <select
                                    id="tat-cancel-unit"
                                    name="tat-cancel-unit"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.cancel?.unit}
                                  >
                                    <option value="0">Choose</option>
                                    <option value="2">Months</option>
                                    <option value="3">Days</option>
                                    <option value="4">Hours</option>
                                    <option value="5">Minute</option>
                                    <option value="6">Second</option>
                                    <option value="7">Clear Day</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-cancel-value" className="form-label">
                                    TAT Cancel Value
                                  </Label>

                                  <Input
                                    id="tat-cancel-value"
                                    name="tat-cancel-value"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.cancel?.value}
                                    autoComplete="off"
                                    min="0"
                                    disabled={state?.tat?.cancel?.unit === 0}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Amenities ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.amenities?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`amenities`)}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Amenities</div>
                              {accordions?.amenities?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.amenities?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              {allAmenities?.length > 0 ? (
                                allAmenities?.map((amenity, index) => (
                                  <Col md={4} key={index}>
                                    <div className="mb-3">
                                      <input
                                        type="checkbox"
                                        className="form-check-input mx-1"
                                        id={index}
                                        style={{ cursor: 'pointer' }}
                                        checked={state?.amenities?.filter(data => data?.value === amenity?.value)?.length > 0}
                                        onChange={() => amenitiesHandler(amenity)}
                                      />
                                      <Label htmlFor={index} style={{ cursor: 'pointer', fontWeight: 'normal' }}>
                                        {amenity?.label}
                                      </Label>
                                    </div>
                                  </Col>
                                ))
                              ) : (
                                <span>Amenities not available!</span>
                              )}

                              {/* <Col md={4}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="amenities"
                                    className="form-label"
                                  >
                                    Amenities
                                  </Label>
                                  <Select
                                    id="amenities"
                                    name="amenities"
                                    options={allAmenities}
                                    value={state?.amenities}
                                    isMulti={true}
                                    onChange={(data) =>
                                      inputSelectHandler(`amenities`, data)
                                    }
                                  />
                                </div>
                              </Col> */}
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Partner Bank Details ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.partnerBank?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`partnerBank`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Parnter Bank Details</div>
                              {accordions?.partnerBank?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.partnerBank?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="bank-name" className="form-label">
                                    Bank Name
                                  </Label>

                                  <Input
                                    id="bank-name"
                                    name="bank-name"
                                    type="text"
                                    className="form-control"
                                    value={state?.bankDetails?.name}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="name-on-bank" className="form-label">
                                    Name on Bank
                                  </Label>

                                  <Input
                                    id="name-on-bank"
                                    name="name-on-bank"
                                    type="text"
                                    className="form-control"
                                    value={state?.bankDetails?.nameOnBank}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="bank-account-number" className="form-label">
                                    Bank Account number
                                  </Label>

                                  <Input
                                    id="bank-account-number"
                                    name="bank-account-number"
                                    type="text"
                                    className="form-control"
                                    value={state?.bankDetails?.accountNumber}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="branch" className="form-label">
                                    Branch
                                  </Label>

                                  <Input
                                    id="branch"
                                    name="branch"
                                    type="text"
                                    className="form-control"
                                    value={state?.bankDetails?.branch}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="ifsc-code" className="form-label">
                                    IFSC Code
                                  </Label>

                                  <Input
                                    id="ifsc-code"
                                    name="ifsc-code"
                                    type="text"
                                    className="form-control"
                                    value={state?.bankDetails?.ifscCode}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="authorised-signatory-name" className="form-label">
                                    Authorised Signatory Name
                                  </Label>

                                  <Input
                                    id="authorised-signatory-name"
                                    name="authorised-signatory-name"
                                    type="text"
                                    className="form-control"
                                    value={state?.bankDetails?.signatoryName}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="authorised-signatory-number" className="form-label">
                                    Authorised Signatory Number
                                  </Label>

                                  <Input
                                    id="authorised-signatory-number"
                                    name="authorised-signatory-number"
                                    type="text"
                                    className="form-control"
                                    value={state?.bankDetails?.signatoryNumber}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="authorised-signatory-email" className="form-label">
                                    Authorised Signatory Email
                                  </Label>

                                  <Input
                                    id="authorised-signatory-email"
                                    name="authorised-signatory-email"
                                    type="text"
                                    className="form-control"
                                    value={state?.bankDetails?.signatoryEmail}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow &&
                                    state?.bankDetails?.signatoryEmail !== `` &&
                                    !isValidEmail(state?.bankDetails?.signatoryEmail) && <Error text="Please enter valid email" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="spoc-signatory-name" className="form-label">
                                    SPOC Signatory Name
                                  </Label>

                                  <Input
                                    id="spoc-signatory-name"
                                    name="spoc-signatory-name"
                                    type="text"
                                    className="form-control"
                                    value={state?.bankDetails?.spocSignatoryName}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="spoc-signatory-number" className="form-label">
                                    SPOC Signatory Number
                                  </Label>

                                  <Input
                                    id="spoc-signatory-number"
                                    name="spoc-signatory-number"
                                    type="text"
                                    className="form-control"
                                    value={state?.bankDetails?.spocSignatoryNumber}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="spoc-signatory-email" className="form-label">
                                    SPOC Signatory Email
                                  </Label>

                                  <Input
                                    id="spoc-signatory-email"
                                    name="spoc-signatory-email"
                                    type="text"
                                    className="form-control"
                                    value={state?.bankDetails?.spocSignatoryEmail}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow &&
                                    state?.bankDetails?.spocSignatoryEmail !== `` &&
                                    !isValidEmail(state?.bankDetails?.spocSignatoryEmail) && <Error text="Please enter valid email" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="thriwe-commission" className="form-label">
                                    Thriwe Commission
                                  </Label>

                                  <Input
                                    id="thriwe-commission"
                                    name="thriwe-commission"
                                    type="text"
                                    className="form-control"
                                    value={state?.bankDetails?.thriweCommission}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="payout-frequency" className="form-label">
                                    Payout frequency
                                  </Label>

                                  <select
                                    id="payout-frequency"
                                    name="payout-frequency"
                                    className="form-select"
                                    value={state?.bankDetails?.payoutFrequency}
                                    onChange={inputChangeHandler}
                                  >
                                    <option value="">Choose...</option>
                                    <option value="Once a Week">Once a Week</option>
                                    <option value="Twice a Week">Twice a Week</option>
                                    <option value="Once a month">Once a month</option>
                                    <option value="Twice a month">Twice a month</option>
                                  </select>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Custom Dynamic Field ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.customDynamicField?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`customDynamicField`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Custom Dynamic Field</div>
                              {accordions?.customDynamicField?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.customDynamicField?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <div className="mb-3">
                              <button
                                type="button"
                                className={`btn btn-success`}
                                onClick={() => {
                                  setSelectedDynamicField(null);
                                  setIsShowDynamicForm(true);
                                }}
                              >
                                Add New Field <GoPlus />
                              </button>
                            </div>
                            <Card>
                              <CardBody>
                                <DataTable
                                  columns={columns ?? []}
                                  data={data ?? []}
                                  customStyles={{
                                    headRow: {
                                      style: {
                                        borderBottom: '1px solid #ddd',
                                        backgroundColor: '#f9fafb',
                                        fontWeight: '600',
                                      },
                                    },
                                    cells: {
                                      style: {
                                        border: '0.5px solid #ddd',
                                        borderBottom: '0px solid #ddd',
                                      },
                                    },
                                  }}
                                />
                              </CardBody>
                            </Card>
                          </div>
                        </Collapse>
                      </AccordionItem>
                    </Accordion>
                  </CardBody>
                </Col>
              </Row>
            </Form>
          </CardBody>

          <CardFooter>
            <Row>
              <Col md={12}>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler}>
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};

export default AddPartnerForm;
