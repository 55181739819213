import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import axios from 'axios';

import { Button, Modal, ModalBody, Col, Label, Input, Row } from 'reactstrap';
import { ASSIGN_COUPON, GET_COUPON_FACILITY, GET_COUPON_PARTNER, GET_COUPON_SERVICE, POST_STATIC_COUPON } from '../../../../../../../store/actions';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Constant } from '../../../../../../Helpers/constant';
import { baseUrl, customHeaders, uaeProjectsBaseURL } from '../../../../../../Config';
import { isANumber, isValidAplha } from '../../../../../../Helpers/Helpers';
import moment from 'moment';
import { Error } from '../../../../../../Common';

const invoicesStatus = [
  { label: 'Paid', value: 'Paid' },
  { label: 'Pending', value: 'Pending' },
];

const CouponUploadModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Partners
  const { couponPartners, couponPartnersLoading } = useSelector(state => ({
    couponPartners: state?.GetCouponPartner?.data,
    couponPartnersLoading: state?.GetCouponPartner?.loading,
  }));

  // Facilities
  const { couponFacility } = useSelector(state => ({
    couponFacility: state?.GetCouponFacility?.data,
  }));

  // Services
  const { couponServices } = useSelector(state => ({
    couponServices: state?.GetCouponServices?.data,
  }));

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    expiryDate: '',
    startDate: '',
    isGeneral: false,
    projects: [],
    partner: null,
    facility: null,
    services: [],
    priority: '',
    code: '',
    maxUsage: '',
    redemptionLink: '',

    lotPrice: '',
    perCouponPrice: '',
    vendor: {
      name: '',
    },
    invoice: {
      number: '',
      date: '',
      status: '',
    },
  };

  const [state, setState] = useState(initialObj);
  const [projects, setProjects] = useState([]);
  const [partners, setPartners] = useState([]);
  const [locations, setLocations] = useState([]);
  const [services, setServices] = useState([]);

  const [isValidationShow, setIsValidationShow] = useState(false);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const [isFacilityDisabled, setIsFacilityDisabled] = useState(true);
  const [isServiceDisabled, setIsServiceDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);

  useEffect(() => {
    setProjects(
      data?.projects?.map(project => {
        return { value: project.id, label: project.name, data: project };
      }),
    );
  }, []);

  useEffect(() => {
    if (couponPartners?.length > 0) {
      setPartners(
        couponPartners?.map(partner => {
          return {
            value: partner?.objectId,
            label: partner?.name,
            data: partner,
          };
        }),
      );
    } else {
      setPartners([]);
    }

    if (couponFacility?.length > 0) {
      setLocations(
        couponFacility?.map(facility => {
          return { value: facility?.objectId, label: facility?.name };
        }),
      );
      setIsFacilityDisabled(false);
    } else {
      setIsFacilityDisabled(true);
      setLocations([]);
    }

    if (couponServices?.length > 0) {
      setServices(
        couponServices?.map(service => {
          return { value: service?.objectId, label: service?.name };
        }),
      );
      setIsServiceDisabled(false);
    } else {
      setIsServiceDisabled(true);
      setServices([]);
    }
  }, [couponPartners, couponFacility, couponServices]);

  useEffect(() => {
    if (couponPartners === null) {
      dispatch({
        type: GET_COUPON_PARTNER,
      });
    }
  }, [dispatch]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    let lotPrice = '';
    let maxUsage = '';
    let priority = '';
    let couponPrice = '';

    switch (name) {
      case 'general':
        updatedState = {
          ...state,
          isGeneral: e.target.checked,
          projects: e.target.checked ? [] : state.projects,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'code':
        updatedState = {
          ...state,
          code: value,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'maxUsage':
        if (value?.length === 0) {
          maxUsage = ``;
        } else {
          maxUsage = isANumber(value) ? value : state.maxUsage;
        }
        updatedState = {
          ...state,
          maxUsage: maxUsage,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'priority':
        if (value?.length === 0) {
          priority = ``;
        } else {
          priority = isANumber(value) ? value : state.priority;
        }
        updatedState = {
          ...state,
          priority: priority,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'redemptionLink':
        updatedState = {
          ...state,
          redemptionLink: value,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'lotPrice':
        if (value?.length === 0) {
          lotPrice = ``;
        } else {
          lotPrice = isANumber(value) ? value : state.lotPrice;
        }
        updatedState = {
          ...state,
          lotPrice: lotPrice,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'perCouponPrice':
        if (value?.length === 0) {
          couponPrice = ``;
        } else {
          couponPrice = isANumber(value) ? value : state.perCouponPrice;
        }
        updatedState = {
          ...state,
          perCouponPrice: couponPrice,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'vendorName':
        updatedState = {
          ...state,
          vendor: {
            ...state?.vendor,
            name: isValidAplha(value) ? value : state?.vendor?.name,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'number':
        updatedState = {
          ...state,
          invoice: {
            ...state?.invoice,
            number: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    if (label === `invoice-status`) {
      updatedState = {
        ...state,
        invoice: {
          ...state?.invoice,
          status: data,
        },
      };
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT PROJECT HANDLER ---------------------------- */
  const inputProjectHandler = data => {
    const updatedState = {
      ...state,
      projects: data,
      isGeneral: false,
    };
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  const inputPartnersHandler = data => {
    console.log('partnersHandler11', data);
    const updatedState = {
      ...state,
      partner: data,
      facility: null,
      services: [],
    };
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);

    dispatch({
      type: GET_COUPON_FACILITY,
      payload: {
        urlParam: {
          partnerId: data?.value,
        },
      },
    });

    dispatch({
      type: GET_COUPON_SERVICE,
      payload: {
        urlParam: {
          partnerId: data?.value,
        },
      },
    });
  };

   /* ---------------------------- TYPED USER HANDLER ---------------------------- */
   async function loadOptions(search, loadedOptions) {
    let URL=`${baseUrl}/admin/partners`;
    if(search){
      URL = `${URL}?name=${search}`;
      
    }else{
      URL = `${URL}?pageIndex=0&pageSize=10`;

    }
    const response = await fetch(`${URL}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const responseData = await response.json();

    return {
      options: responseData?.results?.map((data, index) => {
        return {
          value: data.objectId,
          label: `${data?.name}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  const inputFacilityHandler = data => {
    const updatedState = {
      ...state,
      facility: data,
      services: [],
    };
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);

    dispatch({
      type: GET_COUPON_SERVICE,
      payload: {
        urlParam: {
          partnerId: state?.partner?.value,
          facilityId: data?.value,
        },
      },
    });
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        expiryDate: new Date(state.expiryDate).toJSON(),
        isGeneral: state.isGeneral,
        projects: state.projects.map(project => {
          return {
            id: project?.value,
            name: project?.label,
            code: project?.data.code,
          };
        }),
        services: state.services.map(service => {
          return {
            id: service.value,
            name: service.label,
          };
        }),
        priority: parseInt(state?.priority),
        maxUsage: parseInt(state?.maxUsage),
        code: state?.code,
        redemptionLink: state?.redemptionLink,

        lotPrice: parseFloat(state?.lotPrice),
        perCouponPrice: parseFloat(state?.perCouponPrice),
        vendor: {
          ...state?.vendor,
        },
        invoice: {
          number: state?.invoice?.number,
          date: state?.invoice?.date,
          status: state?.invoice?.status?.value,
        },
      };

      dispatch({
        type: POST_STATIC_COUPON,
        payload: {
          data: payload,
        },
      });

      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'expiryDate':
        updatedState = {
          ...state,
          expiryDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'startDate':
        updatedState = {
          ...state,
          startDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'date':
        updatedState = {
          ...state,
          invoice: {
            ...state?.invoice,
            date: new Date(date).toISOString(),
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (
      data?.code !== `` &&
      data?.expiryDate !== `` &&
      data?.startDate !== `` &&
      data?.services.length !== 0 &&
      data?.maxUsage > 0 &&
      data?.priority > 0 &&
      data?.lotPrice !== `` &&
      data?.perCouponPrice !== `` &&
      data?.vendor?.name !== `` &&
      data?.invoice?.number !== `` &&
      data?.invoice?.status !== `` &&
      data?.invoice?.date !== ``
    ) {
      if ((data?.projects?.length === 0 && data?.isGeneral === true) || (data?.projects?.length > 0 && data?.isGeneral === false)) {
        setIsSubmitBtnDisabled(false);
      } else {
        setIsSubmitBtnDisabled(true);
      }
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.code !== `` &&
      data?.expiryDate !== `` &&
      data?.startDate !== `` &&
      data?.lotPrice !== `` &&
      data?.maxUsage !== `` &&
      data?.priority !== `` &&
      data?.perCouponPrice !== `` &&
      data?.vendor?.name !== `` &&
      data?.invoice?.number !== `` &&
      data?.invoice?.status !== `` &&
      data?.invoice?.date !== `` &&
      data?.services?.length > 0 &&
      moment(data?.startDate) < moment(data?.expiryDate) &&
      moment() <= moment(data?.startDate) &&
      moment() <= moment(data?.expiryDate) &&
      moment() <= moment(data?.invoice?.date)
    ) {
      if ((data?.projects?.length === 0 && data?.isGeneral === true) || (data?.projects?.length > 0 && data?.isGeneral === false)) {
        setIsValidationShow(false);
        return true;
      } else {
        setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
        return false;
      }
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- TYPED SERVICE HANDLER ---------------------------- */
  async function loadServices(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/services`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        partnerId: state?.partner !== null ? state?.partner?.value : null,
        facilityId: state?.facility !== null ? state?.facility?.value : null,
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON SELECT SERVICE ---------------------------- */
  const onSelectService = event => {
    const updatedState = {
      ...state,
      services: event,
    };
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Upload Coupon
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>Get state</button> */}
        <form>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="partner" className="form-label">
                    Partner <span className="lbl_mandatory">*</span>
                  </Label>
                  {/* <Select
                    id="partners"
                    name="partners"
                    isMulti={false}
                    options={partners}
                    value={state?.partner}
                    onChange={inputPartnersHandler}
                    style={{
                      border: '4px solid #ced4da',
                      bordeRadius: '0.25rem',
                    }}
                  /> */}
                   <AsyncPaginate
                debounceTimeout={500}
                value={state?.partner}
                loadOptions={loadOptions}
                onChange={data => inputPartnersHandler(data)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder=""
                isDisabled={state?.benefitGroupItemType === ``}
              />
                </div>
                {isValidationShow && state?.partner === null && <Error text="Please select partner" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="location" className="form-label">
                    Facility
                  </Label>
                  <Select
                    value={state?.facility}
                    id="facility"
                    name="facility"
                    isMulti={false}
                    isDisabled={isFacilityDisabled}
                    options={locations}
                    onChange={inputFacilityHandler}
                    style={{
                      border: '4px solid #ced4da',
                      bordeRadius: '0.25rem',
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div className="d-flex justify-content-between">
                  <Label htmlFor="choices-multiple-default" className="form-label">
                    Services
                    <span className="lbl_mandatory">*</span>
                  </Label>
                </div>

                <AsyncPaginate
                  debounceTimeout={500}
                  value={state?.services}
                  loadOptions={loadServices}
                  onChange={e => onSelectService(e)}
                  isMulti={true}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Select..."
                  isDisabled={isServiceDisabled}
                />
                {isValidationShow && state?.services?.length === 0 && <Error text="Please select service" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div className="d-flex justify-content-between">
                  <Label htmlFor="choices-multiple-default" className="form-label">
                    Select Program <span className="lbl_mandatory">*</span>
                  </Label>
                  <div>
                    <Label htmlFor="general" className="form-label mx-2">
                      Global Pool
                    </Label>
                    <div className="form-switch d-inline-block ">
                      <Input
                        className="form-check-input code-switcher"
                        type="checkbox"
                        id="general"
                        name="general"
                        checked={state?.isGeneral}
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </div>
                </div>
                <Select
                  value={state?.projects}
                  id="programs"
                  name="programs"
                  isMulti={true}
                  onChange={inputProjectHandler}
                  options={projects}
                  style={{
                    border: '4px solid #ced4da',
                    bordeRadius: '0.25rem',
                  }}
                  isDisabled={state?.isGeneral}
                />
                {isValidationShow && !state?.isGeneral && state?.projects?.length === 0 && <Error text="Please select programs" />}
              </div>
            </Col>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="code" className="form-label">
                    Coupon Code <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input type="text" name="code" id="code" value={state?.code} onChange={inputChangeHandler} className="form-control form-control" />
                  {isValidationShow && state?.code?.trim() === `` && <Error text="Please enter coupon code" />}
                </div>
              </Col>
            </div>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="maxUsage" className="form-label">
                    Max Usage <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="maxUsage"
                    id="maxUsage"
                    value={state?.maxUsage}
                    onChange={inputChangeHandler}
                    className="form-control form-control"
                  />
                  {isValidationShow && state?.maxUsage?.trim() === `` && <Error text="Please enter max usage" />}
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="priority" className="form-label">
                    Priority <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="priority"
                    id="priority"
                    value={state?.priority}
                    onChange={inputChangeHandler}
                    className="form-control form-control"
                  />
                </div>
                {isValidationShow && state?.priority?.trim() === `` && <Error text="Please enter priority" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label className="form-label">
                  Lot Price
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Input id="lotPrice" name="lotPrice" type="text" className="form-control" onChange={inputChangeHandler} value={state?.lotPrice} />
                {isValidationShow && state?.lotPrice?.trim() === `` && <Error text="Please enter Lot Price" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label className="form-label">
                  Invoice Number
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Input id="number" name="number" type="text" className="form-control" onChange={inputChangeHandler} value={state?.invoice?.number} />
                {isValidationShow && state?.invoice?.number?.trim() === `` && <Error text="Please enter invoice number" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label className="form-label">
                  Per Coupon Price
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Input
                  id="perCouponPrice"
                  name="perCouponPrice"
                  type="number"
                  onWheel={e => e.target.blur()}
                  className="form-control"
                  onChange={inputChangeHandler}
                  value={state?.perCouponPrice}
                />
                {isValidationShow && state?.perCouponPrice?.trim() === `` && <Error text="Please enter coupon price" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="vendorName" className="form-label">
                    Vendor Name <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="vendorName"
                    id="vendorName"
                    value={state?.vendor?.name}
                    onChange={inputChangeHandler}
                    className="form-control form-control"
                  />
                  {isValidationShow && state?.vendor?.name?.trim() === `` && <Error text="Please enter vendor name" />}
                </div>
              </div>
            </Col>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="status" className="form-label">
                    Invoice Status <span className="lbl_mandatory">*</span>
                  </Label>
                  <Select
                    id="invoice-status"
                    name="invoice-status"
                    isMulti={false}
                    options={invoicesStatus}
                    value={state?.invoice?.status}
                    onChange={data => inputSelectHandler(`invoice-status`, data)}
                    style={{
                      border: '4px solid #ced4da',
                      bordeRadius: '0.25rem',
                    }}
                  />
                  {isValidationShow && state?.invoice?.status === `` && <Error text="Please select invoice status" />}
                </div>
              </Col>
            </div>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="date" className="form-label">
                    Date <span className="lbl_mandatory">* </span>
                    <span className="icon_calendar">
                      <i className="las la-calendar"></i>
                    </span>
                  </Label>
                  <div className="area_expandable">
                    <Flatpickr
                      className="form-control"
                      name="date"
                      id="date"
                      value={state?.invoice?.date}
                      onChange={date => datePickHandler(`date`, date)}
                      options={{
                        // minDate: 'today',
                        defaultMinute: '59',
                        defaultHour: '23',
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }}
                    />
                  </div>
                </div>
                {isValidationShow && state?.invoice?.date === `` && <Error text="Please select date" />}
                {isValidationShow && state?.invoice?.date !== `` && moment() > moment(state?.invoice?.date) && <Error text="Invalid date" />}
              </div>
            </Col>

            {/* ---------------------------------------------------------------------- */}
            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="start-date" className="form-label">
                    Start Date <span className="lbl_mandatory">* </span>
                    <span className="icon_calendar">
                      <i className="las la-calendar"></i>
                    </span>
                  </Label>
                  <div className="area_expandable">
                    <Flatpickr
                      className="form-control"
                      name="start-date"
                      id="start-date"
                      value={state?.startDate}
                      onChange={date => datePickHandler(`startDate`, date)}
                      options={{
                        // minDate: 'today',
                        defaultMinute: '00',
                        defaultHour: '00',
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }}
                    />
                  </div>
                </div>
                {isValidationShow && state?.startDate === `` && <Error text="Please select start date" />}
                {isValidationShow && state?.startDate !== `` && moment() > moment(state?.startDate) && <Error text="Invalid start date" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="expiry-date" className="form-label">
                    Expiry Date <span className="lbl_mandatory">* </span>
                    <span className="icon_calendar">
                      <i className="las la-calendar"></i>
                    </span>
                  </Label>
                  <div className="area_expandable">
                    <Flatpickr
                      className="form-control"
                      name="expiry-date"
                      id="expiry-date"
                      value={state?.expiryDate}
                      onChange={date => datePickHandler(`expiryDate`, date)}
                      options={{
                        // minDate: 'today',
                        defaultMinute: '59',
                        defaultHour: '23',
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }}
                    />
                  </div>
                </div>
                {isValidationShow && state?.expiryDate === `` && <Error text="Please select expiry date" />}
                {isValidationShow && state?.expiryDate !== `` && moment(state?.startDate) > moment(state?.expiryDate) && (
                  <Error text="Invalid expiry date" />
                )}
              </div>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(CouponUploadModal);
