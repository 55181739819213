import { all, call, put, takeEvery } from 'redux-saga/effects';
import { adminAPIURL, customHeaders } from '../../../../Containers/Config/index.js';
import apiCall from '../../apiCall.js';
import { GET_CARD_SUMMARY, GET_CARD_SUMMARY_SUCCESS, GET_CARD_SUMMARY_ERROR } from '../../../actions.js';

function* fetchCardSummary(card, startDate, endDate, payload) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    delete payload?.cards;

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v2/get-card-summary/${card?.type}`,
      method: 'GET',
      headers: filteredHeaders,
      params: { card_name: card.name, ...(startDate && endDate && { start_date: startDate, end_date: endDate }), ...payload },
    });

    if (response.status === 200) {
      yield put({
        type: GET_CARD_SUMMARY_SUCCESS,
        payload: response?.data,
        meta: { cardName: card.name },
      });
    } else {
      yield put({
        type: GET_CARD_SUMMARY_ERROR,
        payload: response.data.message,
        meta: { cardName: card.name },
      });
    }
  } catch (error) {
    yield put({
      type: GET_CARD_SUMMARY_ERROR,
      payload: error.message,
      meta: { cardName: card.name },
    });
  }
}

function* getCardSummarySaga({ payload }) {
  const { cards, start_date, end_date } = payload;

  // Create an array of API calls
  const apiCalls = cards.map(card => call(fetchCardSummary, card, start_date, end_date, payload));

  // Execute all API calls in parallel
  yield all(apiCalls);
}

export function* watchGetCardSummary() {
  yield takeEvery(GET_CARD_SUMMARY, getCardSummarySaga);
}

function* fetchAllCardsSaga() {
  yield all([watchGetCardSummary()]);
}

export default fetchAllCardsSaga;
