export default function Copyright() {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="text-center">
          <p className="mb-0 text-muted">Copyright &copy; {new Date().getFullYear()} Powered by Thriwe</p>
        </div>
      </div>
    </div>
  );
}
