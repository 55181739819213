import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';

import { CANCEL_BOOKING_BY_ADMIN, CANCEL_BOOKING_BY_ADMIN_EMPTY, GET_REASON_BY_ACTIONS } from '../../../../../../../store/actions';
import { Error } from '../../../../../../Common';

const CancelBooking = ({ onClose, currentSelectedBooking }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [selectedRemark, setSelectedRemark] = useState(null);
  const [isShowError, setIsShowError] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { cancelBooking, cancelBookingLoading, remarksList, remarksListLoading } = useSelector(state => ({
    cancelBooking: state?.CancelBookingByAdmin?.data,
    cancelBookingLoading: state?.CancelBookingByAdmin?.loading,

    remarksList: state?.GetReasonByActions?.data,
    remarksListLoading: state?.GetReasonByActions?.loading,
  }));

  useEffect(() => {
    if (cancelBooking !== null) {
      dispatch({
        type: CANCEL_BOOKING_BY_ADMIN_EMPTY,
      });
      onClose();
    }

    return () => {
      dispatch({
        type: CANCEL_BOOKING_BY_ADMIN_EMPTY,
      });
    };
  }, [cancelBooking]);

  useEffect(() => {
    dispatch({
      type: GET_REASON_BY_ACTIONS,
      payload: {
        urlParams: {
          booking_type: 'airport_transfers',
          booking_status: currentSelectedBooking?.bookingStatus,
          action: 'cancel_booking',
          is_active: true,
        },
      },
    });
  }, [currentSelectedBooking]);

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    if (label === `reason_id`) {
      setSelectedRemark(data);
    } else {
      setIsShowError(false);
    }
  };

  /* ---------------------------- DECLINE BOOKING HANDLER ---------------------------- */
  const cancelBookingHandler = () => {
    if (selectedRemark === null) {
      setIsShowError(true);
    } else {
      dispatch({
        type: CANCEL_BOOKING_BY_ADMIN,
        payload: {
          body: {
            booking_id: `${currentSelectedBooking?.id}`,
            reason_id: `${selectedRemark?.value}`,
          },
        },
      });
    }
  };

  return (
    <Modal isOpen={true} centered>
      <ModalHeader toggle={onClose}>
        <h4>Cancel Booking</h4>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={12} className="mb-3">
            <Select
              id="decline-remarks"
              name="decline-remarks"
              placeholder="Select reason for cancel the booking"
              value={selectedRemark}
              options={remarksList?.reasons?.map((remark, index) => ({
                label: `${remark?.reason}`,
                value: `${remark?.id}`,
              }))}
              onChange={data => inputSelectHandler(`reason_id`, data)}
            />
            {isShowError && selectedRemark === null && <Error text="Kindly select reason for declining" ref={null} />}
          </Col>

          <Col md={12} className="mt-3 mb-4 text-center">
            {!cancelBookingLoading && (
              <Button color="primary" style={{ fontSize: '14px', width: '160px' }} onClick={cancelBookingHandler}>
                Cancel Booking
              </Button>
            )}

            {cancelBookingLoading && (
              <Button color="primary" style={{ fontSize: '14px', width: '160px' }}>
                <Spinner className="btn-replacer-spinner" />
              </Button>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default CancelBooking;
