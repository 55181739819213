import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import fetch from './../../fetch';
import { umsUrl } from '../../../../Containers/Config/index.js';
import { LOGIN_V2, LOGIN_V2_SUCCESS, LOGIN_V2_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';

function* userLoginV2({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${umsUrl}/admin/v2/login`,
      method: 'POST',
      data: payload?.data,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      yield put({ type: LOGIN_V2_SUCCESS, payload: response });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: LOGIN_V2_ERROR, payload: response?.data?.message });
    }
  } catch (error) {
    yield put({ type: LOGIN_V2_ERROR, payload: error });
  }
}

export function* watchUserLoginV2() {
  yield takeEvery(LOGIN_V2, userLoginV2);
}

function* userLoginV2Saga() {
  yield all([fork(watchUserLoginV2)]);
}

export default userLoginV2Saga;
