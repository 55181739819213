import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import * as moment from 'moment';

import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../../Containers/Common';
import { GET_STATIC_COUPON } from '../../../../../../../store/actions';

const StaticCouponFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    serviceId: '',
    serviceName: '',
    couponCode: '',
    // status: '',
    createdAt: '',
    expiryDate: '',
  };
  const [filter, setFilter] = useState(initialState);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'serviceId':
        updatedState = {
          ...filter,
          serviceId: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      case 'serviceName':
        updatedState = {
          ...filter,
          serviceName: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      case 'couponCode':
        updatedState = {
          ...filter,
          couponCode: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;
      // case 'status':
      //   updatedState = {
      //     ...filter,
      //     status: value,
      //   };
      //   setFilter(updatedState);
      //   validationCheck(updatedState);
      //   break;
      case 'createdAt':
        updatedState = {
          ...filter,
          createdAt: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;
      case 'expiryDate':
        updatedState = {
          ...filter,
          expiryDate: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };
  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'expiryDate':
        updatedState = {
          ...filter,
          expiryDate: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      case 'createdAt':
        updatedState = {
          ...filter,
          createdAt: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;
      default:
      // do nothing
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    let filterValue = { ...filter };
    console.log(filterValue);
    props.filterHandler({ ...filterValue });
    props.onCloseClick();
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    setIsResetDisabled(true);
    dispatch({
      type: GET_STATIC_COUPON,
      payload: {
        urlParam: {
          pageIndex: 0,
        },
      },
    });
    console.log('here reset');
    props.setCurrentlyAppliedFilters(null);
    props.onCloseClick();
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (filter?.name !== `` || filter?.email !== `` || filter?.mobileNumber !== ``) {
      setIsResetDisabled(false);
    } else {
      setIsResetDisabled(true);
    }
  };

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Customer Filters
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            {/* <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="firstName" className="form-label">
                  First Name
                </Label>
                <Input id="firstName" name="firstName" type="text" className="form-control" value={filter?.firstName} onChange={inputChangeHandler} />
              </div>
            </Col> */}

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="serviceId" className="form-label">
                  Service ID
                </Label>
                <Input id="serviceId" name="serviceId" type="text" className="form-control" value={filter?.serviceId} onChange={inputChangeHandler} />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="serviceName" className="form-label">
                  Service Name
                </Label>
                <Input
                  id="serviceName"
                  name="serviceName"
                  type="text"
                  className="form-control"
                  value={filter?.serviceName}
                  onChange={inputChangeHandler}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="couponCode" className="form-label">
                  Coupon Code
                </Label>
                <Input
                  id="couponCode"
                  name="couponCode"
                  type="text"
                  className="form-control"
                  value={filter?.couponCode}
                  onChange={inputChangeHandler}
                />
              </div>
            </Col>
            {/* <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="couponStatus" className="form-label">
                  Status
                </Label>
                <Input id="couponStatus" name="status" type="text" className="form-control" value={filter?.status} onChange={inputChangeHandler} />
              </div>
            </Col> */}

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="expiryDate" className="form-label">
                  Expiry Date
                </Label>
                <Flatpickr
                  className="form-control"
                  onChange={data => datePickHandler('expiryDate', data)}
                  value={[filter?.expiryDate?.from, filter?.expiryDate?.to]}
                  options={{
                    mode: 'range',
                    dateFormat: 'Y-m-d',
                    // minDate: 'today',
                  }}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="createdAt" className="form-label">
                  Created Date
                </Label>
                <Flatpickr
                  className="form-control"
                  onChange={data => datePickHandler('createdAt', data)}
                  value={[filter?.createdAt?.from, filter?.createdAt?.to]}
                  options={{
                    mode: 'range',
                    dateFormat: 'Y-m-d',
                    // minDate: 'today',
                  }}
                />
              </div>
            </Col>
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button className="btn btn-light w-100" onClick={resetFilterHandler}>
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default StaticCouponFilter;
