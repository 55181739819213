import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import Countdown from 'react-countdown';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, CardFooter, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { formattedWithComma, hasPermission, isDateIsGreaterThanLast7Days } from '../../../../../../Helpers/Helpers';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Bookings');
  const [currentFilterBookingStatus, setCurrentFilterBookingStatus] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [columns, setColumns] = useState([]);
  const [clearRow, setClearRow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Update Booking
  const { updateBookings } = useSelector(state => ({
    updateBookings: state?.UpdateBooking?.data,
  }));

  // Resend Mail
  const { resendMail } = useSelector(state => ({
    resendMail: state?.ResendMail?.data,
  }));

  useEffect(() => {
    getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));
    if (props?.data?.length > 0) {
      setBookingData(
        props?.data?.map(booking => {
          return { ...booking };
        }),
      );
    } else {
      setData([]);
    }
    setCurrentFilterBookingStatus(props?.appliedFilters?.statusRange || []);
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      const fetchColmuns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: 'Booking Id',
          width: '140px',
          cell: row => (
            <span
              className="cursor-pointer text-decoration-underline"
              style={{ color: '#3577f1' }}
              onClick={() => actionBtnHandler(`VIEW`, row?.data)}
            >
              {row?.data?.bookingId}
            </span>
          ),
        },
        {
          name: 'Countdown',
          width: '120px',
          wrap: true,
          selector: row => countdownFunc(row.index),
        },
        {
          name: 'Vendor Name',
          width: '150px',
          wrap: true,
          selector: row => row?.vendorName,
        },
        {
          name: 'Name',
          width: '150px',
          wrap: true,
          selector: row => row?.userName,
        },
        {
          name: 'Mobile No',
          width: '130px',
          wrap: true,
          selector: row => row?.userMobile,
        },
        {
          name: 'Email',
          wrap: true,
          selector: row => row?.userEmail,

          width: '220px',
        },
        {
          name: 'Terminal Name',
          width: '150px',
          wrap: true,
          selector: row => row?.terminalName,
        },

        {
          name: 'Terminal Number',
          width: '150px',
          wrap: true,
          selector: row => row?.terminalNumber,
        },
        {
          name: 'Pickup Date',
          width: '150px',
          wrap: true,
          selector: row => row?.pickupDate,
        },

        {
          name: 'Pickup Time',
          width: '150px',
          wrap: true,
          selector: row => row?.pickupTime,
        },
        {
          name: 'Chauffeur Name',
          width: '140px',
          wrap: true,
          selector: row => row?.chaufferName,
        },

        {
          name: 'Chauffeur Mobile',
          width: '140px',
          wrap: true,
          selector: row => row?.chaufferMobile,
        },

        {
          name: 'Chauffeur Car No.',
          width: '160px',
          wrap: true,
          selector: row => row?.chaufferCarNo,
        },

        {
          name: 'Chauffeur Car Type',
          width: '160px',
          wrap: true,
          selector: row => row?.chaufferCarType,
        },
        {
          name: 'User Type',
          width: '100px',
          wrap: true,
          selector: row => row?.bookingForSomeoneElse,
        },
        {
          name: 'Benefit Type',
          width: '150px',
          wrap: true,
          selector: row => row?.benefitType,
        },
        {
          name: 'Booking Type',
          width: '140px',
          wrap: true,
          selector: row => row?.bookingType,
        },
        {
          name: 'Partner',
          width: '160px',
          wrap: true,
          selector: row => row?.partnerName,
        },
        {
          name: 'Service',
          width: '350px',
          wrap: true,
          selector: row => row?.serviceName,
        },
        {
          name: 'Payment ID',
          width: '200px',
          wrap: true,
          selector: row => row?.paymentId,
        },
        {
          name: 'Membership ID',
          width: '160px',
          wrap: true,
          selector: row => row?.membershipId,
        },
        {
          name: 'Confirmed At',
          width: '170px',
          wrap: true,
          selector: row => row?.confirmedAt,
        },
        {
          name: 'Created At',
          width: '170px',
          wrap: true,
          selector: row => row?.createdAt,
        },
        {
          name: 'Updated At',
          width: '170px',
          wrap: true,
          selector: row => row?.updatedAt,
        },
        {
          name: 'Booking Status',
          width: '150px',
          cell: row => (
            <span
              className={`booking_status badge break-word bg-${
                Constant.BOOKING_STATUSES.filter(status => status?.name === row.bookingStatus)[0]?.bgColor
              }`}
            >
              {row.bookingStatus}
            </span>
          ),
        },
        {
          name: 'Actions',
          width: '250px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column m-4">
              {Constant.PROJECT_CODE === 'ENBD_V3' && (
                // DEFAULT
                <>
                  {Constant.BOOKING_STATUS.PENDING === row?.originalData?.bookingStatusCode && (
                    <button
                      className="btn btn-sm btn-primary mb-1"
                      onClick={() => {
                        actionBtnHandler(`ASSIGN_VENDOR_AIRPORT`, row?.originalData);
                      }}
                      style={{ width: '150px' }}
                      disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                    >
                      {/* {cell?.vendorId ? 'Change Vendor' : 'Assign Vendor'} */}
                      Assign Vendor
                    </button>
                  )}

                  {(Constant.BOOKING_STATUS.PROCESSED === row?.originalData?.bookingStatusCode ||
                    Constant.BOOKING_STATUS.REJECTED === row?.originalData?.bookingStatusCode) && (
                    <button
                      className="btn btn-sm btn-primary mb-1"
                      onClick={() => {
                        actionBtnHandler(`ASSIGN_VENDOR_AIRPORT`, row?.originalData);
                      }}
                      style={{ width: '150px' }}
                      disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                    >
                      {/* {cell?.vendorId ? 'Change Vendor' : 'Assign Vendor'} */}
                      Change Vendor
                    </button>
                  )}

                  {Constant.BOOKING_STATUS.CONFIRM === row?.originalData?.bookingStatusCode && row?.originalData?.chauffer?.name?.trim() === '' && (
                    <button
                      className="btn btn-sm btn-primary mb-1"
                      onClick={() => {
                        actionBtnHandler(`CHAUFFER_ASSIGN_AIRPORT`, row?.originalData);
                      }}
                      style={{ width: '150px' }}
                      disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                    >
                      Chauffeur Assign
                    </button>
                  )}

                  {Constant.BOOKING_STATUS.PROCESSED === row?.originalData?.bookingStatusCode && (
                    <button
                      className="btn btn-sm btn-primary mb-1"
                      onClick={() => {
                        actionBtnHandler(`CONFIRM_AIRPORT`, row?.originalData);
                      }}
                      style={{ width: '150px' }}
                      disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                    >
                      Confirm
                    </button>
                  )}

                  {Constant.BOOKING_STATUS.CONFIRM === row?.originalData?.bookingStatusCode ||
                    (Constant.BOOKING_STATUS.COMPLETED === row?.originalData?.bookingStatusCode && (
                      <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`UPDATE_AIRPORT`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Update
                      </button>
                    ))}

                  {[
                    Constant?.BOOKING_STATUS?.PENDING,
                    Constant?.BOOKING_STATUS?.CONFIRM,
                    Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
                    Constant?.BOOKING_STATUS?.CANCEL_BY_USER,
                  ].includes(row?.originalData?.bookingStatusCode) && (
                    <button
                      className="btn btn-sm btn-primary mb-1"
                      onClick={() => {
                        actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                      }}
                      style={{ width: '150px' }}
                      disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                    >
                      Resend Email
                    </button>
                  )}

                  {![
                    Constant?.BOOKING_STATUS?.CANCELLED,
                    Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
                    Constant?.BOOKING_STATUS?.CANCEL_BY_USER,
                    Constant.BOOKING_STATUS.SHORT_NOTICE_CANCELLED_BY_THRIWE,
                    Constant.BOOKING_STATUS.BOOKING_SHORTNOTICE_CANCELED_BY_THRIWE_WITH_QUOTA,
                    Constant.BOOKING_STATUS.SHORT_NOTICE_CANCELLED_BY_USER,
                    Constant.BOOKING_STATUS.REJECTED,
                    Constant.BOOKING_STATUS.PAYMENT_FAILED,
                    Constant.BOOKING_STATUS.PAYMENT_PENDING,
                  ].includes(row?.originalData?.bookingStatusCode) && (
                    <button
                      className="btn btn-sm btn-danger mb-1"
                      onClick={() => {
                        actionBtnHandler(`CANCEL`, row?.originalData);
                      }}
                      style={{ width: '150px' }}
                      disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                    >
                      Cancel
                    </button>
                  )}
                </>
              )}

              {Constant.PROJECT_CODE !== 'ENBD_V3' && row?.originalData?.workflow === Constant.WORKFLOWS[0] && (
                <>
                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS.PENDING && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`PROCESS`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Process
                        </button>

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`UPDATE`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Update
                        </button>

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Resend Email
                        </button>

                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => {
                            actionBtnHandler(`CANCEL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS.PROCESSED && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`CONFIRM`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Confirm
                        </button>

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`UPDATE`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Update
                        </button>

                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => {
                            actionBtnHandler(`CANCEL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                  {row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS.CONFIRM && !row?.originalData?.chauffer?.name && (
                    <>
                      <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`CHAUFFER ASSIGN`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Chauffeur Assign
                      </button>
                    </>
                  )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS.CONFIRM && (
                      <>
                        {row?.originalData?.chauffer?.name && (
                          <button
                            className="btn btn-sm btn-primary mb-1"
                            onClick={() => {
                              actionBtnHandler(`UPDATE`, row?.originalData);
                            }}
                            style={{ width: '150px' }}
                            disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                          >
                            Update
                          </button>
                        )}

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Resend Email
                        </button>

                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => {
                            actionBtnHandler(`CANCEL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS.CANCEL_BY_THRIWE && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Resend Email
                        </button>
                      </>
                    )}
                </>
              )}

              {Constant.PROJECT_CODE !== 'ENBD_V3' && row?.originalData?.workflow === Constant.WORKFLOWS[1] && (
                <>
                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS.PENDING && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`ASSIGN_VENDOR`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Assign Vendor
                        </button>

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`UPDATE`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Update
                        </button>

                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => {
                            actionBtnHandler(`CANCEL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS.PROCESSED && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`CHANGE_VENDOR`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Change Vendor
                        </button>

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`CONFIRM`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Confirm
                        </button>

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`UPDATE`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Update
                        </button>

                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => {
                            actionBtnHandler(`CANCEL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS.CONFIRM &&
                    !row?.originalData?.chauffer?.name && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`CHAUFFER ASSIGN`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Chauffeur Assign
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS.CONFIRM && (
                      <>
                        {row?.originalData?.chauffer?.name && (
                          <button
                            className="btn btn-sm btn-primary mb-1"
                            onClick={() => {
                              actionBtnHandler(`UPDATE`, row?.originalData);
                            }}
                            style={{ width: '150px' }}
                            disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                          >
                            Update
                          </button>
                        )}

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Resend Email
                        </button>

                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => {
                            actionBtnHandler(`CANCEL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS.REJECTED && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`CHANGE_VENDOR`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Change Vendor
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS.CANCEL_BY_THRIWE && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Resend Email
                        </button>
                      </>
                    )}
                </>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColmuns);
      setData(
        bookingData?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;
          let confirmedAt = `NA`;
          data?.bookingModifyPersons?.forEach(element => {
            if (element?.bookingStatus === Constant?.BOOKING_STATUS?.CONFIRM) {
              confirmedAt = moment(`${element?.modifiedAt}`).format(`DD-MM-YYYY, h:mm A`);
            }
          });
          return {
            serialNumber: `${props?.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            data: data || `NA`,
            index: index,
            vendorName: data?.vendor?.name || `NA`,
            userName: data?.user?.name || `NA`,
            userMobile: data?.user?.mobile || `NA`,
            userEmail: data?.user?.email || `NA`,
            terminalName: data?.terminalName || `NA`,
            terminalNumber: data?.terminalNumber || `NA`,
            pickupDate: data?.pickupDate ? moment(`${data?.pickupDate}`).format('DD-MM-YYYY') : 'NA',
            pickupTime: data?.pickupTime ? moment(`${data?.pickupTime}`, 'HH:mm').format('h:mm A') : `NA`,
            chaufferName: data?.chauffer?.name || `NA`,
            chaufferMobile: data?.chauffer?.mobile || `NA`,
            chaufferCarNo: data?.chauffer?.carNumber || `NA`,
            chaufferCarType: data?.chauffer?.carType || `NA`,
            bookingForSomeoneElse: data?.bookingForSomeoneElse === 0 ? 'Self' : 'Someone',
            benefitType: Constant?.benefitType[data?.benefitType ?? 0] || `NA`,
            bookingType: data?.bookingType || `NA`,
            partnerName: data?.partner?.name || `NA`,
            serviceName: data?.service?.name || `NA`,
            paymentId: data?.payment?.paymentId || `NA`,
            membershipId: data?.membershipId || `NA`,
            confirmedAt: confirmedAt,
            createdAt: data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            updatedAt: data?.updatedAt ? moment(new Date(`${data?.updatedAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            bookingStatus: status,
            originalData: data,
          };
        }),
      );
      setClearRow(!clearRow);
      setTimeout(() => {
        colorizeSlaBreached();
      }, [100]);
    } else {
      setData([]);
      setColumns([]);
    }
  }, [bookingData]);

  setTimeout(() => {
    colorizeSlaBreached();
  }, [100]);

  useEffect(() => {
    setSelectedBookings([]);
  }, [updateBookings, resendMail]);

  const handleRowSelected = state => {
    const selectedBookingDetails = state?.selectedRows;
    if (selectedBookingDetails.length > 0) {
      setSelectedBookings(
        selectedBookingDetails.map(selectedBooking => {
          return selectedBooking.data;
        }),
      );
    } else {
      setSelectedBookings([]);
    }
  };

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    // const currentSelectedIndex = Number(index.split('.')[0]) - 1 - props.pageNumber * Constant.TABLE_PAGE_SIZE;
    // const selectedBookingDetails = bookingData[currentSelectedIndex];

    if (flag === `EDIT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `VIEW`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `PROCESS`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CANCEL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `RESEND_EMAIL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CHAUFFER ASSIGN`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `ASSIGN_VENDOR`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === 'CHANGE_VENDOR') {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM_AIRPORT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `ASSIGN_VENDOR_AIRPORT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE_AIRPORT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CHAUFFER_ASSIGN_AIRPORT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- BULK UPDATE STATUSES HANDLER ---------------------------- */
  const bulkUpdateStatusHandler = flag => {
    if (flag === 'BULK_PROCESS') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_CONFIRM') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_CANCEL') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_UPDATE') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_RESEND_MAIL') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_CHAUFFER_ASSIGN') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_ASSIGN_VENDOR') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_CHANGE_VENDOR') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- COLORIZE SLA BREACH ---------------------------- */
  function colorizeSlaBreached() {
    if (props?.data?.length > 0) {
      const slaBreachedTableIndexes = props?.data
        ?.map((bookingData, index) => {
          if (bookingData?.slaDetails?.slaBreached) {
            return index;
          }
        })
        .filter(data => data !== undefined);
      Array.from(document.querySelectorAll('.rdt_TableRow')).map((row, index) => {
        if (slaBreachedTableIndexes.includes(index)) {
          row.style.backgroundColor = `#ec7b65`;
          row.style.color = `#fff`;
        } else {
          row.style.backgroundColor = `#fff`;
          row.style.color = `black`;
        }
      });
    } else {
      Array.from(document.querySelectorAll('.rdt_TableRow')).map((row, index) => {
        row.style.backgroundColor = `#fff`;
        row.style.color = `black`;
      });
    }
  }

  /* ---------------------------- COUNTDOWN HANDLER ---------------------------- */
  const countdownFunc = index => {
    if (props?.data?.[index]?.slaDetails?.slaBreached === false && props?.data?.[index]?.slaDetails?.slaLimit === 0) {
      return;
    } else {
      const diffTime = new Date() - new Date(props?.data?.[index]?.slaDetails?.lastProcessedTime);
      const remainingMilisecond = props?.data?.[index]?.slaDetails?.slaLimit - diffTime;
      return <Countdown date={new Date().valueOf() + remainingMilisecond} renderer={data => renderer(data, index)}></Countdown>;
    }
  };

  const renderer = (data, index) => {
    if (data.completed) {
      const selectedRow = Array.from(document.querySelectorAll('.rdt_TableRow'))[index + 1];
      if (selectedRow) {
        selectedRow.style.backgroundColor = `#ec7b65`;
        selectedRow.style.color = `#fff`;
      }
    } else {
      return (
        <span>
          {data.hours}:{data.minutes}:{data.seconds}
        </span>
      );
    }
  };

  useEffect(() => {
    setIsButtonDisabled(isTimePassed());
  }, [selectedBookings]);

  const isTimePassed = () => {
    for (let i = 0; i < selectedBookings.length; i++) {
      if (isDateIsGreaterThanLast7Days(selectedBookings[i]?.bookingDateTime)) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{currentFilteredBookingTitle}</h4>

              <div className="d-flex gap-2">
                {/* {Constant.PROJECT_CODE === 'ENBD_V3' && (
                  <span className="btn btn-danger mb-1" onClick={props?.showManualBookingHandler}>
                    <i className="ri-add-line me-1 text-white fs-14 align-middle"></i>Create Booking
                  </span>
                )} */}

                {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                  <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                    <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                  </span>
                )}
              </div>
            </CardHeader>

            {selectedBookings.length > 0 && hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_UPDATE) && (
              <CardHeader>
                {Constant.PROJECT_CODE !== 'ENBD_V3' && currentFilterBookingStatus.some(status => status === Constant.BOOKING_STATUS.PENDING) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_PROCESS`)}
                    disabled={isButtonDisabled}
                  >
                    Process
                  </button>
                )}

                {Constant.PROJECT_CODE === 'ENBD_V3' && currentFilterBookingStatus.some(status => status === Constant.BOOKING_STATUS.PENDING) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_ASSIGN_VENDOR`)}
                    disabled={isButtonDisabled}
                  >
                    Assign Vendor
                  </button>
                )}

                {((Constant.PROJECT_CODE === 'ENBD_V3' && currentFilterBookingStatus?.[0] === Constant.BOOKING_STATUS.PROCESSED) ||
                  currentFilterBookingStatus?.[0] === Constant.BOOKING_STATUS.REJECTED) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_CHANGE_VENDOR`)}
                    disabled={isButtonDisabled}
                  >
                    Change Vendor
                  </button>
                )}

                {Constant.PROJECT_CODE === 'ENBD_V3' && currentFilterBookingStatus.some(status => status === Constant.BOOKING_STATUS.CONFIRM) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_CHAUFFER_ASSIGN`)}
                    disabled={isButtonDisabled}
                  >
                    Chauffeur Assign
                  </button>
                )}

                {currentFilterBookingStatus.some(status => status === Constant.BOOKING_STATUS.PROCESSED) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_CONFIRM`)}
                    disabled={isButtonDisabled}
                  >
                    Confirm
                  </button>
                )}

                {Constant.PROJECT_CODE !== 'ENBD_V3' &&
                  currentFilterBookingStatus.some(status =>
                    [Constant.BOOKING_STATUS.PENDING, Constant.BOOKING_STATUS.PROCESSED, Constant.BOOKING_STATUS.CONFIRM].includes(status),
                  ) && (
                    <button
                      className="rounded-pill btn btn-primary btn-sm mx-1"
                      onClick={() => bulkUpdateStatusHandler(`BULK_UPDATE`)}
                      disabled={isButtonDisabled}
                    >
                      Update
                    </button>
                  )}

                {Constant.PROJECT_CODE === 'ENBD_V3' &&
                  currentFilterBookingStatus.some(status => [Constant.BOOKING_STATUS.COMPLETED].includes(status)) && (
                    <button
                      className="rounded-pill btn btn-primary btn-sm mx-1"
                      onClick={() => bulkUpdateStatusHandler(`BULK_UPDATE`)}
                      disabled={isButtonDisabled}
                    >
                      Update
                    </button>
                  )}

                {currentFilterBookingStatus.some(status =>
                  [
                    Constant.BOOKING_STATUS.PENDING,
                    Constant.BOOKING_STATUS.PROCESSED,
                    Constant.BOOKING_STATUS.CONFIRM,
                    Constant.BOOKING_STATUS.COMPLETED,
                  ].includes(status),
                ) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_CANCEL`)}
                    disabled={isButtonDisabled}
                  >
                    Cancel
                  </button>
                )}

                {Constant.PROJECT_CODE === 'ENBD_V3' &&
                  currentFilterBookingStatus.some(status =>
                    [
                      Constant.BOOKING_STATUS.PENDING,
                      Constant.BOOKING_STATUS.CONFIRM,
                      Constant.BOOKING_STATUS.CANCEL_BY_USER,
                      Constant.BOOKING_STATUS.CANCEL_BY_THRIWE,
                    ].includes(status),
                  ) && (
                    <button
                      className="rounded-pill btn btn-primary btn-sm mx-1"
                      onClick={() => bulkUpdateStatusHandler(`BULK_RESEND_MAIL`)}
                      disabled={isButtonDisabled}
                    >
                      Resend Email
                    </button>
                  )}

                {Constant.PROJECT_CODE !== 'ENBD_V3' &&
                  currentFilterBookingStatus.some(status =>
                    [
                      Constant.BOOKING_STATUS.CONFIRM,
                      Constant.BOOKING_STATUS.CANCEL_BY_USER,
                      Constant.BOOKING_STATUS.CANCEL_BY_THRIWE,
                      Constant.BOOKING_STATUS.CANCELLED,
                    ].includes(status),
                  ) && (
                    <button
                      className="rounded-pill btn btn-primary btn-sm mx-1"
                      onClick={() => bulkUpdateStatusHandler(`BULK_RESEND_MAIL`)}
                      disabled={isButtonDisabled}
                    >
                      Resend Email
                    </button>
                  )}
              </CardHeader>
            )}

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                selectableRows={currentFilterBookingStatus.length === 1 && Constant.PROJECT_CODE === 'ENBD_V3' ? true : false}
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={(updateBookings !== null ? true : false) || (resendMail !== null ? true : false) || clearRow}
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
