import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import cogoToast from 'cogo-toast';
import { adminAPIURL, customHeaders } from '../../../../../../Containers/Config/index.js';
import apiCall from '../../../../apiCall.js';
import { COMPLETE_BOOKING, COMPLETE_BOOKING_SUCCESS, COMPLETE_BOOKING_ERROR } from '../../../actions.js';

function* completeBooking({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/complete-booking`,
      method: 'POST',
      headers: {
        ...filteredHeaders,
      },
      data: {
        ...payload?.body,
      },
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: COMPLETE_BOOKING_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: COMPLETE_BOOKING_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: COMPLETE_BOOKING_ERROR, payload: error });
  }
}

export function* watchCompleteBooking() {
  yield takeEvery(COMPLETE_BOOKING, completeBooking);
}

function* completeBookingSaga() {
  yield all([fork(watchCompleteBooking)]);
}

export default completeBookingSaga;
