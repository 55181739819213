import Select from 'react-select';
import { Card, CardBody, Col, Row, Label, CardHeader } from 'reactstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Constant } from '../../../../../../../Helpers/constant';
import { AsyncPaginate } from 'react-select-async-paginate';
import { uaeProjectsBaseURL } from '../../../../../../../Config';
import { GET_BINS_LIST } from '../../../../../../../../store/actions';
import { useDispatch } from 'react-redux';
import { Error } from '../../../../../../../Common';
import { useEffect, useState } from 'react';

const ProjectsSelection = ({ setActiveTab, activeTab, selectedBank, setSelectedBank }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [isNextPressed, setIsNextPressed] = useState(false);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    loadBanks('');
  }, []);

  console.log('projectsprojects', projects);

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputChangeHandler = (label, data) => {
    // let updatedState = { ...state };

    console.log('hello selected bank', {
      label,
      data,
    });

    switch (label) {
      case `bank`:
        localStorage.setItem('bankCode', data?.value);
        setSelectedBank(data);

        dispatch({
          type: GET_BINS_LIST,
        });
        break;

      default:
      //
    }
  };

  /* ---------------------------- LOAD BANKS ---------------------------- */
  async function loadBanks(search) {
    let projectCodes = [];
    JSON.parse(localStorage?.getItem('data'))?.program?.client?.clients.map(item => projectCodes.push(item));

    return await axios({
      method: `POST`,
      url: `${uaeProjectsBaseURL}/admin/v1/projects`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Content-Type': 'application/json',
      },
      params: {
        name: search === '' ? null : search,
      },
      data: {
        projectCodes: projectCodes,
      },
    })
      .then(function (response) {
        const tempProject = response?.data?.results?.map(data => {
          return {
            value: data?.code,
            label: `${data?.name}`,
            completeData: data,
          };
        });

        setProjects(tempProject);

        return {
          options: tempProject,
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  console.log('selected banks --', selectedBank);

  return (
    <>
      <Row>
        <Col xxl={12}>
          <h4 className="mb-4 text-primary">{t(`Select Project`)}</h4>
        </Col>
      </Row>

      {projects ? (
        <>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0 flex-grow-1">Select Project</h4>
            </CardHeader>

            <CardBody>
              <Row>
                {projects?.map((project, index) => (
                  <Col md={3}>
                    <Card>
                      <CardBody
                        className="p-4 text-center cursor-pointer"
                        onClick={() => {
                          setSelectedBank(project);
                          setActiveTab(`journey-selection`);
                        }}
                      >
                        <div className="client-project-img-container">
                          <img
                            src={`${project?.completeData?.logo}` ?? `https://assets.thriwe.com/Thriwe_assests_inventory/project-placeholder-img.png`}
                            alt=""
                            style={{
                              height: '40px',
                              borderRadius: '5px',
                              display: 'inline-block',
                            }}
                            className="img-fluid"
                          />
                        </div>

                        <div className="client-project-name">
                          <p>{`${project?.completeData?.name ?? ''}`}</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}

                {/* {(JSON.parse(window?.localStorage?.getItem('data'))?.program?.client?.clients ?? [])?.map((client, index) => (
             
            ))} */}
              </Row>
            </CardBody>
          </Card>
        </>
      ) : (
        <Row>
          <Col xxl={12}>
            <h4 className="mb-4 text-dark fs-5 text-center">Loading...</h4>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProjectsSelection;
