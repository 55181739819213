import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { Col, Label, Row, Input, OffcanvasBody, OffcanvasHeader, Offcanvas } from 'reactstrap';

import { isValidAplha } from '../../../../../../Helpers/Helpers';
import { GET_CATEGORY_EMPTY } from '../../../../../../../store/application/actionType';
import moment from 'moment';

const BenefitGroupStatus = [
  {
    label: `Active`,
    value: true,
  },
  {
    label: `In-Active`,
    value: false,
  },
];

const CustomFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [filter, setFilter] = useState(props.appliedFilters);

  useEffect(() => {
    if (props?.appliedFilters !== null) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };

    switch (name) {
      case 'name':
        updatedState = {
          ...filter,
          name: value,
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...filter };

    if (label === `benefit-group-status`) {
      updatedState = {
        ...filter,
        isActive: data,
      };
    }

    setFilter(updatedState);
  };
   /* ---------------------------- INPUT datepicker HANDLER ---------------------------- */
      const datePickHandler = (flag, date) => {
        let updatedState = null;
    
        switch (flag) {
          case 'createdAt':
            updatedState = {
              ...filter,
              createdAt: {
                from: moment(new Date(date[0])).format('Y-MM-DD'),
                to: moment(new Date(date[1])).format('Y-MM-DD'),
              },
            };
            setFilter(updatedState);
            // validationCheck(updatedState);
            break;
    
        
        }
      
      };
  
  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Filters
        </OffcanvasHeader>
        <OffcanvasBody>
          {/* <button onClick={() => console.log('state', filter)}>get state</button> */}
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Name
                </Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  className="form-control"
                  value={filter?.name}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="benefit-group-status" className="form-label">
                  Status
                </Label>
                <Select
                  id="benefit-group-status"
                  name="benefit-group-status"
                  options={BenefitGroupStatus ?? []}
                  value={filter?.isActive}
                  onChange={data => inputSelectHandler(`benefit-group-status`, data)}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="createdAt" className="form-label">
                Created At
                </Label>
                <Flatpickr
                  className="form-control"
                  onChange={data => datePickHandler('createdAt', data)}
                  value={[filter?.createdAt?.from,filter?.createdAt?.to]}
                  options={{
                    mode:'range',
                    dateFormat: 'Y-m-d',
                    // minDate: 'today',
                  }}
                />
              </div>
            </Col>
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button className="btn btn-light w-100" onClick={props?.resetHandler}>
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default CustomFilter;
