import {
  CANCEL_BOOKING_BY_VENDOR,
  CANCEL_BOOKING_BY_VENDOR_SUCCESS,
  CANCEL_BOOKING_BY_VENDOR_ERROR,
  CANCEL_BOOKING_BY_VENDOR_EMPTY,
} from '../../../actions';

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const CancelBookingByVendor = (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_BOOKING_BY_VENDOR:
      state = {
        ...state,
        // data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      };
      break;

    case CANCEL_BOOKING_BY_VENDOR_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };
      break;

    case CANCEL_BOOKING_BY_VENDOR_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };
      break;

    case CANCEL_BOOKING_BY_VENDOR_EMPTY:
      state = {
        ...initialState,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CancelBookingByVendor;
