import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { Col, Label, Row, Input, OffcanvasBody, OffcanvasHeader, Offcanvas } from 'reactstrap';

import { isValidAplha } from '../../../../../../Helpers/Helpers';
import { BENEFIT_PACK_INFO } from '../../../../../../../store/application/actionType';
import moment from 'moment';
import { AsyncPaginate } from 'react-select-async-paginate';
// import { baseUrl, customHeaders, uaeProjectsBaseURL } from '../../../../../../Config';
import axios from 'axios';
import { baseUrl, customHeaders } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';
const BenefitGroupStatus = [
  {
    label: `Active`,
    value: true,
  },
  {
    label: `In-Active`,
    value: false,
  },
];

const BenefitPackFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
  
    serviceName: '',
    lotNumber: '',
    uploadedBy: '',
    applicableDate: '',
    createdBy: '',
    createdAt: '',
    expiryDate: '',
    benefitGroupId:''
    
  };
  const [filter, setFilter] = useState(initialState);


  const resetHandler = () => {
   
     setFilter({ ...initialState });
        // setIsResetDisabled(true);
        dispatch({
          type: BENEFIT_PACK_INFO,
                 payload: {
                   urlParam: {
                     pageIndex: 0,
                   },
                 },
        });
        console.log('here reset')
        props.setCurrentlyAppliedFilters(null);
        props.onCloseClick();
  };
  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };

    switch (name) {
      case 'createdBy':
        updatedState = {
          ...filter,
          createdBy: value,
        };
        break;
      case 'benefitGroupId':
        updatedState = {
          ...filter,
          benefitGroupId: value,
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  const loadOptionForBenefitGroup = async search => {
    return await axios({
      method: `GET`,
      url: `${baseUrl}/admin/benefitGroups?pageIndex=0&pageSize=50`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search === '' ? null : search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.objectId,
              label: `${data?.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  };

 

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    console.log('label', label);
    console.log('data ', data);
    let updatedState = { ...filter };

    // if (label === `benefit-group-status`) {
      updatedState = {
        ...filter,
        benefitGroupId: data,
      };
    // }

    setFilter(updatedState);
  };
   /* ---------------------------- INPUT datepicker HANDLER ---------------------------- */
      const datePickHandler = (flag, date) => {
        let updatedState = null;
    
        switch (flag) {
          case 'createdAt':
            updatedState = {
              ...filter,
              createdAt: {
                from: moment(new Date(date[0])).format('Y-MM-DD'),
                to: moment(new Date(date[1])).format('Y-MM-DD'),
              },
            };
            setFilter(updatedState);
            // validationCheck(updatedState);
            break;
          case 'expiryDate':
            updatedState = {
              ...filter,
              expiryDate: {
                from: moment(new Date(date[0])).format('Y-MM-DD'),
                to: moment(new Date(date[1])).format('Y-MM-DD'),
              },
            };
            setFilter(updatedState);
            // validationCheck(updatedState);
            break;
          case 'applicableDate':
            updatedState = {
              ...filter,
              applicableDate: {
                from: moment(new Date(date[0])).format('Y-MM-DD'),
                to: moment(new Date(date[1])).format('Y-MM-DD'),
              },
            };
            setFilter(updatedState);
            // validationCheck(updatedState);
            break;
    
        
        }
      
      };
  
  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Filters
        </OffcanvasHeader>
        <OffcanvasBody>
          {/* <button onClick={() => console.log('state', filter)}>get state</button> */}
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="createdBy" className="form-label">
                  Generated By
                </Label>
                <Input
                  id="createdBy"
                  name="createdBy"
                  type="text"
                  className="form-control"
                  value={filter?.createdBy}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="benefitGroupId" className="form-label">
                Benefit Group Name
                </Label>
                {/* <Input
                  id="benefitGroupId"
                  name="benefitGroupId"
                  type="text"
                  className="form-control"
                  value={filter?.benefitGroupId}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                /> */}
                <AsyncPaginate
                  debounceTimeout={500}
                  value={filter?.benefitGroupId}
                  loadOptions={loadOptionForBenefitGroup}
                  onChange={data => inputSelectHandler(`benefitGroup`, data)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Select Benefit Group"
                  // isDisabled={props?.currentSelectedDetails}
                />
              </div>
            </Col>

            {/* <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="benefit-group-status" className="form-label">
                  Status
                </Label>
                <Select
                  id="benefit-group-status"
                  name="benefit-group-status"
                  options={BenefitGroupStatus ?? []}
                  value={filter?.isActive}
                  onChange={data => inputSelectHandler(`benefit-group-status`, data)}
                />
              </div>
            </Col> */}
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="createdAt" className="form-label">
                Created At
                </Label>
                <Flatpickr
                  className="form-control"
                  onChange={data => datePickHandler('createdAt', data)}
                  value={[filter?.createdAt?.from,filter?.createdAt?.to]}
                  options={{
                    mode:'range',
                    dateFormat: 'Y-m-d',
                    // minDate: 'today',
                  }}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="expiryDate" className="form-label">
                Expiry Date
                </Label>
                <Flatpickr
                  className="form-control"
                  onChange={data => datePickHandler('expiryDate', data)}
                  value={[filter?.expiryDate?.from,filter?.expiryDate?.to]}
                  options={{
                    mode:'range',
                    dateFormat: 'Y-m-d',
                    // minDate: 'today',
                  }}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="applicableDate" className="form-label">
                Applicable Date
                </Label>
                <Flatpickr
                  className="form-control"
                  onChange={data => datePickHandler('applicableDate', data)}
                  value={[filter?.applicableDate?.from,filter?.applicableDate?.to]}
                  options={{
                    mode:'range',
                    dateFormat: 'Y-m-d',
                    // minDate: 'today',
                  }}
                />
              </div>
            </Col>
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button className="btn btn-light w-100" onClick={resetHandler}>
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default BenefitPackFilter;
