import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import { BoldCaretDown, CaretDown } from '../../../Components/icons';
import {
  CHANGE_ACTIVITY,
  CHANGE_ACTIVITY_EMPTY,
  FETCH_ACTIVITY,
  GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH,
  GET_USER_ROLES_EMPTY,
  LOGIN_V2_EMPTY,
  LOGOUT,
  VENDOR_LOGIN_V2_EMPTY,
} from '../../../store/actions';
import { Constant } from '../../Helpers/constant';
import { getUserV2Name } from '../../Helpers/Helpers';
import Loader from '../Loader/Loader';
import Select from 'react-select';
import { FaSearch } from 'react-icons/fa'; // Importing search icon
import queryString from 'query-string';
import cogoToast from 'cogo-toast';

export default function Header({ headerClass }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  console.log('Location', location?.pathname);

  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const [isUserStatusDropdown, setIsUserStatusDropdown] = useState(false);
  const [activeUserStatus, setActiveUserStatus] = useState('INACTIVE');
  const [bookingTypeOptions, setBookingTypeOptions] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [bookingType, setBookingType] = useState(null);

  const {
    fetchActivity,
    fetchActivityLoading,
    changeActivity,
    changeActivityLoading,
    userLogout,
    userLogoutLoading,
    bookingTypeData,
    bookingTypeLoading,
  } = useSelector(state => ({
    fetchActivity: state.FetchActivity.data,
    fetchActivityLoading: state.FetchActivity.loading,

    changeActivity: state.ChangeActivity.data,
    changeActivityLoading: state.ChangeActivity.loading,

    userLogout: state.UserLogout.data,
    userLogoutLoading: state.UserLogout.loading,

    bookingTypeData: state.GetBookingTypeForGlobalSearch.data,
    bookingTypeLoading: state.GetBookingTypeForGlobalSearch.loading,
  }));

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  const toggleUserStatusDropdown = () => {
    setIsUserStatusDropdown(!isUserStatusDropdown);
  };

  useEffect(() => {
    if (Number(localStorage.getItem('userType')) === Constant.userTypes.ADMIN_USER_V2)
      dispatch({
        type: FETCH_ACTIVITY,
      });
    dispatch({
      type: GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH,
    });
  }, []);

  useEffect(() => {
    if (fetchActivity) {
      setActiveUserStatus(fetchActivity?.activityStateEnum);
    }
  }, [fetchActivity]);

  useEffect(() => {
    if (changeActivity !== null) {
      dispatch({
        type: FETCH_ACTIVITY,
      });
      dispatch({
        type: CHANGE_ACTIVITY_EMPTY,
      });
    }
  }, [changeActivity]);

  useEffect(() => {
    if (userLogout !== null) {
      const isAdminUserV2 = Number(localStorage.getItem('userType')) === Constant.userTypes.ADMIN_USER_V2;
      dispatch({
        type: GET_USER_ROLES_EMPTY,
      });
      if (isAdminUserV2)
        dispatch({
          type: LOGIN_V2_EMPTY,
        });
      else
        dispatch({
          type: VENDOR_LOGIN_V2_EMPTY,
        });
      setTimeout(() => {
        localStorage.clear();
        isAdminUserV2 ? history.push(`/login-v2`) : history.push(`/v2/vendor/login`);
        window.location.reload();
      }, 200);
    }
  }, [userLogout]);

  useEffect(() => {
    if (bookingTypeData !== null) {
      setBookingTypeOptions(
        bookingTypeData?.results?.map(booking => {
          return {
            value: booking?.key,
            label: booking?.value,
          };
        }),
      );
    }
  }, [bookingTypeData]);

  const profilePictureCreator = name => {
    const canvas = document.createElement('canvas');
    canvas.width = 100;
    canvas.height = 100;
    const context = canvas.getContext('2d');

    // Draw a circle
    context.beginPath();
    context.arc(50, 50, 40, 0, 2 * Math.PI);
    context.fillStyle = '#18ae73';
    context.fill();

    // Draw the first character of the name
    context.font = '40px Arial';
    context.fillStyle = '#fff';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(name?.charAt(0)?.toUpperCase(), 50, 50);

    return canvas.toDataURL();
  };

  const logoutHandler = () => {
    dispatch({
      type: LOGOUT,
    });
  };

  const userStatus = {
    ACTIVE: {
      name: 'Active',
      className: 'user-status-pointer-success',
    },
    INACTIVE: {
      name: 'Inactive',
      className: 'user-status-pointer-inactive',
    },
    ON_BREAK: {
      name: 'Out Break',
      className: 'user-status-pointer-out-break',
    },
  };

  const toggleUserStatus = status => {
    dispatch({
      type: CHANGE_ACTIVITY,
      payload: {
        data: {
          activityState: status,
        },
      },
    });
    setActiveUserStatus(status);
  };

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;

    if (windowSize > 767) document.querySelector('.hamburger-icon').classList.toggle('open');

    //For collapse horizontal menu
    if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
      document.body.classList.contains('menu') ? document.body.classList.remove('menu') : document.body.classList.add('menu');
    }

    //For collapse vertical menu
    if (document.documentElement.getAttribute('data-layout') === 'vertical') {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable');
        document.documentElement.getAttribute('data-sidebar-size') === 'sm'
          ? document.documentElement.setAttribute('data-sidebar-size', '')
          : document.documentElement.setAttribute('data-sidebar-size', 'sm');
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable');
        document.documentElement.getAttribute('data-sidebar-size') === 'lg'
          ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
          : document.documentElement.setAttribute('data-sidebar-size', 'lg');
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable');
        document.documentElement.setAttribute('data-sidebar-size', 'lg');
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
      document.body.classList.contains('twocolumn-panel')
        ? document.body.classList.remove('twocolumn-panel')
        : document.body.classList.add('twocolumn-panel');
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (searchTerm.trim() !== '') {
        const params = {
          search: searchTerm,
          bookingType: bookingType?.value,
        };
        setSearchTerm('');
        history.push({
          pathname: '/v2/global-search-booking',
          search: `?${queryString.stringify(params)}`,
        });
      } else {
        cogoToast.error('Please search with booking id');
      }
    }
  };

  useEffect(() => {
    if (fetchActivityLoading || changeActivityLoading || userLogoutLoading || bookingTypeLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [fetchActivityLoading, changeActivityLoading, userLogoutLoading, bookingTypeLoading]);

  return (
    <header id="page-topbar" className={headerClass}>
      {loading && <Loader />}
      <div className="layout-width">
        <div className="navbar-header">
          <div className="container-fluid px-0">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-1">
                <div className="d-flex align-content-center">
                  <button
                    onClick={toogleMenuBtn}
                    type="button"
                    className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                    id="topnav-hamburger-icon"
                  >
                    <span className="hamburger-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </button>
                </div>
                {location.pathname !== '/v2/global-search-booking' &&
                  Constant.userTypes.ADMIN_USER_V2 === Number(localStorage.getItem('userType')) && (
                    <div
                      className="d-flex align-items-center"
                      style={{ pointerEvents: (activeUserStatus === 'INACTIVE' || activeUserStatus === 'ON_BREAK') && 'none' }}
                    >
                      <Select
                        options={bookingTypeOptions}
                        placeholder="Booking Type"
                        value={bookingType}
                        onChange={data => {
                          setBookingType(data);
                        }}
                        styles={{
                          control: provided => ({
                            ...provided,
                            width: '150px', // Set custom width
                            borderRadius: '0px !important',
                          }),
                        }}
                        isSearchable={false}
                      />
                      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                        <Input
                          type="text"
                          style={{ borderRadius: '0px', paddingLeft: '25px', width: '289px' }} // Space for icon
                          placeholder="Search By Booking ID, Name, Email, Mobile No"
                          value={searchTerm}
                          onChange={e => setSearchTerm(e.target.value)}
                          onKeyDown={handleKeyDown}
                          disabled={!bookingType}
                        />
                        <FaSearch
                          style={{
                            position: 'absolute',
                            left: '7px',
                            color: 'black',
                            cursor: 'pointer',
                          }}
                          color="black"
                        />
                      </div>
                    </div>
                  )}
              </div>

              <div className="d-flex align-items-center">
                {Number(localStorage.getItem('userType')) === Constant.userTypes.ADMIN_USER_V2 && (
                  <div>
                    <Dropdown isOpen={isUserStatusDropdown} toggle={toggleUserStatusDropdown} className="header-item">
                      <DropdownToggle tag="button" type="button" className="btn">
                        <div className="user-status">
                          <div className={`user-status-pointer ${userStatus?.[activeUserStatus]?.className}`} />
                          {userStatus?.[activeUserStatus]?.name} <CaretDown />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        {Object.keys(userStatus).map((item, i) => (
                          <div key={i}>
                            {i > 0 && <DropdownItem divider />}
                            <DropdownItem onClick={() => toggleUserStatus(item)}>
                              <span className="align-middle d-flex align-items-center gap-2" data-key={item}>
                                <div className={`user-status-pointer ${userStatus?.[item]?.className}`} />
                                {userStatus?.[item]?.name}
                              </span>
                            </DropdownItem>
                          </div>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}

                <div>
                  <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="header-item">
                    <DropdownToggle tag="button" type="button" className="btn">
                      <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user-2" src={profilePictureCreator(getUserV2Name())} alt="" />
                        <span className="text-start ms-xl-2">
                          <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text user-name-text-2">{getUserV2Name()}</span>
                        </span>

                        <span>
                          <BoldCaretDown />
                        </span>
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      {/* <DropdownItem>
                        <span className="align-middle" data-key="t-profile">
                          My Profile
                        </span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        <span className="align-middle" data-key="t-change-role">
                          Change Role
                        </span>
                      </DropdownItem> */}
                      {/* <DropdownItem divider /> */}
                      <DropdownItem onClick={logoutHandler}>
                        <span className="align-middle" data-key="t-logout">
                          Log out
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
