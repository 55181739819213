import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadcrumbTrail from '../../../../../Common/BreadcrumbTrail';
import { useDispatch, useSelector } from 'react-redux';
import { GET_RULE_DASHBOARD } from '../../../../../../store/actions';
import CustomCard from './Components/Card';
import ProjectChart from './Components/Chart/ProjectChart';
import WorkflowChart from './Components/Chart/WorkflowChart';
import { Loader } from '../../../../../Common';
import moment from 'moment';

const filterOptions = [
  { label: 'Last 7 Days', value: '0' },
  { label: 'Last 14 Days', value: '1' },
  { label: 'Last 21 Days', value: '2' },
  { label: 'Last 30 Days', value: '3' },
];

interface FilterOption {
  label: string;
  value: string;
}

const initialFilterDate = {
  project_wise_workflow_start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  project_wise_workflow_end_date: moment().format('YYYY-MM-DD'),
  most_used_workflow_start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  most_used_workflow_end_date: moment().format('YYYY-MM-DD'),
};

interface FilterDate {
  project_wise_workflow_start_date: string;
  project_wise_workflow_end_date: string;
  most_used_workflow_start_date: string;
  most_used_workflow_end_date: string;
}

const SurgeDashboard = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    projectWiseWorkflow: { label: 'Last 7 Days', value: 'Last 7 Days' },
    mostUsedWorkflow: { label: 'Last 7 Days', value: 'Last 7 Days' },
  });
  const [filterDate, setFilterDate] = useState<FilterDate>(initialFilterDate);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Rule Dashboard
  const { ruleDashboard, ruleDashboardLoading } = useSelector((state: any) => ({
    ruleDashboard: state?.GetRuleDashboard?.data,
    ruleDashboardLoading: state?.GetRuleDashboard?.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_RULE_DASHBOARD,
      payload: {
        urlParams: {
          ...filterDate,
        },
      },
    });
  }, [filterDate]);

  useEffect(() => {
    if (ruleDashboardLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [ruleDashboardLoading]);

  const items = [
    {
      id: 2,
      label: 'Total Workflows',
      percentageIcon: 'ri-arrow-right-down-line',
      counter: `${ruleDashboard?.total_workflows ?? 0}`,
      icon: 'bx bx-user',
      iconClass: 'success',
    },
    {
      id: 3,
      label: 'Live Workflows',
      percentageIcon: 'ri-arrow-right-down-line',
      counter: `${ruleDashboard?.live_workflows ?? 0}`,
      icon: 'bx bx-user',
      iconClass: 'success',
    },
    {
      id: 4,
      label: 'Draft Workflows',
      percentageIcon: 'ri-arrow-right-down-line',
      counter: `${ruleDashboard?.draft_workflows ?? 0}`,
      icon: 'bx bx-user',
      iconClass: 'success',
    },
  ];

  // const filterHandler = (label: string, data: FilterOption) => {
  //   if (label === `projectWiseWorkflow`) {
  //   } else if (label === `mostUsedWorkflow`) {
  //   }
  // };

  const filterHandler = (label: string, data: FilterOption) => {
    // Calculate the new start and end dates based on the selected filter value
    const daysToSubtract = {
      '0': 7,
      '1': 14,
      '2': 21,
      '3': 30,
    }[data.value];

    const endDate = moment().format('YYYY-MM-DD');

    if (label === `projectWise`) {
      setFilter({
        ...filter,
        projectWiseWorkflow: data,
      });
      setFilterDate({
        ...filterDate,
        project_wise_workflow_end_date: endDate,
      });
    } else if (label === `mostUsed`) {
      setFilter({
        ...filter,
        mostUsedWorkflow: data,
      });
      setFilterDate({
        ...filterDate,
        most_used_workflow_start_date: endDate,
      });
    }
  };

  return (
    <div className="page-content">
      {loading && <Loader />}
      <Container fluid className="position-relative">
        <BreadcrumbTrail title="Rule Dashboard" navigationby={() => {}} navigation={[`Dashboard`, `Rule Dashboard`]} />
        <Row>
          {items.map(item => (
            <CustomCard key={item.id} label={item.label} counter={item.counter} icon={item.icon} iconClass={item.iconClass} />
          ))}
        </Row>

        <Row>
          <ProjectChart
            projectWiseWorkflows={ruleDashboard?.project_wise_workflows ?? []}
            filter={filter.projectWiseWorkflow}
            filterOptions={filterOptions}
            filterHandler={filterHandler}
          />
          <WorkflowChart
            mostUsedWorkflows={ruleDashboard?.most_used_workflows ?? []}
            filter={filter.mostUsedWorkflow}
            filterOptions={filterOptions}
            filterHandler={filterHandler}
          />
        </Row>
      </Container>
    </div>
  );
};

export default SurgeDashboard;
