import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CustomTable from '../../../../../../../Common/Table/Table';
import { Constant } from '../../../../../../../Helpers/constant';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const Table = props => {
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookingData, setBookingData] = useState([]);
  const [columns, setColumns] = useState([]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `VIEW`) {
      props.actionHandler({
        templateDetails: data,
        flag: flag,
      });
    }
  };

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => row?.serialNumber,
        },
        {
          name: 'Template Name',
          // width: '140px',
          cell: row => (
            <span
              className="cursor-pointer text-decoration-underline"
              style={{ color: '#3577f1' }}
              onClick={() => actionBtnHandler(`VIEW`, row?.originalData)}
            >
              {row?.name}
            </span>
          ),
        },
        {
          name: 'Event Name',
          // width: '140px',
          cell: row => row?.event_name,
        },
        {
          name: 'Project Name',
          // width: '140px',
          cell: row => row?.project_name,
        },
        {
          name: 'Created At',
          // width: '140px',
          cell: row => row?.created_at,
        },
        {
          name: 'Status',
          // width: '140px',
          cell: row => (
            <span
              style={{
                display: 'inline-block',
                padding: '6px 12px',
                fontSize: '14px',
                fontWeight: '600',
                borderRadius: '8px',
                color: row?.status === 'Active' ? '#0F5132' : '#842029', // Dark Green for Active, Dark Red for Inactive
                backgroundColor: row?.status === 'Active' ? '#D1E7DD' : '#F8D7DA', // Light Green for Active, Light Red for Inactive
              }}
            >
              {row?.status}
            </span>
          ),
        },
      ];
      setColumns(fetchColumns);

      setBookingData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props?.pageNumber * props?.limit + index + 1 - props?.limit}.`,
            name: data?.name || 'NA',
            event_name: data?.event_name || 'NA',
            project_name: data?.project_name || 'NA',
            created_at: data?.created_at ? moment(new Date(`${data?.created_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            status: data?.status || 'NA',
            originalData: data,
          };
        }),
      );
    } else {
      setBookingData([]);
      setColumns([]);
    }
  }, [props?.data]);

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <h2 className="fw-semibold card-title mb-0 flex-grow-1">All Configuration Mappings</h2>
              <button type="button" className="btn btn-success waves-effect waves-light" onClick={() => history.push(`/surge/mapping`)}>
                Create Mapping
              </button>
            </CardHeader>

            <CardBody>
              <CustomTable
                columns={columns ?? []}
                data={bookingData ?? []}
                totalCount={props.totalCount}
                limit={props.limit}
                handleRowsPerPageChange={props.handleRowsPerPageChange}
                currentPage={props.pageNumber}
                handlePageChange={props.handlePageChange}
                indexType={Constant.INDEX_TYPE.ONE_BASED}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
