import React, { useState } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import CountUp from 'react-countup';
import moment from 'moment';

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({ data, title, lastUpdatedAt }) => {
  const [selectedItems, setSelectedItems] = useState(data?.distributions);

  const getTimeAgoText = (isoDate: string): string => {
    const date = moment(isoDate);
    const now = moment();

    if (date.isSame(now, 'day')) {
      const diffHours = now.diff(date, 'hours');
      console.log('diffHours', diffHours);
      if (diffHours < 1) {
        return 'Last Updated Just now';
      } else if (diffHours < 24) {
        return `Last Updated ${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
      } else {
        return 'Last Updated Today';
      }
    } else if (date.isSame(now.clone().subtract(1, 'day'), 'day')) {
      return 'Last Updated Yesterday';
    } else if (date.isBetween(now.clone().subtract(7, 'days'), now, 'day', '[]')) {
      return 'Last Updated 7 Days';
    } else {
      return `Last Updated ${date.format('DD MMM YYYY')}`; // Example: "30 Jan 2025, 06:30 PM"
    }
  };

  // Toggle legend selection (remove/add item)
  const toggleLegend = item => {
    setSelectedItems(prev => {
      const isRemoved = prev?.some(d => d?.name === item.name);
      return isRemoved ? prev?.filter(d => d?.name !== item.name) : [...prev, item];
    });
  };

  // Pie chart data based on selected items
  const pieData = {
    labels: selectedItems?.map(item => item.name),
    datasets: [
      {
        data: selectedItems?.map(item => item.value),
        backgroundColor: selectedItems?.map(item => item.color),
        borderWidth: 1,
        cutout: '65%',
      },
    ],
  };

  // Calculate updated total count based on selected items
  const updatedTotalCount = selectedItems?.reduce((sum, item) => sum + item.value, 0);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide default legend to make custom legends
      },
      datalabels: {
        color: 'white', // Set the text color inside the pie chart
        font: {
          weight: 'normal',
        },
      },
    },
  };

  return (
    <Col xl={6} md={12} lg={6} className="mb-2" style={{ borderRadius: '10px' }}>
      <Card>
        <div className="d-flex justify-content-between m-4">
          <h4 className="card-title mb-0 flex-grow-1 fw-semibold">{title}</h4>
          <p className="text-muted">{getTimeAgoText(lastUpdatedAt)}</p>
        </div>
        <CardBody style={{ maxHeight: '400px', position: 'relative', textAlign: 'center' }}>
          <div style={{ height: '300px', position: 'relative' }}>
            <Doughnut data={pieData} options={options} />
            {/* Total Bookings in Center */}
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              <div style={{ fontSize: '12px', fontWeight: 'normal' }}>{data?.countsDisplayHeading}</div>
              <CountUp separator="" start={0} end={Number(updatedTotalCount)} duration={2} style={{ fontSize: '25px' }} />
            </div>
          </div>
          {/* Custom Legends */}
          <div className="d-flex flex-wrap justify-content-center mt-3">
            {data?.distributions?.map(item => {
              const isSelected = selectedItems?.some(d => d.name === item.name);
              return (
                <div
                  key={item.name}
                  onClick={() => toggleLegend(item)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '3px',
                    padding: '5px 10px',
                    backgroundColor: '#F2F2F7',
                    color: 'black',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  {/* Square Color Box */}
                  <div
                    style={{
                      width: '13px',
                      height: '13px',
                      backgroundColor: item.color,
                      borderRadius: '3px',
                      marginRight: '8px',
                      border: '1px solid #ccc',
                      fontSize: 10,
                    }}
                  ></div>
                  <span style={{ textDecoration: !isSelected ? 'line-through' : 'none', fontWeight: '400', fontSize: 12 }}>{item.name}</span>
                </div>
              );
            })}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default DonutChart;
