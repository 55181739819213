import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, BreadCrumb } from '../../../../Common';

import { Container } from 'reactstrap';
import {
  BENEFIT_PACK_INFO,
  BENEFIT_PACK_INFO_EMPTY,
  BENEFIT_PACK_LIST,
  CLICK_DOWNLOAD_DUMP,
  CLICK_DOWNLOAD_DUMP_EMPTY,
  CREATE_BENEFIT_PACK,
  CREATE_BENEFIT_PACK_EMPTY,
  GET_BENEFIT_GROUPS,
  GET_CLIENTS,
  UPDATE_BENEFIT_PACK,
  UPDATE_BENEFIT_PACK_EMPTY,
} from '../../../../../store/application/actionType';
import BenefitPackForm from './Components/Forms/BenefitPackForm';
import { Constant } from '../../../../Helpers/constant';
import BenefitPackInfoTable from './Components/Tables/BenefitInfoTable';
import ChangeExpiryModal from './Components/Modals/ChangeExpiryModal';
import BenefitPacksTable from './Components/Tables/BenefitPacksTable';
import BenefitPackModal from './Components/Modals/BenefitPackModal';
import BenefitPackFilter from './Components/Filter/BenefitPackFilter';

const BenefitPacksManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isShowFilters, setIsShowFilters] = useState(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [isShowBenefitPackModal, setIsShowBenefitPackModal] = useState(false);

  const [currentSelectedBenefit, SetCurrentSelectedBenefit] = useState('');

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [currentPageNumberSecondary, setCurrentPageNumberSecondary] = useState(0);
  const [currentPageIndexSecondary, setCurrentPageIndexSecondary] = useState(0);

  const [benefitPackInfoTableShow, setBenefitPackInfoTableShow] = useState(true);
  const [benefitPackTableShow, setBenefitPackTableShow] = useState(false);
  const [isCreateBenefitPackFormShow, setIsCreateBenefitPackFormShow] = useState(false);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState(null);

  const [isChangeExpiryModalShow, setIsChangeExpiryModalShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Benefit Packs
  const { benefitPacks, benefitPacksLoading } = useSelector(state => ({
    benefitPacks: state?.BenefitPack?.data,
    benefitPacksLoading: state?.BenefitPack?.loading,
  }));

  // Benefit Packs Update
  const { updateBenefitPacks, updateBenefitPacksLoading } = useSelector(state => ({
    updateBenefitPacks: state?.UpdateBenefitPack?.data,
    updateBenefitPacksLoading: state?.UpdateBenefitPack?.loading,
  }));

  // Benefit Pack Info
  const { benefitPackInfo, benefitPackInfoLoading } = useSelector(state => ({
    benefitPackInfo: state?.BenefitPackInfo?.data,
    benefitPackInfoLoading: state?.BenefitPackInfo?.loading,
  }));

  // Benefit Groups
  const { benefitGroups, benefitGroupsLoading } = useSelector(state => ({
    benefitGroups: state?.BenefitGroups?.data,
    benefitGroupsLoading: state?.BenefitGroups?.loading,
  }));

  // Clients
  const { clients, clientsLoading } = useSelector(state => ({
    clients: state?.Clients?.data,
    clientsLoading: state?.Clients?.loading,
  }));

  // Create Benefit Pack
  const { createBenefitPack, createBenefitPackLoading } = useSelector(state => ({
    createBenefitPack: state?.CreateBenefitPack?.data,
    createBenefitPackLoading: state?.CreateBenefitPack?.loading,
  }));

  // Click Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.DownloadDump?.data,
    downloadDumpLoading: state?.DownloadDump?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: BENEFIT_PACK_INFO,
      payload: {
        urlParam: {
          pageIndex: currentPageIndex,
        },
      },
    });

    if (benefitGroups === null) {
      dispatch({
        type: GET_BENEFIT_GROUPS,
      });
    }
    if (clients === null) {
      dispatch({
        type: GET_CLIENTS,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (createBenefitPack !== null) {
      dispatch({
        type: CREATE_BENEFIT_PACK_EMPTY,
      });

      setBenefitPackInfoTableShow(true);
      setBenefitPackTableShow(false);
      setIsShowBenefitPackModal(false);

      dispatch({
        type: BENEFIT_PACK_INFO,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
          },
        },
      });
    }
  }, [createBenefitPack]);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: CLICK_DOWNLOAD_DUMP_EMPTY,
      });
    }
  }, [downloadDump]);

  useEffect(() => {
    if (updateBenefitPacks !== null) {
      dispatch({
        type: UPDATE_BENEFIT_PACK_EMPTY,
      });
      setBenefitPackInfoTableShow(true);
      setBenefitPackTableShow(false);
      setIsShowBenefitPackModal(false);
      dispatch({
        type: BENEFIT_PACK_INFO,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
          },
        },
      });
    }
  }, [updateBenefitPacks]);

    /* ---------------------------- ON RESET HANDLER ---------------------------- */
 
    const filterSubmitHandler = filters => {
      console.log('filters-->', filters);
      setIsShowFilters(false);
     
      setCurrentPageNumber(0);
      let urlParam={
            benefitGroupId: filters?.benefitGroupId === `` ? null : filters?.benefitGroupId?.value,
            createdBy: filters?.createdBy === `` ? null : filters?.createdBy,
            // createdAt: filters?.createdAt === `` ? null : filters?.createdAt,
            createdAtFrom: filters?.createdAt === `` ? null : filters?.createdAt?.from,
            createdAtTo: filters?.createdAt === `` ? null : filters?.createdAt?.to,

            expiryDateFrom: filters?.expiryDate === `` ? null : filters?.expiryDate?.from,
            expiryDateTo: filters?.expiryDate === `` ? null : filters?.expiryDate?.to,

            applicableDateFrom: filters?.applicableDate === `` ? null : filters?.applicableDate?.from,
            applicableDateTo: filters?.applicableDate === `` ? null : filters?.applicableDate?.to,

            pageIndex: 0
      }
      console.log('url: ' , urlParam)
      setCurrentlyAppliedFilters(urlParam);
      dispatch({
        type: BENEFIT_PACK_INFO,
        payload: {
            urlParam
        },
      });
    };
  /* ---------------------------- BUTTON HANDLER ---------------------------- */
  const createBenefitPackBtnHandler = () => {
    setIsShowBenefitPackModal(true);
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.data);

    if (params?.actionType === `VIEW`) {
      setBenefitPackInfoTableShow(false);
      setBenefitPackTableShow(true);
      setIsShowBenefitPackModal(false);

      setCurrentPageIndexSecondary(0);
      setCurrentPageNumberSecondary(0);
      SetCurrentSelectedBenefit(params?.data?.objectId);

      dispatch({
        type: BENEFIT_PACK_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
            benefitPacksInfoId: params?.data?.objectId,
          },
        },
      });
    } else if (params?.actionType === `DOWNLOAD`) {
      // do nothing
      dispatch({
        type: CLICK_DOWNLOAD_DUMP,
        payload: {
          urlParam: {
            benefitPacksInfoId: params?.data?.objectId,
            downloadType: 2,
          },
          data: null,
        },
      });
    } else if (params?.actionType === `CHANGE_EXPIRY`) {
      // do nothing
      setIsChangeExpiryModalShow(true);
    } else if (params?.actionType === 'UPDATE') {
      setIsShowBenefitPackModal(true);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: BENEFIT_PACK_INFO,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          ...currentlyAppliedFilters
        },
      },
    });
  };

  const paginationHandlerSecondary = pageNumber => {
    setCurrentPageNumberSecondary(pageNumber);
    setCurrentPageIndexSecondary(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: BENEFIT_PACK_LIST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          benefitPacksInfoId: currentSelectedBenefit,
        },
      },
    });
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const BenefitPackSubmitHandler = (label, data) => {
    setIsShowBenefitPackModal(false);

    if (label === 'CREATE') {
      dispatch({
        type: CREATE_BENEFIT_PACK,
        payload: {
          data: data,
        },
      });
    } else if (label === 'UPDATE') {
      dispatch({
        type: UPDATE_BENEFIT_PACK,
        payload: {
          data: {
            request: [data],
          },
        },
      });
    }
    setCurrentSelectedDetails(null);
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      benefitPacksLoading ||
      benefitGroupsLoading ||
      clientsLoading ||
      createBenefitPackLoading ||
      benefitPackInfoLoading ||
      downloadDumpLoading ||
      updateBenefitPacksLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    benefitPacksLoading,
    benefitGroupsLoading,
    clientsLoading,
    createBenefitPackLoading,
    benefitPackInfoLoading,
    downloadDumpLoading,
    updateBenefitPacksLoading,
  ]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Benefit Management`) {
      setBenefitPackInfoTableShow(true);
      setBenefitPackTableShow(false);
      setIsShowBenefitPackModal(false);
      setCurrentSelectedDetails(null);

      dispatch({
        type: BENEFIT_PACK_INFO,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
          },
        },
      });
    } else if (title === `Information`) {
      // do nothing
    }
  };

  const onCloseBenefitPackModal = () => {
    setIsShowBenefitPackModal(false);
    setCurrentSelectedDetails(null);
  };

  return (
    <>
      <div className="page-content">
        {loading && <Loader />}

        {isShowBenefitPackModal && (
          <BenefitPackModal
            data={{ benefitGroups, clients }}
            onSubmit={BenefitPackSubmitHandler}
            currentSelectedDetails={currentSelectedDetails}
            onCloseHandler={onCloseBenefitPackModal}
          />
        )}

        <Container fluid className="position-relative">
          {isChangeExpiryModalShow && <ChangeExpiryModal data={currentSelectedDetails} onCloseHandler={() => setIsChangeExpiryModalShow(false)} />}

          {benefitPackInfoTableShow && (
            <>
              <BreadCrumb title="Benefits" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Benefit Management`]} />
              <BenefitPackFilter
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
                show={isShowFilters}
                onCloseClick={() => setIsShowFilters(false)}
                // resetHandler={resetHandler}
              />
              <BenefitPackInfoTable
                data={benefitPackInfo?.results ?? []}
                totalData={benefitPackInfo?.count}
                pageNumber={currentPageNumber}
                dataStartedFrom={currentPageIndex}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                onCreateBenefitPack={createBenefitPackBtnHandler}
                filterBtnHandler={() => {
                  setIsShowFilters(true);
                }}
              />
            </>
          )}

          {benefitPackTableShow && (
            <>
              <BreadCrumb
                title="Benefits"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Benefit Management`, `Information`]}
              />
              <BenefitPacksTable
                data={benefitPacks?.results ?? []}
                totalData={benefitPacks?.count}
                pageNumber={currentPageNumberSecondary}
                dataStartedFrom={currentPageIndexSecondary}
                pageHandler={paginationHandlerSecondary}
              />
            </>
          )}

          {isCreateBenefitPackFormShow && (
            <>
              <BreadCrumb
                title="Benefits"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Benefit Management`, currentSelectedDetails ? `Update` : `Create`]}
              />

              <BenefitPackForm
                data={{ benefitGroups, clients }}
                onSubmit={currentSelectedDetails ? BenefitPackSubmitHandler : BenefitPackSubmitHandler}
                currentSelectedDetails={currentSelectedDetails}
              />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default BenefitPacksManagement;
