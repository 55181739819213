import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { GET_USER_DETAILS, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getUserDetails({ payload }) {
  let headers = {
    ...customHeaders,
  };

  console.log('Constant.PROJECT_CODE', Constant.PROJECT_CODE, localStorage.getItem('bankCode'));

  if (Constant.PROJECT_CODE === `ALRAJHI_LANDMARK` || Constant.PROJECT_CODE === `SAB_LANDMARK` || Constant.PROJECT_CODE === `JIZAN_UNIVERSITY`) {
    const selectedBankCode = localStorage.getItem('bankCode');
    if (selectedBankCode !== null) {
      headers['Project-Code'] = selectedBankCode;
    }
  }

  console.log('Constant.PROJECT_CODE-1', headers);

  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/v1/search`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...headers,
      },
      params: {
        ...payload?.urlParam,
      },
    });

    // yield put({
    //   type: GET_USER_DETAILS_SUCCESS,
    //   payload: {
    //     message: 'User searched successfully.',
    //     status: 'success',
    //     code: 2025,
    //     totalCount: 1,
    //     users: [
    //       {
    //         userId: '79de5f71-1f79-44da-97eb-7f38660fffc8',
    //         firstName: 'Rahul Kumar',
    //         email: 'rahul.karn@thriwe.com',
    //       },
    //     ],
    //   },
    // });

    if (response?.status === 200) {
      yield put({
        type: GET_USER_DETAILS_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(`${response?.response?.data?.message}`);
      yield put({
        type: GET_USER_DETAILS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_USER_DETAILS_ERROR, payload: error });
  }
}

export function* watchGetUserDetails() {
  yield takeEvery(GET_USER_DETAILS, getUserDetails);
}

function* getUserDetailsSaga() {
  yield all([fork(watchGetUserDetails)]);
}

export default getUserDetailsSaga;
