import { useEffect } from 'react';

import moment from 'moment';
import { Card, CardBody, Col, Row, Container, Label } from 'reactstrap';

import { Constant } from '../../../../../Helpers/constant';

const BookingDetails = ({ details, bookingTypeID }) => {
  let cancelledBy = `NA`;
  let cancelledReason = `NA`;
  let cancelledAt = `NA`;
  details?.bookingModifyPersons?.forEach(element => {
    if (
      element?.bookingStatus === Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE ||
      element?.bookingStatus === Constant?.BOOKING_STATUS?.CANCEL_BY_USER ||
      element?.bookingStatus === Constant.BOOKING_STATUS.SHORT_NOTICE_CANCELLED_BY_THRIWE ||
      element?.bookingStatus === Constant.BOOKING_STATUS.BOOKING_SHORTNOTICE_CANCELED_BY_THRIWE_WITH_QUOTA
    ) {
      cancelledBy = element?.name;
      cancelledReason = element?.remarks;
      cancelledAt = moment(`${element?.modifiedAt}`).format('DD-MM-YYYY, h:mm A');
    }
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      {/* <button onClick={() => console.log(bookingDetails)}>Get state</button> */}
      <Row>
        <Col lg={12}>
          <Card className="mt-n4 mx-n4 mb-n5">
            <div className="bg-soft-warning">
              <CardBody className="pb-4 mb-5">
                <Row>
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md-auto mb-1 mt-4"></div>
                      <div className="col-md">
                        <h4 className="fw-semibold" id="ticket-title">
                          Booking Id - <span className="">{details?.bookingId || ''}</span>
                        </h4>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Personal Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Full Name'}</Label>
                    <Label className="text-muted">{details?.user?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Email address'}</Label>
                    <Label className="text-muted">{details?.user?.email || `NA`}</Label>
                  </div>
                </Col>

                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Mobile Number'}</Label>
                    <Label className="text-muted">{details?.user?.mobile || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Creation and Service</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Date of request'}</Label>
                    <Label className="text-muted">{moment(`${details?.createdAt}`).format('DD-MM-YYYY, h:mm A') || `NA`}</Label>
                  </div>
                </Col>

                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Pick up Date'}</Label>
                    <Label className="text-muted">
                      {details?.dateTimeOfService?.date ? moment(`${details?.dateTimeOfService?.date}`).format('DD-MM-YYYY') : 'NA'}
                    </Label>
                  </div>
                </Col>

                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Pick up Time'}</Label>
                    <Label className="text-muted">
                      {details?.dateTimeOfService?.time ? moment(`${details?.dateTimeOfService?.time}`, 'HH:mm').format('h:mm A') : `NA`}
                    </Label>
                  </div>
                </Col>

                {/* {bookingTypeID === 6 && (
                  <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Pick up Time'}</Label>
                      <Label className="text-muted">{details?.noOfBags || `NA`}</Label>
                    </div>
                  </Col>
                )} */}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {details?.guest?.length > 0 && (
        <Row>
          <Col xxl={12}>
            <Card>
              <CardBody className="p-4">
                <h6 className="fw-semibold text-uppercase mb-3">Guest Details</h6>
                <div className="border mt-2 mb-3 border"></div>

                {details?.guest?.length > 0 &&
                  details?.guest?.map((person, index) => (
                    <Row key={index}>
                      <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <div className="mb-3">
                          <Label className="d-flex">{`Guest Name`}</Label>
                          <Label className="text-muted">{person?.name || `NA`}</Label>
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <div className="mb-3">
                          <Label className="d-flex">{`Guest Phone`}</Label>
                          <Label className="text-muted">{person?.phone || `NA`}</Label>
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <div className="mb-3">
                          <Label className="d-flex">{`Guest Email`}</Label>
                          <Label className="text-muted">{person?.email || `NA`}</Label>
                        </div>
                      </Col>
                    </Row>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      {bookingTypeID === 5 && (
        <Row>
          <Col xxl={12}>
            <Card>
              <CardBody className="p-4">
                <h6 className="fw-semibold text-uppercase mb-3">PASSENGERS &amp; LUGGAGES</h6>
                <div className="border mt-2 mb-3 border"></div>

                <Row>
                  <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label className="d-flex">{'No. of Passengers'}</Label>
                      <Label className="text-muted">{details?.numberOfPassengers?.toString() || `NA`}</Label>
                    </div>
                  </Col>

                  <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label className="d-flex">{'No. of Baby Seats'}</Label>
                      <Label className="text-muted">{details?.numberOfBabySeats?.toString() || `NA`}</Label>
                    </div>
                  </Col>

                  <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label className="d-flex">{'No. of Luggages'}</Label>
                      <Label className="text-muted">{details?.numberOfLuggages?.toString() || `NA`}</Label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      {bookingTypeID === 6 && (
        <Row>
          <Col xxl={12}>
            <Card>
              <CardBody className="p-4">
                <h6 className="fw-semibold text-uppercase mb-3">Flight details</h6>
                <div className="border mt-2 mb-3 border"></div>

                <Row>
                  <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Airport Name'}</Label>
                      <Label className="text-muted">{details?.airport?.name || `NA`}</Label>
                    </div>
                  </Col>

                  {bookingTypeID === 6 && (
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label className="d-flex">{'Class Of Travel'}</Label>
                        <Label className="text-muted">{details?.classOfTravel?.type || `NA`}</Label>
                      </div>
                    </Col>
                  )}

                  <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Flight Number'}</Label>
                      <Label className="text-muted">{details?.flightNumber || `NA`}</Label>
                    </div>
                  </Col>

                  <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Terminal'}</Label>
                      <Label className="text-muted">{details?.terminalNumber || `NA`}</Label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      {bookingTypeID === 5 && (
        <Row>
          <Col xxl={12}>
            <Card>
              <CardBody className="p-4">
                <h6 className="fw-semibold text-uppercase mb-3">Address</h6>
                <div className="border mt-2 mb-3 border"></div>

                <Row>
                  <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Pick up address'}</Label>
                      <Label className="text-muted">{details?.pickupLocation?.address || `NA`}</Label>
                    </div>
                  </Col>

                  <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Drop Address'}</Label>
                      <Label className="text-muted">
                        {`${details?.dropLocation?.address}, ${details?.dropLocation?.region}, ${details?.dropLocation?.emirates}` || `NA`}
                      </Label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      {/* <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Chauffeur details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Chauffeur Name'}</Label>
                    <Label className="text-muted">{details?.chauffer?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Chauffeur Mobile Number'}</Label>
                    <Label className="text-muted">{details?.chauffer?.mobile || `NA`}</Label>
                  </div>
                </Col>

                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Car Number'}</Label>
                    <Label className="text-muted">{details?.chauffer?.carNumber || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> */}

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Remarks and Reason</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Remarks'}</Label>
                    <Label className="text-muted">{details?.remarks || `NA`}</Label>
                  </div>
                </Col>

                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Reason for cancellation'}</Label>
                    <Label className="text-muted">{cancelledReason}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BookingDetails;
