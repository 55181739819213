import { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';

import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';

import useCountdownTimer from './CountDownTimerHook';

const VerificationOTP = ({ data, loading, isResendLoader, onClose, onConfirm, onResend }) => {
  const { t } = useTranslation();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [otp, setOtp] = useState('');
  const [timeLeft, startTimer] = useCountdownTimer(120);

  useEffect(() => {
    if (isResendLoader === false) {
      startTimer(120);
    }
  }, [isResendLoader]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const handleChange = value => {
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const onSubmit = () => {
    onConfirm(otp);
  };

  /* ---------------------------- ON RESEND HANDLER ---------------------------- */
  const onResendHandler = () => {
    onResend();
  };

  console.log('data-1', data);

  return (
    <Modal isOpen={true}>
      <ModalHeader
        className="bg-soft-secondary p-3"
        toggle={() => {
          onClose();
        }}
      >
        {t(`OTP Verification`)}
      </ModalHeader>

      <ModalBody>
        {t(`OTP_MESSAGE`)}
        {/* <span className="fw-semibold my-2">&nbsp;{`${data?.countryCode && `${data?.countryCode}-`}${data?.phoneNumber ?? ''}`}</span> */}
        <div className="d-flex justify-content-center">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            renderInput={(props, index) => (
              <input
                {...props}
                onKeyPress={e => {
                  if (!/^\d$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            )}
            inputStyle={{
              padding: '5px',
              margin: '10px 10px',
              alignItems: 'center',
              textAlign: 'center',
              height: '40px',
              width: '40px',
              color: 'black',
              borderRadius: '5px',
              backgroundColor: 'transparent',
              border: '1px solid #E1E1E1',
              outline: 'none',
              background: '#FFFFFF',
              gap: '10px',
            }}
          />
        </div>

        <div className="mt-12 d-flex align-items-center justify-content-between">
          <div> {t(`Didn’t get the code?`)} &nbsp;</div>

          {timeLeft > 0 ? (
            <div>{`Resend Otp in ${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}`}</div>
          ) : (
            <button className="btn btn-link cursor-pointer text-center" onClick={onResendHandler} type="button">
              <span className="text-decoration-underline">{t(`Resend Otp`)}</span>
            </button>
          )}
        </div>
      </ModalBody>

      <ModalFooter className="pb-0">
        <div className="modal-footer mx-auto">
          {loading ? (
            <Button color="primary" className="btn-load" outline>
              <span className="d-flex align-items-center">
                <Spinner size="sm" className="flex-shrink-0">
                  Loading...
                </Spinner>
                <span className="flex-grow-1 ms-2">Loading...</span>
              </span>
            </Button>
          ) : (
            <Button disabled={otp?.length === 6 ? false : true} className="btn btn-primary" color="primary" onClick={onSubmit}>
              {t(`Verify OTP`)}
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default VerificationOTP;
