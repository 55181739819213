import axios from 'axios';
import { Constant } from './constant';
import { PermissionConstants } from './Constants/permissions';
import jwt_decode from 'jwt-decode';

export const post_req = async (endPoint, data, header) => {
  return axios({
    method: 'POST',
    url: endPoint,
    data: data,
    headers: header,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (err) {
      return err;
    });
};

export const get_req = async (endPoint, header) => {
  return axios({
    method: 'get',
    url: endPoint,
    headers: header,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (err) {
      return err;
    });
};

export const validateEmail = email => {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const extractFileName = disposition => {
  let filename = '';
  if (disposition && disposition.indexOf('attachment') !== -1) {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
      filename = decodeURI(filename);
    }
  }
  return filename;
};

export const hasPermission = permissionName => {
  return Constant?.CURRENTLY_ASSIGNED_PERMISSIONS?.includes(`${PermissionConstants?.ALL_PERMISSIONS[permissionName]}`);
};

export const doNotAllowSpecialsCharacters = text => {
  if (text === ``) {
    return true;
  } else {
    let regex = new RegExp('^[0-9a-zA-Z \b]+$');
    return regex.test(text);
  }
};

export const removeSpaceToUnderscoreAndCaps = text => {
  return text?.toUpperCase()?.replaceAll(` `, `_`);
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isValidUrl = string => {
  if (string === ``) {
    return true;
  } else {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      string,
    );
  }
};

export const validateUrl = string => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    string,
  );
};

export const isValidPassword = string => {
  return /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/m.test(string);
};

export const isValidEmail = string => {
  if (string === ``) {
    return true;
  } else {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      string,
    );
  }
};

export const isCorrectEmail = string => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    string,
  );
};

export const isValidAplha = string => {
  if (string === ``) {
    return true;
  } else {
    return /^[A-Za-z ]+$/.test(string);
  }
};

export const isValidAplhaWithSpecialCharacter = string => {
  if (string === ``) {
    return true;
  } else {
    return /^[a-zA-Z _/]+$/.test(string);
  }
};

export const isValidNumber = string => {
  if (string === ``) {
    return true;
  } else {
    return /^[0-9]+$/.test(string);
  }
};

export const isValidAlphaNumbericWithoutSpace = string => {
  if (string === ``) {
    return true;
  } else {
    return /^[a-z0-9]+$/i.test(string);
  }
};

export const isValidAlphaNumberic = string => {
  if (string === ``) {
    return true;
  } else {
    return /^[a-zA-Z0-9\s]+$/.test(string);
  }
};

export const isValidAlphaWithSpec = string => {
  if (string === ``) {
    return true;
  } else {
    return /^[a-zA-Z0-9_._*%{}",'@/:;&\()-\s]+$/.test(string);
  }
};

export const isValidTextWithUnderscore = text => {
  return /^[a-zA-Z0-9_]+$/.test(text);
};

export const isANumber = n => {
  let numStr = /^-?(\d+\.?\d*)$|(\d*\.?\d+)$/;
  let containsNonNumeric = /\D/.test(n);
  return numStr.test(n.toString()) && !containsNonNumeric;
};

export const isANumberWithDecimal = str => {
  return /^\d*\.?\d*$/.test(str);
};

export const isDecimalNumber = num => {
  return num !== parseInt(num, 10);
};

export const validateNumber = str => {
  const regex = /^(?=.*\d)\d*(?:\.\d+)?$/;
  return regex.test(str);
};

export const removeEmptyAndNullValues = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (value !== '' && value !== null) {
      if (Array.isArray(value)) {
        const filteredArray = value
          .map(item => {
            if (typeof item === 'object') {
              return removeEmptyAndNullValues(item);
            }
            return item;
          })
          .filter(item => {
            if (typeof item === 'object') {
              return Object.keys(item).length !== 0;
            }
            return item !== '' && item !== null;
          });
        if (filteredArray.length !== 0) {
          acc[key] = filteredArray;
        }
      } else if (typeof value === 'object') {
        const nested = removeEmptyAndNullValues(value);
        if (Object.keys(nested).length !== 0) {
          acc[key] = nested;
        }
      } else {
        acc[key] = value;
      }
    }
    return acc;
  }, {});
};

export const getUserType = () => {
  return localStorage.getItem('userType') ?? -1;
};

export const isDatePassed = (data = '') => {
  if (data) {
    return new Date()?.getTime() > new Date(data)?.getTime();
  } else {
    return false;
  }
};

export const findGMUserLevel = featureToFetch => {
  const currentFeature = featureToFetch;
  let currentLevel = null;
  // const levelPermissions = ['SUPPLY-DATA_L1', 'CLIENT-DATA_L2'];
  const levelPermissions = jwt_decode(JSON.parse(localStorage.getItem('authUser'))?.token)?.tags?.levels;

  const fetchedPermissionsList = levelPermissions?.filter(permission => permission?.includes(`${currentFeature}`));

  if (fetchedPermissionsList?.length > 0) {
    currentLevel = fetchedPermissionsList?.[0]?.split('_')?.[1];
  } else {
    return null;
  }

  return currentLevel;
};

export const isAllowedGmUserToAction = (tData, userLevel) => {
  if (userLevel === `L1`) {
    return tData?.uploadBy;
  } else if (userLevel === `L2`) {
    return tData?.firstApprover;
  } else if (userLevel === `L3`) {
    return tData?.secondApprover;
  } else {
    return null;
  }
};
export const isDateWithinLast7Days = (date = new Date()) => {
  const currentDate = new Date();
  const pastDate = new Date();
  pastDate.setDate(currentDate.getDate() - 7);
  return date >= pastDate && date <= currentDate;
};

export const isDateIsGreaterThanLast7Days = (date = new Date()) => {
  if (new Date()?.getTime() < new Date(date)?.getTime()) {
    return false;
  } else if (isDateWithinLast7Days(new Date(date))) {
    return false;
  } else {
    return true;
  }
};

export const isTimePassed = (givenDate, givenTime) => {
  // Combine the given date and time into a single string
  const givenDateTimeString = `${givenDate} ${givenTime}`;

  // Create a Date object from the given date and time
  const givenDateTime = new Date(givenDateTimeString);

  // Get the current date and time
  const currentDateTime = new Date();

  // Compare the two dates
  return givenDateTime < currentDateTime;
};

export const formattedWithComma = (number = '') => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const validateApplicationReferenceNumber = number => {
  const pattern = /^CN-6822025(00[1-9]|0[1-9][0-9]|[1-2][0-9][0-9]|3[0-5][0-9]|36[0-6])\d{7}$/;
  return pattern.test(number);
};

export const getUserName = () => {
  let userName = ``;
  userName = JSON.parse(localStorage.getItem('authUser'))?.data?.usname?.split(' ')?.[0];
  return userName;
};

export const getUserV2Name = () => {
  let userName = ``;
  userName = JSON.parse(localStorage.getItem('authUserV2'))?.data?.name?.split(' ')?.[0];
  return userName;
};
