import React, { useEffect, useState } from 'react';
import Header from '../Containers/Common/Header';
import Footer from '../Containers/Common/Footer';
import Sidebar from './Sidebar';

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarImageType,
} from '../store/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';
import cogoToast from 'cogo-toast';

const NormalLayout = props => {
  const [headerClass, setHeaderClass] = useState('');

  const dispatch = useDispatch();
  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    fetchActivity,
  } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
    leftSidebarType: state.Layout.leftSidebarType,
    layoutModeType: state.Layout.layoutModeType,
    layoutWidthType: state.Layout.layoutWidthType,
    layoutPositionType: state.Layout.layoutPositionType,
    topbarThemeType: state.Layout.topbarThemeType,
    leftsidbarSizeType: state.Layout.leftsidbarSizeType,
    leftSidebarViewType: state.Layout.leftSidebarViewType,
    leftSidebarImageType: state.Layout.leftSidebarImageType,

    fetchActivity: state.FetchActivity.data,
  }));

  console.log('fetchActivity', fetchActivity);

  /*
      layout settings
      */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType
    ) {
      dispatch(changeLeftsidebarViewType(leftSidebarViewType));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      dispatch(changeSidebarTheme(leftSidebarType));
      dispatch(changeLayoutMode(layoutModeType));
      dispatch(changeLayoutWidth(layoutWidthType));
      dispatch(changeLayoutPosition(layoutPositionType));
      dispatch(changeTopbarTheme(topbarThemeType));
      dispatch(changeLayout(layoutType));
      dispatch(changeSidebarImageType(leftSidebarImageType));
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    dispatch,
  ]);

  // Main Area Loader
  const { mainAreaLoader } = useSelector(state => ({
    mainAreaLoader: state?.MainAreaLoader?.loading,
  }));

  // Full Screen Loader
  const { fullScreenLoader } = useSelector(state => ({
    fullScreenLoader: state?.FullScreenLoader?.loading,
  }));

  /*
      call dark/light mode
      */
  const onChangeLayoutMode = value => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };

  // class add remove in header
  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass('topbar-shadow');
    } else {
      setHeaderClass('');
    }
  }

  return (
    <React.Fragment>
      <div
        id="layout-wrapper"
        onClick={() => {
          if (fetchActivity?.activityStateEnum === 'INACTIVE' || fetchActivity?.activityStateEnum === 'ON_BREAK') {
            cogoToast.info('Please mark yourself as Active in order to work on any booking');
          }
        }}
      >
        <Header headerClass={headerClass} />
        <div
          style={{ pointerEvents: (fetchActivity?.activityStateEnum === 'INACTIVE' || fetchActivity?.activityStateEnum === 'ON_BREAK') && 'none' }}
        >
          <Sidebar layoutType={'V2'} />
          <div
            className="main-content d-flex flex-column"
            style={{
              minHeight: '100vh',
            }}
          >
            {props.children}
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NormalLayout;
