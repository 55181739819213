import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweDiscoveryUrl } from '../../../../../Containers/Config';
import { Constant } from '../../../../../Containers/Helpers/constant';
import { RULE_DETAILS, RULE_DETAILS_ERROR, RULE_DETAILS_SUCCESS } from '../../../actionType';
import apiCall from '../../../apiCall';

function* getRuleDetails({ payload }) {
  console.log('payloaddd', payload);
  try {
    const response = yield call(apiCall, {
      url: `${thriweDiscoveryUrl}/v1/admin/rules/${payload?.data?.ruleId}`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: RULE_DETAILS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: RULE_DETAILS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: RULE_DETAILS_ERROR, payload: error });
  }
}

export function* watchgetRuleDetails() {
  yield takeEvery(RULE_DETAILS, getRuleDetails);
}

function* getRuleDetailsSaga() {
  yield all([fork(watchgetRuleDetails)]);
}

export default getRuleDetailsSaga;
