import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

//import logo
import logoSm from '../assets/images/logo-sm.png';
import logoDark from '../assets/images/logo-dark.png';
import logoLight from '../assets/images/logo-light.png';
import logodark1 from '../assets/custom/thriwe-white-logo.png';
import logoLight1 from './../assets/custom/logo-thriwe-light.png';
import SidebarOverlay from './../assets/custom/SidebarOverlay.png';

//Import Components
import VerticalLayout from './VerticalLayouts';
import TwoColumnLayout from './TwoColumnLayout';
import { Container } from 'reactstrap';
import HorizontalLayout from './HorizontalLayout';
import { useLocation, useParams } from 'react-router-dom';
import { Constant } from '../Containers/Helpers/constant';
import VerticalLayoutsV2 from './VerticalLayoutsV2';

const Sidebar = ({ layoutType }) => {
  const location = useLocation();
  const [projectUtility, setProjectUtility] = useState(JSON.parse(localStorage.getItem('projectUtility')));
  const [isClientType, setIsClientType] = useState(false);
  // let value = { color: '#3C454B' };

  // root.style.setProperty('--vz-vertical-menu-bg-dark', `#405189`);
  useEffect(() => {
    const isClientType = location?.pathname?.includes('/client/') ?? false;

    setIsClientType(isClientType);
    const root = document.documentElement;
    if (projectUtility) {
      root.style.setProperty('--vz-vertical-menu-bg-dark', `url('${SidebarOverlay}')`);
    }
  }, []);

  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName('vertical-overlay');
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener('click', function () {
        document.body.classList.remove('vertical-sidebar-enable');
      });
    }
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
    } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    } else {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    }
  };

  const getRedirectionURL = () => {
    let redirectionRoute = `/`;
    const userType = parseInt(JSON.parse(window.localStorage.getItem('userType') ?? -1));

    if (userType === Constant?.userTypes?.ADMIN_USER) {
      redirectionRoute = `/dashboard`;
    } else if (userType === Constant?.userTypes?.CLIENT) {
      redirectionRoute = `/client/dashboard`;
    } else if (userType === Constant?.userTypes?.VENDOR) {
      redirectionRoute = `/vendor/dashboard`;
    } else if (userType === Constant?.userTypes?.VENDOR_USER_V2) {
      redirectionRoute = `/v2/vendor-dashboard`;
    } else if (userType === Constant?.userTypes?.ADMIN_USER_V2) {
      redirectionRoute = `/v2/dashboard`;
    } else {
      redirectionRoute = `/`;
    }
    return redirectionRoute;
  };

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <Link to={getRedirectionURL()} className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoSm} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logodark1} alt="" height="17" style={{ position: 'relative', width: '110px', height: '42px' }} />
            </span>
          </Link>

          <Link to={getRedirectionURL()} className="logo logo-light">
            <span className="logo-sm">{/* <img src={logoSm} alt="" height="22" />   */}</span>
            <span className="logo-lg">
              {projectUtility && Constant.PROJECT_CODE !== 'ADIB_V2' ? (
                <img
                  src={projectUtility?.results[0]?.images?.logo}
                  alt=""
                  height="17"
                  style={{ position: 'relative', width: '100%', height: '42px' }}
                />
              ) : (
                <img src={logoLight1} alt="" height="17" style={{ position: 'relative', width: '110px', height: '42px' }} />
              )}
            </span>
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        {layoutType === 'horizontal' ? (
          <div id="scrollbar">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <HorizontalLayout />
              </ul>
            </Container>
          </div>
        ) : layoutType === 'twocolumn' ? (
          <TwoColumnLayout layoutType={layoutType} />
        ) : layoutType === 'V2' ? (
          <SimpleBar id="scrollbar" className="h-100">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <VerticalLayoutsV2 layoutType={layoutType} />
              </ul>
            </Container>
          </SimpleBar>
        ) : (
          <SimpleBar id="scrollbar" className="h-100">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <VerticalLayout layoutType={layoutType} />
              </ul>
            </Container>
          </SimpleBar>
        )}
        <div className="sidebar-background"></div>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
