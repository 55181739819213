import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, Container, Row, Button, CardHeader } from 'reactstrap';
import BreadcrumbTrail from '../../../Common/BreadcrumbTrail';
import TabularView from './Components/Views/TabularView';
import StatusFilter from './Components/Filter/StatusFilter';
import { GET_ACTION_BY_STATUS, GET_REASON_BY_ACTIONS, REMARK_STATUS, UPDATE_REASON_EMPTY } from '../../../../store/actions';
import AddRemarks from './Components/Modals/RemarksModal';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { Constant } from '../../../Helpers/constant';
import { Loader } from '../../../Common';

const DynamicReason = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { skip = 1, limit = Constant.TABLE_PAGE_SIZE } = queryString.parse(location.search);

  const [customActiveTab, setCustomActiveTab] = useState(null);
  const [isCancelModalShow, setIsCancelModalShow] = useState(false);
  const [appliedName, setAppliedName] = useState(null);
  const [appliedAction, setAppliedAction] = useState(null);
  const [dynamicTabs, setDynamicTabs] = useState([]); // Store API-based tabs
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(Number(skip) - 1);
  const [rowsPerPage, setRowsPerPage] = useState(Number(limit));
  const [currentSelectedReason, setCurrentSelectedReason] = useState(null);
  const [laoding, setLaoding] = useState(false);

  const { tabStatus, tabStatusLoading, getActionLoading, getAction, getReason, getReasonLoading, addReason, updateReason, updateReasonLoading } =
    useSelector((state: any) => ({
      tabStatus: state.RemarkStatusDetails.data,
      tabStatusLoading: state.RemarkStatusDetails.loading,

      getAction: state.GetActionByStatus.data,
      getActionLoading: state.GetActionByStatus.loading,

      getReason: state.GetReasonByActions.data,
      getReasonLoading: state.GetReasonByActions.loading,

      addReason: state?.AddReason?.data,

      updateReason: state?.UpdateReason?.data,
      updateReasonLoading: state?.UpdateReason?.loading,
    }));

  useEffect(() => {
    dispatch({
      type: REMARK_STATUS,
      payload: { urlParams: { booking_type: 'airport_transfers' } },
    });
  }, [dispatch]);

  useEffect(() => {
    if (tabStatus) {
      const defaultRemark = tabStatus?.remarks?.find(remark => remark.is_default);
      setAppliedName(defaultRemark);
    }
  }, [tabStatus]);

  useEffect(() => {
    setCurrentPageNumber(Number(skip) - 1);
  }, [skip]);

  useEffect(() => {
    setRowsPerPage(Number(limit));
  }, [limit]);

  useEffect(() => {
    if (appliedName) {
      dispatch({
        type: GET_ACTION_BY_STATUS,
        payload: { urlParams: { booking_type: 'airport_transfers', booking_status: appliedName?.id } },
      });
    }
  }, [appliedName, dispatch]);

  useEffect(() => {
    if (getAction) {
      setDynamicTabs(getAction.actions || []); // Store API-based tabs
      const defaultAction = getAction.actions?.find(action => action.is_default);
      setAppliedAction(defaultAction);
      if (defaultAction) {
        setCustomActiveTab(defaultAction.id.toString()); // Set default tab dynamically
      }
    }
  }, [getAction]);

  useEffect(() => {
    if (appliedAction) {
      dispatch({
        type: GET_REASON_BY_ACTIONS,
        payload: {
          urlParams: {
            booking_type: 'airport_transfers',
            booking_status: appliedName?.id,
            action: appliedAction?.id,
            page_size: limit,
            page_index: Number(skip) - 1,
          },
        },
      });
      dispatch({
        type: UPDATE_REASON_EMPTY,
      });
    }
  }, [appliedAction, appliedName, dispatch, addReason, updateReason, skip, limit]);

  const toggleCustom = tabId => {
    if (customActiveTab !== tabId) {
      setCustomActiveTab(tabId);
    }
  };

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);

    const updatedParams = {
      ...currentParams,
      ...newParams,
    };

    const newUrl = queryString.stringify(updatedParams);

    console.log('pathname', location.pathname);
    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  const tableActionHandler = (label, data) => {
    console.log('tableActionHandler', label, data);
  };

  const handleRowsPerPageChange = newRowsPerPage => {
    updateURLParams({ skip: 1, limit: newRowsPerPage });
  };

  const paginationHandler = pageNumber => {
    updateURLParams({ skip: pageNumber + 1, limit: limit });
  };

  useEffect(() => {
    if (tabStatusLoading || getActionLoading || getReasonLoading || updateReasonLoading) {
      setLaoding(true);
    } else {
      setLaoding(false);
    }
  }, [tabStatusLoading, getActionLoading, getReasonLoading, updateReasonLoading]);

  return (
    <div className="page-content">
      {laoding && <Loader />}
      <Container fluid>
        <BreadcrumbTrail title="Dynamic Reason" navigationby={() => {}} navigation={['Dynamic Reason', 'Airport Transport']} />
        {isModalOpen && (
          <AddRemarks
            onCloseHandler={() => {
              setIsModalOpen(false);
            }}
            appliedName={appliedName}
            appliedAction={appliedAction}
            data={currentSelectedReason}
          />
        )}
        <StatusFilter
          statusFilters={tabStatus?.remarks || []}
          setAppliedName={setAppliedName}
          appliedName={appliedName}
          setAppliedAction={setAppliedAction}
          updateURLParams={updateURLParams}
        />

        <Card>
          <CardHeader>
            <h4>{appliedName?.name}</h4>
          </CardHeader>

          <CardBody>
            {(dynamicTabs ?? [])?.length > 0 ? (
              <Nav tabs className="nav-tabs-custom nav-success mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                <CardHeader className="d-flex justify-content-end p-0 dynamic-reasons-tab-container">
                  <div className="custom-tabs-container">
                    {dynamicTabs?.map((tab, index) => {
                      return (
                        <div
                          className={`custom-tab ${customActiveTab === tab.id.toString() ? 'active' : ''}`}
                          key={index}
                          onClick={() => {
                            toggleCustom(tab.id.toString());
                            setAppliedAction(tab);
                            updateURLParams({ skip: 1, limit: Constant.TABLE_PAGE_SIZE });
                          }}
                        >
                          {tab?.name}
                        </div>
                      );
                    })}
                  </div>
                </CardHeader>

                <div style={{ marginLeft: 'auto', padding: '10px' }}>
                  <Button
                    color="primary"
                    onClick={() => {
                      setIsModalOpen(true);
                      setCurrentSelectedReason(null);
                    }}
                  >
                    Add Reason
                  </Button>
                </div>
              </Nav>
            ) : (
              <CardBody className="text-center fw-normal">No Action Found</CardBody>
            )}

            <TabContent activeTab={customActiveTab} className="text-muted">
              {dynamicTabs.map(tab => (
                <TabPane key={tab.id} tabId={tab.id.toString()}>
                  <TabularView
                    data={getReason?.reasons}
                    totalCount={getReason?.total_count}
                    skip={currentPageNumber}
                    limit={rowsPerPage}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    handlePageChange={paginationHandler}
                    actionBtnHandler={tableActionHandler}
                    setCurrentSelectedBooking={setCurrentSelectedReason}
                    isTableDisplay={true}
                    setIsModalOpen={setIsModalOpen}
                  />
                </TabPane>
              ))}
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default DynamicReason;
