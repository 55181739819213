import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import Countdown from 'react-countdown';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, CardFooter, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../../../Common';
import { Constant } from '../../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../../Helpers/Constants/permissions';
import { formattedWithComma, hasPermission, isDateIsGreaterThanLast7Days } from '../../../../../../../Helpers/Helpers';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Bookings');
  const [currentFilterBookingStatus, setCurrentFilterBookingStatus] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [columns, setColumns] = useState([]);
  const [clearRow, setClearRow] = useState(false);

  console.log('Data', data);
  /* ---------------------------- REDUX STATES ---------------------------- */
  // Update Booking
  const { updateBookings } = useSelector(state => ({
    updateBookings: state?.UpdateBooking?.data,
  }));

  // Resend Mail
  const { resendMail } = useSelector(state => ({
    resendMail: state?.ResendMail?.data,
  }));

  useEffect(() => {
    getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));
    if (props?.data?.length > 0) {
      setBookingData(
        props?.data?.map(booking => {
          return { ...booking };
        }),
      );
    } else {
      setData([]);
      setBookingData([]);
    }
    setCurrentFilterBookingStatus(props?.appliedFilters?.statusRange || []);
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      const fetchColmuns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => row?.serialNumber,
        },
        {
          name: 'Booking Id',
          width: '140px',
          cell: row => (
            <span
              className="cursor-pointer text-decoration-underline"
              style={{ color: '#3577f1' }}
              onClick={() => actionBtnHandler(`VIEW`, row?.originalData)}
            >
              {row?.booking_id}
            </span>
          ),
        },
        {
          name: 'Name',
          width: '150px',
          wrap: true,
          selector: row => row?.userName,
        },
        {
          name: 'Mobile No',
          width: '130px',
          wrap: true,
          selector: row => row?.userMobile,
        },
        {
          name: 'Email',
          wrap: true,
          selector: row => row?.userEmail,
          width: '250px',
        },
        {
          name: 'Terminal Name',
          width: '150px',
          wrap: true,
          selector: row => row?.terminalName,
        },

        {
          name: 'Terminal Number',
          width: '150px',
          wrap: true,
          selector: row => row?.terminalNumber,
        },
        {
          name: 'Pickup Date',
          width: '150px',
          wrap: true,
          selector: row => row?.pickupDate,
        },

        {
          name: 'Pickup Time',
          width: '150px',
          wrap: true,
          selector: row => row?.pickupTime,
        },
        {
          name: 'User Type',
          width: '100px',
          wrap: true,
          selector: row => row?.bookingForSomeoneElse,
        },
        {
          name: 'Benefit Type',
          width: '150px',
          wrap: true,
          selector: row => row?.benefitType,
        },
        {
          name: 'Booking Type',
          width: '240px',
          wrap: true,
          selector: row => row?.bookingType,
        },
        {
          name: 'Partner',
          width: '160px',
          wrap: true,
          selector: row => row?.partnerName,
        },
        {
          name: 'Service',
          width: '200px',
          wrap: true,
          selector: row => row?.serviceName,
        },
        {
          name: 'Payment ID',
          width: '200px',
          wrap: true,
          selector: row => row?.paymentId,
        },
        {
          name: 'Membership ID',
          width: '160px',
          wrap: true,
          selector: row => row?.membershipId,
        },
        {
          name: 'Confirmed At',
          width: '170px',
          wrap: true,
          selector: row => row?.confirmedAt,
        },
        {
          name: 'Created At',
          width: '170px',
          wrap: true,
          selector: row => row?.createdAt,
        },
        {
          name: 'Updated At',
          width: '170px',
          wrap: true,
          selector: row => row?.updatedAt,
        },
        {
          name: 'Booking Status',
          width: '200px',
          cell: row => (
            <span
              className={`booking_status badge break-word bg-${
                Constant.BOOKING_STATUSES_V2.filter(status => status?.bookingStatus === row?.booking_status)[0]?.bgColor
              }`}
            >
              {row?.booking_status}
            </span>
          ),
        },
        {
          name: 'Actions',
          width: '250px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column m-4">
              <>
                {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_V2_UPDATE) &&
                  row?.originalData?.booking_status === Constant?.BOOKING_STATUS_V2.PENDING && (
                    <>
                      <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`PROCESS`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        // // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Process
                      </button>

                      <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        // // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Resend Email
                      </button>

                      <button
                        className="btn btn-sm btn-danger mb-1"
                        onClick={() => {
                          actionBtnHandler(`CANCEL`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        // // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Cancel
                      </button>
                    </>
                  )}

                {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_V2_UPDATE) &&
                  row?.originalData?.booking_status === Constant?.BOOKING_STATUS_V2.PROCESSED && (
                    <>
                      <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`CONFIRM`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        // // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Confirm
                      </button>

                      <button
                        className="btn btn-sm btn-danger mb-1"
                        onClick={() => {
                          actionBtnHandler(`CANCEL`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        // // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Cancel
                      </button>
                    </>
                  )}

                {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_V2_UPDATE) &&
                  row?.originalData?.booking_status === Constant?.BOOKING_STATUS_V2.CONFIRM && (
                    <>
                      <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`CHAUFFER ASSIGN`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Chauffeur Assign
                      </button>
                      <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Resend Email
                      </button>

                      <button
                        className="btn btn-sm btn-danger mb-1"
                        onClick={() => {
                          actionBtnHandler(`CANCEL`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Cancel
                      </button>
                    </>
                  )}

                {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_V2_UPDATE) &&
                  row?.originalData?.booking_status === Constant?.BOOKING_STATUS_V2.CHAUFFEUR_ASSIGN && (
                    <>
                      <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Resend Email
                      </button>

                      <button
                        className="btn btn-sm btn-danger mb-1"
                        onClick={() => {
                          actionBtnHandler(`CANCEL`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Cancel
                      </button>
                    </>
                  )}

                {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_V2_UPDATE) &&
                  row?.originalData?.booking_status === Constant?.BOOKING_STATUS_V2.CANCELLED_BY_THRIWE && (
                    <>
                      <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                        // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                      >
                        Resend Email
                      </button>
                    </>
                  )}
              </>
            </div>
          ),
        },
      ];
      setColumns(fetchColmuns);
      setData(
        bookingData?.map((data, index) => {
          return {
            serialNumber: `${props?.pageNumber * Constant.TABLE_PAGE_SIZE + index + 1 - Constant.TABLE_PAGE_SIZE}.`,
            index: index,
            booking_id: data?.booking_id || `NA`,
            userName: data?.user?.firstName || data?.user?.lastName ? `${data?.user?.firstName || ''} ${data?.user?.lastName}` : `NA`,
            userMobile: data?.user?.phoneNumber || `NA`,
            userEmail: data?.user?.email || `NA`,
            terminalName: data?.terminalName || `NA`,
            terminalNumber: data?.airport_booking_details?.[0]?.terminal_number || `NA`,
            pickupDate: data?.airport_booking_details?.[0]?.pickup_date
              ? moment(`${data?.airport_booking_details?.[0]?.pickup_date}`).format('DD-MM-YYYY')
              : 'NA',
            pickupTime: data?.airport_booking_details?.[0]?.pickup_time
              ? moment(`${data?.airport_booking_details?.[0]?.pickup_time}`, 'HH:mm').format('h:mm A')
              : `NA`,
            bookingForSomeoneElse: data?.is_booking_for_someone ? 'Someone' : 'Self',
            benefitType: data?.benefit_type || `NA`,
            bookingType: data?.booking_type || `NA`,
            partnerName: data?.booking_display?.benefitPartner?.name || 'NA',
            serviceName: data?.booking_display?.displayName || 'NA',
            paymentId: data?.payment?.paymentId || `NA`,
            membershipId: data?.membership_id || `NA`,

            createdAt: data?.created_at ? moment(new Date(`${data?.created_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            updatedAt: data?.updated_at ? moment(new Date(`${data?.updated_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            booking_status: data?.booking_status,
            originalData: data,
          };
        }),
      );
      setClearRow(!clearRow);
    } else {
      setData([]);
      setColumns([]);
    }
  }, [bookingData]);

  useEffect(() => {
    setSelectedBookings([]);
  }, [updateBookings, resendMail]);

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  useEffect(() => {
    setIsButtonDisabled(isTimePassed());
  }, [selectedBookings]);

  const isTimePassed = () => {
    for (let i = 0; i < selectedBookings.length; i++) {
      if (isDateIsGreaterThanLast7Days(selectedBookings[i]?.bookingDateTime)) {
        return true;
      }
    }
    return false;
  };

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    console.log('flag', flag);
    if (flag === `EDIT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `VIEW`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `PROCESS`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CANCEL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `RESEND_EMAIL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CHAUFFER ASSIGN`) {
      console.log('flag 2', flag);
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `ASSIGN_VENDOR`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === 'CHANGE_VENDOR') {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM_AIRPORT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `ASSIGN_VENDOR_AIRPORT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE_AIRPORT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CHAUFFER_ASSIGN_AIRPORT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{currentFilteredBookingTitle}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )}
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma((props?.pageNumber - 1) * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma((props?.pageNumber - 1) * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber - 1} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
