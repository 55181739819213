import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import Select from 'react-select';
import { Container, Row } from 'reactstrap';
import { GET_CARD_SUMMARY, GET_CARD_SUMMARY_EMPTY, GET_DASHBOARD_CONFIGURATION } from '../../../../store/actions';
import { DonutChart, Loader } from '../../../Common';
import BreadcrumbTrail from '../../../Common/BreadcrumbTrail';
import BookingCard from './components/Cards/BookingCard';
import BookingTimelineCard from './components/Cards/BookingTimelineCard';
import BookingTypeCard from './components/Cards/BookingTypeCard';
import TeamActivityCard from './components/Cards/TeamActivityCard';

const autoUpdateOptions = [
  { value: '1', label: '1 Minute' },
  { value: '5', label: '5 Minute' },
  { value: '20', label: '20 Minute' },
  { value: '60', label: '60 Minute' },
  { value: 'Never', label: 'Never' },
];

interface RootState {
  GetDashboardConfiguration?: {
    data?: any;
    loading?: boolean;
  };
  GetCardSummary?: {
    data?: any;
    loading?: boolean;
  };
}

const DashboardV2 = () => {
  const dispatch = useDispatch();
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Store interval reference

  const [loading, setLoading] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState<string>('Never'); // Track selected interval
  const [selectedCard, setSelectedCard] = useState(null);
  const [teamActivityCard, setTeamActivityCard] = useState(null);
  const [timeAggregationCard, setTimeAggregationCard] = useState([]);
  const [approvalPendingBookingCard, setApprovalPendingBookingCard] = useState(null);
  const [otherBookingCard, setOtherBookingCard] = useState(null);
  const [bookingCard, setBookingCard] = useState(null);
  const [fromApprovalPendingCard, setFromApprovalPendingCard] = useState(false);
  const [isModificationBooking, setIsModificationBooking] = useState(false);

  const { getDashboardConfiguration, getDashboardConfigurationLoading } = useSelector((state: RootState) => ({
    getDashboardConfiguration: state.GetDashboardConfiguration.data,
    getDashboardConfigurationLoading: state.GetDashboardConfiguration.loading,
  }));

  const { cardData, cardDataLoading } = useSelector((state: RootState) => ({
    cardData: state.GetCardSummary.data,
    cardDataLoading: state.GetCardSummary.loading,
  }));

  // Auto-update logic
  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear previous interval before setting a new one
    }

    if (selectedInterval !== 'Never') {
      let totalCards = [
        ...(Array.isArray(teamActivityCard) ? teamActivityCard : [teamActivityCard]),
        ...(Array.isArray(timeAggregationCard) ? timeAggregationCard : [timeAggregationCard]),
        ...(Array.isArray(approvalPendingBookingCard) ? approvalPendingBookingCard : [approvalPendingBookingCard]),
        ...(Array.isArray(bookingCard?.cards) ? bookingCard.cards : [bookingCard?.cards]),
      ].filter(Boolean); // Removes undefined, null, or falsy values
      intervalRef.current = setInterval(() => {
        dispatch({
          type: GET_CARD_SUMMARY,
          payload: {
            cards: totalCards,
          },
        });
      }, Number(selectedInterval) * 60 * 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // Cleanup when unmounting
      }
    };
  }, [selectedInterval]);

  useEffect(() => {
    getDashboardData();
  }, []);

  const reFetch = () => {
    getDashboardData();
  };

  useEffect(() => {
    if (getDashboardConfiguration !== null) {
      const defaultVisibleCards = getDashboardConfiguration?.modules?.main_dashboard?.sections
        ?.filter((data: any) => data?.defaultVisibility === true)
        ?.flatMap((section: any) => section?.cards || []); // Flatten all cards into a single array

      console.log('defaultVisibleCards', defaultVisibleCards);

      // Find specific cards
      const userActivityCard = defaultVisibleCards.find((card: any) => card?.name === 'user_activity_card');

      // Get remaining cards
      const remainingCards = defaultVisibleCards.filter(
        (card: any) => card?.name === 'past_bookings_card' || card?.name === 'ongoing_bookings_card' || card?.name === 'upcoming_bookings_card',
      );

      const otherCards = defaultVisibleCards.filter(
        (card: any) => card?.name !== 'user_activity_card' && !remainingCards.some(remainingCard => remainingCard.name === card.name),
      );

      // Set values in state
      setTeamActivityCard(userActivityCard || null);
      setOtherBookingCard(otherCards || []);
      setTimeAggregationCard(remainingCards || []);
      dispatch({
        type: GET_CARD_SUMMARY,
        payload: { cards: defaultVisibleCards },
      });
    }
  }, [getDashboardConfiguration]);

  useEffect(() => {
    if (getDashboardConfigurationLoading || cardDataLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getDashboardConfigurationLoading, cardDataLoading]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_CARD_SUMMARY_EMPTY,
      });
    };
  }, []);

  const handleBookingTypeEvent = (item, index) => {
    setSelectedCard(index);
    let bookingSection = getDashboardConfiguration?.modules?.main_dashboard?.sections?.find(section => section?.name === item?.toggleSection);
    setBookingCard(bookingSection);
    if (bookingSection?.cards?.length > 0) {
      dispatch({
        type: GET_CARD_SUMMARY,
        payload: {
          cards: bookingSection?.cards,
        },
      });
    }
  };

  const getDashboardData = () => {
    dispatch({
      type: GET_DASHBOARD_CONFIGURATION,
    });
  };

  const autoUpdateHandler = (selectedOption: any) => {
    setSelectedInterval(selectedOption.value);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid className="position-relative">
          {loading && <Loader />}
          <div className="bredcumb-responsive">
            <BreadcrumbTrail title={'Dashboard'} navigationby={() => {}} navigation={['Dashboard']} />
            <div className="d-flex gap-3">
              <div style={{ height: 40 }}>
                <Select
                  options={autoUpdateOptions}
                  onChange={autoUpdateHandler}
                  placeholder="Select Auto Update"
                  styles={{
                    control: provided => ({
                      ...provided,
                      width: '200px', // Set custom width
                    }),
                  }}
                />
              </div>
              <button style={{ height: 37 }} className="btn btn-success d-flex align-items-center gap-1" onClick={reFetch}>
                <i className=" ri-refresh-line"></i>
                Refresh
              </button>
            </div>
          </div>

          <div className="sla-container">
            <div className="sla-scroll">
              {teamActivityCard && <TeamActivityCard item={teamActivityCard} cardData={cardData} />}
              {timeAggregationCard &&
                timeAggregationCard?.map((timeLineStatsBooking, index) => (
                  <BookingTimelineCard
                    key={index}
                    item={timeLineStatsBooking}
                    handleBookingType={() => {
                      handleBookingTypeEvent(timeLineStatsBooking, index);
                      setFromApprovalPendingCard(false);
                    }}
                    isSelected={selectedCard === index}
                    cardData={cardData}
                  />
                ))}

              {otherBookingCard &&
                otherBookingCard?.map((otherCards, index) => (
                  <BookingCard
                    key={index}
                    item={otherCards}
                    handleBookingType={() => {
                      handleBookingTypeEvent(otherCards, index);
                      setFromApprovalPendingCard(true);
                      setIsModificationBooking(otherCards?.name === 'approval_pending_bookings_card');
                    }}
                    isSelected={selectedCard === index}
                    cardData={cardData}
                  />
                ))}
            </div>
          </div>

          {bookingCard && (
            <div className="card-5 w-100">
              <div className="d-flex align-items-end gap-2">
                <h4 className="text-black text-format-7 m-0">{bookingCard?.displayName}</h4>
                <p className="mb-0 text-color-light-grey">Today {moment(new Date()).format('DD, MMM hh:mm:ss')}</p>
              </div>
              <div className="scrollable-1 mt-3">
                <div className="d-inline-flex gap-4">
                  {bookingCard?.cards?.map((item, key) => (
                    <div className="card card-6 " key={key}>
                      <BookingTypeCard
                        item={item}
                        cardData={cardData}
                        fromApprovalPendingCard={fromApprovalPendingCard}
                        isModificationBooking={isModificationBooking}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* 
          <Row className="mt-4">
            {data?.projectWiseBookings && (
              <DonutChart
                lastUpdatedAt={data?.projectWiseBookings?.[0]?.lastUpdatedAt}
                data={data?.projectWiseBookings?.[0]?.data}
                title={'Project Wise Bookings'}
              />
            )}

            {data?.categoryWiseBooking && (
              <BarChart
                data={data?.categoryWiseBooking?.[0]?.data}
                title={'Category Day Wise Delayed Booking'}
                lastUpdatedAt={data?.projectWiseBookings?.[0]?.lastUpdatedAt}
              />
            )}
          </Row> */}
        </Container>
      </div>
    </>
  );
};

export default DashboardV2;
