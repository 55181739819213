import React, { useEffect, useState } from 'react';
import { VectorMap } from '@react-jvectormap/core';
import { worldMill } from '@react-jvectormap/world';
import { Card, CardBody, Col, Progress } from 'reactstrap';
import CountUp from 'react-countup';
import Select from 'react-select';

const COUNTRY_BOUNDS = {
  IN: { zoom: 9, center: [20.5937, 78.9629] }, // India
  US: { zoom: 3, center: [37.0902, -95.7129] }, // USA
  FR: { zoom: 6, center: [46.603354, 1.888334] }, // France
  DE: { zoom: 6, center: [51.1657, 10.4515] }, // Germany
  CN: { zoom: 4, center: [35.8617, 104.1954] }, // China
  JP: { zoom: 5, center: [36.2048, 138.2529] }, // Japan
  GB: { zoom: 6, center: [55.3781, -3.436] }, // UK
  SA: { zoom: 10, center: [23.8859, 45.0792] }, // Saudi Arabia
  AE: { zoom: 75, center: [23.4241, 53.8478] }, // UAE
  DEFAULT: { zoom: 1, center: [0, 0] }, // Default World View
};

const COUNTRY_CODES = {
  UAE: 'AE',
  India: 'IN',
  USA: 'US',
  France: 'FR',
  Germany: 'DE',
  China: 'CN',
  Japan: 'JP',
  UK: 'GB',
  'Saudi Arabia': 'SA',
};

const DynamicCountryMap = ({ title, data }) => {
  const [selectedCountryState, setSelectedCountryState] = useState('');
  const [countryConfig, setCountryConfig] = useState(COUNTRY_BOUNDS[selectedCountryState] || COUNTRY_BOUNDS.DEFAULT);
  const [mapKey, setMapKey] = useState(0); // Key to force re-render
  const [countryOptions, setcountryOptions] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [markers, setMakers] = useState([]);
  const [countryData, setCountryData] = useState<any>(null);

  useEffect(() => {
    setCountryConfig(COUNTRY_BOUNDS[selectedCountryState] || COUNTRY_BOUNDS.DEFAULT);
    setMapKey(prevKey => prevKey + 1); // Force re-render of the map
    const countryDetails = countryOptions?.find(country => COUNTRY_CODES[country?.value] === selectedCountryState);
    setMaxValue(Math.max(...(countryDetails?.completeData?.regions ?? []).map(city => city.value), 0));
    setMakers(
      countryDetails?.completeData?.regions?.map(city => ({
        latLng: [city.lattitude, city.longitude],
        name: city.name,
        style: { r: 4, fill: city.color || 'blue' },
      })) || [],
    );
    setCountryData(countryDetails);
  }, [selectedCountryState]);

  console.log('markers', markers);

  const handleCountryChange = selectedOption => {
    setSelectedCountryState(COUNTRY_CODES[selectedOption?.value]);
  };

  useEffect(() => {
    if (data?.distributions?.length > 0) {
      const selectedCountry = data?.distributions?.find(country => country?.isDefault === true);
      setcountryOptions(
        data?.distributions?.map(country => {
          return { value: country?.country, label: country?.country, completeData: country };
        }),
      );
      setSelectedCountryState(COUNTRY_CODES[selectedCountry?.country]);
    }
  }, [data]);

  // const maxValue =

  // const markers =

  return (
    <Col xl={6} md={12} lg={6} className="mb-2" style={{ borderRadius: '10px' }}>
      <Card>
        <div className="d-flex justify-content-between m-4 align-items-center">
          <h4 className="card-title mb-0 flex-grow-1 fw-semibold">{title}</h4>
          <Select
            options={countryOptions}
            placeholder="Select Country"
            value={countryOptions.find(option => COUNTRY_CODES[option.value] === selectedCountryState)}
            onChange={handleCountryChange}
            styles={{
              control: provided => ({
                ...provided,
                width: '150px', // Set custom width
              }),
            }}
            isSearchable={false}
          />
        </div>
        <CardBody style={{ maxHeight: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Map Section */}
          <div style={{ flexBasis: '70%', height: '350px', position: 'relative' }}>
            {selectedCountryState && (
              <VectorMap
                key={mapKey} // Force re-render on country change
                map={worldMill}
                regionStyle={{ initial: { fill: '#e3e3e3' }, selected: { fill: '#007BFF' } }}
                backgroundColor="#ffffff"
                markers={markers}
                markerStyle={{ initial: { stroke: '#fff' } }}
                zoomOnScroll={false}
                zoomButtons={false}
                focusOn={{
                  scale: countryConfig?.zoom,
                  x: 10,
                  y: 10,
                  lat: countryConfig?.center?.[0],
                  lng: countryConfig?.center?.[1],
                  animate: true,
                }}
                zoomMax={100}
                zoomAnimate={true}
              />
            )}
          </div>

          {/* Progress Bars Section */}
          <div className="p-3 rounded" style={{ flexBasis: '30%', overflowY: 'auto', maxHeight: '100%' }}>
            {countryData?.completeData?.regions.map((city, index) => (
              <div key={index} className="mb-3">
                <div className="d-flex justify-content-between">
                  <strong>{city.name}</strong>
                  <CountUp separator="" start={0} end={Number(city.value)} duration={2} className="fs-13 fw-semibold" />
                </div>
                <Progress
                  value={(city.value / maxValue) * 100}
                  style={{ height: '6px', backgroundColor: '#EDEDED' }}
                  barStyle={{ backgroundColor: city.color }}
                  animated={true}
                />
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default DynamicCountryMap;
