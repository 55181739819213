import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { CANCEL_BOOKING_BY_ADMIN, CANCEL_BOOKING_BY_ADMIN_EMPTY, GET_REASON_BY_ACTIONS } from '../../../../../../../store/actions';
import { Error } from '../../../../../../Common';

const ShortNoticeCancel = ({ onClose, currentSelectedBooking }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [selectedRemark, setSelectedRemark] = useState(null);
  const [isShowError, setIsShowError] = useState(false);
  const [quotaOption, setQuotaOption] = useState('with');

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { cancelBooking, cancelBookingLoading, remarksList, remarksListLoading } = useSelector(state => ({
    cancelBooking: state?.CancelBookingByAdmin?.data,
    cancelBookingLoading: state?.CancelBookingByAdmin?.loading,

    remarksList: state?.GetReasonByActions?.data,
    remarksListLoading: state?.GetReasonByActions?.loading,
  }));

  const handleSubmit = () => {
    if (selectedRemark === null) {
      setIsShowError(true);
    } else {
      dispatch({
        type: CANCEL_BOOKING_BY_ADMIN,
        payload: {
          body: {
            booking_id: `${currentSelectedBooking?.id}`,
            reason_id: `${selectedRemark?.value}`,
            with_quota: quotaOption === 'with' ? true : false,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (cancelBooking !== null) {
      dispatch({
        type: CANCEL_BOOKING_BY_ADMIN_EMPTY,
      });
      onClose();
    }

    return () => {
      dispatch({
        type: CANCEL_BOOKING_BY_ADMIN_EMPTY,
      });
    };
  }, [cancelBooking]);

  useEffect(() => {
    dispatch({
      type: GET_REASON_BY_ACTIONS,
      payload: {
        urlParams: {
          booking_type: 'airport_transfers',
          booking_status: currentSelectedBooking?.bookingStatus,
          action: 'cancel_booking',
          is_active: true,
        },
      },
    });
  }, [currentSelectedBooking]);

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    if (label === `reason_id`) {
      setSelectedRemark(data);
    } else {
      setIsShowError(false);
    }
  };

  return (
    <Modal isOpen={true} centered size="md">
      <ModalHeader toggle={onClose}></ModalHeader>

      <ModalBody>
        <p className="fs-20 text-center" style={{ color: '#001849' }}>
          You are cancelling a booking with short notice
        </p>
        <div className="mb-3" style={{ padding: '0 25px' }}>
          <Label htmlFor="name" className="form-label">
            Remarks
          </Label>
          <Select
            id="decline-remarks"
            name="decline-remarks"
            placeholder="Select reason for reject the booking"
            value={selectedRemark}
            options={remarksList?.reasons?.map((remark, index) => ({
              label: `${remark?.reason}`,
              value: `${remark?.id}`,
            }))}
            onChange={data => inputSelectHandler(`reason_id`, data)}
          />
          {isShowError && selectedRemark === null && <Error text="Kindly select reason for declining" ref={null} />}
        </div>
        <div className="d-flex gap-2 justify-content-center">
          <FormGroup style={{ border: '0.5px solid #CED4DA', padding: '5px' }}>
            <Label check className="d-flex align-items-center gap-2">
              <Input type="radio" name="quota" value="with" checked={quotaOption === 'with'} onChange={() => setQuotaOption('with')} />
              <p className="m-0 fs-14" style={{ color: '#405189' }}>
                With Quota
              </p>
            </Label>
          </FormGroup>
          <FormGroup style={{ border: '0.5px solid #CED4DA', padding: '5px' }}>
            <Label check className="d-flex align-items-center gap-2">
              <Input type="radio" name="quota" value="without" checked={quotaOption === 'without'} onChange={() => setQuotaOption('without')} />
              <p className="m-0 fs-14" style={{ color: '#405189' }}>
                Without Quota
              </p>
            </Label>
          </FormGroup>
        </div>
      </ModalBody>

      <div className="d-flex justify-content-center mb-4 gap-3">
        {!cancelBookingLoading && (
          <Button color="primary" style={{ fontSize: '14px', width: '100px', borderRadius: '10px' }} onClick={handleSubmit}>
            Yes
          </Button>
        )}

        {cancelBookingLoading && (
          <Button color="primary" style={{ fontSize: '14px', width: '100px', borderRadius: '10px' }}>
            <Spinner className="btn-replacer-spinner" />
          </Button>
        )}

        <Button color="white" style={{ fontSize: '14px', width: '100px', borderRadius: '10px', border: '1px solid #401589' }} onClick={onClose}>
          No
        </Button>
      </div>
    </Modal>
  );
};

export default ShortNoticeCancel;
