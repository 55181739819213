import { SHOW_MODIFY_FIELDS, SHOW_MODIFY_FIELDS_SUCCESS, SHOW_MODIFY_FIELDS_ERROR, SHOW_MODIFY_FIELDS_EMPTY } from '../../../actions';

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const ShowModifyField = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODIFY_FIELDS:
      state = {
        ...state,
        // data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      };
      break;

    case SHOW_MODIFY_FIELDS_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };
      break;

    case SHOW_MODIFY_FIELDS_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };
      break;

    case SHOW_MODIFY_FIELDS_EMPTY:
      state = {
        ...initialState,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ShowModifyField;
