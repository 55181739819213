import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Container, Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap';
import { LIST_TEMPLATE, LIST_TEMPLATE_EMPTY, TEMPLATE_DETAILS, TEMPLATE_DETAILS_EMPTY } from '../../../../../../store/actions';
import { Loader } from '../../../../../Common';
import BreadcrumbTrail from '../../../../../Common/BreadcrumbTrail';
import { Constant } from '../../../../../Helpers/constant';
import TemplateListTable from './Components/Table/TemplateTable';
import ConfigureMappingListTable from './Components/Table/ConfigureTemplateTable';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Filter from './Components/Filter';
import classNames from 'classnames';
import GetTemplateList from '../../../../../../store/application/Surge-v2/Rules/ListTemplate/reducer';
import Summary from './Components/Summary';

const SurgeEvent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Parse URL query parameters
  const { page = 1, perPageLimit = Constant.TABLE_PAGE_SIZE } = queryString.parse(location.se);

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(Number(perPageLimit));
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [arrowNavTab, setarrowNavTab] = useState('1');
  const [currentSelectedTemplateDetails, setCurrentSelectedTemplateDetails] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Template Configuration List
  const { template, templateLoading } = useSelector((state: any) => ({
    template: state?.GetTemplateList?.data,
    templateLoading: state?.GetTemplateList?.loading,
  }));

  const { templateDetails, templateDetailsLoading } = useSelector((state: any) => ({
    templateDetails: state?.TemplateDetails?.data,
    templateDetailsLoading: state?.TemplateDetails?.loading,
  }));

  useEffect(() => {
    if (templateDetails) {
      const summaryCard = document.getElementById('summary-card');
      if (summaryCard) {
        summaryCard.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [templateDetails]);

  useEffect(() => {
    const { page, perPageLimit } = queryString.parse(location.search);
    setCurrentPageNumber(Number(page || 1)); // Default to 1-based page
    setRowsPerPage(Number(perPageLimit || Constant.TABLE_PAGE_SIZE));
  }, [location.search]);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (arrowNavTab === '1') {
      dispatch({
        type: LIST_TEMPLATE,
        payload: {
          urlParams: {
            skip: (currentPageNumber - 1) * rowsPerPage,
            limit: rowsPerPage,
            ...currentlyAppliedFilters,
            configured: true,
          },
        },
      });
    } else {
      dispatch({
        type: LIST_TEMPLATE,
        payload: {
          urlParams: {
            skip: (currentPageNumber - 1) * rowsPerPage,
            limit: rowsPerPage,
            ...currentlyAppliedFilters,
          },
        },
      });
    }
  }, [currentlyAppliedFilters, currentPageNumber, rowsPerPage, dispatch, arrowNavTab]);

  useEffect(() => {
    return () => {
      dispatch({
        type: LIST_TEMPLATE_EMPTY,
      });
      dispatch({
        type: TEMPLATE_DETAILS_EMPTY,
      });
    };
  }, []);

  const handleRowsPerPageChange = (newRowsPerPage, page) => {
    updateURLParams({ page: 1, perPageLimit: newRowsPerPage });
  };

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);
    const updatedParams = {
      page: currentPageNumber,
      perPageLimit: rowsPerPage,
      ...currentParams,
      ...newParams,
    };

    const newUrl = queryString.stringify(updatedParams);

    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    updateURLParams({ page: pageNumber });
  };

  const filterSubmitHandler = filters => {
    setCurrentlyAppliedFilters(filters);
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    }
  };

  // Arrow Nav tabs
  const arrowNavToggle = tab => {
    if (arrowNavTab !== tab) {
      setarrowNavTab(tab);
      setCurrentPageNumber(1);
      setRowsPerPage(Number(perPageLimit));
      dispatch({
        type: TEMPLATE_DETAILS_EMPTY,
      });
      setCurrentlyAppliedFilters(prev => (prev === null ? undefined : null));
    }
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = ({ flag, templateDetails }) => {
    if (flag === `VIEW`) {
      dispatch({
        type: TEMPLATE_DETAILS,
        payload: {
          data: {
            templateId: templateDetails?.id,
          },
        },
      });
    }
    setCurrentSelectedTemplateDetails(templateDetails);
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (templateLoading || templateDetailsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [templateLoading, templateDetailsLoading]);

  return (
    <div className="page-content">
      {loading && <Loader />}

      <Container fluid className="position-relative">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
          <BreadcrumbTrail title="Template Management" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Template Management`]} />
          <Filter filterHandler={filterSubmitHandler} currentlyAppliedFilters={currentlyAppliedFilters} />
        </div>

        <Nav pills className="nav nav-pills arrow-navtabs mb-3 fs-14">
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer', padding: '15px' }}
              className={classNames({
                active: arrowNavTab === '1',
              })}
              onClick={() => {
                arrowNavToggle('1');
              }}
            >
              Configuration Mapping
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer', padding: '15px' }}
              className={classNames({
                active: arrowNavTab === '2',
              })}
              onClick={() => {
                arrowNavToggle('2');
              }}
            >
              All Templates
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={arrowNavTab} className="text-muted">
          <TabPane tabId="1" id="arrow-overview">
            <ConfigureMappingListTable
              data={template?.results}
              totalCount={template?.count}
              pageNumber={currentPageNumber}
              appliedFilters={() => {}}
              limit={perPageLimit}
              handleRowsPerPageChange={handleRowsPerPageChange}
              handlePageChange={paginationHandler}
              actionHandler={tableActionHandler}
            />
          </TabPane>
          <TabPane tabId="2" id="arrow-profile">
            <TemplateListTable
              data={template?.results}
              totalCount={template?.count}
              pageNumber={currentPageNumber}
              appliedFilters={() => {}}
              limit={perPageLimit}
              handleRowsPerPageChange={handleRowsPerPageChange}
              handlePageChange={paginationHandler}
              actionHandler={tableActionHandler}
            />
          </TabPane>
        </TabContent>
        {templateDetails && <Summary templateDetails={templateDetails} currentSelectedTemplateDetails={currentSelectedTemplateDetails} />}
      </Container>
    </div>
  );
};

export default SurgeEvent;
