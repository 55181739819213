import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from './../../../../Common';
import { Container } from 'reactstrap';
import Table from './Components/Tables/Table';
import {
  BLOCK_DATE_DEACTIVATE,
  BLOCK_DATE_DEACTIVATE_EMPTY,
  BLOCK_DATE_EMPTY,
  BLOCK_DATE_LIST,
  GET_LOCATION,
} from '../../../../../store/application/actionType';
import { Constant } from '../../../../Helpers/constant';
import CustomFilter from './Components/Filters/CustomFilter';
import BlockDates from './Components/Modals/BlockDates';
import RemoveConfirmation from './Components/Modals/RemoveConfirmation';
import BlockDatesFilterNew from './Components/Filters/blockDatesFilterNew';

const LocationBlockDateManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState(null);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [isBlockDatesModal, setIsBlockDatesModal] = useState(false);
  const [isShowRemoveConfirmationModal, setIsShowRemoveConfirmationModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Facilities
  const { blockDateList, blockDateListLoading } = useSelector(state => ({
    blockDateList: state?.BlockedDatesList?.data,
    blockDateListLoading: state?.BlockedDatesList?.loading,
  }));

  // Create Block Date
  const { createBlockDate, createBlockDateLoading } = useSelector(state => ({
    createBlockDate: state?.CreateBlockDate?.data,
    createBlockDateLoading: state?.CreateBlockDate?.loading,
  }));

  // Update Block Date
  const { deactiveBlockDate, deactiveBlockDateLoading } = useSelector(state => ({
    deactiveBlockDate: state?.DeactivateBlockDate?.data,
    deactiveBlockDateLoading: state?.DeactivateBlockDate?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: BLOCK_DATE_LIST,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          filter: `"isActive":true`,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (createBlockDate != null) {
      setIsBlockDatesModal(false);
      dispatch({
        type: BLOCK_DATE_EMPTY,
      });
      dispatch({
        type: BLOCK_DATE_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            filter: `"isActive":true`,
          },
        },
      });
    }
  }, [createBlockDate]);

  useEffect(() => {
    if (deactiveBlockDate !== null) {
      dispatch({
        type: BLOCK_DATE_DEACTIVATE_EMPTY,
      });
      dispatch({
        type: BLOCK_DATE_EMPTY,
      });
      dispatch({
        type: BLOCK_DATE_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            filter: `"isActive":true`,
          },
        },
      });
    }
  }, [deactiveBlockDate]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    // if(!currentlyAppliedFilters)return false;
    setCurrentPageNumber(0);
    setIsShowFilters(false);

    const urlParams = {
      facilityName: filters?.facilityName === `` ? null : filters?.facilityName,
      // createdAt: filters?.createdAt === `` ? null : filters?.createdAt,
      createdAtFrom: filters?.createdAt === `` ? null : filters?.createdAt?.from,
      createdAtTo: filters?.createdAt === `` ? null : filters?.createdAt?.to,
      pageIndex: 0
      // name: filters?.name === `` ? null : filters?.name,
      // merchantName: filters?.merchantName === `` ? null : filters?.merchantName,
      // email: filters?.email === `` ? null : filters?.email,
      // updateDate: filters?.updateDate === `` ? null : filters?.updateDate,
      // categoryId: filters?.categoryId === null ? null : filters?.categoryId?.value,
      // partnerId: filters?.partnerId === null ? null : filters?.partnerId?.value,
      // countryId: filters?.countryId === null ? null : filters?.countryId?.value,
      // subRegionId: filters?.subRegionId === null ? null : filters?.subRegionId?.value,
      // regionId: filters?.regionId === null ? null : filters?.regionId?.value,
    };
    setCurrentlyAppliedFilters(urlParams);
    dispatch({
      type: BLOCK_DATE_LIST,
      payload: {
        urlParam: {
          filter: `"isActive":true`,
          // pageIndex: 0,
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    // const urlParams = {
    //   name: currentlyAppliedFilters?.name === `` ? null : currentlyAppliedFilters?.name,
    //   name: currentlyAppliedFilters?.name === `` ? null : currentlyAppliedFilters?.name,
    //   name: currentlyAppliedFilters?.name === `` ? null : currentlyAppliedFilters?.name,
    //   merchantName: currentlyAppliedFilters?.merchantName === `` ? null : currentlyAppliedFilters?.merchantName,
    //   email: currentlyAppliedFilters?.email === `` ? null : currentlyAppliedFilters?.email,
    //   updateDate: currentlyAppliedFilters?.updateDate === `` ? null : currentlyAppliedFilters?.updateDate,
    //   categoryId: currentlyAppliedFilters?.categoryId === null ? null : currentlyAppliedFilters?.categoryId?.value,
    //   partnerId: currentlyAppliedFilters?.partnerId === null ? null : currentlyAppliedFilters?.partnerId?.value,
    //   countryId: currentlyAppliedFilters?.countryId === null ? null : currentlyAppliedFilters?.countryId?.value,
    //   subRegionId: currentlyAppliedFilters?.subRegionId === null ? null : currentlyAppliedFilters?.subRegionId?.value,
    //   regionId: currentlyAppliedFilters?.regionId === null ? null : currentlyAppliedFilters?.regionId?.value,
    // };

    dispatch({
      type: BLOCK_DATE_LIST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          filter: `"isActive":true`,
          ...currentlyAppliedFilters
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);
    setCurrentConfirmationModalActionFor(params?.actionType);

    if (params?.actionType === `DELETE`) {
      setIsShowRemoveConfirmationModal(true);
    }
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Blocked Facilities`) {
      // nothing to do here
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (createBlockDateLoading || blockDateListLoading || deactiveBlockDateLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createBlockDateLoading, blockDateListLoading, deactiveBlockDateLoading]);

  /* ---------------------------- REMOVE CONFIRMATION MODAL HANDLER ---------------------------- */
  const removeBlockHandler = () => {
    setIsShowRemoveConfirmationModal(false);
    setCurrentSelectedDetails(null);
    setCurrentConfirmationModalActionFor(null);

    dispatch({
      type: BLOCK_DATE_DEACTIVATE,
      payload: {
        data: {
          isActive: false,
        },
        urlParams: {
          objectId: currentSelectedDetails?.objectId,
        },
      },
    });
  };

  /* ---------------------------- CLOSE REMOVE CONFIRMATION HANDLER ---------------------------- */
  const onCloseRemoveConfirmationModal = () => {
    setIsShowRemoveConfirmationModal(false);
    setCurrentSelectedDetails(null);
    setCurrentConfirmationModalActionFor(null);
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        {/*  ----------------------------  MODAL'S ---------------------------- */}
        {isBlockDatesModal && (
          <BlockDates data={currentSelectedDetails} onCloseHandler={() => setIsBlockDatesModal(false)} setData={setCurrentSelectedDetails} />
        )}

        {isShowRemoveConfirmationModal && <RemoveConfirmation onClose={onCloseRemoveConfirmationModal} onSubmit={removeBlockHandler} />}

        {/*  ----------------------------  UI'S ---------------------------- */}
        <Container fluid className="position-relative">
          <>
            <BreadCrumb title="Blocked facilities" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Blocked Facilities']} />
            <BlockDatesFilterNew
              filterHandler={filterSubmitHandler}
              appliedFilters={currentlyAppliedFilters}
              show={isShowFilters}
              onCloseClick={() => setIsShowFilters(false)}
             setCurrentlyAppliedFilters={setCurrentlyAppliedFilters}
             
            />

            <Table
              title={`Blocked facilities`}
              data={blockDateList?.results}
              totalData={blockDateList?.count}
              pageHandler={paginationHandler}
              pageNumber={currentPageNumber}
              actionHandler={tableActionHandler}
              isShowFilterBtn={true}
              filterBtnHandler={() => setIsShowFilters(true)}
              setIsBlockDatesModal={() => {
                setIsBlockDatesModal(true);
              }}
            />
          </>
        </Container>
      </div>
    </>
  );
};

export default LocationBlockDateManagement;
