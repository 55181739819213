import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import classnames from 'classnames';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Card, CardBody, Col, Form, Label, Row, Input, CardFooter, Accordion, AccordionItem, Collapse } from 'reactstrap';

import { Constant } from '../../../../../Helpers/constant';
import { Error, PreviewCardHeader } from '../../../../../Common';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';
import { hasPermission, isValidAlphaWithSpec, isValidUrl } from '../../../../../Helpers/Helpers';
import {
  CREATE_SERVICE,
  GET_BOOKING_TYPES_LIST,
  GET_COUNTRY,
  GET_COUPON_CATEGORY,
  GET_COUPON_PARTNER,
  GET_COUPON_PARTNER_EMPTY,
  GET_REDEMPTION_TYPE,
  GET_COUPON_FACILITY,
  GET_COUPON_FACILITY_EMPTY,
  UPDATE_SERVICE,
  GET_SUB_REGION,
  GET_SUB_REGION_EMPTY,
  GET_REGION,
} from '../../../../../../store/application/actionType';
import axios, { Axios } from 'axios';
import { baseUrl, customHeaders } from '../../../../../Config';
import { AsyncPaginate } from 'react-select-async-paginate';

const procurementTypes = [
  { label: 'Free', value: 'Free' },
  { label: 'Advance', value: 'Advance' },
  { label: 'Redemption', value: 'Redemption' },
];

const ServiceForm = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const dispatch = useDispatch();

  // Accordions
  const [accordions, setAccordions] = useState({
    general: {
      status: true,
      error: false,
    },
    displayProperties: {
      status: false,
      error: false,
    },
    bookingProperties: {
      status: false,
      error: false,
    },
    tat: {
      status: false,
      error: false,
    },
    pricingsAndPayments: {
      status: false,
      error: false,
    },
    couponProcurementTime: {
      status: false,
      error: false,
    },
    mqlSetting: {
      status: false,
      error: false,
    },
    expirySetting: {
      status: false,
      error: false,
    },
  });

  const initialState = {
    name: '',
    displayName: '',
    category: null,
    partner: null,
    facility: null,
    country: null,
    region: null,
    subRegion: null,
    images: {
      logo: '',
      coverImageMobile: '',
      coverImageWeb: '',
      displayImageMobile: '',
      displayImageWeb: '',
    },
    thirdPartyWebsite: '',
    taxType: '',
    currency: '',
    procurementType: null,
    longDescription: '',
    shortDescription: '',
    costPrice: 0,
    sellingPrice: 0,
    taxPercentage: '0',
    benefitOffered: '',
    termsAndCondition: '',
    redemptionProcess: '',

    bookingType: null,
    faq: '',
    workfloId: '',
    Duration: {
      unit: 0,
      value: 0,
    },

    priority: 0,
    tat: {
      minimum: {
        value: 0,
        unit: 0,
      },
      maximum: {
        value: 0,
        unit: 0,
      },
      cancel: {
        value: 0,
        unit: 0,
      },
      confirm: {
        value: 0,
        unit: 0,
      },
    },
    couponProcurementTat: {
      min: {
        value: 0,
        unit: 0,
      },
      max: {
        value: 0,
        unit: 0,
      },
    },
    voucherDetails: {
      expiryBlock: 0,
      expiryAlertLevelFirst: 0,
      expiryAlertLevelSecond: 0,
      expiryAlertLevelThird: 0,
    },
    mql: {
      mqlAlertLevelFirst: 0,
      mqlAlertLevelSecond: 0,
      mqlAlertLevelThird: 0,
      isActive: false,
    },
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [allCategories, setAllCategories] = useState([]);
  const [allPartners, setAllPartners] = useState([]);
  const [allFacilities, setAllFacilities] = useState([]);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [allRegions, setAllRegions] = useState(null);
  const [allSubRegions, setAllSubRegions] = useState(null);
  const [bookingTypesData, setBookingTypesData] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Categories
  const { categories } = useSelector(state => ({
    categories: state?.GetCouponCategory?.data,
  }));

  // Partners
  const { partners } = useSelector(state => ({
    partners: state?.GetCouponPartner?.data,
  }));

  // Facilities
  const { facilities } = useSelector(state => ({
    facilities: state?.GetCouponFacility?.data,
  }));

  // Currencies
  const { currencies } = useSelector(state => ({
    currencies: state?.Countries?.data,
  }));

  // Booking Types
  const { bookingTypes } = useSelector(state => ({
    bookingTypes: state?.BookingTypes?.data,
  }));

  // Countries
  const { countries } = useSelector(state => ({
    countries: state?.Countries?.data,
  }));

  // Regions
  const { regions } = useSelector(state => ({
    regions: state?.Regions?.data,
  }));

  // Sub Regions
  const { subRegions } = useSelector(state => ({
    subRegions: state?.SubRegions?.data,
  }));

  useEffect(() => {
    if (countries === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }

    if (categories === null) {
      dispatch({
        type: GET_COUPON_CATEGORY,
      });
    }
    if (currencies === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }
    if (bookingTypes === null) {
      dispatch({
        type: GET_BOOKING_TYPES_LIST,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (categories !== null) {
      setAllCategories(
        categories?.map(category => {
          return {
            value: category?.objectId,
            label: category?.name,
            data: category,
          };
        }),
      );
    }
  }, [categories]);

  useEffect(() => {
    if (partners !== null) {
      setAllPartners(
        partners?.map(partner => {
          return {
            value: partner?.objectId,
            label: partner?.name,
            data: partner,
          };
        }),
      );
    }
  }, [partners]);

  useEffect(() => {
    if (facilities !== null) {
      setAllFacilities(
        facilities?.map(facility => {
          return {
            value: facility?.objectId,
            label: facility?.name,
            data: facility,
          };
        }),
      );
    }
  }, [facilities]);

  useEffect(() => {
    if (currencies !== null) {
      setAllCurrencies(
        currencies?.map(currency => {
          return {
            value: currency?.currency,
            label: currency?.currency,
          };
        }),
      );
    }
  }, [currencies]);

  useEffect(() => {
    if (bookingTypes !== null) {
      setBookingTypesData(
        bookingTypes?.results?.map(bookingType => {
          return {
            value: bookingType?.objectId,
            label: bookingType?.name,
            data: bookingType,
          };
        }),
      );
    }
  }, [bookingTypes]);

  useEffect(() => {
    if (countries !== null) {
      setAllCountries(
        countries?.map(country => {
          return {
            value: country?.countryId,
            label: country?.name,
            data: country,
          };
        }),
      );
    } else {
      setAllCountries(null);
    }
  }, [countries]);

  useEffect(() => {
    if (regions !== null) {
      setAllRegions(
        regions?.map(region => {
          return {
            value: region?.stateId,
            label: region?.name,
          };
        }),
      );
    } else {
      setAllRegions(null);
    }
  }, [regions]);

  useEffect(() => {
    if (subRegions !== null) {
      setAllSubRegions(
        subRegions?.map(subRegion => {
          return {
            value: subRegion?.cityId,
            label: subRegion?.name,
          };
        }),
      );
    } else {
      setAllSubRegions(null);
    }
  }, [subRegions]);

  useEffect(() => {
    if (props?.data !== undefined) {
      const tempStateData = {
        ...props?.data,
        category: {
          value: props?.data?.category?.id,
          label: props?.data?.category?.name,
          data: props?.data?.category,
        },
        partner: {
          value: props?.data?.partner?.id,
          label: props?.data?.partner?.name,
          data: props?.data?.partner,
        },
        facility: {
          value: props?.data?.facility?.id,
          label: props?.data?.facility?.name,
          data: props?.data?.facility,
        },
        bookingType: {
          value: props?.data?.bookingType?.objectId,
          label: props?.data?.bookingType?.name,
          data: props?.data?.bookingType,
        },
        currency: {
          value: props?.data?.currency,
          label: props?.data?.currency,
        },
        country: {
          value: props?.data?.country?.id,
          label: props?.data?.country?.name,
          data: props?.data?.country,
        },
        procurementType: {
          value: props?.data?.procurementType,
          label: props?.data?.procurementType,
        },
      };
      setState({
        ...tempStateData,
      });
      validateErrors(`validationCheck`, { ...tempStateData });

      dispatch({
        type: GET_COUPON_PARTNER,
        payload: {
          urlParam: {
            categoryId: props?.data?.category?.id,
          },
        },
      });

      dispatch({
        type: GET_COUPON_FACILITY,
        payload: {
          urlParam: {
            partnerId: props?.data?.partner?.id,
          },
        },
      });
    }
  }, [props?.data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: isValidAlphaWithSpec(value) ? value : state?.name,
        };
        break;

      case 'displayName':
        updatedState = {
          ...state,
          displayName: isValidAlphaWithSpec(value) ? value : state?.displayName,
        };
        break;

      case 'logo':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            logo: value,
          },
        };
        break;

      case 'coverImageMobile':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            coverImageMobile: value,
          },
        };
        break;

      case 'coverImageWeb':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            coverImageWeb: value,
          },
        };
        break;

      case 'displayImageMobile':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            displayImageMobile: value,
          },
        };
        break;

      case 'displayImageWeb':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            displayImageWeb: value,
          },
        };
        break;

      case 'thirdPartyWebsite':
        updatedState = {
          ...state,
          thirdPartyWebsite: value,
        };
        break;

      case 'shortDescription':
        updatedState = {
          ...state,
          shortDescription: isValidAlphaWithSpec(value) ? value : state?.shortDescription,
        };
        break;

      case 'longDescription':
        updatedState = {
          ...state,
          longDescription: isValidAlphaWithSpec(value) ? value : state?.longDescription,
        };
        break;

      case 'taxType':
        updatedState = {
          ...state,
          taxType: value,
        };
        break;

      case 'currency':
        updatedState = {
          ...state,
          currency: value,
        };
        break;

      case 'costPrice':
        updatedState = {
          ...state,
          costPrice: isNaN(value) ? state?.costPrice : parseInt(value || 0),
        };
        break;

      case 'sellingPrice':
        updatedState = {
          ...state,
          sellingPrice: isNaN(value) ? state?.sellingPrice : parseInt(value || 0),
        };
        break;

      case 'taxPercentage':
        updatedState = {
          ...state,
          taxPercentage: value.length > 3 ? state?.taxPercentage : isNaN(value) ? state?.taxPercentage : parseInt(value || 0),
        };
        break;

      case 'priority':
        updatedState = {
          ...state,
          priority: value.length > 4 ? state?.priority : isNaN(value) ? state?.priority : parseInt(value || 0),
        };
        break;

      // -------
      case 'tat-minimum-unit':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            minimum: {
              ...state?.tat?.minimum,
              unit: parseInt(value || 0),
              value: value === '0' ? 0 : state?.tat?.minimum?.value,
            },
          },
        };
        break;

      case 'tat-minimum-value':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            minimum: {
              ...state?.tat?.minimum,
              value: value.length > 3 ? state?.tat?.minimum?.value : isNaN(value) ? state?.tat?.minimum?.value : parseInt(value || 0),
            },
          },
        };
        break;

      case 'tat-maximum-unit':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            maximum: {
              ...state?.tat?.maximum,
              unit: parseInt(value || 0),
              value: value === '0' ? 0 : state?.tat?.maximum?.value,
            },
          },
        };
        break;

      case 'tat-maximum-value':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            maximum: {
              ...state?.tat?.maximum,
              value: value.length > 3 ? state?.tat?.maximum?.value : isNaN(value) ? state?.tat?.maximum?.value : parseInt(value || 0),
            },
          },
        };
        break;

      case 'tat-cancel-unit':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            cancel: {
              ...state?.tat?.cancel,
              unit: parseInt(value || 0),
              value: value === '0' ? 0 : state?.tat?.cancel?.value,
            },
          },
        };
        break;

      case 'tat-cancel-value':
        updatedState = {
          ...state,
          tat: {
            ...state?.tat,
            cancel: {
              ...state?.tat?.cancel,
              value: value.length > 3 ? state?.tat?.cancel?.value : isNaN(value) ? state?.tat?.cancel?.value : parseInt(value || 0),
            },
          },
        };
        break;

      // ---- MQL
      case 'mql-isActive':
        updatedState = {
          ...state,
          mql: {
            ...state?.mql,
            isActive: !state?.mql?.isActive,
            mqlAlertLevelFirst: 0,
            mqlAlertLevelSecond: 0,
            mqlAlertLevelThird: 0,
          },
          voucherDetails: {
            ...state?.voucherDetails,
            expiryBlock: 0,
            expiryAlertLevelFirst: 0,
            expiryAlertLevelSecond: 0,
            expiryAlertLevelThird: 0,
          },
        };
        break;

      case 'voucher-mql-1':
        updatedState = {
          ...state,
          mql: {
            ...state?.mql,
            // mqlAlertLevelFirst: parseInt(value || 0),
            mqlAlertLevelFirst:
              value.length > 3 ? state?.mql?.mqlAlertLevelFirst : isNaN(value) ? state?.mql?.mqlAlertLevelFirst : parseInt(value || 0),
          },
        };
        break;

      case 'voucher-mql-2':
        updatedState = {
          ...state,
          mql: {
            ...state?.mql,
            mqlAlertLevelSecond:
              value.length > 3 ? state?.mql?.mqlAlertLevelSecond : isNaN(value) ? state?.mql?.mqlAlertLevelSecond : parseInt(value || 0),
          },
        };
        break;

      case 'voucher-mql-3':
        updatedState = {
          ...state,
          mql: {
            ...state?.mql,
            mqlAlertLevelThird:
              value.length > 3 ? state?.mql?.mqlAlertLevelThird : isNaN(value) ? state?.mql?.mqlAlertLevelThird : parseInt(value || 0),
          },
        };
        break;

      // ---- coupon procurement
      case 'procurement-minimum-tat-unit':
        updatedState = {
          ...state,
          couponProcurementTat: {
            ...state?.couponProcurementTat,
            min: {
              ...state?.couponProcurementTat?.min,
              unit: parseInt(value || 0),
              value: value === '0' ? 0 : state?.couponProcurementTat?.min?.value,
            },
          },
        };
        break;

      case 'procurement-minimum-tat-value':
        updatedState = {
          ...state,
          couponProcurementTat: {
            ...state?.couponProcurementTat,
            min: {
              ...state?.couponProcurementTat?.min,
              value:
                value.length > 3
                  ? state?.couponProcurementTat?.min?.value
                  : isNaN(value)
                  ? state?.couponProcurementTat?.min?.value
                  : parseInt(value || 0),
            },
          },
        };
        break;

      case 'procurement-maximum-tat-unit':
        updatedState = {
          ...state,
          couponProcurementTat: {
            ...state?.couponProcurementTat,
            max: {
              ...state?.couponProcurementTat?.max,
              unit: parseInt(value || 0),
              value: value === '0' ? 0 : state?.couponProcurementTat?.max?.value,
            },
          },
        };
        break;

      case 'procurement-maximum-tat-value':
        updatedState = {
          ...state,
          couponProcurementTat: {
            ...state?.couponProcurementTat,
            max: {
              ...state?.couponProcurementTat?.max,
              value:
                value.length > 3
                  ? state?.couponProcurementTat?.max?.value
                  : isNaN(value)
                  ? state?.couponProcurementTat?.max?.value
                  : parseInt(value || 0),
            },
          },
        };
        break;

      // ---- Voucher Expiry Settings
      case 'voucher-expiry-block':
        updatedState = {
          ...state,
          voucherDetails: {
            ...state?.voucherDetails,
            expiryBlock:
              value.length > 3 ? state?.voucherDetails?.expiryBlock : isNaN(value) ? state?.voucherDetails?.expiryBlock : parseInt(value || 0),
          },
        };
        break;

      case 'voucher-expiry-alert-1':
        updatedState = {
          ...state,
          voucherDetails: {
            ...state?.voucherDetails,
            // expiryAlertLevelFirst: parseInt(value || 0),
            expiryAlertLevelFirst:
              value.length > 3
                ? state?.voucherDetails?.expiryAlertLevelFirst
                : isNaN(value)
                ? state?.voucherDetails?.expiryAlertLevelFirst
                : parseInt(value || 0),
          },
        };
        break;

      case 'voucher-expiry-alert-2':
        updatedState = {
          ...state,
          voucherDetails: {
            ...state?.voucherDetails,
            expiryAlertLevelSecond:
              value.length > 3
                ? state?.voucherDetails?.expiryAlertLevelSecond
                : isNaN(value)
                ? state?.voucherDetails?.expiryAlertLevelSecond
                : parseInt(value || 0),
          },
        };
        break;

      case 'voucher-expiry-alert-3':
        updatedState = {
          ...state,
          voucherDetails: {
            ...state?.voucherDetails,
            expiryAlertLevelThird:
              value.length > 3
                ? state?.voucherDetails?.expiryAlertLevelThird
                : isNaN(value)
                ? state?.voucherDetails?.expiryAlertLevelThird
                : parseInt(value || 0),
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- DATE CHANGE HANDLER ---------------------------- */
  const ckEditorDataInput = (name, data) => {
    let updatedState = { ...state };

    switch (name) {
      case 'redemptionProcess':
        updatedState = {
          ...state,
          redemptionProcess: data,
        };
        break;

      case 'benefitOffered':
        updatedState = {
          ...state,
          benefitOffered: data,
        };
        break;

      case 'termsAndCondition':
        updatedState = {
          ...state,
          termsAndCondition: data,
        };
        break;

      case 'shortDescription':
        updatedState = {
          ...state,
          shortDescription: data,
        };
        break;

      case 'longDescription':
        updatedState = {
          ...state,
          longDescription: data,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };


  const loadOptionForBenefits = async (search,type) => {
    console.log(search,type);
    let URL='';
    if (type === `partner`) {
      URL = `${baseUrl}/admin/partners?pageIndex=0&pageSize=10`;
     
    } else if (type === `category`) {
     
      URL = `${baseUrl}/admin/categories?pageIndex=0&pageSize=10`;
     
    } 
console.log('url-->',URL);
    return await axios({
      method: `GET`,
      url: URL,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search === '' ? null : search,
        categoryId:type==='partner'? state?.category?.value :null
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.objectId,
              label: `${data?.name}`,
              data: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  };
  
  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    if (label === `category`) {
      updatedState = {
        ...state,
        category: data,
        partner: null,
        facility: null,

        images: {
          ...data?.data?.images,
        },
        shortDescription: data?.data?.shortDescription,
        longDescription: data?.data?.longDescription,
        bookingType: {
          data: { ...data?.data?.bookingType },
          label: data?.data?.bookingType?.name,
          value: data?.data?.bookingType?.objectId,
        },
      };

      dispatch({
        type: GET_COUPON_PARTNER_EMPTY,
      });
      dispatch({
        type: GET_COUPON_FACILITY_EMPTY,
      });

      dispatch({
        type: GET_COUPON_PARTNER,
        payload: {
          urlParam: {
            pageSize:10,
            categoryId: data?.value,
          },
        },
      });

      dispatch({
        type: GET_COUPON_FACILITY,
        payload: {
          urlParam: {
            categoryId: data?.value,
          },
        },
      });
    } else if (label === `partner`) {
      updatedState = {
        ...state,
        partner: data,
        facility: null,

        images: {
          ...data?.data?.images,
        },
        shortDescription: data?.data?.shortDescription,
        longDescription: data?.data?.longDescription,
        benefitOffered: data?.data?.defaultBenefitOffered ?? ``,
        redemptionProcess: data?.data?.defaultRedemptionProcess ?? ``,
        termsAndCondition: data?.data?.defaultRedemptionProcess ?? ``,
        bookingType: {
          data: { ...data?.data?.bookingType },
          label: data?.data?.bookingType?.name,
          value: data?.data?.bookingType?.objectId,
        },
        thirdPartyWebsite: data?.data?.thirdPartyWebsite,
        tat: data?.data?.tat,
      };

      dispatch({
        type: GET_COUPON_FACILITY_EMPTY,
      });

      dispatch({
        type: GET_COUPON_FACILITY,
        payload: {
          urlParam: {
            partnerId: data?.value,
          },
        },
      });
    } else if (label === `facility`) {
      updatedState = {
        ...state,
        facility: data,

        images: {
          ...data?.data?.images,
        },
        shortDescription: data?.data?.shortDescription,
        longDescription: data?.data?.longDescription,
        termsAndCondition: data?.data?.termsAndCondition ?? ``,
        benefitOffered: data?.data?.benefitOffered ?? ``,
        redemptionProcess: data?.data?.redemptionProcess ?? ``,
        tat: data?.data?.tat,
        thirdPartyWebsite: data?.data?.thirdPartyWebsite,
      };
    } else if (label === `procurementType`) {
      updatedState = {
        ...state,
        procurementType: data,
      };
    } else if (label === `currency`) {
      updatedState = {
        ...state,
        currency: data,
      };
    } else if (label === `bookingType`) {
      updatedState = {
        ...state,
        bookingType: data,
      };
    } else if (label === `country`) {
      updatedState = {
        ...state,
        country: data,
        region: null,
        subRegion: null,
      };

      dispatch({ type: GET_SUB_REGION_EMPTY });

      dispatch({
        type: GET_REGION,
        payload: {
          urlParam: {
            countryId: parseInt(data?.value),
          },
        },
      });
    } else if (label === `region`) {
      updatedState = {
        ...state,
        region: data,
        subRegion: null,
      };

      dispatch({
        type: GET_SUB_REGION,
        payload: {
          urlParam: {
            regionId: parseInt(data?.value),
          },
        },
      });
    } else if (label === `subregion`) {
      updatedState = {
        ...state,
        subRegion: data,
      };
    } else if (label === `type`) {
      updatedState = {
        ...state,
        type: data,
      };
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- VALIDATE ERRORS ---------------------------- */
  const validateErrors = (flag, data = state) => {
    let isError = false;
    let accordionsTemp = { ...accordions };

    if (
      data?.category === null ||
      data?.partner === null ||
      data?.country === null ||
      data?.bookingType === null ||
      data?.procurementType === null ||
      data?.name?.trim() === ``
    ) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        general: {
          ...accordionsTemp?.general,
          status: flag === `validationCheck` ? accordionsTemp?.general?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        general: {
          status: flag === `validationCheck` ? accordionsTemp?.general?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
        couponProcurementTime: {
          status: flag === `validationCheck` ? accordionsTemp?.couponProcurementTime?.status : false,
          error: false,
        },
        mqlSetting: {
          status: flag === `validationCheck` ? accordionsTemp?.mqlSetting?.status : false,
          error: false,
        },
      };
    }

    if (
      data?.displayName?.trim() === `` ||
      data?.images?.coverImageMobile === `` ||
      !isValidUrl(data?.images?.coverImageMobile) ||
      data?.images?.coverImageWeb === `` ||
      !isValidUrl(data?.images?.coverImageWeb) ||
      data?.images?.displayImageMobile === `` ||
      !isValidUrl(data?.images?.displayImageMobile) ||
      data?.images?.displayImageWeb === `` ||
      !isValidUrl(data?.images?.displayImageWeb) ||
      data?.shortDescription === `` ||
      data?.longDescription === `` ||
      data?.redemptionProcess === `` ||
      data?.termsAndCondition === `` ||
      data?.benefitOffered === ``
    ) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        displayProperties: {
          ...accordionsTemp?.displayProperties,
          status: flag === `validationCheck` ? accordionsTemp?.displayProperties?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        displayProperties: {
          status: flag === `validationCheck` ? accordionsTemp?.displayProperties?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
        couponProcurementTime: {
          status: flag === `validationCheck` ? accordionsTemp?.couponProcurementTime?.status : false,
          error: false,
        },
        mqlSetting: {
          status: flag === `validationCheck` ? accordionsTemp?.mqlSetting?.status : false,
          error: false,
        },
      };
    }

    if (!isValidUrl(data?.thirdPartyWebsite)) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        bookingProperties: {
          ...accordionsTemp?.bookingProperties,
          status: flag === `validationCheck` ? accordionsTemp?.bookingProperties?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        bookingProperties: {
          status: flag === `validationCheck` ? accordionsTemp?.bookingProperties?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
        couponProcurementTime: {
          status: flag === `validationCheck` ? accordionsTemp?.couponProcurementTime?.status : false,
          error: false,
        },
        mqlSetting: {
          status: flag === `validationCheck` ? accordionsTemp?.mqlSetting?.status : false,
          error: false,
        },
      };
    }

    if (data?.currency === ``) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        pricingsAndPayments: {
          ...accordionsTemp?.pricingsAndPayments,
          status: flag === `validationCheck` ? accordionsTemp?.pricingsAndPayments?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        pricingsAndPayments: {
          status: flag === `validationCheck` ? accordionsTemp?.pricingsAndPayments?.status : false,
          error: false,
        },
        tat: {
          status: flag === `validationCheck` ? accordionsTemp?.tat?.status : false,
          error: false,
        },
        couponProcurementTime: {
          status: flag === `validationCheck` ? accordionsTemp?.couponProcurementTime?.status : false,
          error: false,
        },
        mqlSetting: {
          status: flag === `validationCheck` ? accordionsTemp?.mqlSetting?.status : false,
          error: false,
        },
      };
    }

    setIsValidationShow(flag === `submissionCheck` ? isError : isValidationShow ? true : false);
    setAccordions({ ...accordionsTemp });
    return isError;
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (!validateErrors(`submissionCheck`)) {
      const payload = {
        ...state,
        category: {
          id: state?.category?.value,
          name: state?.category?.label,
        },
        partner: {
          id: state?.partner?.value,
          name: state?.partner?.label,
        },
        facility: {
          id: state?.facility?.value,
          name: state?.facility?.label,
        },
        currency: state?.currency?.value,
        bookingType: state?.bookingType?.data,
        country: {
          id: state?.country?.value,
          name: state?.country?.label,
        },
        region: {
          id: state?.region?.id,
          name: state?.region?.name,
        },
        subRegion: {
          id: state?.subRegion?.id,
          name: state?.subRegion?.name,
        },
        procurementType: state?.procurementType?.value,
        taxPercentage: state?.taxPercentage?.toString(),
      };

      if (props?.title === `Update Benefit`) {
        dispatch({
          type: UPDATE_SERVICE,
          payload: {
            data: {
              requests: [{ ...payload }],
            },
          },
        });
      } else if (props?.title === `Add Benefit`) {
        dispatch({
          type: CREATE_SERVICE,
          payload: {
            data: payload,
          },
        });
      }
    }
  };

  /* ---------------------------- ACCORDION HANDLER ---------------------------- */
  const accordionToggleHandler = flag => {
    setAccordions({
      ...accordions,
      [flag]: {
        ...accordions[flag],
        status: !accordions[flag]['status'],
      },
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Row>
      <Col xxl={12}>
        {/* <button onClick={() => console.log(state)}>Get state</button> */}
        <Card>
          <PreviewCardHeader
            title={props?.title}
            primaryButton={hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE) && props?.title === `Add Benefit`}
            primaryButtonText={`Upload Benefits`}
            primaryClickHandler={() => props?.bulkUploadAction()}
          />
          <CardBody>
            <Form>
              <Row>
                <Col xxl={12}>
                  <CardBody>
                    <Accordion
                      className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-secondary"
                      id="accordionBordered"
                    >
                      {/* ---------------------------- ACCORDION - General ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.general?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`general`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>General</div>
                              {accordions?.general?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.general?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="category" className="form-label">
                                    Sub-Category <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <AsyncPaginate
                                    debounceTimeout={500}
                                    value={state?.category}
                                    // loadOptions={loadOptionForBenefitGroup}
                                    loadOptions={search => loadOptionForBenefits(search, 'category')}
                                    // onChange={inputSelectHandler}
                                    // loadOptions={loadOptions}
                                    onChange={data => inputSelectHandler('category', data)}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={() => 'No results found'}
                                    cacheUniqs={[['code']]}
                                    placeholder="Select..."
                                    // isDisabled={isServiceDisabled}
                                  />
                                  {isValidationShow && state?.category === null && <Error text="Please select" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="partner" className="form-label">
                                    Partner <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <AsyncPaginate
                                    debounceTimeout={500}
                                    value={state?.partner}
                                    // loadOptions={loadOptionForBenefitGroup}
                                    loadOptions={search => loadOptionForBenefits(search, 'partner')}
                                    // onChange={inputSelectHandler}
                                    // loadOptions={loadOptions}
                                    onChange={data => inputSelectHandler('partner', data)}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={() => 'No results found'}
                                    cacheUniqs={[['code']]}
                                    placeholder="Select..."
                                    isDisabled={!state?.category}
                                  />
                                  {isValidationShow && state?.partner === null && <Error text="Please select" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="facility" className="form-label">
                                    Facility
                                    {/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>
                                  <Select
                                    id="facility"
                                    name="facility"
                                    options={allFacilities ?? []}
                                    value={state?.facility}
                                    onChange={data => inputSelectHandler(`facility`, data)}
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="name" className="form-label">
                                    System Name <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    value={state.name}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && state?.name?.trim() === `` && <Error text="Please enter system name" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="priority" className="form-label">
                                    Priority
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="priority"
                                    name="priority"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.priority}
                                    autoComplete="off"
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="country" className="form-label">
                                    Country <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Select
                                    id="country"
                                    name="country"
                                    options={allCountries ?? []}
                                    value={state?.country}
                                    onChange={data => inputSelectHandler(`country`, data)}
                                  />
                                  {isValidationShow && state?.country === null && <Error text="Please select country" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="bookingType" className="form-label">
                                    Booking Type
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Select
                                    id="bookingType"
                                    name="bookingType"
                                    options={bookingTypesData ?? []}
                                    value={state?.bookingType}
                                    onChange={data => inputSelectHandler(`bookingType`, data)}
                                  />
                                  {isValidationShow && state?.bookingType === null && <Error text="Please select booking type" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="procurementType" className="form-label">
                                    Procurement Type
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Select
                                    id="procurementType"
                                    name="procurementType"
                                    options={procurementTypes ?? []}
                                    value={state?.procurementType}
                                    onChange={data => inputSelectHandler(`procurementType`, data)}
                                  />
                                  {isValidationShow && state?.procurementType === null && <Error text="Please select procurement type" />}
                                </div>
                              </Col>

                              {/* <Col md={4}>
																<div className="mb-3">
																	<Label htmlFor="region" className="form-label">
																		Region <span className="lbl_mandatory"> *</span>
																	</Label>
																	<Select
																		id="region"
																		name="region"
																		options={allRegions ?? []}
																		value={state?.region}
																		onChange={(data) => inputSelectHandler(`region`, data)}
																	/>
																	{isValidationShow && state?.region === null && (
																		<Error text="Please select region" />
																	)}
																</div>
															</Col> */}

                              {/* <Col md={4}>
																<div className="mb-3">
																	<Label htmlFor="subregion" className="form-label">
																		Subregion <span className="lbl_mandatory"> *</span>
																	</Label>
																	<Select
																		id="subregion"
																		name="subregion"
																		options={allSubRegions ?? []}
																		value={state?.subRegion}
																		onChange={(data) => inputSelectHandler(`subregion`, data)}
																	/>
																	{isValidationShow && state?.subRegion === null && (
																		<Error text="Please select sub region" />
																	)}
																</div>
															</Col> */}
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Display Properties ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.displayProperties?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`displayProperties`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Display Properties</div>
                              {accordions?.displayProperties?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.displayProperties?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="displayName" className="form-label">
                                    Display Name <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Input
                                    id="displayName"
                                    name="displayName"
                                    type="text"
                                    className="form-control"
                                    value={state?.displayName}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && state?.displayName?.trim() === `` && <Error text="Please enter display name" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="coverImageMobile" className="form-label">
                                    Cover Image Mobile URL <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Input
                                    id="coverImageMobile"
                                    name="coverImageMobile"
                                    type="text"
                                    className="form-control"
                                    value={state.images.coverImageMobile}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow &&
                                    (state?.images?.coverImageMobile === `` ||
                                      (state?.images?.coverImageMobile === `` && !isValidUrl(state?.images?.coverImageMobile))) && (
                                      <Error text="Please enter valid URL" />
                                    )}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="coverImageWeb" className="form-label">
                                    Cover Image Web URL <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Input
                                    id="coverImageWeb"
                                    name="coverImageWeb"
                                    type="text"
                                    className="form-control"
                                    value={state.images.coverImageWeb}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow &&
                                    (state?.images?.coverImageWeb === `` ||
                                      (state?.images?.coverImageWeb === `` && !isValidUrl(state?.images?.coverImageMobile))) && (
                                      <Error text="Please enter valid URL" />
                                    )}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="displayImageMobile" className="form-label">
                                    Display Image Mobile URL <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Input
                                    id="displayImageMobile"
                                    name="displayImageMobile"
                                    type="text"
                                    className="form-control"
                                    value={state.images.displayImageMobile}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow &&
                                    (state?.images?.displayImageMobile === `` ||
                                      (state?.images?.displayImageMobile === `` && !isValidUrl(state?.images?.coverImageMobile))) && (
                                      <Error text="Please enter valid URL" />
                                    )}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="displayImageWeb" className="form-label">
                                    Display Image Web URL <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Input
                                    id="displayImageWeb"
                                    name="displayImageWeb"
                                    type="text"
                                    className="form-control"
                                    value={state.images.displayImageWeb}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow &&
                                    (state?.images?.displayImageWeb === `` ||
                                      (state?.images?.displayImageWeb === `` && !isValidUrl(state?.images?.coverImageMobile))) && (
                                      <Error text="Please enter valid URL" />
                                    )}
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="shortDescription" className="form-label">
                                    Short Description <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <div className="snow-editor" style={{ height: 300 }}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.shortDescription}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`shortDescription`, data);
                                      }}
                                    />
                                  </div>
                                  {isValidationShow && state?.shortDescription === `` && <Error text="Please enter short description" />}
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="longDescription" className="form-label">
                                    Long Description <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <div className="snow-editor" style={{ height: 300 }}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.longDescription}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`longDescription`, data);
                                      }}
                                    />
                                  </div>
                                  {isValidationShow && state?.longDescription === `` && <Error text="Please enter long description" />}
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="redemptionProcess" className="form-label">
                                    Default Redemption Process
                                    <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <div className="snow-editor" style={{ height: 300 }}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.redemptionProcess}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`redemptionProcess`, data);
                                      }}
                                    />
                                  </div>
                                  {isValidationShow && state?.redemptionProcess === `` && <Error text="Please enter redemption process" />}
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="termsAndCondition" className="form-label">
                                    Default T&C <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <div className="snow-editor" style={{ minHeight: 300 }}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.termsAndCondition}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`termsAndCondition`, data);
                                      }}
                                    />
                                  </div>
                                  {isValidationShow && state?.termsAndCondition === `` && <Error text="Please enter default term & conditions" />}
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3">
                                  <Label htmlFor="benefitOffered" className="form-label">
                                    Default Benefit Offered <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <div className="snow-editor" style={{ minHeight: 300 }}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={state?.benefitOffered}
                                      config={{
                                        toolbar: {
                                          items: Constant.CkEditorToolbar,
                                        },
                                      }}
                                      onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        ckEditorDataInput(`benefitOffered`, data);
                                      }}
                                    />
                                  </div>
                                  {isValidationShow && state?.termsAndCondition === `` && <Error text="Please enter benefit offered" />}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Booking Properties ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.bookingProperties?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`bookingProperties`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Booking Properties</div>
                              {accordions?.bookingProperties?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.bookingProperties?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="thirdPartyWebsite" className="form-label">
                                    Default Third Party Website URL
                                  </Label>
                                  <Input
                                    id="thirdPartyWebsite"
                                    name="thirdPartyWebsite"
                                    type="text"
                                    className="form-control"
                                    value={state.thirdPartyWebsite}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                  {isValidationShow && !isValidUrl(state?.thirdPartyWebsite) && <Error text="Please enter valid URL" />}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - TAT ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.tat?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`tat`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>TAT</div>
                              {accordions?.tat?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.tat?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              {/* ------------------------ TAT Minimum ------------------------ */}
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-minimum-unit" className="form-label">
                                    TAT Minimum Unit
                                  </Label>

                                  <select
                                    id="tat-minimum-unit"
                                    name="tat-minimum-unit"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.minimum?.unit}
                                  >
                                    <option value="0">Choose</option>
                                    <option value="2">Months</option>
                                    <option value="3">Days</option>
                                    <option value="4">Hours</option>
                                    <option value="5">Minute</option>
                                    <option value="6">Second</option>
                                    <option value="7">Clear Day</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-minimum-value" className="form-label">
                                    TAT Minimum Value
                                  </Label>

                                  <Input
                                    id="tat-minimum-value"
                                    name="tat-minimum-value"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.minimum?.value}
                                    autoComplete="off"
                                    min="0"
                                    disabled={state?.tat?.minimum?.unit === 0}
                                  />
                                </div>
                              </Col>

                              {/* ------------------------ TAT Maximum ------------------------ */}
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-maximum-unit" className="form-label">
                                    TAT Maximum Unit
                                  </Label>

                                  <select
                                    id="tat-maximum-unit"
                                    name="tat-maximum-unit"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.maximum?.unit}
                                  >
                                    <option value="0">Choose</option>
                                    <option value="2">Months</option>
                                    <option value="3">Days</option>
                                    <option value="4">Hours</option>
                                    <option value="5">Minute</option>
                                    <option value="6">Second</option>
                                    <option value="7">Clear Day</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-maximum-value" className="form-label">
                                    TAT Maximum Value
                                  </Label>

                                  <Input
                                    id="tat-maximum-value"
                                    name="tat-maximum-value"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.maximum?.value}
                                    autoComplete="off"
                                    min="0"
                                    disabled={state?.tat?.maximum?.unit === 0}
                                  />
                                </div>
                              </Col>

                              {/* ------------------------ TAT Cancel ------------------------ */}
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-cancel-unit" className="form-label">
                                    TAT Cancel Unit
                                  </Label>

                                  <select
                                    id="tat-cancel-unit"
                                    name="tat-cancel-unit"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.cancel?.unit}
                                  >
                                    <option value="0">Choose</option>
                                    <option value="2">Months</option>
                                    <option value="3">Days</option>
                                    <option value="4">Hours</option>
                                    <option value="5">Minute</option>
                                    <option value="6">Second</option>
                                    <option value="7">Clear Day</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-cancel-value" className="form-label">
                                    TAT Cancel Value
                                  </Label>

                                  <Input
                                    id="tat-cancel-value"
                                    name="tat-cancel-value"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.tat?.cancel?.value}
                                    autoComplete="off"
                                    min="0"
                                    disabled={state?.tat?.cancel?.unit === 0}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Pricing & Payments ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.pricingsAndPayments?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`pricingsAndPayments`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Pricings & Payments</div>
                              {accordions?.pricingsAndPayments?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.pricingsAndPayments?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="taxType" className="form-label">
                                    Default TAX Type
                                  </Label>
                                  <select id="taxType" name="taxType" className="form-select" value={state?.taxType} onChange={inputChangeHandler}>
                                    <option value="">Choose...</option>
                                    <option value="GST">GST</option>
                                    <option value="VAT">VAT</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="currency" className="form-label">
                                    Default Currency <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Select
                                    id="currency"
                                    name="currency"
                                    options={allCurrencies ?? []}
                                    value={state?.currency}
                                    onChange={data => inputSelectHandler(`currency`, data)}
                                  />
                                  {isValidationShow && state?.currency === `` && <Error text="Please select currency" />}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="costPrice" className="form-label">
                                    Cost Price <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Input
                                    id="costPrice"
                                    name="costPrice"
                                    type="text"
                                    className="form-control"
                                    value={state.costPrice}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="sellingPrice" className="form-label">
                                    Selling Price <span className="lbl_mandatory">*</span>
                                  </Label>
                                  <Input
                                    id="sellingPrice"
                                    name="sellingPrice"
                                    type="text"
                                    className="form-control"
                                    value={state.sellingPrice}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="taxPercentage" className="form-label">
                                    Tax Percentage
                                  </Label>
                                  <Input
                                    id="taxPercentage"
                                    name="taxPercentage"
                                    type="text"
                                    className="form-control"
                                    value={state?.taxPercentage}
                                    onChange={inputChangeHandler}
                                    autoComplete="off"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - Coupon Procurement Time ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.couponProcurementTime?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`couponProcurementTime`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>Coupon Procurement Time (Delayed Booking) </div>
                              {accordions?.couponProcurementTime?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse
                          isOpen={accordions?.couponProcurementTime?.status}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse1"
                        >
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="procurement-minimum-tat-unit" className="form-label">
                                    TAT Unit
                                  </Label>
                                  <select
                                    className="form-control"
                                    id="procurement-minimum-tat-unit"
                                    name="procurement-minimum-tat-unit"
                                    onChange={inputChangeHandler}
                                    value={state?.couponProcurementTat?.min?.unit}
                                  >
                                    <option value="0">Choose</option>
                                    <option value="4">Hours</option>
                                    <option value="5">Minute</option>
                                    <option value="2">Months</option>
                                    <option value="3">Days</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="procurement-minimum-tat-value" className="form-label">
                                    TAT Value
                                  </Label>

                                  <Input
                                    id="procurement-minimum-tat-value"
                                    name="procurement-minimum-tat-value"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.couponProcurementTat?.min?.value}
                                    autoComplete="off"
                                    min="0"
                                    disabled={state?.couponProcurementTat?.min?.unit === 0}
                                  />
                                </div>
                              </Col>

                              {/* <Col md={6}>
																<div className="mb-3">
																	<Label
																		htmlFor="procurement-maximum-tat-unit"
																		className="form-label"
																	>
																		Maximum TAT Unit
																	</Label>

																	<select
																		className="form-control"
																		id="procurement-maximum-tat-unit"
																		name="procurement-maximum-tat-unit"
																		onChange={inputChangeHandler}
																		value={state?.couponProcurementTat?.max?.unit}
																	>
																		<option value="0">Choose...</option>
																		<option value="4">Hours</option>
																		<option value="3">Days</option>
																	</select>
																</div>
															</Col>

															<Col md={6}>
																<div className="mb-3">
																	<Label
																		htmlFor="procurement-maximum-tat-value"
																		className="form-label"
																	>
																		Maximum TAT Value
																	</Label>

																	<Input
																		id="procurement-maximum-tat-value"
																		name="procurement-maximum-tat-value"
																		type="number"
																		className="form-control"
																		onChange={inputChangeHandler}
																		value={state?.couponProcurementTat?.max?.value}
																		autoComplete="off"
																		min="0"
																		disabled={
																			state?.couponProcurementTat?.max?.value === 0
																		}
																	/>
																</div>
															</Col> */}
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>

                      {/* ---------------------------- ACCORDION - MQL Setting ---------------------------- */}
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames('accordion-button', {
                              collapsed: !accordions?.mqlSetting?.status,
                            })}
                            type="button"
                            onClick={() => accordionToggleHandler(`mqlSetting`)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>MQL Settings</div>
                              {accordions?.mqlSetting?.error && (
                                <div style={{ marginRight: '23px', color: 'red' }}>
                                  <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                </div>
                              )}
                            </div>
                          </button>
                        </h2>

                        <Collapse isOpen={accordions?.mqlSetting?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                          <div className="accordion-body color-black">
                            <Row>
                              <Col md={12}>
                                <div
                                  style={{
                                    marginBottom: '10px',
                                  }}
                                >
                                  <div className="form-switch d-inline-block ">
                                    <Input
                                      className="form-check-input code-switcher"
                                      type="checkbox"
                                      id="mql-isActive"
                                      name="mql-isActive"
                                      checked={state?.mql?.isActive}
                                      onChange={inputChangeHandler}
                                    />
                                  </div>
                                  <Label className="form-label">Is Active?</Label>
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="voucher-mql-1" className="form-label">
                                    MQL 1
                                  </Label>

                                  <Input
                                    id="voucher-mql-1"
                                    name="voucher-mql-1"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.mql?.mqlAlertLevelFirst}
                                    autoComplete="off"
                                    disabled={!state?.mql?.isActive}
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-minimum-value" className="form-label">
                                    MQL 2
                                  </Label>

                                  <Input
                                    id="voucher-mql-2"
                                    name="voucher-mql-2"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.mql?.mqlAlertLevelSecond}
                                    autoComplete="off"
                                    disabled={!state?.mql?.isActive}
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="tat-maximum-unit" className="form-label">
                                    MQL 3
                                  </Label>

                                  <Input
                                    id="voucher-mql-3"
                                    name="voucher-mql-3"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.mql?.mqlAlertLevelThird}
                                    autoComplete="off"
                                    disabled={!state?.mql?.isActive}
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={12}>
                                <hr />
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="voucher-expiry-block" className="form-label">
                                    Expiry Block
                                  </Label>

                                  <Input
                                    id="voucher-expiry-block"
                                    name="voucher-expiry-block"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.voucherDetails?.expiryBlock}
                                    autoComplete="off"
                                    disabled={!state?.mql?.isActive}
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="voucher-expiry-alert-1" className="form-label">
                                    Expiry Alert 1{/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>

                                  <Input
                                    id="voucher-expiry-alert-1"
                                    name="voucher-expiry-alert-1"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.voucherDetails?.expiryAlertLevelFirst}
                                    autoComplete="off"
                                    disabled={!state?.mql?.isActive}
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="voucher-expiry-alert-2" className="form-label">
                                    Expiry Alert 2{/* <span className="lbl_mandatory"> *</span> */}
                                  </Label>

                                  <Input
                                    id="voucher-expiry-alert-2"
                                    name="voucher-expiry-alert-2"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.voucherDetails?.expiryAlertLevelSecond}
                                    autoComplete="off"
                                    disabled={!state?.mql?.isActive}
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="voucher-expiry-alert-3" className="form-label">
                                    Expiry Alert 3
                                  </Label>

                                  <Input
                                    id="voucher-expiry-alert-3"
                                    name="voucher-expiry-alert-3"
                                    type="text"
                                    className="form-control"
                                    onChange={inputChangeHandler}
                                    value={state?.voucherDetails?.expiryAlertLevelThird}
                                    autoComplete="off"
                                    disabled={!state?.mql?.isActive}
                                    min="0"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </AccordionItem>
                    </Accordion>
                  </CardBody>
                </Col>
              </Row>
            </Form>
          </CardBody>

          <CardFooter>
            <Row>
              <Col md={12}>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler}>
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};

export default ServiceForm;
