import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';

import { ASSIGN_CHAUFFEUR, ASSIGN_CHAUFFEUR_EMPTY } from '../../../../../../../store/actions';
import { Error } from '../../../../../../Common';
import MobileField from '../../../../../../Common/FormControls/MobileField';
import { isValidAplha } from '../../../../../../Helpers/Helpers';

const AssignChauffeur = ({ onClose, currentSelectedBooking, carsListOfVendor, chauffeursList }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(assignChauffeurInitialObj);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [chauffeurOptions, setChauffeurOptions] = useState([]);
  const [isAddChauffeur, setIsAddChauffeur] = useState(true);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { assignChauffeur, assignChauffeurLoading } = useSelector(state => ({
    assignChauffeur: state?.AssignChauffeur?.data,
    assignChauffeurLoading: state?.AssignChauffeur?.loading,
  }));

  useEffect(() => {
    if (chauffeursList?.chauffeurs) {
      const chauffeurOption = chauffeursList?.chauffeurs?.map(chauffeur => ({
        label: `${chauffeur?.name}`,
        value: `${chauffeur?.id}`,
        data: chauffeur,
      }));
      setChauffeurOptions([
        {
          value: 'add_chauffeur',
          label: <span style={{ cursor: 'pointer !important', fontWeight: 'medium' }}>➕ Add Chauffeur</span>,
          isCustom: true,
        },
        ...chauffeurOption,
      ]);
    } else {
      setChauffeurOptions([
        {
          value: 'add_chauffeur',
          label: <span style={{ cursor: 'pointer !important', fontWeight: 'medium' }}>➕ Add Chauffeur</span>,
          isCustom: true,
        },
      ]);
    }
  }, [chauffeursList]);

  useEffect(() => {
    if (assignChauffeur !== null) {
      dispatch({
        type: ASSIGN_CHAUFFEUR_EMPTY,
      });
      onClose();
    }

    return () => {
      dispatch({
        type: ASSIGN_CHAUFFEUR_EMPTY,
      });
    };
  }, [assignChauffeur]);

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    switch (label) {
      case `chauffeur`:
        if (data?.value === 'add_chauffeur') {
          setIsAddChauffeur(false);
          updatedState = {
            ...state,
            chauffeur: {
              ...state?.chauffeur,
              name: ``,
              mobileNumber: ``,
              countryCode: ``,
            },
            car: {
              code: ``,
              name: ``,
              number: ``,
              id: null,
            },
          };
        } else {
          updatedState = {
            ...state,
            chauffeur_id: data,
            chauffeur: {
              ...state?.chauffeur,
              name: `${data?.data?.name}`,
              mobileNumber: `${data?.data?.mobileNumber}`,
              countryCode: `${data?.data?.countryCode}`,
            },
          };
        }

        break;

      case `carId`:
        updatedState = {
          ...state,
          car: {
            ...state?.car,
            id: data,
          },
        };
        break;

      case `code`:
        updatedState = {
          ...state,
          car: {
            ...state?.car,
            code: data,
          },
        };
        break;

      case `name`:
        updatedState = {
          ...state,
          car: {
            ...state?.car,
            name: isValidAplha(data) ? data : state?.car?.name,
          },
        };
        break;

      case `number`:
        updatedState = {
          ...state,
          car: {
            ...state?.car,
            number: data,
          },
        };
        break;

      default:
        break;
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- MOBILE CHANGE HANDLER ---------------------------- */
  const mobileChangeHandler = async (value, data) => {
    let updatedState = {
      ...state,
      countryCode: data?.dialCode,
      mobileNumber: value.slice(data?.dialCode?.length),
    };

    setState({
      ...state,
      chauffeur: {
        ...state?.chauffeur,
        countryCode: data?.dialCode,
        mobileNumber: value.slice(data?.dialCode?.length),
      },
    });

    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    const isChauffeurValid = isAddChauffeur
      ? data?.chauffeur_id && data?.chauffeur_id !== ``
      : data?.chauffeur?.name?.trim() && data?.chauffeur?.name?.trim() !== ``;

    if (data?.car?.code !== `` && data?.car?.name !== `` && data?.car?.number !== `` && data?.car?.id !== null && isChauffeurValid) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        ...state,
        chauffeur_id: state?.chauffeur_id?.data?.id ?? '',

        car: {
          ...state?.car,
          id: state?.car?.id?.value ?? '',
          type: state?.car?.id?.label ?? '',
        },
        booking_id: currentSelectedBooking?.id ?? '',
        vendor_id: `${currentSelectedBooking?.vendorId ?? ''}`,
      };

      dispatch({
        type: ASSIGN_CHAUFFEUR,
        payload: {
          body: payload,
        },
      });
    }
  };

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;

    let updatedState = null;

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          chauffeur: {
            ...state?.chauffeur,
            name: isValidAplha(value) ? value : state?.chauffeur?.name,
          },
        };
        break;

      default:
    }

    setState(updatedState);
  };

  return (
    <Modal isOpen={true} centered>
      <ModalHeader toggle={onClose}>
        <h4>Assign to Chauffeur</h4>
      </ModalHeader>

      <ModalBody>
        <Row>
          {/* <button onClick={() => console.log('submittion3', state)}>get state</button> */}
          {isAddChauffeur && (
            <Col md={12} className="mb-3">
              <Label htmlFor="chauffeurs" className="form-label">
                Chauffeur Name
              </Label>
              <Select
                id="chauffeurs"
                name="chauffeurs"
                placeholder="Chauffeur Name"
                value={state?.chauffeur_id}
                options={chauffeurOptions}
                onChange={data => inputSelectHandler(`chauffeur`, data)}
              />
              {isValidationShow && state?.chauffeur_id === `` && <Error text="Please select chauffeur" />}
            </Col>
          )}

          {!isAddChauffeur && (
            <Col md={12} className="mb-3">
              <Label htmlFor="chauffeurs" className="form-label">
                Chauffeur Name
              </Label>

              <Input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Enter Chauffeur Name"
                value={state?.chauffeur.name}
                onChange={inputChangeHandler}
                autoComplete="off"
              />
              {isValidationShow && state?.chauffeur?.name?.trim() === `` && <Error text="Please enter chauffeur name" />}
            </Col>
          )}

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label">
                Chauffeur Mobile No.
              </Label>
              <div className="input-group" style={{ alignItems: 'center' }}>
                <MobileField
                  placeholder="Chauffeur Mobile No."
                  onChange={mobileChangeHandler}
                  value={`${state?.chauffeur?.countryCode}${state?.chauffeur?.mobileNumber}`}
                  preferredCountries={['sa', 'uae', 'in']}
                  defaultCountry={'sa'}
                  disabled={isAddChauffeur}
                  // inputHandler={searchHandler}
                />
              </div>
              {isValidationShow && state?.chauffeur?.mobileNumber === `` && <Error text="Please select mobile" />}
            </div>
          </Col>

          <Col md={12} className="mb-3">
            <Label htmlFor="code" className="form-label">
              Car Number
            </Label>

            <div className="d-flex gap-2">
              <Input
                id="code"
                name="code"
                type="number"
                placeholder="Code"
                onWheel={e => e.target.blur()}
                className="form-control"
                value={state?.car?.code}
                onChange={e => inputSelectHandler(`code`, e.target.value)}
              />

              <Input
                id="name"
                name="name"
                type="text"
                placeholder="Name"
                onWheel={e => e.target.blur()}
                className="form-control"
                value={state?.car?.name}
                onChange={e => inputSelectHandler(`name`, e.target.value)}
              />

              <Input
                id="number"
                name="number"
                type="number"
                placeholder="Number"
                onWheel={e => e.target.blur()}
                className="form-control"
                value={state?.car?.number}
                onChange={e => inputSelectHandler(`number`, e.target.value)}
              />
            </div>
            {isValidationShow && (state?.car?.code?.trim() === '' || state?.car?.name?.trim() === '' || state?.car?.number?.trim() === '') && (
              <Error text="Please enter car details" />
            )}
          </Col>

          <Col md={12} className="mb-3">
            <Label htmlFor="currency" className="form-label">
              Car Type
            </Label>
            <Select
              id="currency"
              name="currency"
              placeholder="Car Type"
              value={state?.car?.id}
              options={carsListOfVendor?.cars?.map(car => ({
                label: `${car?.name}`,
                value: `${car?.id}`,
                data: car,
              }))}
              onChange={data => inputSelectHandler(`carId`, data)}
            />
            {isValidationShow && state?.car?.id === null && <Error text="Please select car" />}
          </Col>

          <Col md={12} className="mt-3 mb-4 text-center">
            {!assignChauffeurLoading && (
              <Button color="primary" style={{ fontSize: '14px', width: '200px' }} onClick={submitHandler}>
                {isAddChauffeur ? `Assign to Chauffeur` : `Add & Assign chauffeur`}
              </Button>
            )}

            {assignChauffeurLoading && (
              <Button color="primary" style={{ fontSize: '14px', width: '200px' }}>
                <Spinner className="btn-replacer-spinner" />
              </Button>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default AssignChauffeur;

const assignChauffeurInitialObj = {
  booking_id: '',
  chauffeur_id: '',
  chauffeur: {
    name: '',
    mobileNumber: '',
    countryCode: '',
  },
  car: {
    code: '',
    name: '',
    number: '',
    id: null,
    type: '',
  },
};
