import { useEffect, useRef, useState } from 'react';
import { default as CogoToast } from 'cogo-toast';
import Select from 'react-select';
import { Input } from 'reactstrap';
import { useSelector } from 'react-redux';

const GlobalFilter = props => {
  const [filter, setFilter] = useState({ search: '', bookingType: '' });
  const [filterType, setFilterType] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const filterContainerRef = useRef(null);
  const [bookingTypeOptions, setBookingTypeOptions] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { bookingTypeData } = useSelector((state: any) => ({
    bookingTypeData: state.GetBookingTypeForGlobalSearch.data,
  }));

  useEffect(() => {
    if (filterContainerRef.current) {
      filterContainerRef.current.scrollLeft = filterContainerRef.current.scrollWidth;
    }
  }, [appliedFilters]);

  useEffect(() => {
    if (bookingTypeData !== null) {
      setBookingTypeOptions(
        bookingTypeData?.results?.map(booking => {
          return {
            value: booking?.key,
            label: booking?.value,
          };
        }),
      );
    }
  }, [bookingTypeData]);

  useEffect(() => {
    if (props?.search && props?.bookingType && bookingTypeOptions?.length > 0) {
      setAppliedFilters({ search: props?.search });
      setFilterType(bookingTypeOptions.find(booking => booking.value === props?.bookingType));
    }
  }, [props?.search, bookingTypeOptions]);

  const inputChangeHandler = e => {
    setFilter({
      ...filter,
      search: e.target.value,
    });
  };

  const submitHandler = () => {
    if (!filter?.search?.trim()) {
      CogoToast.error('Field cannot be empty');
      return;
    }

    const updatedFilters = { ...filter, search: filter?.search, bookingType: filterType?.value };
    setAppliedFilters(updatedFilters);
    setFilter({ ...filter, search: '', bookingType: filterType?.value });
    props?.filterHandler({ ...filter, search: filter?.search, bookingType: filterType?.value });
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      submitHandler();
    }
  };

  const removeFilter = () => {
    const updatedFilters = { ...filter, search: '' };
    setAppliedFilters(updatedFilters);
    setFilter(prev => ({ ...prev, search: '' }));

    props?.filterHandler({ ...filter, search: '' });
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Select
            options={bookingTypeOptions || []}
            placeholder="Select"
            value={filterType}
            onChange={data => setFilterType(data)}
            styles={{ control: provided => ({ ...provided, width: '200px' }) }}
            isSearchable={false}
            className="filter-select"
          />
          <Input
            type="text"
            placeholder="Search"
            value={filter?.search || ''}
            onChange={inputChangeHandler}
            style={{ width: '200px' }}
            onKeyPress={handleKeyPress}
          />
        </div>

        <div ref={filterContainerRef} style={{ display: 'flex', gap: '10px', overflowX: 'auto', whiteSpace: 'nowrap', width: '412px' }}>
          {appliedFilters?.search && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', background: '#abb9e8', padding: '3px 4px', borderRadius: '4px' }}>
              <span className="fs-10">{appliedFilters?.search}</span>
              <button style={{ border: 'none', background: 'transparent', cursor: 'pointer' }} onClick={removeFilter}>
                &times;
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GlobalFilter;
