import React, { useEffect, useState } from 'react';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import axios, { AxiosResponse } from 'axios';
import { customHeaders, thriweDiscoveryUrl } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  CREATE_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT_EMPTY,
  POST_IMAGE,
  POST_IMAGE_EMPTY,
  UPDATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_EMPTY,
} from '../../../../../../../store/actions';
import { Error, Loader } from '../../../../../../Common';
import parse from 'html-react-parser';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import cogoToast from 'cogo-toast';
import { FaTimes } from 'react-icons/fa';

// Define types for the state
interface AnnouncementState {
  type: OptionType | null;
  title: string;
  content: string;
  file_url: string;
  start_date: string;
  end_date: string;
  is_featured: boolean;
  priority: OptionType | null;
  linkType: OptionType | null;
  announcement_id: string | null;
}

// Define types for the options in the select dropdown
interface OptionType {
  value: string;
  label: string;
}

const AnnoucementLinkOptions = [
  { value: 'Video Link', label: 'Video Link' },
  { value: 'Upload Video', label: 'Upload Video' },
];

const priorityOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

const CreateAnnouncement = props => {
  const dispatch = useDispatch();

  // Initialize state variables unconditionally
  const initialState: AnnouncementState = {
    type: props?.annoucementDetails?.type ? { value: String(props.annoucementDetails.type), label: String(props.annoucementDetails.type) } : null,
    title: props?.annoucementDetails?.title ?? '',
    content: props?.annoucementDetails?.content ?? '',
    file_url: props?.annoucementDetails?.file_url ?? '',
    start_date: props?.annoucementDetails?.start_date ?? '',
    end_date: props?.annoucementDetails?.end_date ?? '',
    is_featured: props?.annoucementDetails?.is_featured ?? false,
    priority: props?.annoucementDetails?.priority
      ? { value: String(props.annoucementDetails.priority), label: String(props.annoucementDetails.priority) }
      : null,
    linkType: props?.annoucementDetails?.type === 'SHORT_VIDEO' ? { value: 'Video Link', label: 'Video Link' } : null,
    announcement_id: null,
  };

  // Hooks must be called in the same order
  const [state, setState] = useState<AnnouncementState>(initialState);
  const [loading, setLoading] = useState<boolean>(false);
  const [isValidationShow, setIsValidationShow] = useState(false);

  console.log('State', state);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const createUploadTicket = useSelector((state: any) => state?.PostImage?.data);
  const createUploadTicketLoading = useSelector((state: any) => state?.PostImage?.loading);

  const createAnnouncement = useSelector((state: any) => state?.CreateAnnouncementForGlobeToyota?.data);
  const createAnnouncementLoading = useSelector((state: any) => state?.CreateAnnouncementForGlobeToyota?.loading);

  const updateAnnouncement = useSelector((state: any) => state?.UpdateAnnouncementForGlobeToyota?.data);
  const updateAnnouncementLoading = useSelector((state: any) => state?.UpdateAnnouncementForGlobeToyota?.loading);

  /* ---------------------------- EFFECTS ---------------------------- */
  useEffect(() => {
    if (createAnnouncement !== null) {
      dispatch({ type: CREATE_ANNOUNCEMENT_EMPTY });
      dispatch({ type: POST_IMAGE_EMPTY });
      setState(null);
      props?.setIsManageAnnouncement(true);
    }
  }, [createAnnouncement, dispatch]);

  useEffect(() => {
    if (createUploadTicket) {
      setState(prevState => ({ ...prevState, file_url: createUploadTicket?.data }));
    }
  }, [createUploadTicket]);

  useEffect(() => {
    setLoading(createUploadTicketLoading || createAnnouncementLoading || updateAnnouncementLoading);
  }, [createUploadTicketLoading, createAnnouncementLoading, updateAnnouncementLoading]);

  useEffect(() => {
    if (updateAnnouncement !== null) {
      dispatch({ type: UPDATE_ANNOUNCEMENT_EMPTY });
    }
  }, [updateAnnouncement, dispatch]);

  // Load options function for AsyncPaginate
  const loadOptionForAnnouncement = async (search: string) => {
    try {
      const response: AxiosResponse = await axios({
        method: 'GET',
        url: `${thriweDiscoveryUrl}/v1/admin/announcement-types`,
        headers: {
          Authorization: Constant?.CLIENT_TOKEN,
          ...customHeaders,
        },
        params: {
          searchName: search === '' ? null : search, // Changed `name` to `searchName`
        },
      });

      const options = response.data.results.map(data => ({
        value: data.enum,
        label: data.name,
      }));

      return {
        options,
        hasMore: false,
      };
    } catch (error) {
      console.error('Error fetching announcement types:', error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  // Handle selection change
  const onSelectAnnouncement = (selectedOption: OptionType | null) => {
    let isFileEmpty = false;
    if (state?.file_url) {
      if (selectedOption?.value === 'SHORT_VIDEO')
        isFileEmpty = selectedOption.value === 'SHORT_VIDEO' && (state?.type?.value === 'ANNOUNCEMENT' || state?.type?.value === 'GLOBE_TOYOTA_HUB');
      if (state.type.value === 'SHORT_VIDEO')
        isFileEmpty = state.type.value === 'SHORT_VIDEO' && (selectedOption.value === 'ANNOUNCEMENT' || selectedOption.value === 'GLOBE_TOYOTA_HUB');
    }
    setState(prevState => ({
      ...prevState,
      type: selectedOption,
      file_url: isFileEmpty ? '' : state?.file_url,
    }));
  };

  const ckEditorDataInput = (name: string, data: any) => {
    let updatedState = null;

    switch (name) {
      case 'announcementTitle':
        updatedState = {
          ...state,
          title: data,
        };
        setState(updatedState);
        break;

      case 'announcementContent':
        updatedState = {
          ...state,
          content: data,
        };
        setState(updatedState);
        break;

      default:
      // do nothing
    }
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- DATE SELECT HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...state };
    const selectedDate = new Date(date);

    switch (flag) {
      case 'startDate':
        updatedState = {
          ...state,
          start_date: selectedDate.toISOString(),
        };
        break;

      case 'endDate':
        updatedState = {
          ...state,
          end_date: selectedDate.toISOString(),
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  const handleFileUpload = (files, type) => {
    const uploadedFile = files[0];
    if (!uploadedFile) return;

    console.log('Uploaded file:', uploadedFile);

    const allowedExtensions = {
      image: ['jpg', 'jpeg', 'png', 'svg', 'webp'],
      video: ['mp4', 'avi', 'mov', 'mkv'],
    };

    const maxFileSize = {
      image: 10 * 1024 * 1024, // 10MB for images
      video: 50 * 1024 * 1024, // 50MB for videos
    };

    // Check if the provided type is valid
    if (!allowedExtensions[type]) {
      cogoToast.error(`Invalid file type: ${type}`);
      return;
    }

    const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();

    // Validate the file extension based on the type
    if (!allowedExtensions[type].includes(fileExtension)) {
      cogoToast.error(`Invalid file extension for ${type}. Allowed: ${allowedExtensions[type].join(', ')}`);
      return;
    }

    // Validate the file size based on the type
    if (uploadedFile.size > maxFileSize[type]) {
      cogoToast.error(`File size exceeds the limit for ${type}. Max size: ${maxFileSize[type] / (1024 * 1024)}MB`);
      return;
    }
    const formData = new FormData();
    const newFile = new File([uploadedFile], uploadedFile.name, { type: uploadedFile.type });
    formData.append('file', newFile);
    dispatch({
      type: POST_IMAGE,
      payload: {
        data: formData,
        urlParam: {
          fileType: 4,
        },
      },
    });
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag: string) => {
    const startDate = new Date(data?.start_date);
    const endDate = new Date(data?.end_date);

    const isContentValid = data?.type?.value === 'SHORT_VIDEO' || data?.content !== '';

    if (
      data?.type !== null &&
      data?.title !== '' &&
      data?.start_date !== '' &&
      data?.end_date !== '' &&
      data?.file_url !== '' &&
      isContentValid &&
      data?.priority !== null &&
      startDate <= endDate
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === 'submissionCheck' ? true : isValidationShow);
      return false;
    }
  };

  const submitHandler = () => {
    console.log(validateErrors(state, `submissionCheck`));
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        type: state.type.value,
        title: state.title,
        content: state.content,
        file_url: state.file_url,
        start_date: state.start_date,
        end_date: state.end_date,
        is_featured: state.is_featured,
        priority: state.priority ? Number(state.priority?.value) : 0, // only positive integers
      };
      if (props?.annoucementDetails) {
        dispatch({
          type: UPDATE_ANNOUNCEMENT,
          payload: {
            data: {
              ...payload,
              announcement_id: props?.annoucementDetails?.announcement_id,
            },
            id: props?.annoucementDetails?.announcement_id,
          },
        });
      } else {
        dispatch({
          type: CREATE_ANNOUNCEMENT,
          payload: {
            data: {
              ...payload,
            },
          },
        });
      }
    }
  };

  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'title': {
        // Count words by splitting on spaces
        const wordCount = value.trim().length;
        if (wordCount <= 200) {
          updatedState = {
            ...state,
            title: value?.trim() ? value : '',
          };
          setState(updatedState);
          validateErrors(updatedState, `validationCheck`);
        }
        break;
      }
      default:
    }
  };

  return (
    <Row>
      {loading && <Loader />}
      <Card>
        <Row>
          <Col xxl={12}>
            <Row>
              <Col xl={8} lg={8} md={6} sm={6} xs={12}>
                <Card>
                  <CardHeader className="fw-medium fs-18">{props?.annoucementDetails ? 'Update Announcement' : 'Create Announcement'}</CardHeader>
                  <CardBody>
                    <Row>
                      <Col xl={12}>
                        <div className="mb-3">
                          <Label htmlFor="name" className="form-label">
                            Announcement Type
                          </Label>
                          <AsyncPaginate
                            debounceTimeout={500}
                            value={state?.type}
                            loadOptions={loadOptionForAnnouncement}
                            onChange={onSelectAnnouncement}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => 'No results found'}
                            cacheUniqs={[['code']]}
                            placeholder="Select"
                            isDisabled={props?.annoucementDetails ? true : false}
                          />
                          {isValidationShow && state?.type === null && <Error text="Please select annoucement type" />}
                        </div>
                      </Col>
                      <Col xl={12}>
                        <div className="mb-3">
                          <Label htmlFor="name" className="form-label">
                            Announcement Title
                          </Label>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            value={state.title}
                            onChange={inputChangeHandler}
                            autoComplete="off"
                          />
                          {isValidationShow && state?.title === '' && <Error text="Please enter annoucement title" />}
                        </div>
                      </Col>
                      {state.type && state.type?.value !== 'SHORT_VIDEO' && (
                        <Col xl={12}>
                          <div className="mb-3">
                            <Label htmlFor="name" className="form-label">
                              Announcement Content
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              data={state?.content || ''}
                              onChange={(_, editor) => {
                                let data = editor?.getData();
                                data = data.replace(/<a /g, '<a target="_blank" ');
                                ckEditorDataInput(`announcementContent`, data);
                              }}
                            />
                            {isValidationShow && state?.content === '' && <Error text="Please enter annoucement content" />}
                          </div>
                        </Col>
                      )}
                      {state.type && state.type?.value === 'SHORT_VIDEO' && (
                        <Col md={12}>
                          <div className="mb-3">
                            <Label htmlFor="benefit-group-status" className="form-label">
                              Annoucement Link Type
                            </Label>
                            <Select
                              id="benefit-group-status"
                              name="benefit-group-status"
                              options={AnnoucementLinkOptions ?? []}
                              value={state.linkType}
                              onChange={data => setState({ ...state, linkType: data })}
                            />
                            {isValidationShow && state?.linkType === null && <Error text="Please select annoucement link type" />}
                          </div>
                        </Col>
                      )}
                      {((props?.annoucementDetails && state.type?.value === 'SHORT_VIDEO' && state.linkType.value === 'Video Link') ||
                        state?.linkType?.value === 'Video Link') && (
                        <Col md={12}>
                          <div className="mb-3">
                            <Label htmlFor="name" className="form-label">
                              Annoucement Link
                            </Label>
                            <Input
                              id="name"
                              name="name"
                              type="text"
                              className="form-control"
                              value={state.file_url}
                              onChange={e => setState({ ...state, file_url: e.target.value })}
                              autoComplete="off"
                            />
                            {isValidationShow && state?.file_url === `` && <Error text="Please provide a video link" />}
                          </div>
                        </Col>
                      )}
                      {state?.type?.value && (state?.type?.value !== 'SHORT_VIDEO' || state?.linkType?.value === 'Upload Video') && (
                        <Col className="mb-3">
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleFileUpload(acceptedFiles, state?.type?.value === 'SHORT_VIDEO' ? 'video' : 'image');
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <>
                                <div className="dropzone dz-clickable">
                                  <div className="dz-message needsclick" {...getRootProps()}>
                                    <div className="mb-3">
                                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    </div>
                                    <h4>{state?.type?.value === 'SHORT_VIDEO' ? `Upload Video` : `Annoucement Files`}</h4>
                                    <p style={{ fontSize: 'small' }}>Upload JPG, PNG Resolution 1080 * 500</p>
                                    <p style={{ fontSize: 'small' }}>Max file size 10 MB</p>
                                  </div>
                                </div>
                                {isValidationShow && state?.file_url === `` && <Error text="Please upload a file" />}
                              </>
                            )}
                          </Dropzone>
                          {state.file_url && (
                            <div className="list-unstyled mb-0" id="file-previews">
                              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img height="80" className="avatar-sm rounded bg-light" alt="File Preview" src={state.file_url} />
                                    </Col>
                                    <Col className="d-flex justify-content-between align-items-center">
                                      <a href={state.file_url} target="_blank" rel="noopener noreferrer" className="text-muted font-weight-bold">
                                        {state?.file_url?.split('/')?.pop()}
                                      </a>
                                      <Button color="danger" size="sm" className="ms-2" onClick={() => setState(prev => ({ ...prev, file_url: '' }))}>
                                        <i className="ri-delete-bin-5-line"></i>
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </div>
                          )}
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="mb-3">
                          <Label htmlFor="to" className="form-label">
                            Start Date
                            <span className="icon_calendar">
                              <i className="las la-calendar"></i>
                            </span>
                          </Label>
                          <div className="area_expandable">
                            <Flatpickr
                              className="form-control"
                              name="startDate"
                              id="startDate"
                              value={state.start_date}
                              onChange={date => datePickHandler(`startDate`, date)}
                              options={{
                                enableTime: true,
                                dateFormat: 'd M, Y H:i', // Format: "01 Sep, 2024 14:30"
                                minDate: 'today', // Set minimum date to today
                              }}
                            />
                            {isValidationShow && state?.start_date === `` && <Error text="Please select start date" />}

                            {isValidationShow &&
                              state?.start_date !== `` &&
                              state?.end_date !== `` &&
                              new Date(state?.start_date) >= new Date(state?.end_date) && <Error text="Start date is invalid" />}
                          </div>
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="mb-3">
                          <Label htmlFor="to" className="form-label">
                            End Date
                            <span className="icon_calendar">
                              <i className="las la-calendar"></i>
                            </span>
                          </Label>
                          <div className="area_expandable">
                            <Flatpickr
                              className="form-control"
                              name="endDate"
                              id="endDate"
                              value={state.end_date}
                              onChange={date => datePickHandler(`endDate`, date)}
                              options={{
                                enableTime: true,
                                dateFormat: 'd M, Y H:i', // Format: "01 Sep, 2024 14:30"
                                minDate: 'today', // Set minimum date to today
                              }}
                            />
                            {isValidationShow && state?.end_date === `` && <Error text="Please select end date" />}
                          </div>
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="flex-shrink-0 mt-2">
                          <div className="form-check form-switch form-switch-right form-switch-md">
                            <Label className="form-label text-muted">Is Active</Label>
                            <Input
                              className="form-check-input code-switcher"
                              type="checkbox"
                              checked={state?.is_featured}
                              onChange={() => setState({ ...state, is_featured: !state.is_featured })}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="mb-3">
                          <Label htmlFor="priority" className="form-label">
                            Priority
                          </Label>
                          <Select
                            id="benefit-group-status"
                            name="benefit-group-status"
                            options={priorityOptions ?? []}
                            value={state.priority}
                            onChange={data => setState({ ...state, priority: data })}
                          />
                          {isValidationShow && state?.priority === null && <Error text="Please select priority" />}
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Button className="btn btn-success btn-md mb-3 mt-2 px-5" color="primary" onClick={submitHandler}>
                          {props?.annoucementDetails ? 'Update Announcement' : 'Create Announcement'}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <CardHeader className="fw-medium fs-18U">Preview</CardHeader>
                {state?.type?.value !== 'SHORT_VIDEO' && (
                  <div className="mb-3 ">
                    <img
                      src={state.file_url || 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png'}
                      alt="Announcement Preview"
                      style={{
                        width: '100%',
                        height: '30vh',
                        borderRadius: '8px',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                )}

                {/* Display the title */}
                <div className="mb-3">{parse(state?.title)}</div>

                {/* Display the content */}
                <div className="mb-3">{parse(state?.content)}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default CreateAnnouncement;
