import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ASSIGN_VENDOR_REMARKS_LIST,
  BOOKING_ASSIGN_TO_ME,
  CANCELLATION_REMARKS_FOR_VENDOR,
  GET_CARS_LIST_OF_VENDOR,
  GET_CHAUFFEURS_LIST,
  GET_VENDORS_LIST_DATA,
  SHOW_MODIFY_FIELDS,
} from '../../../../../../../store/actions';
import AssignChauffeur from './AssignChauffeur';
import AssignVendor from './AssignVendor';
import BookingConfirm from './BookingConfirm';
import CancelBooking from './CancelBooking';
import DeclineBooking from './DeclineBooking';
import LoaderData from './LoaderData';
import ModifyBooking from './ModifyBooking';
import ApprovalAction from './ApprovalAction';
import MarkAsComplete from './MarkAsComplete';
import RaiseDispute from './RaiseDispute';
import ShortNoticeCancel from './ShortNoticeCancel';

const BookingModals = ({
  currentSelectedBooking,
  currentActiveBookingAction,
  setCurrentActiveBookingAction,
  isMultiSelectable,
  approvalRequestType,
}) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATE ---------------------------- */
  const [isShowCancelBooking, setIsShowCancelBooking] = useState(false);
  const [isShowDeclineBooking, setIsShowDeclineBooking] = useState(false);
  const [isShowAssignChauffeur, setIsShowAssignChauffeur] = useState(false);
  const [isShowAssignVendor, setIsShowAssignVendor] = useState(false);
  const [isShowVendorConfirmBooking, setIsShowVendorConfirmBooking] = useState(false);
  const [isShowModifyBooking, setIsShowModifyBooking] = useState(false);
  const [isShowApprovalAction, setIsShowApprovalAction] = useState(false);
  const [isShowRejectionAction, setIsShowRejectionAction] = useState(false);
  const [isShowMarkAsComplete, setIsShowMarkAsComplete] = useState(false);
  const [isShowRaiseDispute, setIsShowRaiseDispute] = useState(false);
  const [isShowShortNoticeCancel, setIsShowShortNoticeCancel] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { assignVendorRemarks, cancellationRemarks, carsListOfVendor, chauffeursList, vendorsList } = useSelector((state: any) => ({
    assignVendorRemarks: state?.AssignVendorRemarksList?.data,
    cancellationRemarks: state?.CancellationRemarksForVendor?.data,
    carsListOfVendor: state?.GetCarsListOfVendor?.data,
    chauffeursList: state?.GetChauffersList?.data,
    vendorsList: state?.GetVendorsListData?.data,
  }));

  useEffect(() => {
    if (cancellationRemarks === null) {
      dispatch({
        type: CANCELLATION_REMARKS_FOR_VENDOR,
        payload: {
          params: {
            entity: 'booking',
            isVendorCancel: true,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (assignVendorRemarks === null) {
      dispatch({
        type: ASSIGN_VENDOR_REMARKS_LIST,
        payload: {
          params: {
            entity: 'vendor',
            isSystemPreferred: false,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if ((isMultiSelectable ?? false) === false && currentSelectedBooking !== null && currentSelectedBooking?.vendorId !== '') {
      dispatch({
        type: GET_CARS_LIST_OF_VENDOR,
        payload: {
          params: {
            vendor_id: `${currentSelectedBooking?.vendorId || ''}`,
          },
        },
      });
    }
  }, [currentSelectedBooking]);

  useEffect(() => {
    if ((isMultiSelectable ?? false) === false && currentSelectedBooking !== null) {
      dispatch({
        type: GET_VENDORS_LIST_DATA,
        payload: {
          params: {
            booking_id: currentSelectedBooking?.id || ``,
          },
        },
      });
    }
  }, [currentSelectedBooking]);

  useEffect(() => {
    if ((isMultiSelectable ?? false) === false && currentSelectedBooking !== null && currentSelectedBooking?.vendorId !== '') {
      dispatch({
        type: GET_CHAUFFEURS_LIST,
        payload: {
          params: {
            vendor_id: `${currentSelectedBooking?.vendorId || ''}`,
            booking_id: currentSelectedBooking?.id || ``,
          },
        },
      });
    }
  }, [currentSelectedBooking]);

  useEffect(() => {
    if (currentActiveBookingAction !== null) {
      switch (currentActiveBookingAction) {
        case `assign_to_me`:
          AssignToMe();
          break;

        case `assign_chauffeur`:
          setIsShowAssignChauffeur(true);
          break;

        case `change_chauffeur`:
          setIsShowAssignChauffeur(true);
          break;

        case `assign_to_vendor`:
          setIsShowAssignVendor(true);
          break;

        case `reassign_vendor`:
          setIsShowAssignVendor(true);
          break;

        case `cancel_booking`:
          setIsShowCancelBooking(true);
          break;

        case `vendor_declined`:
          setIsShowDeclineBooking(true);
          break;

        case `vendor_accept`:
          setIsShowVendorConfirmBooking(true);
          break;

        case `modify_booking`:
          dispatch({
            type: SHOW_MODIFY_FIELDS,
            payload: {
              params: {
                booking_type: 'airport_transfers',
                booking_id: currentSelectedBooking?.id,
              },
            },
          });
          setIsShowModifyBooking(true);
          break;

        case `approval`:
          setIsShowApprovalAction(true);
          break;

        case `reject`:
          setIsShowRejectionAction(true);
          break;

        case `complete_booking`:
          setIsShowMarkAsComplete(true);
          break;

        case `raise_a_dispute`:
          setIsShowRaiseDispute(true);
          break;

        case 'short_notice_cancel_booking':
          setIsShowShortNoticeCancel(true);
          break;
        default:
        // No need to do anything
      }
    }
  }, [currentActiveBookingAction]);

  const AssignToMe = () => {
    dispatch({
      type: BOOKING_ASSIGN_TO_ME,
      payload: {
        params: {
          bookingId: currentSelectedBooking?.id,
        },
      },
    });
  };

  return (
    <>
      <LoaderData setCurrentActiveBookingAction={setCurrentActiveBookingAction} />

      {isShowAssignChauffeur && (
        <AssignChauffeur
          carsListOfVendor={carsListOfVendor}
          chauffeursList={chauffeursList}
          currentSelectedBooking={isMultiSelectable ?? false ? currentSelectedBooking?.[0] : currentSelectedBooking}
          onClose={() => {
            setIsShowAssignChauffeur(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}

      {isShowAssignVendor && (
        <AssignVendor
          vendorsList={vendorsList}
          currentSelectedBooking={isMultiSelectable ?? false ? currentSelectedBooking?.[0] : currentSelectedBooking}
          onClose={() => {
            setIsShowAssignVendor(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}

      {isShowCancelBooking && (
        <CancelBooking
          currentSelectedBooking={isMultiSelectable ?? false ? currentSelectedBooking?.[0] : currentSelectedBooking}
          onClose={() => {
            setIsShowCancelBooking(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}

      {isShowDeclineBooking && (
        <DeclineBooking
          currentSelectedBooking={isMultiSelectable ?? false ? currentSelectedBooking?.[0] : currentSelectedBooking}
          remarksList={cancellationRemarks}
          onClose={() => {
            setIsShowDeclineBooking(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}

      {isShowVendorConfirmBooking && (
        <BookingConfirm
          currentSelectedBooking={isMultiSelectable ?? false ? currentSelectedBooking?.[0] : currentSelectedBooking}
          onClose={() => {
            setIsShowVendorConfirmBooking(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}

      {isShowModifyBooking && (
        <ModifyBooking
          currentSelectedBooking={isMultiSelectable ?? false ? currentSelectedBooking?.[0] : currentSelectedBooking}
          onClose={() => {
            setIsShowModifyBooking(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}

      {isShowApprovalAction && (
        <ApprovalAction
          currentSelectedBooking={isMultiSelectable ?? false ? currentSelectedBooking?.[0] : currentSelectedBooking}
          onClose={() => {
            setIsShowApprovalAction(false);
            setCurrentActiveBookingAction(null);
          }}
          actionType="approve"
          approvalRequestType={approvalRequestType}
        />
      )}

      {isShowRejectionAction && (
        <ApprovalAction
          currentSelectedBooking={isMultiSelectable ?? false ? currentSelectedBooking?.[0] : currentSelectedBooking}
          onClose={() => {
            setIsShowRejectionAction(false);
            setCurrentActiveBookingAction(null);
          }}
          actionType="reject"
          approvalRequestType={approvalRequestType}
        />
      )}

      {isShowMarkAsComplete && (
        <MarkAsComplete
          currentSelectedBooking={currentSelectedBooking}
          onClose={() => {
            setIsShowMarkAsComplete(false);
            setCurrentActiveBookingAction(null);
          }}
          isMultiSelectable={isMultiSelectable ?? false}
        />
      )}

      {isShowRaiseDispute && (
        <RaiseDispute
          currentSelectedBooking={isMultiSelectable ?? false ? currentSelectedBooking?.[0] : currentSelectedBooking}
          onClose={() => {
            setIsShowRaiseDispute(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}

      {isShowShortNoticeCancel && (
        <ShortNoticeCancel
          currentSelectedBooking={isMultiSelectable ?? false ? currentSelectedBooking?.[0] : currentSelectedBooking}
          onClose={() => {
            setIsShowShortNoticeCancel(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}
    </>
  );
};

export default BookingModals;
