import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// import { baseUrl, customHeaders, umsUrl, adminAPIURL } from '../../../../Containers/Config/index.js';
import { baseUrl, customHeaders, umsUrl, adminAPIURL } from '../../../../../Containers/Config/index.js';
import { REMARK_STATUS, REMARK_STATUS_SUCCESS, REMARK_STATUS_ERROR } from '../../actions.js';
// import apiCall from '../../apiCall';
import apiCall from '../../../apiCall.js';

function* getRemarkStatusDetails({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v2/remarks/status-tabs`,
      method: 'GET',
      headers: {
        // Authorization: Constant?.CLIENT_TOKEN,
        ...filteredHeaders,
        // withCredentials: true
      },
      params: payload?.urlParams,
    });

    if (response.status === 200) {
      yield put({
        type: REMARK_STATUS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: REMARK_STATUS_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: REMARK_STATUS_ERROR, payload: error });
  }
}

export function* watchRemarkStatusDetails() {
  yield takeEvery(REMARK_STATUS, getRemarkStatusDetails);
}

function* remarkStatusDetailsSaga() {
  yield all([fork(watchRemarkStatusDetails)]);
}

export default remarkStatusDetailsSaga;
