import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import cogoToast from 'cogo-toast';
import { adminAPIURL, customHeaders } from '../../../../../../Containers/Config/index.js';
import apiCall from '../../../../apiCall.js';
import { BOOKING_ASSIGN_TO_ME, BOOKING_ASSIGN_TO_ME_ERROR, BOOKING_ASSIGN_TO_ME_SUCCESS } from '../../../actions.js';

function* getBookingAssignToMe({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/bookings/${payload?.params?.bookingId}/assign-to-me`,
      method: 'POST',
      headers: {
        ...filteredHeaders,
      },
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: BOOKING_ASSIGN_TO_ME_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: BOOKING_ASSIGN_TO_ME_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: BOOKING_ASSIGN_TO_ME_ERROR, payload: error });
  }
}

export function* watchBookingAssignToMe() {
  yield takeEvery(BOOKING_ASSIGN_TO_ME, getBookingAssignToMe);
}

function* bookingAssignToMeSaga() {
  yield all([fork(watchBookingAssignToMe)]);
}

export default bookingAssignToMeSaga;
