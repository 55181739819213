import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import LoginForm from './Components/Form/Login';
import FullLoader from '../../../Common/Loader/CompleteLoader';
import { CLIENT_LOGIN, GET_USER_ROLES, VENDOR_LOGIN_V2 } from '../../../../store/actions';
import { Constant } from '../../../Helpers/constant';

interface RootState {
  VendorLoginV2: {
    data?: any;
    loading?: boolean;
  };
  UserRoles: {
    data?: any;
    loading?: boolean;
  };
}

interface LoginData {
  email: string;
  password: string;
  [key: string]: any;
}

interface Role {
  id: string;
  name: string;
  description: string;
}

const VendorLoginV2: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { login, loginLoading } = useSelector((state: RootState) => ({
    login: state?.VendorLoginV2?.data,
    loginLoading: state?.VendorLoginV2?.loading,
  }));

  const { userRoles, userRolesLoading } = useSelector((state: RootState) => ({
    userRoles: state.UserRoles.data as { roles: Role[] } | null,
    userRolesLoading: state.UserRoles.loading,
  }));

  useEffect(() => {
    if (login !== null) {
      dispatch({ type: GET_USER_ROLES });
    }
  }, [login]);

  useEffect(() => {
    if (userRoles !== null) {
      localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.VENDOR_USER_V2 ?? -1));

      localStorage.setItem(
        'authUserV2',
        JSON.stringify({
          status: 'success',
          data: {
            name: `${login?.data?.user?.firstName} ${login?.data?.user?.lastName}`,
          },
        }),
      );
      history.push(`/v2/vendor-dashboard`);
    }
  }, [userRoles]);

  const onLogin = (data: LoginData) => {
    dispatch({
      type: VENDOR_LOGIN_V2,
      payload: {
        data,
      },
    });
  };

  return (
    <>
      {loginLoading && <FullLoader />}
      <LoginForm onSubmit={onLogin} />
    </>
  );
};

export default VendorLoginV2;
