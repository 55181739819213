import axios from 'axios';
import CogoToast from 'cogo-toast'; // Importing CogoToast for error toast
import moment from 'moment';
import { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Input } from 'reactstrap';
import { customHeaders, thriweDiscoveryUrl } from '../../../../../../../Config';
import { Constant } from '../../../../../../../Helpers/constant';

const initialState = {
  user_id: '',
  start_date: '',
  end_date: '',
  order_by: 'desc',
};

const Filter = props => {
  const [filter, setFilter] = useState(initialState);
  const [filterType, setFilterType] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState([]); // Array to store applied filters

  console.log('filterType', filterType);

  const inputChangeHandler = e => {
    const { value } = e.target;
    if (value?.trim()) {
      setFilter({
        ...filter,
        [filterType?.value]: value, // Apply trim on change
      });
    }
  };

  useEffect(() => {
    if (!props?.currentlyAppliedFilters) {
      setAppliedFilters([]);
      setFilterType(null);
      setFilter(initialState);
    }
  }, [props?.currentlyAppliedFilters]);

  const submitHandler = () => {
    const trimmedValue = filter[filterType?.value]?.trim(); // Trim input value before validation

    // General Empty Validation
    if (!trimmedValue) {
      CogoToast.error('Field cannot be empty'); // Show toast error
      return;
    }

    // Email Validation
    if (filterType?.value === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!trimmedValue || !emailRegex.test(trimmedValue)) {
        CogoToast.error('Please enter a valid email address'); // Show toast error
        return;
      }
    }

    // Mobile Number Validation
    if (filterType?.value === 'mobile_number') {
      if (!trimmedValue || !/^\d+$/.test(trimmedValue)) {
        CogoToast.error('Mobile number should contain only digits'); // Show toast error
        return;
      }
    }

    // Check if filter type already exists
    const updatedFilters = appliedFilters.filter(f => f.key !== filterType?.value);
    updatedFilters.push({
      key: filterType?.value,
      label: filterType?.label,
      value: trimmedValue,
    });

    setAppliedFilters(updatedFilters);

    // Reset filter field for the current type
    setFilter({ ...filter, [filterType?.value]: '' });

    // Transform appliedFilters into the desired format
    const filtersObject = updatedFilters.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});
    console.log('filtersObject', { ...filter, ...filtersObject });
    props?.filterHandler({ ...filter, ...filtersObject });
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      submitHandler();
    }
  };

  const removeFilter = key => {
    // Remove filter from appliedFilters and filter state
    const updatedFilters = appliedFilters.filter(f => f.key !== key);
    setAppliedFilters(updatedFilters);
    setFilter({ ...filter, [key]: '' });

    // Transform updatedFilters into the desired format
    const filtersObject = updatedFilters.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});

    // Call the filterHandler with the updated filters
    props?.filterHandler({ ...filter, ...filtersObject });
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'createdAtRange':
        updatedState = {
          ...filter,
          start_date: moment(new Date(date[0])).format('Y-MM-DD'),
          end_date: moment(new Date(date[1])).format('Y-MM-DD'),
        };
        setFilter(updatedState);
        break;
    }
    if (date[0] && date[1]) {
      const filtersObject = appliedFilters.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {});
      props?.filterHandler({ ...updatedState, ...filtersObject });
    }
  };

  async function loadFilterOptions(search) {
    return await axios({
      method: `GET`,
      url: `${thriweDiscoveryUrl}/v1/admin/fetch-filters`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search,
        feature: `events`,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.key,
              label: `${data?.name}`,
              data: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON SELECT SERVICE ---------------------------- */
  const onSelectFilterType = event => {
    setFilterType(event);
    setFilter({ ...filter, [event.value]: '' });
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <AsyncPaginate
            debounceTimeout={500}
            value={filterType}
            loadOptions={loadFilterOptions}
            onChange={e => onSelectFilterType(e)}
            isMulti={false}
            closeMenuOnSelect={true}
            noOptionsMessage={() => 'No results found'}
            cacheUniqs={[['code']]}
            placeholder="Search By"
            styles={{
              container: base => ({
                ...base,
                width: '180px',
              }),
              menu: provided => ({
                ...provided,
                zIndex: 2,
              }),
            }}
          />

          <Input
            id="name"
            name="name"
            type={'text'}
            placeholder="Search"
            className="form-control"
            value={filter?.[filterType?.value] || ''}
            onChange={inputChangeHandler}
            autoComplete="off"
            disabled={filterType === null}
            style={{ width: '200px' }}
            onKeyPress={handleKeyPress}
          />

          {/* <Flatpickr
            className="form-control"
            onChange={date => datePickHandler(`createdAtRange`, date)}
            value={[filter?.start_date, filter?.end_date]}
            placeholder="Select Range"
            options={{
              mode: 'range',
              dateFormat: 'Y-m-d',
              maxDate: 'today',
            }}
            style={{ width: '200px' }}
          /> */}
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {appliedFilters?.map(({ key, label, value }) => (
            <div
              key={key}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                background: 'rgb(271, 164, 216)',
                padding: '5px 3px',
                borderRadius: '4px',
              }}
            >
              <span>{`${value}`}</span>
              <button style={{ border: 'none', background: 'transparent', cursor: 'pointer' }} onClick={() => removeFilter(key)}>
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Filter;
