import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import cogoToast from 'cogo-toast';
import { adminAPIURL, customHeaders } from '../../../../../../Containers/Config/index.js';
import apiCall from '../../../../apiCall.js';
import { ASSIGN_VENDOR, ASSIGN_VENDOR_ERROR, ASSIGN_VENDOR_SUCCESS } from '../../../actions.js';

function* assignVendor({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/assign-vendor`,
      method: 'POST',
      headers: {
        ...filteredHeaders,
      },
      data: {
        ...payload?.body,
      },
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: ASSIGN_VENDOR_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: ASSIGN_VENDOR_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: ASSIGN_VENDOR_ERROR, payload: error });
  }
}

export function* watchAssignVendor() {
  yield takeEvery(ASSIGN_VENDOR, assignVendor);
}

function* assignVendorSaga() {
  yield all([fork(watchAssignVendor)]);
}

export default assignVendorSaga;
