import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { InactiveUserIcon, TeamActivityIndicatorIcon, UserFrameIcon, UsersOnBreakIcon } from '../../../../../../Components/icons';
import moment from 'moment';
import CountUp from 'react-countup';
import vendorAssign from '../../../../../../assets/custom/v2/vendor.svg';

const TeamActivityCard = ({ item, cardData }) => {
  console.log('cardData', cardData);
  const totalCount =
    cardData?.[item?.name]?.data?.totalUsers || cardData?.[item?.name]?.data?.totalUsers === 0
      ? Number(cardData?.[item?.name]?.data?.totalUsers)
      : null;
  const userActive =
    cardData?.[item?.name]?.data?.usersActive || cardData?.[item?.name]?.data?.usersActive === 0
      ? Number(cardData?.[item?.name]?.data?.usersActive)
      : null;
  const usersInactive =
    cardData?.[item?.name]?.data?.usersInactive || cardData?.[item?.name]?.data?.usersInactive === 0
      ? Number(cardData?.[item?.name]?.data?.usersInactive)
      : null;

  const usersOnBreak =
    cardData?.[item?.name]?.data?.usersOnBreak || cardData?.[item?.name]?.data?.usersOnBreak === 0
      ? Number(cardData?.[item?.name]?.data?.usersOnBreak)
      : null;

  return (
    <Card style={{ borderRadius: '20px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 2px 0px' }} className="sla-card">
      <CardBody style={{ padding: '0.3rem 0.7rem 1rem 1rem' }}>
        <p className="mb-0 text-color-light-grey text-end m-0">Today {moment(new Date()).format('DD, MMM hh:mm:ss')}</p>
        <div className="d-flex justify-content-between">
          <img src={item?.icon ? item?.icon : vendorAssign} className="img-fluid" alt="" style={{ maxWidth: '45px', height: '45px' }} />

          <div className="text-end">
            <p className="text-black text-format-7 m-0">{item?.displayName}</p>
            {totalCount != null ? (
              <CountUp separator="" start={0} end={totalCount} duration={2} className="fw-bold text-format-3 m-0" />
            ) : (
              <p className="fw-bold text-format-3 m-0">-</p> // Showing dash when data is missing
            )}
          </div>
        </div>

        <div className="d-flex">
          <div className="d-flex justify-content-between w-100">
            <div className="flex-2">
              <span>
                <TeamActivityIndicatorIcon />
              </span>
              {userActive !== null ? <span className="text-format-1">{userActive}</span> : <p className="fw-bold text-format-3 m-0">-</p>}
              {/* {item?.data?.usersActive && } */}
            </div>

            <div className="flex-2">
              <span>
                <InactiveUserIcon />
              </span>
              {usersInactive !== null ? <span className="text-format-1">{usersInactive}</span> : <p className="fw-bold text-format-3 m-0">-</p>}
            </div>

            <div className="flex-2">
              <span>
                <UsersOnBreakIcon />
              </span>
              {usersOnBreak !== null ? <span className="text-format-1">{usersOnBreak}</span> : <p className="fw-bold text-format-3 m-0">-</p>}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default TeamActivityCard;
