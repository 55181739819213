import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, Container, Row } from 'reactstrap';
import BreadcrumbTrail from '../../../Common/BreadcrumbTrail';
import { useHistory, useLocation } from 'react-router-dom';
import SLACard from './Components/Card/SLACard';
import moment from 'moment';
import StatusCard from './Components/Card/StatusCard';
import ActionBookingCard from './Components/Card/ActionBookingCard';
import Flatpickr from 'flatpickr';
import Select from 'react-select';
import { DonutChart, GeoChart, Loader } from '../../../Common';
import { useDispatch, useSelector } from 'react-redux';
import { GET_CARD_SUMMARY, GET_CARD_SUMMARY_EMPTY, GET_DASHBOARD_CONFIGURATION } from '../../../../store/actions';
import queryString from 'query-string';

const initialDate = {
  startDate: '',
  endDate: '',
  timelineFilter: null,
};

const bookingOptions = [
  { value: 'past_bookings', label: 'Past Booking' },
  { value: 'ongoing_bookings', label: 'Ongoing Booking' },
  { value: 'upcoming_bookings', label: 'Upcoming Booking' },
];

interface RootState {
  GetDashboardConfiguration?: {
    data?: any;
    loading?: boolean;
  };
  GetCardSummary?: {
    data?: any;
    loading?: boolean;
  };
}

const BookingDashboard = () => {
  const inputRef = useRef(null);
  const flatpickrInstance = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { nextNavigation, sectionFilter } = queryString.parse(location.search);
  const selectedFilterType = bookingOptions.find(option => option.value === sectionFilter) || null;

  const [filter, setFilter] = useState({ ...initialDate });
  const [laoding, setLaoding] = useState(false);
  const [slaBooking, setSlaBooking] = useState(null);
  const [statusBooking, setStatusBooking] = useState(null);
  const [actionItemBooking, setActionItemBooking] = useState(null);

  console.log('statusBooking', statusBooking);

  console.log('filter check', filter);

  const { getDashboardConfiguration, getDashboardConfigurationLoading } = useSelector((state: RootState) => ({
    getDashboardConfiguration: state?.GetDashboardConfiguration?.data,
    getDashboardConfigurationLoading: state?.GetDashboardConfiguration?.loading,
  }));

  const { cardData, cardDataLoading } = useSelector((state: RootState) => ({
    cardData: state.GetCardSummary.data,
    cardDataLoading: state.GetCardSummary.loading,
  }));

  const [data, setData] = useState({
    slaWiseBookings: null,
    statusWiseBookings: null,
    actionTypeBookings: null,
    regionWiseChart: null,
    projectWiseBookings: null,
    pickAndDrops: null,
  });

  const categorizeBooking = (startDate, endDate) => {
    const todayMinus2 = moment().subtract(2, 'days').toISOString();
    const todayPlus2 = moment().add(2, 'days').toISOString();
    const todayMinus3 = moment().subtract(3, 'days').toISOString();
    const todayPlus3 = moment().add(3, 'days').toISOString();

    const start = moment(startDate);
    const end = moment(endDate);

    if (start.isSameOrBefore(todayPlus2) && end.isSameOrAfter(todayMinus2)) {
      return 'ongoing_bookings';
    } else if (start.isSameOrAfter(todayPlus3)) {
      return 'upcoming_bookings';
    } else if (end.isBefore(todayMinus3)) {
      return 'past_bookings';
    }
    return 'unknown'; // If none of the conditions match
  };

  useEffect(() => {
    setFilter({ ...initialDate, timelineFilter: selectedFilterType });
  }, [selectedFilterType]);

  useEffect(() => {
    flatpickrInstance.current = Flatpickr(inputRef.current, {
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
      clickOpens: false, // Prevents default click to open
      defaultDate: [initialDate?.startDate, initialDate.endDate],
      onOpen: () => {
        setTimeout(() => {
          const calendarElement = document.querySelector('.flatpickr-calendar') as HTMLElement;

          if (calendarElement) {
            calendarElement.style.top = '4%';
            calendarElement.style.left = '35%';
            calendarElement.style.right = 'auto';
          }
        }, 0); // Ensures the calendar has been rendered before applying styles
      },
      onChange: date => {
        if (date[0] && date[1]) {
          const categorizeBookingValue = categorizeBooking(moment(new Date(date[0])).toISOString(), moment(new Date(date[1])).toISOString());

          updateURLParams(
            bookingOptions.find(bookingType => bookingType.value === categorizeBookingValue),
            false,
          );

          setFilter({
            timelineFilter: null,
            startDate: moment(new Date(date[0])).toISOString(),
            endDate: moment(new Date(date[1])).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').add(999, 'milliseconds')?.toISOString(),
          });
        }
      },
    });
  }, []);

  useEffect(() => {
    if (!filter?.startDate && !filter?.endDate) {
      const currentDate = moment();
      let startDate = '';
      let endDate = '';

      switch (filter.timelineFilter?.value) {
        case 'upcoming_bookings':
          startDate = currentDate.clone().add(2, 'days').toISOString();
          endDate = moment(new Date()).add(30, 'days').add(23, 'hours').add(59, 'minutes').add(59, 'seconds').add(999, 'milliseconds')?.toISOString();
          break;

        case 'ongoing_bookings':
          startDate = currentDate.clone().subtract(2, 'days').toISOString();
          endDate = moment(new Date()).add(2, 'days').add(23, 'hours').add(59, 'minutes').add(59, 'seconds').add(999, 'milliseconds')?.toISOString();
          break;

        case 'past_bookings':
          startDate = currentDate.clone().subtract(3, 'months').subtract(2, 'days').toISOString();
          endDate = moment(new Date())
            .subtract(2, 'days')
            .add(23, 'hours')
            .add(59, 'minutes')
            .add(59, 'seconds')
            .add(999, 'milliseconds')
            ?.toISOString();
          break;

        default:
          startDate = '';
          endDate = '';
      }

      setFilter(prev => ({ ...prev, startDate, endDate }));
    }
  }, [filter.timelineFilter]);

  useEffect(() => {
    if (getDashboardConfiguration === null) {
      dispatch({
        type: GET_DASHBOARD_CONFIGURATION,
      });
    }
  }, []);

  useEffect(() => {
    if (sectionFilter && nextNavigation && getDashboardConfiguration && filter?.startDate && filter?.endDate) {
      fetchAllCards();
    }
  }, [filter]);

  const fetchAllCards = () => {
    const availableSections = getDashboardConfiguration?.modules?.[nextNavigation.toString()]?.sections;
    const defaultSections = availableSections?.filter(card => card.navigationFilter === sectionFilter);
    console.log('defaultSections', defaultSections);
    let totalCards = [];
    const slaBooking = defaultSections?.find(section => section?.priority === 1);
    const statusBooking = defaultSections?.find(section => section?.priority === 2);
    const actionBooking = defaultSections?.find(section => section?.priority === 3);

    setSlaBooking(slaBooking);
    setStatusBooking(statusBooking);
    setActionItemBooking(actionBooking);
    // Extract cards from SLA and Status Booking
    totalCards = [...(slaBooking?.cards || []), ...(statusBooking?.cards || []), ...(actionBooking?.cards || [])];
    if (totalCards?.length > 0) {
      dispatch({
        type: GET_CARD_SUMMARY,
        payload: {
          cards: totalCards,
          start_date: filter?.startDate,
          end_date: filter?.endDate,
        },
      });
    }
  };

  useEffect(() => {
    if (getDashboardConfigurationLoading || cardDataLoading) {
      setLaoding(true);
    } else {
      setLaoding(false);
    }
  }, [getDashboardConfigurationLoading, cardDataLoading]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_CARD_SUMMARY_EMPTY,
      });
    };
  }, []);

  const openCalendar = () => {
    if (flatpickrInstance.current) {
      flatpickrInstance.current.open();
    }
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === 'Dashboard') {
      history.push('/v2/dashboard');
    } else if (title === 'Template Management') {
      history.push('/surge/templates');
    }
  };

  const updateURLParams = (data?: { value: string; label: string }, isSetTrue = true) => {
    const currentParams = queryString.parse(location.search);

    const updatedParams = {
      ...currentParams,
      sectionFilter: data?.value ?? '',
    };

    const newUrl = queryString.stringify(updatedParams);
    if (isSetTrue) setFilter({ startDate: '', endDate: '', timelineFilter: data });

    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        {laoding && <Loader />}
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-2">
            <BreadcrumbTrail
              title={getDashboardConfiguration?.modules?.[nextNavigation?.toString()]?.name}
              navigationby={breadcrumNavigationHandler}
              navigation={['Dashboard']}
            />
            <div style={{ borderLeft: '3px solid #405189', height: '28px' }}></div>
            <p className="mt-1 fw-semibold">
              {filter?.startDate && filter.endDate && (
                <>
                  {moment(filter.startDate).format('D MMM')} - {moment(filter.endDate).format('D MMM')}
                </>
              )}
            </p>
            <div className="mt-1">
              <i onClick={openCalendar} style={{ cursor: 'pointer' }} className="ri-pencil-fill"></i>
            </div>
            <input ref={inputRef} type="text" placeholder="Select date" style={{ display: 'none' }} />
          </div>
          <div className="d-flex gap-3">
            <div style={{ height: 40 }}>
              <Select
                options={bookingOptions}
                placeholder="Select Type"
                value={filter?.timelineFilter}
                styles={{
                  control: provided => ({
                    ...provided,
                    width: '200px', // Set custom width
                  }),
                }}
                onChange={data => updateURLParams(data)}
              />
            </div>

            <button style={{ height: 37 }} className="btn btn-success d-flex align-items-center gap-1" onClick={() => fetchAllCards()}>
              <i className=" ri-refresh-line"></i>
              Refresh
            </button>
          </div>
        </div>

        {/* SLA Wise Bookings */}
        {slaBooking && (
          <Card style={{ borderRadius: '10px', marginBottom: '1rem' }}>
            <CardBody>
              <div className="d-flex gap-2" style={{ alignItems: 'baseline', marginBottom: '10px' }}>
                <p className="fs-20 fw-medium m-0">{slaBooking?.displayName}</p>
                <p style={{ color: '#999999' }} className="fs-12 m-0">
                  {moment().format('dddd D, MMM hh:mm:ss')}
                </p>
              </div>

              {/* Scrollable Container */}
              <div className="sla-container">
                <div className="sla-scroll">
                  {slaBooking?.cards.map((slaBooking, index) => (
                    <SLACard key={index} slaBooking={slaBooking} cardData={cardData} filter={filter} />
                  ))}
                </div>
              </div>
            </CardBody>
          </Card>
        )}

        {/*  Status Wise Bookings */}
        {statusBooking && (
          <Card style={{ borderRadius: '10px' }}>
            <CardBody>
              <div className="d-flex gap-2" style={{ alignItems: 'baseline' }}>
                <p className="fs-20 fw-medium">{statusBooking?.displayName}</p>
                <p style={{ color: '#999999' }} className="fs-12">
                  {moment().format('dddd D, MMM hh:mm:ss')}
                </p>
              </div>

              {/* Scrollable Container */}
              <div className="sla-container p-1">
                <div className="status-scroll">
                  {statusBooking.cards.map((statusBooking, index: number) => (
                    <StatusCard key={index} statusBooking={statusBooking} cardData={cardData} filter={filter} />
                  ))}
                </div>
              </div>
            </CardBody>
          </Card>
        )}

        {/*  Action Items On Bookings */}
        {actionItemBooking && (
          <Card style={{ borderRadius: '10px' }}>
            <CardBody>
              <div className="d-flex gap-2" style={{ alignItems: 'baseline' }}>
                <p className="fs-20 fw-medium">{actionItemBooking?.displayName}</p>
                <p style={{ color: '#999999' }} className="fs-12">
                  {moment().format('dddd D, MMM hh:mm:ss')}
                </p>
              </div>

              {/* Scrollable Container */}
              <div className="sla-container p-1">
                <div className="action-scroll">
                  {actionItemBooking?.cards.map((actionBooking, index: number) => (
                    <ActionBookingCard key={index} actionBooking={actionBooking} cardData={cardData} filter={filter} />
                  ))}
                </div>
              </div>
            </CardBody>
          </Card>
        )}

        <Row>
          {data?.projectWiseBookings && (
            <DonutChart
              lastUpdatedAt={data?.projectWiseBookings?.lastUpdatedAt}
              data={data?.projectWiseBookings?.data}
              title={'Project Wise Bookings'}
            />
          )}

          {data?.pickAndDrops && (
            <DonutChart lastUpdatedAt={data?.projectWiseBookings?.lastUpdatedAt} data={data?.pickAndDrops?.data} title={'Pickups vs Drops'} />
          )}

          {data?.regionWiseChart && <GeoChart data={data?.regionWiseChart?.data} title={'Region Wise Bookings'} />}
        </Row>
      </Container>
    </div>
  );
};

export default BookingDashboard;
