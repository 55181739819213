import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartOptions } from 'chart.js';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import Select from 'react-select';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register Chart.js modules
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

// Define the type for the props
interface WorkflowData {
  workflow: string;
  usage_count: number;
}

interface FilterOption {
  label: string;
  value: string;
}

interface BarChartProps {
  mostUsedWorkflows: WorkflowData[];
  filter: FilterOption;
  filterOptions: FilterOption[];
  filterHandler: (label: string, data: FilterOption) => void;
}

const WorkflowChart: React.FC<BarChartProps> = ({ mostUsedWorkflows, filter, filterOptions, filterHandler }) => {
  // Map numeric indices for the X-axis labels
  const numericLabels = mostUsedWorkflows.map((_, index) => index + 1);
  const dataValues = mostUsedWorkflows.map(item => item.usage_count);

  console.log('numericLabels', numericLabels);

  // Data for the bar chart
  const data = {
    labels: [1, 2, 3, 4, 5], // Numeric values for X-axis
    datasets: [
      {
        label: 'Most Used Workflow',
        data: dataValues,
        backgroundColor: ['#4c6ef5', '#51cf66', '#fcc419', '#845ef7', '#5c7cfa'], // Dynamic colors if needed
      },
    ],
  };

  // Chart options
  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // We'll create a custom legend
      },
      datalabels: {
        anchor: 'end', // Positions at the end of the bar
        align: 'top', // Positions label above the bar
        offset: -5, // Adds space/margin above the bar
        formatter: value => value, // Displays the values
        color: '#000', // Label color
        font: {
          weight: 'bold',
          size: 12,
        },
        clamp: true,
      },
    },
    scales: {
      x: {
        type: 'linear', // Use numeric values on the X-axis
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
        },
      },
      y: {
        beginAtZero: true,
        grid: { display: false, color: '#878a99' },
      },
    },
  };

  // Create the custom legend dynamically based on the data
  const legend = mostUsedWorkflows.map((item, index) => ({
    name: item.workflow,
    color: ['#4c6ef5', '#51cf66', '#fcc419', '#845ef7', '#5c7cfa'][index % 5], // Reuse colors if more than 5 items
  }));

  return (
    <Col xl={6} md={12} lg={6} className="mb-2" style={{ borderRadius: '10px' }}>
      <Card>
        <CardHeader className="d-flex">
          <h4 className="card-title mb-0 flex-grow-1 fw-semibold">Most Used Workflows</h4>
          <Select
            id="mostUsed"
            name="mostUsed"
            options={filterOptions ?? []}
            value={filter}
            onChange={data => filterHandler(`mostUsed`, data)}
            isSearchable={false}
          />
        </CardHeader>
        <CardBody style={{ maxHeight: '400px', overflowY: 'scroll', position: 'relative', textAlign: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Bar data={data} options={options} />
            {/* Custom Legend */}
            <div style={{ marginTop: '20px', display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
              {legend.map((item, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: item.color,
                      borderRadius: '4px',
                    }}
                  ></div>
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default WorkflowChart;
