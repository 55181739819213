import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import * as moment from 'moment';

import { PreviewCardHeader } from '../../../../../../Common';
import {
  Card,
  CardBody,
  Col,
  Form,
  Label,
  Row,
  CardFooter,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  GET_BOOKING_LIST_EMPTY,
  GET_REGION,
  GET_REGION_EMPTY,
  GET_SUB_REGION,
  GET_SUB_REGION_EMPTY,
} from '../../../../../../../store/application/actionType';
import { Constant } from '../../../../../../Helpers/constant';

const statuses = [
  {
    name: `Pending`,
    objectId: Constant?.BOOKING_STATUS?.PENDING,
  },
  {
    name: `Processed`,
    objectId: Constant?.BOOKING_STATUS?.PROCESSED,
  },
  {
    name: `Confirmed`,
    objectId: Constant?.BOOKING_STATUS?.CONFIRM,
  },
  {
    name: `Cancelled (ALL)`,
    objectId: Constant?.BOOKING_STATUS?.CANCELLED,
  },
  {
    name: `Cancelled by User`,
    objectId: Constant?.BOOKING_STATUS?.CANCEL_BY_USER,
  },
  {
    name: `Cancelled by Thriwe`,
    objectId: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
  },
  {
    name: `Short Notice Cancelled by User`,
    objectId: Constant?.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_USER,
  },
  {
    name: `Short Notice Cancelled by Thriwe`,
    objectId: Constant?.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_THRIWE,
  },
  {
    name: `Short Notice Cancelled By Thriwe With Quota`,
    objectId: Constant?.BOOKING_STATUS?.BOOKING_SHORTNOTICE_CANCELED_BY_THRIWE_WITH_QUOTA,
  },
];

const Filter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    createdAtRange: {
      from: ``,
      to: ``,
    },
    sortOn: 'updatedAt',
  };
  const [filter, setFilter] = useState(initialState);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  const [facilities, setFacilities] = useState(null);

  const [searchCode, setSearchCode] = useState(`0`);
  const [searchValue, setSearchValue] = useState(``);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'createdAtRange':
        updatedState = {
          ...filter,
          createdAtRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;
      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.createdAtRange?.from !== `` || data?.createdAtRange?.to !== ``) {
      setIsResetDisabled(false);
    } else {
      setIsResetDisabled(true);
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    let filterValue = { ...filter, customerDetails: { ...initialState.customerDetails }, bookingId: '', membershipId: '' };

    if (searchCode !== '0' && searchValue !== ``) {
      if (searchCode === '1') {
        filterValue = {
          ...filterValue,
          customerDetails: {
            ...filterValue.customerDetails,
            name: searchValue,
          },
        };
        setFilter(filterValue);
      } else if (searchCode === '2') {
        filterValue = {
          ...filterValue,
          customerDetails: {
            ...filterValue.customerDetails,
            mobile: searchValue,
          },
        };
        setFilter(filterValue);
      } else if (searchCode === '3') {
        filterValue = {
          ...filterValue,
          customerDetails: {
            ...filterValue.customerDetails,
            email: searchValue,
          },
        };
        setFilter(filterValue);
      } else if (searchCode === '4') {
        filterValue = {
          ...filterValue,
          bookingId: searchValue,
        };
        setFilter(filterValue);
      } else if (searchCode === '5') {
        filterValue = {
          ...filterValue,
          membershipId: searchValue,
        };
        setFilter(filterValue);
      }
    }
    props.filterHandler({ ...filterValue, filter: true });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter(initialState);
    setIsResetDisabled(true);
    setSearchCode('0');
    setSearchValue(``);
    props.filterHandler({ ...initialState, filter: false });

    dispatch({
      type: GET_BOOKING_LIST_EMPTY,
    });
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            {/* <button onClick={() => console.log({ facilities })}>
              get state
            </button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="created-at-range" className="form-label">
                          Created At - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            onChange={date => datePickHandler(`createdAtRange`, date)}
                            value={[filter?.createdAtRange?.from, filter?.createdAtRange?.to]}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={filterSubmitHandler} disabled={isFilterDisabled}>
                      Search
                    </button>

                    <button type="submit" className="btn btn-primary overlay-disable" onClick={resetFilterHandler} disabled={isResetDisabled}>
                      Reset Filter
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
