import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

const MetaData = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [modal_varying1, setmodal_varying1] = useState(true);

  useEffect(() => {
    setState(props?.data?.data);
    setCurrentIndex(props?.data?.currentIndex);
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'offerId':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              offerId: value,
            },
          },
        };
        break;

      case 'offerUserId':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              offerUserId: value,
            },
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    props.onSubmit({
      currentIndex: currentIndex,
      data: {
        // ...props.data,
        ...state,
      },
    });

    props.onSubmit({
      currentIndex: currentIndex,
      data: {
        ...props?.data?.data,
        benefitGroupItem: {
          ...state?.benefitGroupItem,
          completeData: {
            ...state?.benefitGroupItem?.completeData,
            offerId: state?.benefitGroupItem?.completeData?.offerId ?? '',
            offerUserId: state?.benefitGroupItem?.completeData?.offerUserId ?? '',
          },
        },
      },
    });

    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {};

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="price-settings"
      // centered
      size="md"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Price Settings
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>get state</button> */}
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="offerId" className="form-label">
                Offer Id
              </Label>
              <Input
                id="offerId"
                name="offerId"
                type="text"
                onWheel={e => e.target.blur()}
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.offerId}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="offerUserId" className="form-label">
                Offer user Id
              </Label>
              <Input
                id="offerUserId"
                name="offerUserId"
                type="text"
                onWheel={e => e.target.blur()}
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.offerUserId}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MetaData;
