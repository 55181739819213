import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';

import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input, Container, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
// import { PROGRAM_USER_EMPTY } from '../../../../../store/application/actionType'
import { PreviewCardHeader } from '../../../../../../Common';
import {
  BLOCK_DATE_LIST
} from '../../../../../../../store/application/actionType';
import { AsyncPaginate } from 'react-select-async-paginate';
import { customHeaders, uaeProjectsBaseURL } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';
import Select from 'react-select';
import { isValidAplha, isValidNumber } from '../../../../../../Helpers/Helpers';
import moment from 'moment';

const BlockDatesFilterNew = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
   
    createdAt:``,
    facilityName:``
  };
  const [filter, setFilter] = useState(initialState);
 
  const [isResetDisabled, setIsResetDisabled] = useState(true);
 

  useEffect(() => {
    if (props?.appliedFilters !== null) {
      setFilter(props?.appliedFilters);
      validationCheck(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

 



  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };

    switch (name) {
      case 'facilityName':
        updatedState = {
          ...filter,
          facilityName: isValidAplha(value) ? value : filter?.name,
        };
        break;

    

      default:
      // do nothing
    }

    setFilter(updatedState);
    validationCheck({ ...updatedState });
  };

  

  /* ---------------------------- DATE SELECT HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };

    switch (flag) {
      case 'createdAt':
               updatedState = {
                 ...filter,
                 createdAt: {
                  from: moment(new Date(date[0])).format('Y-MM-DD'),
                  to: moment(new Date(date[1])).format('Y-MM-DD'),
                },
               };
               setFilter(updatedState);
               validationCheck(updatedState);
               break;

      default:
      // do nothing
    }

    setFilter(updatedState);
    validationCheck(updatedState);
  };

  
  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (
      data?.name !== `` ||
      data?.merchantName !== `` ||
      data?.phoneNumber !== `` ||
      data?.email !== `` ||
      data?.updateDate !== `` ||
      data?.categoryId !== null ||
      data?.partnerId !== null ||
      data?.countryId !== null ||
      data?.regionId !== null ||
      data?.subRegionId !== null
    ) {
      setIsResetDisabled(false);
    } else {
      setIsResetDisabled(true);
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    setIsResetDisabled(true);
 dispatch({
      type: BLOCK_DATE_LIST,
      payload: {
        urlParam: {
          pageIndex: 0,
        },
      },
    });
    props.setCurrentlyAppliedFilters(null);
    props.onCloseClick();
  };

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} id="offcanvasExample" toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Outlet Filters
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="facilityName" className="form-label">
                Facility Name
                </Label>
                <Input
                  id="facilityName"
                  name="facilityName"
                  type="text"
                  className="form-control"
                  value={filter?.facilityName}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="createdAt" className="form-label">
                 Created At
                </Label>
                <Flatpickr
                                 className="form-control"
                                 onChange={data => datePickHandler('createdAt', data)}
                                 value={[filter?.createdAt?.from,filter?.createdAt?.to]}
                                 options={{
                                  mode:'range',
                                   dateFormat: 'Y-m-d',
                                   // minDate: 'today',
                                 }}
                               />
              </div>
            </Col>

        
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button
            className="btn btn-light w-100"
            onClick={resetFilterHandler}
            // disabled={isResetDisabled}
          >
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default BlockDatesFilterNew;
