import { useEffect, useRef } from 'react';

import moment from 'moment';
import Flatpickr from 'flatpickr';
import { Col, Row } from 'reactstrap';

const BreadcrumbTrail = ({ title, navigation, navigationby, filter = null, updateFilter = null, isShowDate = false }) => {
  const inputRef = useRef(null);
  const flatpickrInstance = useRef(null);

  useEffect(() => {
    if (isShowDate) {
      flatpickrInstance.current = Flatpickr(inputRef?.current, {
        enableTime: false,
        mode: 'range',
        dateFormat: 'Y-m-d',
        clickOpens: false, // Prevents default click to open
        defaultDate: [filter?.startDate, filter?.endDate],
        onOpen: () => {
          setTimeout(() => {
            const calendarElement = document.querySelector('.flatpickr-calendar') as HTMLElement;

            if (calendarElement) {
              calendarElement.style.top = '4%';
              calendarElement.style.left = '35%';
              calendarElement.style.right = 'auto';
            }
          }, 0); // Ensures the calendar has been rendered before applying styles
        },
        onChange: date => {
          if (date[0] && date[1]) {
            const endDate = moment(new Date(date[1])).add(23, 'hours').add(59, 'minutes')?.toISOString();
            updateFilter(moment(new Date(date[0]))?.toISOString(), endDate);
          }
        },
      });
    }
  }, []);

  const openCalendar = () => {
    if (flatpickrInstance.current) {
      flatpickrInstance.current.open();
    }
  };

  const getDateLabel = (startDate, endDate) => {
    const start = moment(startDate).startOf('day');
    const end = moment(endDate).startOf('day');
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
    const tomorrow = moment().add(1, 'day').startOf('day');

    if (start.isSame(end)) {
      if (start.isSame(today)) return 'Today';
      if (start.isSame(yesterday)) return 'Yesterday';
      if (start.isSame(tomorrow)) return 'Tomorrow';
    }

    return null;
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="flex-grow-1 mb-4">
          <div className="d-flex gap-1">
            {' '}
            <h1 className="fs-24 mb-1 text-primary">{title}</h1>
            {isShowDate && (
              <>
                <div style={{ borderLeft: '3px solid #405189', height: '28px' }}></div>
                <p className="mt-1 fw-semibold">
                  {filter?.startDate && filter.endDate && (
                    <>
                      {getDateLabel(filter.startDate, filter.endDate)
                        ? getDateLabel(filter.startDate, filter.endDate)
                        : `${moment(filter.startDate).format('D MMM')} - ${moment(filter.endDate).format('D MMM')}`}
                    </>
                  )}
                </p>
                <div className="mt-1">
                  <i onClick={openCalendar} style={{ cursor: 'pointer' }} className="ri-pencil-fill"></i>
                </div>
                <input ref={inputRef} type="text" placeholder="Select date" style={{ display: 'none' }} />
              </>
            )}
          </div>
          <ol className="breadcrumb m-0">
            {navigation?.map((text: string, index: number) => (
              <li key={`${index}`} className="breadcrumb-item">
                <span
                  className={`${navigation?.length !== index + 1 || navigation?.length === 1 ? 'cursor-pointer fw-medium' : 'text-muted'}`}
                  onClick={() => navigationby(text)}
                >
                  {text}
                </span>
              </li>
            ))}
          </ol>
        </div>
      </Col>
    </Row>
  );
};

export default BreadcrumbTrail;
