import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import CountUp from 'react-countup';
import { FaFire } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import tat from '../../../../../../assets/custom/v2/tat.svg';

const SLACard = ({ slaBooking, cardData, filter }) => {
  const history = useHistory();

  const totalCount =
    cardData?.[slaBooking?.name]?.data?.totalCount || cardData?.[slaBooking?.name]?.data?.totalCount === 0
      ? Number(cardData?.[slaBooking?.name]?.data?.totalCount)
      : null;
  console.log('totalCount', totalCount);

  const routeHandler = () => {
    const params = {
      name: slaBooking?.name,
      type: slaBooking?.type,
      startDate: filter?.startDate,
      endDate: filter?.endDate,
    };
    if (slaBooking?.nextNavigation) {
      history.push({
        pathname: '/v2/booking/airport-transfer',
        search: `?${queryString.stringify(params)}`,
      });
    }
  };

  return (
    <Card
      style={{ border: '1px solid #A2A1A833', borderRadius: '20px', cursor: slaBooking?.nextNavigation && 'pointer', boxShadow: 'none' }}
      onClick={routeHandler}
      className="sla-card"
    >
      <CardBody style={{ padding: '1rem 1rem 0.5rem 1rem' }}>
        <div className="d-flex align-items-center" style={{ gap: '1rem', justifyContent: 'space-between' }}>
          {/* <FaFire className="icon-fire" size={20} color="#EA9C2B" /> */}
          <img src={slaBooking?.icon ? slaBooking?.icon : tat} className="img-fluid" alt="" style={{ width: '46px' }} />
          <p className="sla-title">{slaBooking?.displayName}</p>
        </div>
        <div className={`mt-3 mb-0 ${!slaBooking?.data?.totalCount ? 'm-2' : 'm-1'}`}>
          {
            totalCount != null ? (
              <CountUp separator="" start={0} end={totalCount} duration={2} className="fw-semibold" style={{ fontSize: '30px' }} />
            ) : (
              <p className="fw-bold text-format-3 m-0">-</p>
            ) // Showing dash when data is missing
          }

          {/* <p className="sla-status text-success fs-12" style={{ margin: '7px' }}>
            No Change in Booking
          </p> */}
        </div>
      </CardBody>
      <hr className="m-0" style={{ border: '0px solid #A2A1A833' }} />
      <p className="text-sm fw-light" style={{ margin: '12px 14px', color: '#A2A1A8' }}>
        Last updated {moment(slaBooking?.lastUpdatedAt).fromNow()}
      </p>
    </Card>
  );
};

export default SLACard;
