import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RxCross1 } from 'react-icons/rx';
import { Button, Card, CardBody, CardHeader, Col, Label, Row, TabContent, TabPane } from 'reactstrap';

import { GET_BOOKING_DETAILS_EMPTY } from '../../../../../../../store/actions';

const Summary = ({ details, currentSelectedBooking, setCurrentSelectedBooking, setCurrentActiveBookingAction }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [changeLogs, setChangeLogs] = useState(null);

  useEffect(() => {
    scrollToSection('summary-section');
  }, [details]);

  useEffect(() => {
    setCurrentActiveTab(0);
  }, [currentSelectedBooking]);

  const scrollToSection = elementName => {
    const section = document.getElementById(elementName);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Row id="summary-section">
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex justify-content-end">
              <h4 className="card-title mb-0 flex-grow-1 fw-semibold fs-5">
                Booking ID - <span className="fw-normal text-decoration-underline">{currentSelectedBooking?.bookingId}</span>{' '}
              </h4>

              <div className="d-flex gap-3 align-items-center">
                {currentSelectedBooking?.actions?.includes(`modify_booking`) && (
                  <Button
                    className="customized-btn danger"
                    onClick={() => {
                      setCurrentActiveBookingAction(`modify_booking`);
                    }}
                  >
                    Modify
                  </Button>
                )}

                {currentSelectedBooking?.actions?.includes(`cancel_booking`) && (
                  <Button
                    className="customized-btn danger"
                    onClick={() => {
                      console.log('I am here', currentSelectedBooking?.isShortNoticeCancel);
                      if (currentSelectedBooking?.isShortNoticeCancel) {
                        setCurrentActiveBookingAction(`short_notice_cancel_booking`);
                      } else {
                        setCurrentActiveBookingAction(`cancel_booking`);
                      }
                    }}
                  >
                    Cancel Booking
                  </Button>
                )}
                <span
                  className="cancel-details-icon"
                  onClick={() => {
                    dispatch({
                      type: GET_BOOKING_DETAILS_EMPTY,
                    });
                    setCurrentSelectedBooking(null);
                  }}
                >
                  <RxCross1 />
                </span>
              </div>
            </CardHeader>

            <CardHeader className="d-flex justify-content-end p-0">
              <div className="custom-tabs-container">
                {details?.result?.map((bookingDetail, index) => {
                  return (
                    <div
                      className={`custom-tab ${index === currentActiveTab ? 'active' : ''}`}
                      key={index}
                      onClick={() => {
                        setCurrentActiveTab(index);
                        setChangeLogs(null);
                      }}
                    >
                      {bookingDetail?.tabDetails?.tabHeading}
                    </div>
                  );
                })}
              </div>
            </CardHeader>

            <CardBody>
              <TabContent activeTab={currentActiveTab}>
                {details?.result?.map((bookingDetail, index) => {
                  if (bookingDetail?.tabDetails?.tabHeading === `Booking Logs`) {
                    return (
                      <TabPane tabId={index} key={index} className="mt-2">
                        <Row className="pe-4">
                          <Col md={5}>
                            <div className="modification-wrapper">
                              {bookingDetail?.tabDetails?.data?.map((logDetail, index) => (
                                <div
                                  className="modification-container"
                                  key={index}
                                  style={{
                                    cursor: `${logDetail?.isActionAble?.status ? 'pointer' : 'unset'}`,
                                  }}
                                  onClick={() => {
                                    setChangeLogs(logDetail?.isActionAble?.status ? logDetail?.isActionAble : null);
                                  }}
                                >
                                  <div className="d-flex gap-3 align-items-start">
                                    <div className={``} style={{ color: `${logDetail?.color || '#405189'}` }}>
                                      <i className="mdi mdi-24px mdi-circle-slice-8" />
                                    </div>

                                    <div className="modification-details-section">
                                      <p className="modification-status-tag" style={{ color: `${logDetail?.color || ''}` }}>
                                        {logDetail?.bookingStatus || `NA`}
                                      </p>
                                      <p className="modification-date">Date - {logDetail?.dateTime || ``}</p>

                                      {Boolean(`${logDetail?.actionBy}`?.trim()) && (
                                        <p className="modification-action-by">Action by - {logDetail?.actionBy || `NA`}</p>
                                      )}

                                      {Boolean(`${logDetail?.remarks}`?.trim()) && (
                                        <p className="modification-remarks-section">
                                          Remarks - <span className="modification-remarks">{logDetail?.remarks || `NA`}</span>
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>

                          {changeLogs !== null && changeDetails(changeLogs)}
                        </Row>
                      </TabPane>
                    );
                  } else if (bookingDetail?.tabDetails?.tabHeading === `Modify Details`) {
                    return (
                      <>
                        <TabPane tabId={index} key={index} className="mt-2">
                          <Row>
                            {changeDetails({
                              prevState: bookingDetail?.tabDetails?.data?.[0]?.details,
                              currentState: bookingDetail?.tabDetails?.data?.[1]?.details,
                            })}
                          </Row>
                        </TabPane>
                      </>
                    );
                  } else {
                    return (
                      <TabPane tabId={index} key={index} className="mt-2">
                        <Row>
                          {bookingDetail?.tabDetails?.data?.map((tabDetail, index) => {
                            return (
                              <Row key={index}>
                                <Col md={12}>
                                  <p className="text-primary custom-section-heading">{tabDetail?.sectionHeading}</p>
                                </Col>

                                {tabDetail?.details?.map((details, index) => {
                                  return (
                                    <Col md={4} key={index}>
                                      <div className="mb-2 d-flex flex-column">
                                        <Label className="custom-section-label">
                                          {details?.icon && <img src={details?.icon} />}
                                          {details?.heading || 'NA'}
                                        </Label>
                                        <Label className="custom-section-text">{details?.value || 'NA'}</Label>
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                            );
                          })}
                        </Row>
                      </TabPane>
                    );
                  }
                })}
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Summary;

const events = ['Vendor Assigned', 'Chauffeur Reassigned', 'Booking Modified', 'Booking Completed'];

const DetailItem = ({ label, value, highlight, isColor }) => {
  return (
    <div className="modified-item">
      <span className="modified-label">{label}</span>
      <span className={`modified-value`} style={{ color: isColor ? isColor : '#121639' }}>
        {value}
      </span>
    </div>
  );
};

const changeDetails = data => {
  console.log('changeLogs --', data);
  return (
    <Col md={7}>
      <div className="booking-truncate-details">
        <div className="booking-prev-details-section">
          <p className="modification-section-heading">Booking Details</p>
          {data?.prevState?.map((pState, index) => (
            <DetailItem key={index} label={pState?.heading || `NA`} value={pState?.value || `NA`} />
          ))}
        </div>
        <div className="booking-prev-next-section">
          <p className="modification-section-heading">Modified Booking Details</p>

          {data?.currentState?.map((cState, index) => (
            <DetailItem key={index} label={cState?.heading || `NA`} value={cState?.value || `NA`} isColor={'#EA9C2B'} />
          ))}
        </div>
      </div>
    </Col>
  );
};
