import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweDiscoveryUrl, thriweMarketPlaceUaeUrl, thriweMarketPlaceUrl } from '../../../../../Containers/Config';
import { Constant } from '../../../../../Containers/Helpers/constant';
import apiCall from '../../../apiCall';
import { UPDATE_ANNOUNCEMENT, UPDATE_ANNOUNCEMENT_ERROR, UPDATE_ANNOUNCEMENT_SUCCESS } from '../../../actionType';

function* updateAnnouncementForGlobeToyota({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweDiscoveryUrl}/v1/admin/announcement/${payload?.id}`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        // 'Project-Code': 'GLOBE_TOYOTA',
        ...customHeaders,
      },
      data: payload?.data,
      params: {
        ...payload?.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: UPDATE_ANNOUNCEMENT_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: UPDATE_ANNOUNCEMENT_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: UPDATE_ANNOUNCEMENT_ERROR, payload: error });
  }
}

export function* watchupdateAnnouncementForGlobeToyota() {
  yield takeEvery(UPDATE_ANNOUNCEMENT, updateAnnouncementForGlobeToyota);
}

function* updateAnnouncementForGlobeToyotaSaga() {
  yield all([fork(watchupdateAnnouncementForGlobeToyota)]);
}

export default updateAnnouncementForGlobeToyotaSaga;
