import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, umsUrl } from '../../../../Containers/Config/index.js';
import { GET_USER_TOKEN, GET_USER_TOKEN_SUCCESS, GET_USER_TOKEN_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';


function* getUserToken({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${umsUrl}/admin/v2/user-token?role_id=${payload?.urlParams?.role_id}`,
      method: 'GET',
      headers: {
        ...customHeaders,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_USER_TOKEN_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_USER_TOKEN_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_USER_TOKEN_ERROR, payload: error });
  }
}

export function* watchUserToken() {
  yield takeEvery(GET_USER_TOKEN, getUserToken);
}

function* watchUserTokenSaga() {
  yield all([fork(watchUserToken)]);
}

export default watchUserTokenSaga;
