import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Link, useParams } from 'react-router-dom';

import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Label, Input } from 'reactstrap';

import { Error, Loader } from '../../../../../Common';
import { isValidEmail } from '../../../../../Helpers/Helpers';
import ThriweWhite from '../../../../../../assets/custom/thriwe-white-logo.png';

import ParticlesAuth from '../../../../../../pages/AuthenticationInner/ParticlesAuth';

interface LoginData {
  email: string;
  password: string;
  [key: string]: any;
}

interface LoginFormProps {
  onSubmit: (data: LoginData) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    email: '',
    password: '',
  };

  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'email':
        updatedState = {
          ...state,
          email: value,
        };
        break;

      case 'password':
        updatedState = {
          ...state,
          password: value,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.email?.trim() !== `` && data?.password?.trim() !== `` && isValidEmail(data?.email?.trim())) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = async () => {
    if (validateErrors(state, `submissionCheck`)) {
      submitLogin();
    }
  };

  const submitLogin = () => {
    const payload = {
      ...state,
    };
    onSubmit({
      ...payload,
    });
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        {isButtonLoading && <Loader />}
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <p className="mt-3 fs-15 fw-medium">&nbsp;</p>
                    <Link to="/v2/vendor/login" className="d-inline-block auth-logo">
                      <img src={ThriweWhite} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4" style={{ borderRadius: '40px' }}>
                  <CardBody className="p-md-5 p-sm-3">
                    {/* {client !== 'adib' && ( */}
                    <>
                      <div className="text-center mt-2">
                        <h1 className="text-primary">Login</h1>
                      </div>
                      <div className="p-2 mt-4 mb-3">
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Email ID
                          </Label>
                          <Input
                            id="email"
                            name="email"
                            type="text"
                            placeholder={'Enter Email ID'}
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.email}
                          />
                          {isValidationShow && state?.email?.trim() === `` && <Error text={`Email can't be empty`} />}
                          {isValidationShow && state?.email?.trim() !== `` && !isValidEmail(state?.email?.trim()) && (
                            <Error text={`Please enter valid email`} />
                          )}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label" htmlFor="password-input">
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              id="password"
                              name="password"
                              type={isShowPassword ? 'text' : 'password'}
                              placeholder={`Enter Password`}
                              className="form-control"
                              onChange={inputChangeHandler}
                              value={state?.password}
                            />
                            {isValidationShow && state?.password?.trim() === `` && <Error text="Password can't be empty" />}
                            {isValidationShow && state?.password?.trim() !== `` && <Error text="Please enter correct password" />}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                              onClick={() => {
                                setIsShowPassword(!isShowPassword);
                              }}
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                          <Label className="form-check-label" htmlFor="auth-remember-check">
                            {'Remember me'}
                          </Label>
                          {/* <div className="float-end">
                            <Link to="/client/forgot-password" className="text-muted">
                              {`Forgot password?`}
                            </Link>
                          </div> */}
                        </div>

                        <div className="mt-4">
                          <Button type="button" className="btn btn-success btn-load w-100" onClick={submitHandler}>
                            <span className="d-flex align-items-center">
                              <span className="flex-grow-1 me-2">Login</span>
                            </span>
                          </Button>
                        </div>
                      </div>
                    </>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(LoginForm);
