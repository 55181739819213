import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// import { baseUrl, customHeaders, umsUrl, adminAPIURL } from '../../../../Containers/Config/index.js';
import { baseUrl, customHeaders, umsUrl, adminAPIURL } from '../../../../../Containers/Config/index.js';
import { GET_ACTION_BY_STATUS, GET_ACTION_BY_STATUS_SUCCESS, GET_ACTION_BY_STATUS_ERROR } from '../../actions.js';
// import apiCall from '../../apiCall';
import apiCall from '../../../apiCall.js';

function* getActionByStatus({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v2/remarks/actions`,
      method: 'GET',
      headers: {
        // Authorization: Constant?.CLIENT_TOKEN,
        ...filteredHeaders,
        // withCredentials: true
      },
      params: payload?.urlParams,
    });

    if (response.status === 200) {
      yield put({
        type: GET_ACTION_BY_STATUS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_ACTION_BY_STATUS_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_ACTION_BY_STATUS_ERROR, payload: error });
  }
}

export function* watchGetActionByStatus() {
  yield takeEvery(GET_ACTION_BY_STATUS, getActionByStatus);
}

function* getActionByStatusSaga() {
  yield all([fork(watchGetActionByStatus)]);
}

export default getActionByStatusSaga;
