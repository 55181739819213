import React from 'react';
import { Container } from 'reactstrap';
import BreadcrumbTrail from '../../../../../Common/BreadcrumbTrail';
import CreateConfigurationMappingForm from './Components/Form/Form';
import { useHistory } from 'react-router-dom';

const ConfigurationMapping = () => {
  const history = useHistory();

  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Template Management`) {
      history.push(`/surge/templates`);
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <BreadcrumbTrail
          title="Create Mapping"
          navigationby={breadcrumNavigationHandler}
          navigation={[`Dashboard`, `Template Management`, `Create Mapping`]}
        />
        <CreateConfigurationMappingForm />
      </Container>
    </div>
  );
};

export default ConfigurationMapping;
