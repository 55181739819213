import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { formattedWithComma, hasPermission } from '../../../../../../Helpers/Helpers';
import { TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import moment from 'moment';
import CustomTable from '../../../../../../Common/Table/Table';
import parse from 'html-react-parser';

interface Announcement {
  id: string;
  title: string;
  type: string;
  content: string;
  created_at: string;
  updated_at: string;
  user?: {
    email?: string;
  };
  is_featured: boolean;
}

// interface ManageAnnouncementProps {
//   data: Announcement[];
//   pageNumber: number;
//   totalCount: number;
//   pageHandler: (page: number) => void;
//   actionHandler: (action: { announcementDetails: Announcement; actionType: string }) => void;
// }

const ManageAnnouncement = props => {
  const [data, setData] = useState([]);
  const [announcementData, setAnnouncementTData] = useState<Announcement[]>([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setAnnouncementTData(
        props?.data?.map(booking => ({
          ...booking,
        })),
      );
    } else {
      setData([]);
      setAnnouncementTData([]);
    }
  }, [props?.data]);

  useEffect(() => {
    if (announcementData?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: (row: any) => row?.serialNumber,
        },
        {
          name: 'Announcement Title',
          minWidth: '200px',
          cell: (row: any) => row?.title,
        },
        {
          name: 'Announcement Type',
          width: '160px',
          cell: (row: any) => row?.type,
        },
        // {
        //   name: 'Date Of Upload',
        //   width: '140px',
        //   cell: (row: any) => row?.upload,
        // },
        {
          name: 'Date Of Upload',
          width: '200px',
          cell: (row: any) => row?.created_at,
        },
        {
          name: 'Status',
          width: '200px',
          cell: (row: any) => (
            <span
              style={{
                display: 'inline-block',
                padding: '6px 12px',
                fontSize: '14px',
                fontWeight: '600',
                borderRadius: '8px',
                color: row?.status === 'Active' ? '#0F5132' : '#842029', // Dark Green for Active, Dark Red for Inactive
                backgroundColor: row?.status === 'Active' ? '#D1E7DD' : '#F8D7DA', // Light Green for Active, Light Red for Inactive
              }}
            >
              {row?.status}
            </span>
          ),
        },
        {
          name: 'Actions',
          width: '250px',
          cell: (row: any) => (
            <div className="d-flex justify-content-center flex-column m-4">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.ANNOUNCEMENT_UPDATE) && (
                <button
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`UPDATE`, row?.originalData);
                  }}
                  style={{ width: '150px' }}
                >
                  Edit
                </button>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);
      console.log('announcementData', announcementData);
      setData(
        announcementData?.map((data, index) => ({
          serialNumber: `${props?.limit * props?.pageNumber + (index + 1)}.`,
          title: parse(data?.title) || 'NA',
          type: data?.type || 'NA',
          content: parse(data?.content) || 'NA',
          status: data?.is_featured ? 'Active' : 'In Active',
          created_at: data?.created_at ? moment(new Date(`${data?.created_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
          updated_at: data?.updated_at ? moment(new Date(`${data?.updated_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
          originalData: data,
        })),
      );
    } else {
      setData([]);
      setColumns([]);
    }
  }, [announcementData]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag: string, data: Announcement) => {
    if (flag === `UPDATE`) {
      props.actionHandler({
        announcementDetails: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex justify-content-end">
              <h4 className="card-title mb-0 flex-grow-1">Announcement List</h4>
            </CardHeader>

            <CardBody>
              <CustomTable
                columns={columns ?? []}
                data={data ?? []}
                totalCount={props.totalCount}
                limit={props.limit}
                handleRowsPerPageChange={props.handleRowsPerPageChange} // Allows rows-per-page customization
                currentPage={props.pageNumber}
                handlePageChange={props.handlePageChange}
                indexType={Constant.INDEX_TYPE.ZERO_BASED}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ManageAnnouncement;
