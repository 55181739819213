import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';

import axios from 'axios';
import cogoToast from 'cogo-toast';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';

import {
  CREATE_CLIENT_USER_EMPTY,
  GET_BENEFIT_ITEMS_EMPTY,
  GET_BINS_LIST,
  GET_BINS_LIST_EMPTY,
  GET_CLIENT_BENEFIT_CONFIG_EMPTY,
  GET_MEMBERSHIPS_DATA_EMPTY,
  GET_USER_DETAILS,
  VALIDATE_USER_EMPTY,
  VENDOR_OTP_VALIDATE_EMPTY,
  VENDOR_RESEND_OTP_EMPTY,
} from '../../../../../../../../../store/actions';
import { Error, Loader } from '../../../../../../../../Common';
import MobileField from '../../../../../../../../Common/FormControls/MobileField';
import { uaeProjectsBaseURL } from '../../../../../../../../Config';
import { Constant } from '../../../../../../../../Helpers/constant';
import { isANumber, isValidAplha, isValidEmail } from '../../../../../../../../Helpers/Helpers';

const initialState = {
  countryCode: '966',
  phoneNumber: '',
  email: ``,
  name: ``,
  binNumber: null,
  bank: null,
  typedBinNumber: ``,
  typedBinNumberData: null,
};

const ExistingCustomerForm = ({ benefitItems, onSubmit, isUserFound, setIsUserFound, selectedBank }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const [isValidationShow, setIsValidationShow] = useState(false);
  // const [isUserFound, setIsUserFound] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { binsList, binsListLoading } = useSelector(state => ({
    binsList: state?.GetBinsList?.data,
    binsListLoading: state?.GetBinsList?.loading,
  }));

  const { createUserLoading } = useSelector(state => ({
    createUserLoading: state?.CreateClientUser?.loading,
  }));

  // USER DETAILS
  const { userDetails, userDetailsLoading } = useSelector(state => ({
    userDetails: state?.GetUserDetails?.data,
    userDetailsLoading: state?.GetUserDetails?.loading,
  }));

  console.log('selectedBankselectedBank', binsList);

  useEffect(() => {
    if (selectedBank !== null) {
      localStorage.setItem('bankCode', selectedBank?.value);
      setState({
        ...state,
        bank: selectedBank,
      });

      dispatch({
        type: GET_BINS_LIST,
      });
    }
  }, [selectedBank]);

  useEffect(() => {
    if (userDetails !== null && userDetails?.users?.length > 0) {
      setIsUserFound(true);
      setState({
        ...initialState,
        countryCode: `${state?.countryCode}`,
        phoneNumber: `${state?.phoneNumber}`,
        email: `${userDetails?.users?.[0]?.['email']}`,
        name: `${userDetails?.users?.[0]?.['firstName']}`,
        bank: selectedBank,
      });
    } else if (userDetails !== null && userDetails?.users?.length === 0) {
      cogoToast?.info(`User not found!`);
      setIsUserFound(false);
      setState({
        ...initialState,
        countryCode: `${state?.countryCode}`,
        phoneNumber: `${state?.phoneNumber}`,
        bank: selectedBank,
      });
      // dispatch({
      //   type: GET_USER_DETAILS_EMPTY,
      // });
    }
  }, [userDetails]);

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputChangeHandler = (label, data) => {
    let updatedState = { ...state };

    switch (label) {
      case `bank`:
        localStorage.setItem('bankCode', data?.value);
        updatedState = {
          ...state,
          bank: data,
        };

        dispatch({
          type: GET_BINS_LIST,
        });
        break;

      case `binNumber`:
        updatedState = {
          ...state,
          binNumber: data,
          typedBinNumber: ``,
          typedBinNumberData: null,
        };
        break;

      case `name`:
        updatedState = {
          ...state,
          name: isValidAplha(data) ? data : state?.name,
        };
        break;

      case `email`:
        updatedState = {
          ...state,
          email: data,
        };
        break;

      default:
      //
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- MOBILE CHANGE HANDLER ---------------------------- */
  const mobileChangeHandler = async (value, data) => {
    let updatedState = {
      ...state,
      countryCode: data?.dialCode,
      phoneNumber: value.slice(data?.dialCode?.length),
    };

    if (state?.email || state.name || state?.binNumber) {
      setState({
        ...state,
        countryCode: data?.dialCode,
        phoneNumber: value.slice(data?.dialCode?.length),
      });
    } else {
      setState(updatedState);
    }

    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.bank !== null &&
      data?.name?.trim() !== `` &&
      data?.email?.trim() !== `` &&
      isValidEmail(data?.email?.trim()) &&
      data?.phoneNumber?.trim() !== ``
    ) {
      if (data?.typedBinNumber !== `` && data?.typedBinNumberData === null) {
        setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
        return false;
      } else if (data?.typedBinNumber !== `` && data?.typedBinNumberData !== null) {
        setIsValidationShow(false);
        return true;
      } else if (data?.binNumber === null) {
        setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
        return false;
      } else {
        setIsValidationShow(false);
        return true;
      }
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      let binNumber = null;
      if (state?.binNumber !== null) {
        binNumber = state?.binNumber;
        binNumber = {
          binId: state?.binNumber?.data?.binId,
          first: state?.binNumber?.data?.number,
        };
      } else if (state?.typedBinNumberData !== null) {
        binNumber = {
          binId: state?.typedBinNumberData?.completeData?.binId,
          first: state?.typedBinNumberData?.completeData?.number,
        };
      }

      const payload = {
        firstName: state?.name,
        countryCode: state?.countryCode,
        phoneNumber: state?.phoneNumber,
        email: state?.email,
        card: {
          ...binNumber,
        },
      };

      onSubmit(payload);
    }
  };

  /* ---------------------------- LOAD BANKS ---------------------------- */
  async function loadBanks(search) {
    let projectCodes = [];
    JSON.parse(localStorage?.getItem('data'))?.program?.client?.clients.map(item => projectCodes.push(item));

    return await axios({
      method: `POST`,
      url: `${uaeProjectsBaseURL}/admin/v1/projects`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Content-Type': 'application/json',
      },
      params: {
        name: search === '' ? null : search,
      },
      data: {
        projectCodes: projectCodes,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.code,
              label: `${data?.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- LOADER HANDLER ---------------------------- */
  useEffect(() => {
    if (binsListLoading || createUserLoading || userDetailsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [binsListLoading, createUserLoading, userDetailsLoading]);

  /* ---------------------------- GET USER DETAILS ---------------------------- */
  const showDetailsHandler = () => {
    if (state?.phoneNumber === '') {
      setIsValidationShow(true);
    } else {
      // dispatch({
      //   type: GET_BINS_LIST_EMPTY,
      // });
      dispatch({
        type: CREATE_CLIENT_USER_EMPTY,
      });
      dispatch({
        type: GET_MEMBERSHIPS_DATA_EMPTY,
      });
      dispatch({
        type: GET_BENEFIT_ITEMS_EMPTY,
      });
      dispatch({
        type: GET_CLIENT_BENEFIT_CONFIG_EMPTY,
      });
      dispatch({
        type: VENDOR_RESEND_OTP_EMPTY,
      });
      dispatch({
        type: VENDOR_OTP_VALIDATE_EMPTY,
      });
      dispatch({
        type: VALIDATE_USER_EMPTY,
      });

      setIsValidationShow(false);

      dispatch({
        type: GET_USER_DETAILS,
        payload: {
          urlParam: {
            phoneNumber: state?.phoneNumber,
            countryCode: state?.countryCode,
          },
        },
      });
    }
  };

  /* ---------------------------- ON ENTER BIN NUMBER ---------------------------- */
  const onEnterBinNumber = data => {
    let updatedState = { ...state };

    if (data?.length <= 6) {
      let tempTypedBinNumber = ``;
      if (data?.length === 0) {
        tempTypedBinNumber = ``;
      } else {
        tempTypedBinNumber = isANumber(data) ? data : state?.typedBinNumber;
      }

      const filteredBinData = binsList?.results?.filter(bin => bin?.number === data);
      if (filteredBinData?.length > 0) {
        updatedState = {
          ...state,
          typedBinNumber: tempTypedBinNumber,
          typedBinNumberData: {
            value: filteredBinData?.[0]?.binId,
            label: `${filteredBinData?.[0]?.name}`,
            completeData: filteredBinData?.[0],
          },
          binNumber: null,
        };
      } else {
        updatedState = {
          ...state,
          typedBinNumber: tempTypedBinNumber,
          typedBinNumberData: null,
          binNumber: null,
        };
      }
    }
    setState({
      ...updatedState,
    });

    validateErrors(updatedState, `validationCheck`);
  };

  return (
    <div>
      {loading && <Loader />}
      {/* <button onClick={() => console.log(state)}>get state</button> */}

      {/* <Card> */}
      <CardHeader className="d-flex">
        <h4 className="card-title mb-0 flex-grow-1">{t(`Card Details`)}</h4>
      </CardHeader>

      <CardBody>
        <Row className="text-black">
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label text-black">
                {t(`Registered Mobile Number`)}
                <span className="lbl_mandatory"> *</span>
              </Label>
              <div className="input-group align-items-center">
                <MobileField
                  placeholder={t(`Registered Mobile Number`)}
                  onChange={mobileChangeHandler}
                  value={`${state?.countryCode}${state?.phoneNumber}`}
                  preferredCountries={['sa', 'uae', 'in']}
                  defaultCountry={'sa'}
                />
              </div>
              {isValidationShow && state?.phoneNumber?.trim() === '' && <Error text="Please enter registered mobile number" />}
            </div>
          </Col>

          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label text-black">
                &nbsp;
              </Label>
              <button className="btn btn-info d-inline-block mt-4 px-3" onClick={showDetailsHandler}>
                {t(`Show Details`)} <i className="ri-arrow-right-line me-1 text-white fs-16 align-middle"></i>
              </button>
            </div>
          </Col>
        </Row>
        <hr />

        <Row>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label text-black">
                {t(`Customer Name`)}
                <span className="lbl_mandatory"> *</span>
              </Label>
              <Input
                placeholder={t(`Name`)}
                id="name"
                name="name"
                type="text"
                className="form-control"
                value={state?.name}
                onChange={e => inputChangeHandler(`name`, e?.target?.value)}
                // disabled={true}
                autoComplete="off"
                disabled={userDetails === null ? true : isUserFound ? true : false}
              />
              {isValidationShow && state?.name?.trim() === `` && userDetails !== null && <Error text={`${t('Please enter customer name')}`} />}
            </div>
          </Col>

          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label text-black">
                {t(`Registered Email ID`)}
                <span className="lbl_mandatory"> *</span>
              </Label>
              <Input
                placeholder={t(`Email ID`)}
                id="email"
                name="email"
                type="text"
                className="form-control"
                value={state?.email}
                onChange={e => inputChangeHandler(`email`, e?.target?.value)}
                autoComplete="off"
                // disabled={true}
                disabled={userDetails === null ? true : isUserFound ? true : false}
              />
              {isValidationShow && state?.email?.trim() === `` && userDetails !== null && <Error text={`${t('Please enter registered email id')}`} />}
              {isValidationShow && state?.email?.trim() !== `` && !isValidEmail(state?.email) && <Error text="Please enter correct email" />}
            </div>
          </Col>

          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label text-black">
                {t(`Select Bank`)}
                <span className="lbl_mandatory"> *</span>
              </Label>
              <AsyncPaginate
                debounceTimeout={500}
                value={state?.bank}
                loadOptions={loadBanks}
                onChange={data => inputChangeHandler(`bank`, data)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder={t(`Bank Name`)}
                isDisabled={userDetails === null || benefitItems !== undefined || state?.bank}
                className="text-black"
              />
              {isValidationShow && state?.bank === null && userDetails !== null && <Error text={`${t('Please select bank')}`} />}
            </div>
          </Col>

          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label text-black">
                {t(`Select Card`)}
                {state?.typedBinNumber?.trim() === `` && <span className="lbl_mandatory"> *</span>}
              </Label>
              <Select
                placeholder={t(`Card Name`)}
                id="card"
                name="card"
                value={state?.binNumber}
                onChange={data => inputChangeHandler(`binNumber`, data)}
                options={binsList?.results?.map(bin => ({
                  value: `${bin?.binId}`,
                  label: `${bin?.name}`,
                  data: bin,
                }))}
                isDisabled={userDetails === null || state?.bank === null || benefitItems !== undefined}
                className="text-black"
              />
              {isValidationShow &&
                state?.typedBinNumber?.trim() === `` &&
                state?.binNumber === null &&
                state?.bank !== null &&
                userDetails !== null && <Error text={`${t('Please select card')}`} />}
            </div>
          </Col>

          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <div
                style={{
                  position: `absolute`,
                  top: `35px`,
                  left: `-6px`,
                }}
                className="text-black"
              >
                Or
              </div>

              <Label htmlFor="name" className="form-label text-black">
                {t(`Bin Number`)} <span className="text-muted fs-11">({t('Card First 6 Digit')})</span>
                {state?.binNumber === null && state?.typedBinNumber?.trim() !== `` && <span className="lbl_mandatory"> *</span>}
              </Label>
              <Input
                placeholder={t(`Bin Number`)}
                id="bin"
                name="bin"
                type="text"
                className="form-control"
                value={state?.typedBinNumber}
                onChange={e => onEnterBinNumber(e?.target?.value)}
                disabled={userDetails === null || state?.bank === null || benefitItems !== undefined}
                autoComplete="off"
              />
              {/* {isValidationShow && state?.typedBinNumber?.trim() === `` && userDetails !== null && state?.bank !== null && (
               <Error text={`${t('Please enter bin number')}`} />
              )} */}
              {isValidationShow &&
                state?.typedBinNumber?.trim() !== `` &&
                state?.typedBinNumberData === null &&
                userDetails !== null &&
                state?.bank !== null && <Error text="Please enter valid bin number" />}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <button
              type="submit"
              className="btn btn-success d-inline-block mt-4 px-4"
              onClick={submitHandler}
              disabled={userDetails === null || benefitItems !== undefined}
            >
              {t(`Submit`)}
            </button>
          </Col>
        </Row>
      </CardBody>
    </div>
  );
};

export default ExistingCustomerForm;
