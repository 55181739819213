import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import axios from 'axios';
import { customHeaders, indianProjectsBaseURL, thriweDiscoveryUrl } from '../../../../../../../Config';
import { Constant } from '../../../../../../../Helpers/constant';
import Select from 'react-select';
import { Error, Loader } from '../../../../../../../Common';
import { CREATE_TEMPLATE_MAPPING, CREATE_TEMPLATE_MAPPING_EMPTY } from '../../../../../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

const initialValues = {
  workflow_template_id: null,
  event_id: null,
  project: null,
  start_date: '',
  end_date: '',
  status: 'INACTIVE',
};

const statusOptions = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'In Active' },
];

const CreateConfigurationMappingForm = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState(initialValues);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [loading, setLoading] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // CREATE SURGE WORKFLOW
  const { createTemplateMapping, createTemplateMappingLoading } = useSelector((state: any) => ({
    createTemplateMapping: state?.CreateTemplateMapping?.data,
    createTemplateMappingLoading: state?.CreateTemplateMapping?.loading,
  }));

  /* ---------------------------- GET TEMPLATE LIST  ---------------------------- */
  async function loadTemplates(search: string) {
    let urlParam = null;

    return await axios({
      method: `GET`,
      url: `${thriweDiscoveryUrl}/v1/admin/rules/workflow-templates`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...urlParam,
        name: search === '' ? null : search,
        skip: 0,
        limit: 150,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.id,
              label: `${data?.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- GET TEMPLATE LIST  ---------------------------- */
  async function loadEvent(search: string) {
    let urlParam = null;

    return await axios({
      method: `GET`,
      url: `${thriweDiscoveryUrl}/v1/admin/list-events`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...urlParam,
        name: search === '' ? null : search,
        skip: 0,
        limit: 150,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.id,
              label: `${data?.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- GET TEMPLATE LIST  ---------------------------- */
  async function loadProject(search: string) {
    let urlParam = null;

    return await axios({
      method: `GET`,
      url: `${indianProjectsBaseURL}/admin/projects`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...urlParam,
        name: search === '' ? null : search,
        pageIndex: 0,
        pageSize: 100,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.code,
              label: `${data?.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    switch (label) {
      case 'template':
        updatedState = {
          ...state,
          workflow_template_id: data,
        };
        break;

      case 'event':
        updatedState = {
          ...state,
          event_id: data,
        };
        break;

      case 'project':
        updatedState = {
          ...state,
          project: data,
        };
        break;

      case 'status':
        updatedState = {
          ...state,
          status: data?.value,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- DATE SELECT HANDLER ---------------------------- */
  const datePickHandler = (flag: string, date) => {
    let updatedState = { ...state };

    switch (flag) {
      case 'start_date':
        updatedState = {
          ...state,
          start_date: new Date(date).toISOString(),
        };
        break;

      case 'end_date':
        updatedState = {
          ...state,
          end_date: new Date(date).toISOString(),
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag: string) => {
    const startDate = new Date(data?.start_date);
    const endDate = new Date(data?.end_date);

    if (
      data?.workflow_template_id !== null &&
      data?.event_id !== null &&
      data?.project !== null &&
      data?.start_date !== '' &&
      data?.end_date !== `` &&
      data?.status !== `` &&
      startDate <= endDate
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        workflow_template_id: state?.workflow_template_id?.value,
        event_id: state?.event_id?.value,
        project_code: state?.project?.value,
        start_date: state.start_date,
        end_date: state.end_date,
        status: state?.status,
      };
      dispatch({
        type: CREATE_TEMPLATE_MAPPING,
        payload: {
          data: {
            ...payload,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (createTemplateMappingLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createTemplateMappingLoading]);

  useEffect(() => {
    if (createTemplateMapping !== null) {
      dispatch({
        type: CREATE_TEMPLATE_MAPPING_EMPTY,
      });

      setState({ ...initialValues, status: 'INACTIVE' });
    }
  }, [createTemplateMapping]);

  return (
    <>
      <Card className="card-inner">
        {loading && <Loader />}

        <CardHeader className="d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Create Mapping</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4} className="mb-3">
              <AsyncPaginate
                debounceTimeout={500}
                value={state?.workflow_template_id}
                loadOptions={loadTemplates}
                onChange={event => inputSelectHandler(`template`, event)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder="Select Template"
              />
              {isValidationShow && state?.workflow_template_id === null && <Error text="Please select template" />}
            </Col>

            <Col md={4} className="mb-3">
              <AsyncPaginate
                debounceTimeout={500}
                value={state?.event_id}
                loadOptions={loadEvent}
                onChange={event => inputSelectHandler(`event`, event)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder="Select Event"
              />
              {isValidationShow && state?.event_id === null && <Error text="Please select event" />}
            </Col>

            <Col md={4} className="mb-3">
              <AsyncPaginate
                debounceTimeout={500}
                value={state?.project}
                loadOptions={loadProject}
                onChange={event => inputSelectHandler(`project`, event)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder="Select Project"
              />
              {isValidationShow && state?.project === null && <Error text="Please select project" />}
            </Col>

            <Col md={4}>
              <div className="mb-3">
                <Flatpickr
                  className="form-control"
                  name="start_time"
                  id="start_time"
                  placeholder="Start Date"
                  value={state?.start_date}
                  onChange={date => datePickHandler(`start_date`, date)}
                  options={{
                    minDate: 'today',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }}
                />
                {isValidationShow && state?.start_date === `` && <Error text="Please select start date" />}

                {isValidationShow &&
                  state?.start_date !== `` &&
                  state?.end_date !== `` &&
                  new Date(state?.start_date).getTime() > new Date(state?.end_date).getTime() && <Error text="Start date is invalid" />}
              </div>
            </Col>

            <Col md={4}>
              <div className="mb-3">
                <Flatpickr
                  className="form-control"
                  name="end_time"
                  id="end_time"
                  placeholder="End Date"
                  value={state?.end_date}
                  onChange={date => datePickHandler(`end_date`, date)}
                  options={{
                    minDate: 'today',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }}
                />
                {isValidationShow && state?.end_date === `` && <Error text="Please select end date" />}
              </div>
            </Col>

            <Col md={4} className="mb-3">
              <div>
                <Select
                  name="status"
                  options={statusOptions ?? []}
                  value={statusOptions.find(status => status.value === state.status)}
                  onChange={data => inputSelectHandler(`status`, data)}
                  placeholder="Status"
                />
                {isValidationShow && state?.status === `` && <Error text="Please select status" />}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Col md={12} className="mb-5">
        <div className="text-center">
          <button type="submit" className="btn btn-primary btn-md" onClick={submitHandler}>
            Create Mapping
          </button>
        </div>
      </Col>
    </>
  );
};

export default CreateConfigurationMappingForm;
