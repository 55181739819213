import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { adminAPIURL, customHeaders } from '../../../../../../Containers/Config/index.js';
import apiCall from '../../../../apiCall.js';
import { GET_FILTER_VALUES, GET_FILTER_VALUES_ERROR, GET_FILTER_VALUES_SUCCESS } from '../../../actions.js';

function* getFilterValues({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/booking-filter-lists`,
      method: 'GET',
      headers: {
        ...filteredHeaders,
      },
      params: payload?.urlParams,
    });

    if (response.status === 200) {
      yield put({
        type: GET_FILTER_VALUES_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_FILTER_VALUES_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_FILTER_VALUES_ERROR, payload: error });
  }
}

export function* watchGetFilterValues() {
  yield takeEvery(GET_FILTER_VALUES, getFilterValues);
}

function* getFilterValuesSaga() {
  yield all([fork(watchGetFilterValues)]);
}

export default getFilterValuesSaga;
