import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import queryString from 'query-string';
import { Container } from 'reactstrap';

import {
  ASSIGN_CHAUFFEUR_EMPTY,
  ASSIGN_VENDOR_EMPTY,
  BOOKING_ASSIGN_TO_ME_EMPTY,
  CANCEL_BOOKING_BY_ADMIN_EMPTY,
  CANCEL_BOOKING_BY_VENDOR_EMPTY,
  CONFIRM_BOOKING_BY_VENDOR_EMPTY,
  GET_BOOKING_DETAILS,
  GET_BOOKING_DETAILS_EMPTY,
  GET_BOOKING_GLOBAL_SEARCH,
  GET_BOOKING_GLOBAL_SEARCH_EMPTY,
  GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH,
} from '../../../../../store/actions';
import { Loader } from '../../../../Common';
import BreadcrumbTrail from '../../../../Common/BreadcrumbTrail';
import { Constant } from '../../../../Helpers/constant';
import BookingModals from './Components/Modals';
import Summary from './Components/Summary';
import DataViews from './Components/Views';
import GlobalFilter from './Components/Filter/GlobalFilter';
import nofoundSvg from '../../../../../assets/custom/no-result-found.svg';

const GlobalSearchBooking = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { skip = 1, limit = Constant.TABLE_PAGE_SIZE, bookingType = '', search = '' } = queryString.parse(location.search);

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [currentPageNumber, setCurrentPageNumber] = useState(Number(skip) - 1);
  const [rowsPerPage, setRowsPerPage] = useState(Number(limit));

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  const [loading, setLoading] = useState(false);
  const [currentActiveBookingAction, setCurrentActiveBookingAction] = useState(null);

  const [currentSelectedBooking, setCurrentSelectedBooking] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { bookingsList, bookingsListLoading, bookingDetails, bookingDetailsLoading, bookingTypeData, bookingTypeLoading } = useSelector(
    (state: any) => ({
      bookingsList: state?.GetBookingGlobalSearch?.data,
      bookingsListLoading: state?.GetBookingGlobalSearch?.loading,

      bookingDetails: state?.GetBookingDetails?.data,
      bookingDetailsLoading: state?.GetBookingDetails?.loading,

      bookingTypeData: state.GetBookingTypeForGlobalSearch.data,
      bookingTypeLoading: state.GetBookingTypeForGlobalSearch.loading,
    }),
  );

  const {
    assignToMeResponse,
    assignChauffeurResponse,
    assignVendorResponse,
    cancelBookingByAdminResponse,
    cancelBookingByVendorResponse,
    confirmBookingByVendorResponse,
    modifyBooking,
    approvalAction,
    completeBookingResponse,
    raiseDispute,
  } = useSelector((state: any) => ({
    assignToMeResponse: state?.BookingAssignToMe?.data,
    assignChauffeurResponse: state?.AssignChauffeur?.data,
    assignVendorResponse: state?.AssignVendor?.data,
    cancelBookingByAdminResponse: state?.CancelBookingByAdmin?.data,
    cancelBookingByVendorResponse: state?.CancelBookingByVendor?.data,
    confirmBookingByVendorResponse: state?.ConfirmBookingByVendor?.data,
    modifyBooking: state?.ModifyBooking?.data,
    approvalAction: state?.ApprovalActionBooking?.data,
    completeBookingResponse: state?.CompleteBooking?.data,
    raiseDispute: state?.RaiseDispute?.data,
  }));

  useEffect(() => {
    if (
      assignToMeResponse !== null ||
      assignChauffeurResponse != null ||
      assignVendorResponse !== null ||
      cancelBookingByAdminResponse !== null ||
      cancelBookingByVendorResponse !== null ||
      confirmBookingByVendorResponse !== null ||
      modifyBooking !== null ||
      approvalAction !== null ||
      completeBookingResponse !== null ||
      raiseDispute !== null
    ) {
      dispatch({
        type: GET_BOOKING_GLOBAL_SEARCH,
        payload: {
          urlParams: {
            page_size: limit,
            page_index: Number(skip) - 1,
            booking_type: bookingType,
            search: search,
            ...currentlyAppliedFilters,
          },
          params: {},
        },
      });

      dispatch({
        type: BOOKING_ASSIGN_TO_ME_EMPTY,
      });
      dispatch({
        type: ASSIGN_CHAUFFEUR_EMPTY,
      });
      dispatch({
        type: ASSIGN_VENDOR_EMPTY,
      });
      dispatch({
        type: CANCEL_BOOKING_BY_ADMIN_EMPTY,
      });
      dispatch({
        type: CANCEL_BOOKING_BY_VENDOR_EMPTY,
      });
      dispatch({
        type: CONFIRM_BOOKING_BY_VENDOR_EMPTY,
      });

      dispatch({
        type: GET_BOOKING_DETAILS_EMPTY,
      });

      setCurrentSelectedBooking(null);
      setCurrentActiveBookingAction(null);
    }
  }, [
    assignToMeResponse,
    assignChauffeurResponse,
    assignVendorResponse,
    cancelBookingByAdminResponse,
    cancelBookingByVendorResponse,
    confirmBookingByVendorResponse,
    modifyBooking,
    approvalAction,
    completeBookingResponse,
    raiseDispute,
  ]);

  useEffect(() => {
    if (bookingType !== `` && search !== ``) {
      dispatch({
        type: GET_BOOKING_GLOBAL_SEARCH,
        payload: {
          urlParams: {
            page_size: limit,
            page_index: Number(skip) - 1,
            booking_type: bookingType,
            search: search,
            ...currentlyAppliedFilters,
          },
        },
      });
    }
  }, [skip, limit, currentlyAppliedFilters, search]);

  useEffect(() => {
    setCurrentPageNumber(Number(skip) - 1);
  }, [skip]);

  useEffect(() => {
    setRowsPerPage(Number(limit));
  }, [limit]);

  useEffect(() => {
    if (currentSelectedBooking) {
      dispatch({
        type: GET_BOOKING_DETAILS,
        payload: {
          params: {
            booking_id: currentSelectedBooking?.id,
          },
        },
      });
    }
  }, [currentSelectedBooking]);

  useEffect(() => {
    if (bookingTypeData === null) {
      dispatch({
        type: GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH,
      });
    }
  }, [bookingTypeData]);

  // useEffect(() => {
  //   dispatch({
  //     type: GET_CARD_DETAILS,
  //     payload: {
  //       params: {
  //         card_id: cardId,
  //       },
  //     },
  //   });

  //   dispatch({
  //     type: STATUS_FILTERS_LIST,
  //     payload: {
  //       params: {
  //         card_id: cardId,
  //         timelineFilter: timelineFilter,
  //         startDate: startDate,
  //         endDate: endDate,
  //       },
  //     },
  //   });
  // }, [location?.search]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_BOOKING_DETAILS_EMPTY,
      });

      dispatch({
        type: GET_BOOKING_GLOBAL_SEARCH_EMPTY,
      });
    };
  }, []);

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);

    const updatedParams = {
      ...currentParams,
      ...newParams,
    };

    const newUrl = queryString.stringify(updatedParams);

    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  const filterSubmitHandler = filters => {
    setCurrentlyAppliedFilters(filters);
    setCurrentActiveBookingAction(null);
    setCurrentSelectedBooking(null);
    dispatch({
      type: GET_BOOKING_DETAILS_EMPTY,
    });
    updateURLParams({ page: 1, ...filters });
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Airport Dashboard`) {
      if (Constant?.userTypes?.VENDOR_USER_V2 === Number(localStorage.getItem('userType'))) {
        history.push(`/v2/vendor-dashboard`);
      } else if (Constant?.userTypes?.ADMIN_USER_V2 === Number(localStorage.getItem('userType'))) {
        history.push(`/v2/dashboard`);
      }
    }
  };

  useEffect(() => {
    if (bookingsListLoading || bookingDetailsLoading || bookingTypeLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookingsListLoading, bookingDetailsLoading, bookingTypeLoading]);

  return (
    <div className="page-content">
      {loading && <Loader zIndex={true} />}

      {
        <BookingModals
          currentSelectedBooking={currentSelectedBooking}
          currentActiveBookingAction={currentActiveBookingAction}
          setCurrentActiveBookingAction={setCurrentActiveBookingAction}
        />
      }

      <Container fluid className="position-relative">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-1 align-items-center">
            <BreadcrumbTrail
              title={Constant?.userTypes?.VENDOR_USER_V2 === parseInt(localStorage.getItem('userType')) ? `My Bookings` : `Search Result`}
              navigationby={breadcrumNavigationHandler}
              navigation={[`Search Result`]}
              // isShowDate={true}
              isShowDate={Constant.userTypes.VENDOR_USER_V2 === Number(localStorage.getItem('userType'))}
            />
          </div>

          <GlobalFilter filterHandler={filterSubmitHandler} search={search} bookingType={bookingType} />
        </div>
        {search ? (
          <>
            <DataViews
              data={bookingsList}
              updateURLParams={updateURLParams}
              skip={currentPageNumber}
              limit={rowsPerPage}
              selectedBooking={currentSelectedBooking}
              setCurrentSelectedBooking={setCurrentSelectedBooking}
              setCurrentActiveBookingAction={setCurrentActiveBookingAction}
              titleText={'All Results'}
              bookingType="Approval Action"
            />

            {bookingDetails && (
              <Summary
                details={bookingDetails}
                currentSelectedBooking={currentSelectedBooking}
                setCurrentSelectedBooking={setCurrentSelectedBooking}
                setCurrentActiveBookingAction={setCurrentActiveBookingAction}
              />
            )}
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%', // Ensures it takes full height of the parent container
              textAlign: 'center',
              padding: '73px',
            }}
          >
            <img src={nofoundSvg} className="availability-tick" style={{ width: '27%' }} />
            <p className="fs-22" style={{ color: '#6F7594' }}>
              No Results Found
            </p>
          </div>
        )}
      </Container>
    </div>
  );
};

export default GlobalSearchBooking;
