import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { APPROVAL_ACTION, APPROVAL_ACTION_EMPTY, GET_REASON_BY_ACTIONS } from '../../../../../../../store/actions';

const ApprovalAction = ({ onClose, currentSelectedBooking, actionType, approvalRequestType }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [selectedRemark, setSelectedRemark] = useState(null);
  const [isShowError, setIsShowError] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { approvalAction, approvalActionLoading, remarksList, remarksListLoading } = useSelector(state => ({
    approvalAction: state?.ApprovalActionBooking?.data,
    approvalActionLoading: state?.ApprovalActionBooking?.loading,

    remarksList: state?.GetReasonByActions?.data,
    remarksListLoading: state?.GetReasonByActions?.loading,
  }));

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    if (label === `reason_id`) {
      setSelectedRemark(data);
    } else {
      setIsShowError(false);
    }
  };

  const handleSubmit = () => {
    if (selectedRemark) {
      dispatch({
        type: APPROVAL_ACTION,
        payload: {
          body: {
            action: 'reject', // approve/reject,
            booking_id: currentSelectedBooking?.id,
            reason_id: selectedRemark?.value, // only when action = reject
            approval_request_type: approvalRequestType?.value,
          },
        },
      });
    } else if (actionType === 'approve') {
      dispatch({
        type: APPROVAL_ACTION,
        payload: {
          body: {
            action: 'approve', // approve/reject,
            booking_id: currentSelectedBooking?.id,
            approval_request_type: approvalRequestType?.value,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (approvalAction !== null) {
      dispatch({
        type: APPROVAL_ACTION_EMPTY,
      });
      onClose();
    }

    return () => {
      dispatch({
        type: APPROVAL_ACTION_EMPTY,
      });
    };
  }, [approvalAction]);

  useEffect(() => {
    dispatch({
      type: GET_REASON_BY_ACTIONS,
      payload: {
        urlParams: {
          booking_type: 'airport_transfers',
          booking_status: currentSelectedBooking?.bookingStatus,
          action: approvalRequestType?.action,
          is_active: true,
        },
      },
    });
  }, [currentSelectedBooking]);

  return (
    <Modal isOpen={true} centered size="md">
      <ModalHeader toggle={onClose}></ModalHeader>

      <ModalBody>
        <p className="fs-22 text-center">Are you sure you want to {actionType} this Booking</p>
        {actionType === 'reject' && (
          <div className="mb-3" style={{ padding: '0 25px' }}>
            <Label htmlFor="name" className="form-label">
              Remarks
            </Label>
            <Select
              id="decline-remarks"
              name="decline-remarks"
              placeholder="Select reason for reject the booking"
              value={selectedRemark}
              options={remarksList?.reasons?.map((remark, index) => ({
                label: `${remark?.reason}`,
                value: `${remark?.id}`,
              }))}
              onChange={data => inputSelectHandler(`reason_id`, data)}
            />
            {isShowError && selectedRemark === null && <Error text="Kindly select reason for declining" ref={null} />}
          </div>
        )}
      </ModalBody>

      <div className="d-flex justify-content-center mb-4 gap-3">
        {!approvalActionLoading && (
          <Button color="primary" style={{ fontSize: '14px', width: '100px', borderRadius: '10px' }} onClick={handleSubmit}>
            Yes
          </Button>
        )}

        {approvalActionLoading && (
          <Button color="primary" style={{ fontSize: '14px', width: '100px', borderRadius: '10px' }}>
            <Spinner className="btn-replacer-spinner" />
          </Button>
        )}

        <Button color="white" style={{ fontSize: '14px', width: '100px', borderRadius: '10px', border: '1px solid #401589' }} onClick={onClose}>
          No
        </Button>
      </div>
    </Modal>
  );
};

export default ApprovalAction;
