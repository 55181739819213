import { useEffect, useState } from 'react';
import { UserIcon } from '../../../../Components/icons';
import { getUserV2Name } from '../../../Helpers/Helpers';
import { useDispatch, useSelector } from 'react-redux';
import { GET_USER_ROLES, GET_USER_TOKEN } from '../../../../store/actions';
import { useHistory } from 'react-router-dom';
import FullLoader from '../../../Common/Loader/CompleteLoader';

interface RootState {
  UserRoles?: {
    data?: any;
    loading?: boolean;
  };
  UserToken?: {
    data?: any;
    loading?: boolean;
  };
}
interface Role {
  id: string;
  name: string;
  description: string;
}

const RoleSelection = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedRole, setSelectedRole] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({ type: GET_USER_ROLES });
  }, [dispatch]);

  const { userRoles, userRolesLoading } = useSelector((state: RootState) => ({
    userRoles: state.UserRoles.data as { roles: Role[] } | null,
    userRolesLoading: state.UserRoles.loading,
  }));

  const { userToken, userTokenLoading } = useSelector((state: RootState) => ({
    userToken: state.UserToken.data as string | null,
    userTokenLoading: state.UserToken.loading,
  }));

  useEffect(() => {
    const selectedRole = JSON.parse(localStorage.getItem('selectedRole'));
    if (selectedRole) {
      history.push('/v2/dashboard');
    }
  }, []);

  useEffect(() => {
    if (userToken !== null && selectedRole) {
      history.push(`/v2/dashboard`);
    }
  }, [userToken, selectedRole, history]);

  useEffect(() => {
    if (userRolesLoading || userTokenLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [userRolesLoading, userTokenLoading]);

  const accessRole = (role: Role) => {
    localStorage.setItem('selectedRole', JSON.stringify(role));
    setSelectedRole(role.id);
    dispatch({
      type: GET_USER_TOKEN,
      payload: {
        urlParams: {
          role_id: role.id,
        },
      },
    });
  };

  return (
    <div className="page-content bg-3">
      <div className="container-fluid px-0 px-sm-5">
        <p className="mt-2 mt-sm-4" style={{ color: 'black', fontSize: '24px', fontWeight: '600' }}>
          Hi {getUserV2Name()}, choose a role to get started
        </p>
        {loading && <FullLoader />}
        <div className="mt-4 mt-sm-5">
          <div className="d-flex gap-3 flex-wrap">
            {userRoles?.roles?.map((value, key) => (
              <div className="card card-3 text-center" key={key} onClick={() => accessRole(value)}>
                <div className="m-2">
                  <UserIcon />
                </div>
                <p className="mt-3 fs-14 fw-semibold m-0">{value.name}</p>
                <p className="m-1 fw-light">{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleSelection;
