import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { ADD_REASON, ADD_REASON_EMPTY, UPDATE_REASON, UPDATE_REASON_EMPTY } from '../../../../../../store/actions';

const AddRemarks = ({ data, onCloseHandler, appliedName, appliedAction }) => {
  const dispatch = useDispatch();
  const [remark, setRemark] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { addReason, addReasonLoading, updateReason, updateReasonLoading } = useSelector(state => ({
    addReason: state?.AddReason?.data,
    addReasonLoading: state?.AddReason?.loading,

    updateReason: state?.UpdateReason?.data,
    updateReasonLoading: state?.UpdateReason?.loading,
  }));

  useEffect(() => {
    if (data) {
      setRemark(data?.reason);
    }
  }, [data]);

  const handleAddReason = () => {
    if (!remark.trim()) {
      setError('Please enter a reason.');
      return;
    }
    if (data) {
      dispatch({
        type: UPDATE_REASON,
        payload: {
          id: data?.id,
          data: {
            reason: remark,
          },
        },
      });
    } else {
      dispatch({
        type: ADD_REASON,
        payload: {
          data: {
            reason: remark,
            booking_type: 'airport_transfers',
            booking_status: appliedName?.id,
            action: appliedAction?.id,
          },
        },
      });
    }

    // onCloseHandler(); // Close modal after adding reason
  };

  useEffect(() => {
    if (addReason || updateReason) {
      onCloseHandler();
      setRemark('');
      setError('');
      dispatch({
        type: ADD_REASON_EMPTY,
      });
      dispatch({
        type: UPDATE_REASON_EMPTY,
      });
    }
  }, [addReason, updateReason]);

  useEffect(() => {
    if (addReasonLoading || updateReasonLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addReasonLoading, updateReasonLoading]);

  return (
    <Modal isOpen={true} centered size="md">
      <ModalHeader toggle={onCloseHandler}>{data ? 'Update Reason' : 'Add Reason'}</ModalHeader>
      <ModalBody>
        <div>
          <label>Reason</label>
          <textarea
            className="form-control"
            rows="3"
            placeholder="Enter your reason here..."
            value={remark}
            onChange={e => setRemark(e.target.value)}
          ></textarea>
          {error && <p className="text-danger mt-2">{error}</p>}
        </div>

        <Row>
          <Col md={12} className="mt-3 mb-4 text-center">
            {!loading && (
              <Button color="primary" style={{ fontSize: '14px', width: '160px' }} onClick={handleAddReason}>
                {data ? 'Update Reason' : 'Add Reason'}
              </Button>
            )}

            {loading && (
              <Button color="primary" style={{ fontSize: '14px', width: '160px' }}>
                <Spinner className="btn-replacer-spinner" />
              </Button>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default AddRemarks;
