import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import cogoToast from 'cogo-toast';
import { adminAPIURL, customHeaders } from '../../../../../../Containers/Config/index.js';
import apiCall from '../../../../apiCall.js';
import { ASSIGN_CHAUFFEUR, ASSIGN_CHAUFFEUR_ERROR, ASSIGN_CHAUFFEUR_SUCCESS } from '../../../actions.js';

function* assignChauffeur({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/assign-chauffeur`,
      method: 'POST',
      headers: {
        ...filteredHeaders,
      },
      data: {
        ...payload?.body,
      },
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: ASSIGN_CHAUFFEUR_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: ASSIGN_CHAUFFEUR_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: ASSIGN_CHAUFFEUR_ERROR, payload: error });
  }
}

export function* watchAssignChauffeur() {
  yield takeEvery(ASSIGN_CHAUFFEUR, assignChauffeur);
}

function* assignChauffeurSaga() {
  yield all([fork(watchAssignChauffeur)]);
}

export default assignChauffeurSaga;
