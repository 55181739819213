import { useDispatch } from 'react-redux';
import { Constant } from '../../../../../../Helpers/constant';
import { GET_BOOKING_DETAILS_EMPTY } from '../../../../../../../store/actions';

const StatusFilter = ({
  statusFilters,
  appliedStatusFilter,
  updateURLParams,
  setCurrentSelectedBooking,
  setCurrentActiveBookingAction,
  setWorkflowDisplayName,
  cardSummaryCount,
}) => {
  const dispatch = useDispatch();

  const getTotalCount = (data, key) => {
    if (data?.[key] && data?.[key]?.data && typeof data?.[key]?.data?.totalCount === 'number') {
      return data?.[key]?.data?.totalCount;
    }
    return 0;
  };

  console.log('statusFilters-', statusFilters);

  return (
    <>
      <div className="horizontal-scrollor">
        <div className="status-badge-container ">
          {statusFilters?.map((filter, index) => {
            return (
              <div
                className={`status-badge ${filter?.cardName === appliedStatusFilter ? 'active' : ''}`}
                key={index}
                onClick={() => {
                  updateURLParams({ skip: 1, limit: Constant?.TABLE_PAGE_SIZE, appliedName: filter?.cardName, appliedType: filter?.cardType });
                  setCurrentSelectedBooking(null);
                  setCurrentActiveBookingAction(null);
                  setWorkflowDisplayName(filter?.displayName);

                  dispatch({
                    type: GET_BOOKING_DETAILS_EMPTY,
                  });
                }}
              >
                <p className="status-badge-text">
                  {filter?.displayName}({getTotalCount(cardSummaryCount, filter?.cardName)}){/* ({filter?.bookingCount || 0}) */}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default StatusFilter;
