import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { LIST_SURGE_RULES, LIST_SURGE_RULES_EMPTY, RULE_DETAILS, RULE_DETAILS_EMPTY, TEMPLATE_DETAILS } from '../../../../../../store/actions';
import { Loader } from '../../../../../Common';
import BreadcrumbTrail from '../../../../../Common/BreadcrumbTrail';
import { Constant } from '../../../../../Helpers/constant';
import Table from './Components/Table/index';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Filter from './Components/Filter';
import Summary from './Components/Summary';

const RulesList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Parse URL query parameters
  const { page = 1, perPageLimit = Constant.TABLE_PAGE_SIZE } = queryString.parse(location.search);

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(Number(perPageLimit));
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Rules List
  const { listRules, listRulesLoading } = useSelector((state: any) => ({
    listRules: state?.GetSurgeRulesList?.data,
    listRulesLoading: state?.GetSurgeRulesList?.loading,
  }));

  // Rule Details
  const { ruleDetails, ruleDetailsLoading } = useSelector((state: any) => ({
    ruleDetails: state?.RuleDetails?.data,
    ruleDetailsLoading: state?.RuleDetails?.loading,
  }));

  useEffect(() => {
    const { page, perPageLimit } = queryString.parse(location.search);
    setCurrentPageNumber(Number(page || 1)); // Default to 1-based page
    setRowsPerPage(Number(perPageLimit || Constant.TABLE_PAGE_SIZE));
  }, [location.search]);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: LIST_SURGE_RULES,
      payload: {
        urlParams: {
          skip: (currentPageNumber - 1) * rowsPerPage,
          limit: rowsPerPage,
          ...currentlyAppliedFilters,
        },
      },
    });
  }, [currentlyAppliedFilters, currentPageNumber, rowsPerPage, dispatch]);

  useEffect(() => {
    // Focus on the Summary container when voucherBookingDetails is not null
    if (ruleDetails) {
      const summaryCard = document.getElementById('summary-card');
      if (summaryCard) {
        summaryCard.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [ruleDetails]);

  useEffect(() => {
    return () => {
      dispatch({
        type: LIST_SURGE_RULES_EMPTY,
      });
      dispatch({
        type: RULE_DETAILS_EMPTY,
      });
    };
  }, []);

  const handleRowsPerPageChange = (newRowsPerPage, page) => {
    updateURLParams({ page: 1, perPageLimit: newRowsPerPage });
  };

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);
    const updatedParams = {
      page: currentPageNumber,
      perPageLimit: rowsPerPage,
      ...currentParams,
      ...newParams,
    };

    const newUrl = queryString.stringify(updatedParams);

    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    if (params?.actionType === `VIEW`) {
      dispatch({
        type: RULE_DETAILS,
        payload: {
          data: {
            ruleId: params?.ruleDetails?.rule_id,
          },
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    updateURLParams({ page: pageNumber });
  };

  const filterSubmitHandler = filters => {
    setCurrentlyAppliedFilters(filters);
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {};

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (listRulesLoading || ruleDetailsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [listRulesLoading, ruleDetailsLoading]);

  return (
    <div className="page-content">
      {loading && <Loader />}

      <Container fluid className="position-relative">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
          <BreadcrumbTrail title="Rule List" navigationby={breadcrumNavigationHandler} navigation={[`Rule List`, `Ledger`]} />
          <Filter filterHandler={filterSubmitHandler} />
        </div>

        <Table
          data={listRules?.results}
          totalCount={listRules?.count}
          pageNumber={currentPageNumber}
          actionHandler={tableActionHandler}
          appliedFilters={() => {}}
          limit={perPageLimit}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handlePageChange={paginationHandler}
        />

        {ruleDetails && <Summary ruleDetails={ruleDetails} />}
      </Container>
    </div>
  );
};

export default RulesList;
