import React from 'react';
import { useDispatch } from 'react-redux';
import { Constant } from '../../../../../Helpers/constant';

const StatusFilter = ({ statusFilters, setAppliedName, appliedName, setAppliedAction, updateURLParams }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="horizontal-scrollor">
        <div className="status-badge-container ">
          {statusFilters?.map((filter, index) => {
            return (
              <div
                className={`status-badge ${filter?.name === appliedName?.name ? 'active' : ''}`}
                key={index}
                onClick={() => {
                  setAppliedName(filter);
                  setAppliedAction(null);
                  updateURLParams({ limit: Constant.TABLE_PAGE_SIZE, skip: 1 });
                  dispatch({
                    //    type: GET_BOOKING_DETAILS_EMPTY,
                  });
                }}
              >
                <p className="status-badge-text">
                  {filter?.name}
                  {/* ({getTotalCount(cardSummaryCount, filter?.cardName)}) */}
                  {/* ({filter?.bookingCount || 0}) */}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default StatusFilter;
