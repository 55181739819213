import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_ADIB_MONTHLY_SUMMARY, GET_ADIB_MONTHLY_SUMMARY_SUCCESS, GET_ADIB_MONTHLY_SUMMARY_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getAdibMonthlySummary({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/monthly-summary-adib`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'Project-Code': 'ADIB',
      },
      data: payload?.data,
    });

    if (response?.status === 200) {
      yield put({
        type: GET_ADIB_MONTHLY_SUMMARY_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_ADIB_MONTHLY_SUMMARY_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_ADIB_MONTHLY_SUMMARY_ERROR, payload: error });
  }
}

export function* watchGetAdibMonthlySummary() {
  yield takeEvery(GET_ADIB_MONTHLY_SUMMARY, getAdibMonthlySummary);
}

function* getAdibMonthlySummarySaga() {
  yield all([fork(watchGetAdibMonthlySummary)]);
}

export default getAdibMonthlySummarySaga;
