import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../../Common';
import { Container } from 'reactstrap';

import { Constant } from '../../../../Helpers/constant';

import { GET_BENEFIT_GROUPS_LIST, GET_BENEFIT_GROUPS_LIST_EMPTY } from '../../../../../store/application/actionType';
import NewBenefitGroupsManagement from './NewBenefitGroup';
import UpdateBenefitGroupsManagement from './UpdateBenefitGroup';
import CustomFilter from './BenefitGroup/Filters/CustomFilter';
import BenefitGroupsTable from './BenefitGroup/Table/BenefitGroupsTable';
import { filter } from 'lodash';

const initialFilters = {
  name: '',
  isActive: {
    label: `Active`,
    value: true,
  },
};

const BenefitGroupsManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isBenefitGroupShow, setIsBenefitGroupShow] = useState(true);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);

  const [isShowCreateJourney, setIsShowCreateJourney] = useState(false);
  const [isShowUpdateJourney, setIsShowUpdateJourney] = useState(false);
  const [currentSelectedBenefitGroup, setCurrentSelectedBenefitGroup] = useState(null);

  const [isShowFilters, setIsShowFilters] = useState(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(initialFilters);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Benefit Groups
  const { BenefitGroupsList, BenefitGroupsListLoading } = useSelector(state => ({
    BenefitGroupsList: state?.GetBenefitGroups?.data,
    BenefitGroupsListLoading: state?.GetBenefitGroups?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (BenefitGroupsList === null) {
      dispatch({
        type: GET_BENEFIT_GROUPS_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
            isActive:filter?.isActive?.value
          },
        },
      });
    }
  }, [BenefitGroupsList]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_BENEFIT_GROUPS_LIST_EMPTY,
      });
    };
  }, [dispatch]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedBenefitGroup(params?.details);

    if (params?.actionType === `UPDATE`) {
      setIsShowCreateJourney(false);
      setIsShowUpdateJourney(true);
      setIsBenefitGroupShow(false);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    // setCurrentPageNumber(pageNumber);
    console.log('pagination',currentlyAppliedFilters)
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
    dispatch({
      type: GET_BENEFIT_GROUPS_LIST,
      payload: {
        urlParam: {
            pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
         
          isActive:currentlyAppliedFilters?.isActive?.value,
          name:currentlyAppliedFilters?.name,
          createdAtFrom:currentlyAppliedFilters?.createdAt?.from ??null,
          createdAtTo:currentlyAppliedFilters?.createdAt?.to ?? null,

      }
    }
    });
  };

  /* ---------------------------- ADD NEW BENEFIT GROUP BUTTON HANDLER ---------------------------- */
  const addNewBenefitGroupButtonHandler = () => {
    setIsShowCreateJourney(true);
    setIsShowUpdateJourney(false);
    setIsBenefitGroupShow(false);
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (BenefitGroupsListLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [BenefitGroupsListLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Benefit Groups List`) {
      setIsBenefitGroupShow(true);
      setIsShowCreateJourney(false);
      setIsShowUpdateJourney(false);

      dispatch({
        type: GET_BENEFIT_GROUPS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    } else if (title === `Create Benefits`) {
      setIsBenefitGroupShow(false);
      setIsShowCreateJourney(true);
      setIsShowUpdateJourney(false);
    } else if (title === `Update Benefits`) {
      setIsBenefitGroupShow(false);
      setIsShowCreateJourney(false);
      setIsShowUpdateJourney(true);
    }
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    setIsShowFilters(false);
    setCurrentlyAppliedFilters(filters);
    setCurrentPageNumber(0);
    let urlParam={
       name: filters?.name === `` ? null : filters?.name,
       isActive: filters?.isActive?.value,
       // createdAt: filters?.createdAt === `` ? null : filters?.createdAt,
       createdAtFrom: filters?.createdAt === `` ? null : filters?.createdAt?.from,
       createdAtTo: filters?.createdAt === `` ? null : filters?.createdAt?.to,
       pageIndex: 0,
      }
      console.log('filter-->',urlParam)

    dispatch({
      type: GET_BENEFIT_GROUPS_LIST,
      payload: {
        urlParam
      },
    });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetHandler = () => {
    setIsShowFilters(false);
    setCurrentPageNumber(0);
    setCurrentlyAppliedFilters(initialFilters);
    dispatch({
      type: GET_BENEFIT_GROUPS_LIST_EMPTY,
    });
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {isBenefitGroupShow && (
            <>
              <BreadCrumb title="Benefit Groups List" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Benefit Groups List`]} />

              <CustomFilter
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
                show={isShowFilters}
                onCloseClick={() => setIsShowFilters(false)}
                resetHandler={resetHandler}
              />

              <BenefitGroupsTable
                data={BenefitGroupsList?.results}
                totalData={BenefitGroupsList?.count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                dataStartedFrom={currentPageIndex}
                actionHandler={tableActionHandler}
                addNewBenefitGroupBtnHandler={addNewBenefitGroupButtonHandler}
                filterBtnHandler={() => {
                  setIsShowFilters(true);
                }}
              />
            </>
          )}

          {isShowCreateJourney && (
            <>
              <BreadCrumb
                title="Create Benefit Group"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Benefit Groups List`, `Create Benefits`]}
              />
              <NewBenefitGroupsManagement />
            </>
          )}

          {isShowUpdateJourney && (
            <>
              <BreadCrumb
                title="Dump Download"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Benefit Groups List`, `Update Benefits`]}
              />
              <UpdateBenefitGroupsManagement data={currentSelectedBenefitGroup} breadcrumNavigation={breadcrumNavigationHandler} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default BenefitGroupsManagement;
