import classnames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

const Summary = ({ templateDetails, currentSelectedTemplateDetails }) => {
  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = useState('1');
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <Row>
      <Col xxl={12}>
        <Card>
          <div className="col-md-6">
            <Nav tabs className="mt-3 nav nav-tabs nav-tabs-custom nav-primary nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === '1',
                  })}
                  onClick={() => {
                    toggleCustom('1');
                  }}
                >
                  Rule Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === '2',
                  })}
                  onClick={() => {
                    toggleCustom('2');
                  }}
                >
                  Update Logs
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <TabContent activeTab={customActiveTab}>
            <TabPane tabId="1" id="home1">
              <Row>
                <Col xxl={12}>
                  <Card>
                    <CardBody className="p-4">
                      <Row>
                        <Col md={4}>
                          <div className="mb-3">
                            <Label className="d-flex text-muted">{'Name'}</Label>
                            <Label>{templateDetails?.name || `NA`}</Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-3">
                            <Label className="d-flex text-muted">{'Start Date'}</Label>
                            <Label>
                              {(currentSelectedTemplateDetails?.start_date !== '0001-01-01T00:00:00Z' &&
                                moment(currentSelectedTemplateDetails?.start_date).format('MMMM Do YYYY, h:mm A')) ||
                                `NA`}
                            </Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-3">
                            <Label className="d-flex text-muted">{'End Date'}</Label>
                            <Label>
                              {' '}
                              {(currentSelectedTemplateDetails?.end_date !== '0001-01-01T00:00:00Z' &&
                                moment(currentSelectedTemplateDetails?.end_date).format('MMMM Do YYYY, h:mm A')) ||
                                `NA`}
                            </Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-3">
                            <Label className="d-flex text-muted">{'Table'}</Label>
                            <Label>{templateDetails?.table_name || `NA`}</Label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <h5 className="mb-4" style={{ color: '#405189' }}>
                          Aggregator Details
                        </h5>

                        <Col md={4}>
                          <div className="mb-3">
                            <Label className="d-flex text-muted">{'Frequency'}</Label>
                            <Label>{templateDetails?.trigger?.frequency ? `${templateDetails?.trigger?.frequency} Minutes` : `NA`}</Label>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="2">
              <Row>
                <Col xxl={12}>
                  <Card>
                    <CardBody className="p-4">
                      <Row>
                        <Col md={4}>
                          <div className="mb-4 d-flex gap-2">
                            <div style={{ color: '#405189' }}>
                              <i className="mdi mdi-24px mdi-circle-slice-8" />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                              <p className="fw-semibold" style={{ color: '#405189', marginBottom: '0 !important' }}>
                                Created On
                              </p>
                              <p style={{ fontSize: '11px' }}>
                                Date - {(templateDetails?.created_at && moment(templateDetails?.created_at).format('MMMM Do YYYY, h:mm A')) || `NA`}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Card>
      </Col>
    </Row>
  );
};

export default Summary;
