import React, { useEffect, useState } from 'react';
import { Button, Container } from 'reactstrap';
import BreadcrumbTrail from '../../../../Common/BreadcrumbTrail';
import { useHistory, useLocation } from 'react-router-dom';
import CreateAnnoucement from './Components/Form/CreateAnnoucement';
import ManageAnnouncement from './Components/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Constant } from '../../../../Helpers/constant';
import queryString from 'query-string';
import { GET_ANNOUNCEMENT_GOLBE, GET_ANNOUNCEMENT_GOLBE_EMPTY } from '../../../../../store/actions';
import { Loader } from '../../../../Common';

const Annoucement = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Parse URL query parameters
  const { page = 1, perPageLimit = Constant.TABLE_PAGE_SIZE } = queryString.parse(location.search);

  const [currentPageNumber, setCurrentPageNumber] = useState(Number(page) - 1); // `page` is 1-based
  const [rowsPerPage, setRowsPerPage] = useState(Number(perPageLimit));
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isManageAnnouncement, setIsManageAnnouncement] = useState(false);
  const [currentSelectAnnouncementDetails, setCurrentSelectAnnouncementDetails] = useState(null);

  console.log('is Management Announcement', isManageAnnouncement);

  // Get
  const { getAnnouncementForGlobe, getAnnouncementForGlobeLoading } = useSelector((state: any) => ({
    getAnnouncementForGlobe: state?.GetAnnouncementForGlobe?.data,
    getAnnouncementForGlobeLoading: state?.GetAnnouncementForGlobe?.loading,
  }));

  const { updateAnnouncement } = useSelector((state: any) => ({
    updateAnnouncement: state?.UpdateAnnouncementForGlobeToyota?.data,
  }));

  useEffect(() => {
    // Sync state with URL
    const { page, perPageLimit } = queryString.parse(location.search);
    setCurrentPageNumber(Number(page || 1) - 1); // Default to 1-based page
    setRowsPerPage(Number(perPageLimit || Constant.TABLE_PAGE_SIZE));
  }, [location.search]);

  useEffect(() => {
    if (isManageAnnouncement) {
      dispatch({
        type: GET_ANNOUNCEMENT_GOLBE,
        payload: {
          urlParams: {
            skip: currentPageNumber * rowsPerPage,
            limit: rowsPerPage,
            ...currentlyAppliedFilters,
          },
        },
      });
    }
  }, [isManageAnnouncement, currentlyAppliedFilters, currentPageNumber, rowsPerPage, dispatch]);

  useEffect(() => {
    if (getAnnouncementForGlobeLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getAnnouncementForGlobeLoading]);

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);

    const updatedParams = {
      page: currentPageNumber + 1, // Default to current page
      perPageLimit: rowsPerPage, // Default to current rowsPerPage
      ...currentParams, // Merge existing query parameters
      ...newParams, // Override with new parameters
    };

    const newUrl = queryString.stringify(updatedParams);

    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_ANNOUNCEMENT_GOLBE_EMPTY,
      });
    };
  }, []);

  useEffect(() => {
    if (updateAnnouncement) {
      setIsManageAnnouncement(true);
      dispatch({
        type: GET_ANNOUNCEMENT_GOLBE_EMPTY,
      });
      updateURLParams({ page: 1 });
    }
  }, [updateAnnouncement]);

  const paginationHandler = pageNumber => {
    updateURLParams({ page: pageNumber + 1 });
  };

  const handleRowsPerPageChange = newRowsPerPage => {
    updateURLParams({ page: 1, perPageLimit: newRowsPerPage });
  };
  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = (title: string) => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    }
  };

  const tableActionHandler = params => {
    if (params?.actionType === `UPDATE`) {
      setCurrentSelectAnnouncementDetails(params?.announcementDetails);
      setIsManageAnnouncement(false);
    }
  };
  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          <div id="main-voucher-container">
            <BreadcrumbTrail title="Annoucements" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Annoucements`]} />
            <Button
              className="btn btn-success btn-md mb-3 mt-2 px-5"
              color="primary"
              onClick={() => {
                setIsManageAnnouncement(!isManageAnnouncement);
                setCurrentSelectAnnouncementDetails(null);
              }}
            >
              {isManageAnnouncement ? 'Add Annoucement' : 'Manage Annoucement'}
            </Button>
          </div>
          {!isManageAnnouncement && (
            <CreateAnnoucement annoucementDetails={currentSelectAnnouncementDetails} setIsManageAnnouncement={setIsManageAnnouncement} />
          )}
          {isManageAnnouncement && (
            <ManageAnnouncement
              data={getAnnouncementForGlobe?.results}
              totalCount={getAnnouncementForGlobe?.count}
              pageNumber={currentPageNumber}
              appliedFilters={currentlyAppliedFilters}
              limit={rowsPerPage}
              handleRowsPerPageChange={handleRowsPerPageChange}
              handlePageChange={paginationHandler}
              actionHandler={tableActionHandler}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default Annoucement;
