import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweDiscoveryUrl } from '../../../../../Containers/Config';
import { Constant } from '../../../../../Containers/Helpers/constant';
import { TEMPLATE_DETAILS, TEMPLATE_DETAILS_ERROR, TEMPLATE_DETAILS_SUCCESS } from '../../../actionType';
import apiCall from '../../../apiCall';

function* templateDetails({ payload }) {
  console.log('payloaddd', payload);
  try {
    const response = yield call(apiCall, {
      url: `${thriweDiscoveryUrl}/v1/admin/rules/workflow-templates/${payload?.data?.templateId}`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: TEMPLATE_DETAILS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: TEMPLATE_DETAILS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: TEMPLATE_DETAILS_ERROR, payload: error });
  }
}

export function* watchtemplateDetails() {
  yield takeEvery(TEMPLATE_DETAILS, templateDetails);
}

function* templateDetailsSaga() {
  yield all([fork(watchtemplateDetails)]);
}

export default templateDetailsSaga;
