import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Constant } from '../../../../../../../Helpers/constant';
import moment from 'moment';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { formattedWithComma, hasPermission, isDateIsGreaterThanLast7Days, isTimePassed } from '../../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../../Helpers/Constants/permissions';
import { TablePagination } from '../../../../../../../Common';

const Table = props => {
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Bookings');
  const [currentFilterBookingStatus, setCurrentFilterBookingStatus] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [columns, setColumns] = useState([]);
  const [clearRow, setClearRow] = useState(false);

  useEffect(() => {
    getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));
    if (props?.data?.length > 0) {
      setBookingData(
        props?.data?.map(booking => {
          return { ...booking };
        }),
      );
      setSelectedBookings([]);
      setClearRow(!clearRow);
    } else {
      setData([]);
      setSelectedBookings([]);
      setBookingData([]);
    }
    // if (!props?.appliedFilters?.statusRange) {
    //   setSelectedBookings([]);
    // }
    setCurrentFilterBookingStatus(props?.appliedFilters?.statusRange || []);
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => row?.serialNumber,
        },
        {
          name: 'Booking Id',
          width: '140px',
          cell: row => (
            <span
              className="cursor-pointer text-decoration-underline"
              style={{ color: '#3577f1' }}
              onClick={() => actionBtnHandler(`VIEW`, row?.data)}
            >
              {row?.data?.booking_id}
            </span>
          ),
        },

        {
          name: 'Customer Type',
          width: '150px',
          wrap: true,
          selector: row => row?.customerType,
        },
        {
          name: 'Parent Booking ID',
          width: '160px',
          wrap: true,
          selector: row => row?.parent_id,
        },
        {
          name: 'Golf Course',
          width: '300px',
          wrap: true,
          selector: row => row?.facilityName,
        },
        {
          name: 'Date of Play',
          width: '120px',
          wrap: true,
          selector: row => row?.dateOfPlay,
        },
        {
          name: 'Time of Play',
          width: '120px',
          wrap: true,
          selector: row => row?.timeOfPlay,
        },
        {
          name: 'Name',
          width: '140px',
          wrap: true,
          selector: row => row?.userName,
        },
        {
          name: 'Mobile No',
          width: '140px',
          wrap: true,
          selector: row => row?.userMobile,
        },
        {
          name: 'Email',
          wrap: true,
          width: '220px',
          selector: row => row?.userEmail,
        },
        {
          name: 'Handicap',
          width: '100px',
          wrap: true,
          selector: row => row?.handicap,
        },
        {
          name: 'Guest 1',
          width: '140px',
          wrap: true,
          selector: row => row?.guest1Name,
        },
        {
          name: 'Guest 2',
          width: '140px',
          wrap: true,
          selector: row => row?.guest2Name,
        },
        {
          name: 'Guest 3',
          width: '140px',
          wrap: true,
          selector: row => row?.guest3Name,
        },
        {
          name: 'Preferred Date 1',
          width: '140px',
          wrap: true,
          selector: row => row?.preferredDate1,
        },
        {
          name: 'Preferred Time 1',
          width: '140px',
          wrap: true,
          selector: row => row?.preferredTime1,
        },
        {
          name: 'Preferred Date 2',
          width: '140px',
          wrap: true,
          selector: row => row?.preferredDate2,
        },
        {
          name: 'Preferred Time 2',
          width: '140px',
          wrap: true,
          selector: row => row?.preferredTime2,
        },
        {
          name: 'Benefit Type',
          width: '150px',
          wrap: true,
          selector: row => row?.benefitType,
        },
        {
          name: 'Partner',
          wrap: true,
          selector: row => row?.partnerName,
        },
        {
          name: 'Service',
          width: '200px',
          wrap: true,
          selector: row => row?.serviceName,
        },
        {
          name: 'Payment ID',
          width: '200px',
          wrap: true,
          selector: row => row?.paymentId,
        },
        {
          name: 'Membership ID',
          width: '160px',
          wrap: true,
          selector: row => row?.membershipId,
        },
        // {
        //   name: 'Confirmed Date Of Play',
        //   width: '180px',
        //   wrap: true,
        //   selector: row => row?.confirmedDate,
        // },
        // {
        //   name: 'Confirmed Time Of Play',
        //   width: '180px',
        //   wrap: true,
        //   selector: row => row?.confirmedTime,
        // },
        // {
        //   name: 'Confirmed At',
        //   width: '170px',
        //   wrap: true,
        //   selector: row => row?.confirmedAt,
        // },
        {
          name: 'Created At',
          width: '170px',
          wrap: true,
          selector: row => row?.createdAt,
        },
        {
          name: 'Updated At',
          width: '170px',
          wrap: true,
          selector: row => row?.updatedAt,
        },
        {
          name: 'Booking Status',
          width: '180px',
          cell: row => (
            <span
              className={`booking_status badge break-word bg-${
                Constant.BOOKING_STATUSES_V2.filter(status => status?.bookingStatus === row?.booking_status)[0]?.bgColor
              }`}
            >
              {row?.booking_status}
            </span>
          ),
        },
        {
          name: 'Actions',
          width: '250px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column m-4">
              <>
                {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_BOOKING_V2_UPDATE) &&
                  row?.originalData?.booking_status === Constant?.BOOKING_STATUS_V2.PENDING && (
                    <>
                      <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`CONFIRM`, row?.originalData);
                        }}
                        disabled={isTimePassed(
                          row?.originalData?.golf_booking_details?.date_of_service,
                          row?.originalData?.golf_booking_details?.time_of_service,
                        )}
                        style={{ width: '150px' }}
                      >
                        Confirm
                      </button>

                      {/* <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`UPDATE`, row?.originalData);
                        }}
                        disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        style={{ width: '150px' }}
                      >
                        Update
                      </button> */}

                      <button
                        className="btn btn-sm btn-danger mb-1"
                        onClick={() => {
                          actionBtnHandler(`CANCEL`, row?.originalData);
                        }}
                        disabled={isTimePassed(
                          row?.originalData?.golf_booking_details?.date_of_service,
                          row?.originalData?.golf_booking_details?.time_of_service,
                        )}
                        style={{ width: '150px' }}
                      >
                        Cancel
                      </button>
                    </>
                  )}

                {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_BOOKING_V2_UPDATE) &&
                  row?.originalData?.booking_status === Constant?.BOOKING_STATUS_V2.CONFIRM && (
                    <>
                      <button
                        className="btn btn-sm btn-danger mb-1"
                        onClick={() => {
                          actionBtnHandler(`CANCEL`, row?.originalData);
                        }}
                        disabled={isTimePassed(
                          row?.originalData?.golf_booking_details?.date_of_service,
                          row?.originalData?.golf_booking_details?.time_of_service,
                        )}
                        style={{ width: '150px' }}
                      >
                        Cancel
                      </button>
                    </>
                  )}

                {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_BOOKING_V2_UPDATE) &&
                  [
                    Constant.BOOKING_STATUS_V2.SHORT_NOTICE_CANCELLED_BY_USER,
                    Constant.BOOKING_STATUS_V2.CANCELLED_BY_THRIWE,
                    Constant.BOOKING_STATUS_V2.CANCELLED_BY_USER,
                    Constant.BOOKING_STATUS_V2.SHORT_NOTICE_CANCELLED_BY_USER,
                    Constant.BOOKING_STATUS_V2.SHORT_NOTICE_CANCELLED_BY_THRIWE,
                    Constant.BOOKING_STATUS_V2.BOOKING_SHORTNOTICE_CANCELED_BY_THRIWE_WITH_QUOTA,
                    Constant.BOOKING_STATUS_V2.PAYMENT_FAILED,
                  ].includes(row?.originalData?.booking_status) && (
                    <>
                      {/* <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                        }}
                        disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        style={{ width: '150px' }}
                      >
                        Resend Email
                      </button> */}
                    </>
                  )}
              </>
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);
      setData(
        bookingData?.map((data, index) => {
          return {
            serialNumber: `${props?.pageNumber * Constant.TABLE_PAGE_SIZE + index + 1 - Constant.TABLE_PAGE_SIZE}.`,
            data: data || `NA`,
            index: index,
            customerType: data?.guest?.length <= 0 ? `PRIMARY` : `GUEST`,
            parent_id: data?.parent_id || `NA`,
            facilityName: data?.booking_display?.benefitFacility?.name || `NA`,
            preferredDate1: data?.golf_booking_details?.preferred_date_of_service1
              ? moment(new Date(`${data?.golf_booking_details?.preferred_date_of_service1}`)).format('DD-MM-YYYY')
              : 'NA',
            preferredTime1: data?.golf_booking_details?.preferred_time_of_service1
              ? moment(`${data?.golf_booking_details?.preferred_time_of_service1}`, 'HH:mm').format('h:mm A')
              : `NA`,
            preferredDate2: data?.golf_booking_details?.preferred_date_of_service2
              ? moment(new Date(`${data?.golf_booking_details?.preferred_date_of_service2}`)).format('DD-MM-YYYY')
              : 'NA',
            preferredTime2: data?.golf_booking_details?.preferred_time_of_service2
              ? moment(`${data?.golf_booking_details?.preferred_time_of_service2}`, 'HH:mm').format('h:mm A')
              : `NA`,
            dateOfPlay: data?.golf_booking_details?.date_of_service
              ? moment(new Date(`${data?.golf_booking_details?.date_of_service}`)).format('DD-MM-YYYY')
              : 'NA',
            timeOfPlay: data?.golf_booking_details?.time_of_service
              ? moment(`${data?.golf_booking_details?.time_of_service}`, 'HH:mm').format('h:mm A')
              : `NA`,
            userName: data?.user?.firstName || data?.user?.lastName ? `${data?.user?.firstName || ''} ${data?.user?.lastName}` : `NA`,
            userMobile: data?.user?.phoneNumber || `NA`,
            userEmail: data?.user?.email || `NA`,
            handicap: data?.golf_booking_details?.handicap || `NA`,
            guest1Name: data?.guest?.[0]?.name || `NA`,
            guest2Name: data?.guest?.[1]?.name || `NA`,
            guest3Name: data?.guest?.[2]?.name || `NA`,
            bookingForSomeoneElse: data?.bookingForSomeoneElse === 0 ? 'Self' : 'Someone',
            benefitType: Constant?.benefitType[data?.benefitType ?? 0] || `NA`,
            bookingType: data?.bookingType || `NA`,
            partnerName: data?.booking_display?.benefitPartner?.name || 'NA',
            serviceName: data?.booking_display?.displayName || 'NA',
            paymentId: data?.payment?.paymentId || `NA`,
            membershipId: data?.membership_id || `NA`,
            confirmedDate: data?.confirmedDate ? moment(new Date(`${data?.confirmedDate}`)).format('DD-MM-YYYY') : 'NA',
            confirmedTime: data?.confirmedTime ? moment(`${data?.confirmedTime}`, 'HH:mm').format('h:mm A') : `NA`,
            // confirmedAt: confirmedAt,
            createdAt: data?.created_at ? moment(new Date(`${data?.created_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            updatedAt: data?.updated_at ? moment(new Date(`${data?.updated_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            booking_status: data?.booking_status,
            originalData: data,
          };
        }),
      );
    } else {
      setData([]);
      setColumns([]);
    }
  }, [bookingData]);

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `EDIT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `VIEW`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `PROCESS`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CANCEL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `RESEND_EMAIL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM_WITH_DATA`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{currentFilteredBookingTitle}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )}
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma((props?.pageNumber - 1) * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma((props?.pageNumber - 1) * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber - 1} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
