import classnames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

const Summary = ({ ruleDetails }) => {
  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = useState('1');
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <Row id="summary-card">
      <Col xxl={12}>
        <Card>
          {/* <CardHeader>
            <h4>ENBD</h4>
          </CardHeader> */}

          <div className="col-md-6">
            <Nav tabs className="mt-3 nav nav-tabs nav-tabs-custom nav-primary nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === '1',
                  })}
                  onClick={() => {
                    toggleCustom('1');
                  }}
                >
                  Rule Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === '2',
                  })}
                  onClick={() => {
                    toggleCustom('2');
                  }}
                >
                  Update Logs
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === '3',
                  })}
                  onClick={() => {
                    toggleCustom('3');
                  }}
                >
                  Summary
                </NavLink>
              </NavItem> */}
            </Nav>
          </div>

          <TabContent activeTab={customActiveTab}>
            <TabPane tabId="1" id="home1">
              <Row>
                <Col xxl={12}>
                  <Card>
                    <CardBody className="p-4">
                      <Row>
                        <Col md={4}>
                          <div className="mb-4">
                            <Label className="d-flex text-muted">{'Name'}</Label>
                            <Label>{ruleDetails?.name || `NA`}</Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-4">
                            <Label className="d-flex text-muted" style={{ fontWeight: '400' }}>
                              {'Start Date'}
                            </Label>
                            <Label>{(ruleDetails?.start_time && moment(ruleDetails?.start_time).format('MMMM Do YYYY, h:mm A')) || `NA`}</Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-4">
                            <Label className="d-flex text-muted" style={{ fontWeight: '400' }}>
                              {'End Date'}
                            </Label>
                            <Label> {(ruleDetails?.end_time && moment(ruleDetails?.end_time).format('MMMM Do YYYY, h:mm A')) || `NA`}</Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-4">
                            <Label className="d-flex text-muted" style={{ fontWeight: '400' }}>
                              {'Table'}
                            </Label>
                            <Label>{ruleDetails?.table_name || `NA`}</Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-4">
                            <Label className="d-flex text-muted" style={{ fontWeight: '400' }}>
                              {'Column'}
                            </Label>
                            <Label>{ruleDetails?.where?.[0]?.column || `NA`}</Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-4">
                            <Label className="d-flex text-muted" style={{ fontWeight: '400' }}>
                              {'Where Operator'}
                            </Label>
                            <Label>{ruleDetails?.where?.[0]?.operator || `NA`}</Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-4">
                            <Label className="d-flex text-muted" style={{ fontWeight: '400' }}>
                              {'Value'}
                            </Label>
                            <Label>{ruleDetails?.where?.[0]?.value || `NA`}</Label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <h5 className="mb-4" style={{ color: '#405189' }}>
                          Aggregator Details
                        </h5>
                        <Col md={4}>
                          <div className="mb-4">
                            <Label className="d-flex text-muted" style={{ fontWeight: '400' }}>
                              {'Aggregator Type'}
                            </Label>
                            <Label>{ruleDetails?.aggregator?.[0]?.type || `NA`}</Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-4">
                            <Label className="d-flex text-muted" style={{ fontWeight: '400' }}>
                              {'Aggregator Operatoe'}
                            </Label>
                            <Label>{ruleDetails?.aggregator?.[0]?.operator || `NA`}</Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-4">
                            <Label className="d-flex text-muted" style={{ fontWeight: '400' }}>
                              {'Value'}
                            </Label>
                            <Label>{ruleDetails?.aggregator?.[0]?.value || `NA`}</Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-4">
                            <Label className="d-flex text-muted" style={{ fontWeight: '400' }}>
                              {'Frequency'}
                            </Label>
                            <Label>{ruleDetails?.trigger?.frequency || `NA`}</Label>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="2">
              <Row>
                <Col xxl={12}>
                  <Card>
                    <CardBody className="p-4">
                      <Row>
                        <Col md={4}>
                          <div className="mb-4 d-flex gap-2">
                            <div style={{ color: '#405189' }}>
                              <i className="mdi mdi-24px mdi-circle-slice-8" />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                              <p className="fw-semibold" style={{ color: '#405189', marginBottom: '0 !important' }}>
                                Created On
                              </p>
                              <p style={{ fontSize: '11px' }}>
                                Date - {(ruleDetails?.created_at && moment(ruleDetails?.created_at).format('MMMM Do YYYY, h:mm A')) || `NA`}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Card>
      </Col>
    </Row>
  );
};

export default Summary;
