import { Container } from 'reactstrap';
import BreadcrumbTrail from '../../../../../Common/BreadcrumbTrail';
import CreateSurgeWorkflowForm from './Components/Form/Form';
import { useHistory } from 'react-router-dom';

const SurgeWorkflow = () => {
  const history = useHistory();

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Template Management`) {
      history.push('/surge/templates');
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <BreadcrumbTrail
          title="Workflow Template"
          navigationby={breadcrumNavigationHandler}
          navigation={[`Dashboard`, `Template Management`, `Workflow Template`]}
        />
        <CreateSurgeWorkflowForm />
      </Container>
    </div>
  );
};
export default SurgeWorkflow;
