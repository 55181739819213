import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { adminAPIURL, customHeaders } from '../../../../../Containers/Config/index.js';
import apiCall from '../../../apiCall.js';
import {
  GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH,
  GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH_ERROR,
  GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH_SUCCESS,
} from '../../actions.js';

function* getBookingTypeForGlobalSearch({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/booking-type-list-for-global-search`,
      method: 'GET',
      headers: {
        ...filteredHeaders,
      },
      params: {
        ...payload?.params,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH_ERROR, payload: error });
  }
}

export function* watchGetBookingTypeForGlobalSearch() {
  yield takeEvery(GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH, getBookingTypeForGlobalSearch);
}

function* getBookingTypeForGlobalSearchSaga() {
  yield all([fork(watchGetBookingTypeForGlobalSearch)]);
}

export default getBookingTypeForGlobalSearchSaga;
